import React, { useRef, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CountTemplateProps } from '../Types/order-validation-config.types';

const CountTemplate: React.FC<CountTemplateProps> = ({
  node,
  loading,
  onUpdate,
}) => {
  const toast = useRef<Toast>(null);
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik({
    initialValues: {
      count: node.data.count || 0,
    },
    validationSchema: Yup.object({
      count: Yup.number()
        .min(1, 'Count must be at least 1')
        .required('Count cannot be empty'),
    }),
    onSubmit: values => {
      if (!values.count) {
        toast.current?.show({
          severity: 'error',
          summary: 'Validation Error',
          detail: 'Count cannot be empty',
          life: 3000,
        });
        return;
      }

      const updatedNode = {
        ...node,
        data: { ...node.data, count: values.count },
      };

      onUpdate(updatedNode);
      setIsEditing(false);
    },
  });

  return (
    <div>
      <Toast ref={toast} />
      {node.children && node.children.length > 0 ? null : isEditing ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="p-inputgroup flex-1">
            <span className="p-float-label flex align-items-center">
              <InputNumber
                autoFocus
                className="w-full p-inputtext-sm"
                inputClassName="border-noround-right"
                value={formik.values.count}
                onChange={e => formik.setFieldValue('count', e.value ?? 0)}
                placeholder="Enter Number"
                min={1}
                maxLength={12}
                disabled={loading}
              />
              <label>Count</label>
            </span>
            <Button
              icon={`pi ${loading ? 'pi-spin pi-spinner' : 'pi-check'}`}
              iconPos="left"
              tooltip="Save"
              tooltipOptions={{ position: 'top' }}
              type="submit"
            />
          </div>
          {formik.errors.count && (
            <small className="p-error">{formik.errors.count}</small>
          )}
        </form>
      ) : (
        <div
          className={`pl-2 pt-1 align-items-center w-7rem border-1 border-200 hover:border-500 border-round h-2rem cursor-text ${
            loading ? 'pointer-events-none' : ''
          }`}
          onClick={() => setIsEditing(true)}
        >
          {formik.values.count || 0}
        </div>
      )}
    </div>
  );
};

export default CountTemplate;
