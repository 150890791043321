import { createSlice } from '@reduxjs/toolkit';

// when we get into the services architecture
// import {} from '../services';

const initialState = {
  isLoading: true,
  permissionList: [],
  tenantMapping: [],
};

const permissionsListSlice = createSlice({
  name: 'permissionsList',
  initialState: initialState,
  reducers: {
    clearState(state) {
      state.permissionList = [];
    },
    setPermission(state, { payload }) {
      state.permissionList = payload || [];
      state.isLoading = false;
    },
    setUserTenants(state, { payload }) {
      state.tenantMapping = payload || [];
    }
  },
});

export const { clearState, setPermission, setUserTenants } = permissionsListSlice.actions;

export const permissionsListReducer = permissionsListSlice.reducer;
