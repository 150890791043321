import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { updateInstallerSchedule } from '../services/calendar.service';
import { validateReschedule } from '../../../utils/reschedule.helper';
import { momentTz } from '../../../utils/Helpers';

type TProps = {
  confirmDialog: {
    button1Text: string;
    button2Text: string;
    header: string;
    isOpen: boolean;
    subtitle: string;
    title: string;
  };
  setConfirmDialog: (data: unknown) => void;
  calenderRefreshHandler: () => void;
  infoData: {
    resourceDetails: {
      resourceData: {
        id: string;
      };
    };
    rowData: {
      project_id: string;
      project_status: string;
    };
    endTime: Date;
    startTime: Date;
  };
  dropTimeOffRequestIds: [] | undefined;
  resourceData: [{ id: number; title: string }];
  setDialogProps: () => null;
  setIsOpen: () => null;
};

const ConfirmationSchedulingDialog = ({
  confirmDialog,
  setConfirmDialog,
  calenderRefreshHandler,
  infoData,
  dropTimeOffRequestIds,
  resourceData,
  setDialogProps,
  setIsOpen,
}: TProps) => {
  const { projectStatusType } = useSelector(
    (state: {
      projectStatusType: { projectStatusType: [{ [key: string]: unknown }] };
    }) => state.projectStatusType
  );
  const continueScheduling = async () => {
    const projectData = infoData.rowData;
    const statusId =
      projectStatusType &&
      projectStatusType.length > 0 &&
      projectStatusType?.find(
        item => item?.status === projectData?.project_status
      );

    const updateObj = {
      installerId: parseInt(infoData.resourceDetails.resourceData.id),
      projectId: parseInt(infoData.rowData.project_id),
      projectStartDateTime: momentTz(infoData.startTime),
      projectEndDateTime: momentTz(infoData.endTime),
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: (statusId && statusId?.status_id) || '',
      user_time_off_request_ids: dropTimeOffRequestIds,
    };
    setConfirmDialog({
      ...confirmDialog,
      button1TextDisabled: true,
      button2TextDisabled: true,
      button2Text: 'Please wait...',
    });
    const technicianName = resourceData.find(
      ({ id }) => id == updateObj.installerId
    );
    validateReschedule(
      {
        ...updateObj,
        technician_name: technicianName?.title || '',
      },
      setDialogProps,
      (value: unknown) => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: value,
        });
      },
      async (action: unknown) => {
        try {
          if (action) {
            const response = await updateInstallerSchedule(updateObj);
            if (response) {
              setConfirmDialog({
                ...confirmDialog,
                isOpen: false,
                button1TextDisabled: false,
                button2TextDisabled: false,
                button2Text: '',
              });
            }
          }
        } catch (error) {
          console.log(error, '...error continueScheduling');
        } finally {
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
            button1TextDisabled: false,
            button2TextDisabled: false,
            button2Text: '',
          });
          calenderRefreshHandler();
        }
      }
    );
  };

  const footerContent = (
    <div>
      <Button
        label={`${confirmDialog.button1Text || 'Cancel'}`}
        onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        size="small"
        outlined
      />
      <Button
        label={`${confirmDialog.button2Text}`}
        onClick={continueScheduling}
        size="small"
      />
    </div>
  );
  return (
    <>
      <Dialog
        header="Confirm Scheduling"
        visible={confirmDialog.isOpen}
        style={{ width: '30vw' }}
        footer={footerContent}
        closable={false}
        pt={{
          header: {
            className: 'bg-teal-50 border-bottom-1 py-3',
          },
          content: {
            className: 'pb-2',
          },
        }}
        onHide={() => {
          setConfirmDialog({ ...confirmDialog, isOpen: false });
        }}
      >
        <p className="mt-3">{confirmDialog?.title}</p>
      </Dialog>
    </>
  );
};

export default ConfirmationSchedulingDialog;
