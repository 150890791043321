import { REACT_APP_ENABlE_ONLY_FOR_PF_USER, REACT_APP_ENABLE_PUSH_NOTIFICATION } from '../../../../constants/envConstants';
import ChangePassword from '../../Auth/Login/ChangePassword';
import PushNotificationSettings from './PushNotificationSettings';

const isPFUser = JSON.parse(localStorage.getItem("isPFUser") ?? "false") ?? false;
const pushNotificationEnabled = REACT_APP_ENABlE_ONLY_FOR_PF_USER === false ? REACT_APP_ENABLE_PUSH_NOTIFICATION : isPFUser;
export const userSettingTabs = [
  { header: 'Change Password', component: ChangePassword, isVisible: true },
  { header: 'Push Notifications', component: PushNotificationSettings, isVisible: pushNotificationEnabled },
];
