export const processFilters = value => {
  let selectedFilterObj = {};
  value?.filters?.forEach(item => {
    const filterValueArray = item?.filter_value
      ?.split(',')
      ?.map(value => parseInt(value));
    switch (item?.filterType?.type_name) {
      case 'store':
        selectedFilterObj.storeNumber = filterValueArray;
        break;
      case 'projectCategory':
        selectedFilterObj.projectCategoryId = filterValueArray;
        break;
      case 'projectType':
        selectedFilterObj.projectTypeId = filterValueArray;
        break;
      case 'status':
        selectedFilterObj.statusId = filterValueArray;
        break;
      case 'sourceStatus':
        selectedFilterObj.imsStatus = filterValueArray;
        break;
      case 'district':
        selectedFilterObj.districtId = [...item?.filter_value?.split(',')];
        break;
      case 'workroom':
        selectedFilterObj.workroomId = filterValueArray;
        break;
      case 'customer':
        selectedFilterObj.customerId = filterValueArray;
        break;
      case 'technician':
        selectedFilterObj.installerIds = filterValueArray;
        break;
      default:
        break;
    }
  });

  return selectedFilterObj;
};
