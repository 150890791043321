/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFBlockUI from '../../../shared/PFPrime/PFBlockUI';
import { validateSlotForm } from '../../../../utils/validation';
import { getWorkScheduleSlotsByHourId } from '../services/user-form-availability.service';

const AddEditUserJobCoverageSlotOfflineDialog = ({
  isVisible,
  setIsVisible,
  slotDetail,
  workHours,
  setCoverageSlotValues,
  setCopyToAllCoverageValues,
  formik: form,
  isClone,
}) => {
  if (
    !form?.values?.slots ||
    !slotDetail?.project_typeId ||
    !slotDetail?.project_categoryId ||
    !slotDetail?.coverage_by ||
    !slotDetail.day
  ) {
    return <></>;
  }
  const { userId } = useSelector(state => state.userForm);
  const uniqueKey = `${slotDetail.project_typeId}_${slotDetail.project_categoryId}_${slotDetail.coverage_by}`;

  const prevCategorySlots = form?.values?.slots?.[uniqueKey] || [];
  const [loading, setLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    visible: false,
    props: {},
  });
  const [currentSlot, setCurrentSlot] = useState(slotDetail);
  const [copyToCoverageValuesCheck, setCopyToCoverageValuesCheck] = useState(
    {}
  );
  const toast = useRef(null);
  const validation = Yup.object().shape({
    slot_name: Yup.string().nullable(true),
    start_time: Yup.date().nullable(true),
    end_time: Yup.date().nullable(true),
  });
  const coverageIndex = useMemo(
    () =>
      prevCategorySlots.findIndex(
        ({ key }) => key === slotDetail.coverage_value
      ),
    [form?.values?.slots?.[uniqueKey], slotDetail?.coverage_value]
  );
  const resetCopyCoverageSlotFlag = () => {
    setCopyToAllCoverageValues(prev => {
      if (Object.keys(prev).length) {
        const copyToHashMap = {};
        for (const key in prev.to) {
          copyToHashMap[key] = false;
        }
        return {
          ...prev,
          to: copyToHashMap,
        };
      }
      return {};
    });
  };
  const setFormSlots = slotsParam => {
    form.setFieldValue('slots', {
      ...form.values.slots,
      [uniqueKey]: prevCategorySlots.length
        ? prevCategorySlots.map((element, i) =>
            i === coverageIndex ? slotsParam : element
          )
        : [slotsParam],
    });
    setCopyToCoverageValuesCheck({});
  };
  const getPrevCoverageSlotsObj = () => {
    return coverageIndex > -1 && prevCategorySlots.length
      ? prevCategorySlots[coverageIndex]
      : {
          key: slotDetail?.coverage_value,
          slots: {},
        };
  };
  const handleSubmit = async values => {
    /* STRUCTURE
    {
      slots: {
        '12_22_34': [
          {
            key: 'abc',
            slots: {
              Monday: [{
                slot_id: +new Date(),
                slot_name: values.slot_name,
                start_time: dayjs(values.start_time).format('HH:mm:00'),
                end_time: dayjs(values.end_time).format('HH:mm:00'),
              }]
            }
          }
        ]
      }
    } */
    try {
      const prevCoverageSlotsObj = getPrevCoverageSlotsObj();
      if (
        !validateSlotForm(
          formik,
          prevCoverageSlotsObj?.slots?.[slotDetail.day] || [],
          workHours,
          currentSlot
        )
      ) {
        return;
      }
      const payload = {
        slot_id: +new Date(),
        slot_name: values.slot_name,
        start_time: dayjs(values.start_time).format('HH:mm:00'),
        end_time: dayjs(values.end_time).format('HH:mm:00'),
      };
      if (currentSlot?.slot_id) {
        const daySlots = prevCoverageSlotsObj.slots[slotDetail.day];
        if (Array.isArray(daySlots) && daySlots.length) {
          const daySlotIndex = daySlots.findIndex(
            ({ slot_id }) => slot_id == currentSlot.slot_id
          );
          if (daySlotIndex > -1) {
            daySlots[daySlotIndex] = {
              ...payload,
              slot_id: currentSlot.slot_id,
            };
            prevCoverageSlotsObj.slots[slotDetail.day] =
              structuredClone(daySlots);
            setFormSlots(prevCoverageSlotsObj);
            toast.current.show({
              severity: 'success',
              summary: `Slot (${payload.slot_name}) updated successfully`,
              life: 2000,
            });
          }
        }
      } else {
        if (prevCoverageSlotsObj.slots[slotDetail.day]) {
          prevCoverageSlotsObj.slots[slotDetail.day].push(payload);
        } else {
          prevCoverageSlotsObj.slots = {
            ...prevCoverageSlotsObj.slots,
            [slotDetail.day]: [payload],
          };
        }
        setFormSlots(prevCoverageSlotsObj);
        toast.current.show({
          severity: 'success',
          summary: `Slot (${payload.slot_name}) added successfully`,
          life: 2000,
        });
      }
      resetCopyCoverageSlotFlag();
      if (!currentSlot?.slot_id) {
        setCoverageSlotValues(prev => {
          const updatedCoverageSlotValues = [];
          prev.forEach(eachSlot => {
            if (eachSlot.action.coverage_value === currentSlot.coverage_value) {
              updatedCoverageSlotValues.push({
                ...eachSlot,
                action: {
                  ...eachSlot.action,
                  [currentSlot.day]: eachSlot.action[currentSlot.day] + 1,
                },
              });
            } else {
              updatedCoverageSlotValues.push(eachSlot);
            }
          });
          return updatedCoverageSlotValues;
        });
      }
      formik.resetForm();
      setCurrentSlot(slotDetail);
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: currentSlot,
    validationSchema: validation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const handleHide = () => {
    setIsVisible(false);
  };
  const handleEdit = data => {
    setCurrentSlot({
      ...data,
      project_typeId: slotDetail?.project_typeId,
      project_categoryId: slotDetail?.project_categoryId,
      coverage_by: slotDetail?.coverage_by,
      coverage_value: slotDetail?.coverage_value,
      coverage_name: slotDetail?.coverage_name,
      day: slotDetail?.day,
      start_time: new Date(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${data.start_time}`
      ),
      end_time: new Date(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${data.end_time}`
      ),
    });
  };
  const handleDelete = async data => {
    setLoading(true);
    try {
      const prevCoverageSlotsObj = getPrevCoverageSlotsObj();
      const daySlots = prevCoverageSlotsObj.slots[slotDetail.day];
      if (Array.isArray(daySlots) && daySlots.length) {
        const remainingDaySlots = daySlots.filter(
          ({ slot_id }) => slot_id !== data.slot_id
        );
        prevCoverageSlotsObj.slots[slotDetail.day] = remainingDaySlots;
        setFormSlots(prevCoverageSlotsObj);
        resetCopyCoverageSlotFlag();
        setCoverageSlotValues(prev => {
          const updatedCoverageSlotValues = [];
          prev.forEach(eachSlot => {
            if (eachSlot.action.coverage_value === currentSlot.coverage_value) {
              updatedCoverageSlotValues.push({
                ...eachSlot,
                action: {
                  ...eachSlot.action,
                  [currentSlot.day]: remainingDaySlots.length,
                },
              });
            } else {
              updatedCoverageSlotValues.push(eachSlot);
            }
          });
          return updatedCoverageSlotValues;
        });
        if (currentSlot?.slot_id === data.slot_id) {
          setCurrentSlot(slotDetail);
        }
        toast.current.show({
          severity: 'success',
          summary: `Slot (${data.slot_name}) removed successfully`,
          life: 2000,
        });
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const handlePull = async () => {
    try {
      setLoading(true);
      const dayWorkHourDetail = workHours.find(
        ({ day }) => day === slotDetail.day
      );
      if (dayWorkHourDetail?.hour_id) {
        const apiResponse = await getWorkScheduleSlotsByHourId(
          userId,
          dayWorkHourDetail.hour_id
        );
        if (apiResponse?.status && Array.isArray(apiResponse?.data)) {
          if (apiResponse.data.length) {
            const prevCoverageSlotsObj = getPrevCoverageSlotsObj();
            prevCoverageSlotsObj.slots[slotDetail.day] = apiResponse.data;
            setFormSlots(prevCoverageSlotsObj);
            resetCopyCoverageSlotFlag();
            if (!currentSlot?.slot_id) {
              setCoverageSlotValues(prev => {
                const updatedCoverageSlotValues = [];
                prev.forEach(eachSlot => {
                  if (
                    eachSlot.action.coverage_value ===
                    currentSlot.coverage_value
                  ) {
                    updatedCoverageSlotValues.push({
                      ...eachSlot,
                      action: {
                        ...eachSlot.action,
                        [currentSlot.day]: apiResponse.data.length,
                      },
                    });
                  } else {
                    updatedCoverageSlotValues.push(eachSlot);
                  }
                });
                return updatedCoverageSlotValues;
              });
            }
            toast.current.show({
              severity: 'success',
              summary: `Slots pulled successfully`,
              life: 2000,
            });
          } else {
            toast.current.show({
              severity: 'error',
              summary: `No slots has been configured for ${slotDetail.day} Work Schedule`,
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: 'error',
            summary:
              apiResponse.message || 'Error occurred while pulling record',
            life: 3000,
          });
        }
      }
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleCopy = (eachCoverageValue, value, name) => {
    if (value) {
      const prevCoverageSlotsObj = getPrevCoverageSlotsObj();
      const daySlots = prevCoverageSlotsObj.slots[slotDetail.day];
      setCopyToCoverageValuesCheck({
        ...copyToCoverageValuesCheck,
        [eachCoverageValue]: value,
      });
      const currentCoverageIndex = prevCategorySlots.findIndex(
        ({ key }) => key === eachCoverageValue
      );
      const currentCoverageSlotsObj =
        currentCoverageIndex > -1
          ? prevCategorySlots[currentCoverageIndex]
          : {
              key: eachCoverageValue,
              slots: {},
            };
      currentCoverageSlotsObj.slots = {
        ...currentCoverageSlotsObj.slots,
        [slotDetail.day]: structuredClone(daySlots),
      };
      form.setFieldValue('slots', {
        ...form.values.slots,
        [uniqueKey]:
          currentCoverageIndex > -1
            ? prevCategorySlots.map(element =>
                element.key === eachCoverageValue
                  ? currentCoverageSlotsObj
                  : element
              )
            : [...prevCategorySlots, currentCoverageSlotsObj],
      });
      setCoverageSlotValues(prev => {
        const updatedCoverageSlotValues = [];
        prev.forEach(eachSlot => {
          if (eachSlot.action.coverage_value === eachCoverageValue) {
            updatedCoverageSlotValues.push({
              ...eachSlot,
              action: {
                ...eachSlot.action,
                [slotDetail.day]:
                  currentCoverageSlotsObj.slots[slotDetail.day].length,
              },
            });
          } else {
            updatedCoverageSlotValues.push(eachSlot);
          }
        });
        return updatedCoverageSlotValues;
      });
      resetCopyCoverageSlotFlag();
      toast.current.show({
        severity: 'success',
        summary: `Slots copied successfully to ${name}`,
        life: 3000,
      });
    }
  };
  const setPullDialogProps = action => {
    if (action) {
      setDialogProps({
        visible: true,
        props: {
          message: (
            <div>
              Please confirm if you would like to pull all slots of{' '}
              <b>{currentSlot.day} Work Schedule</b> to{' '}
              <b>{currentSlot.day} Job Coverage</b>?
              <br />
              On click confirm, all the current slots of{' '}
              <b>{currentSlot.day} Job Coverage</b> will be deleted.
            </div>
          ),
          header: 'Pull Slot Confirmation',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: handlePull,
          rejectLabel: 'Cancel',
          acceptLabel: 'Confirm',
          onHide: () => setPullDialogProps(false),
        },
      });
    } else {
      setDialogProps({
        visible: false,
        props: {},
      });
    }
  };
  const setCopyDialogProps = (action, eachCoverage, checked, name) => {
    if (action) {
      setDialogProps({
        visible: true,
        props: {
          message: (
            <div>
              Please confirm if you would like to copy all slots of{' '}
              <b>{currentSlot.coverage_name} </b> to <b>{name}</b>?
              <br />
              On click confirm, all the current slots of{' '}
              <b>{name} Job Coverage</b> will be deleted.
            </div>
          ),
          header: 'Copy Slot Confirmation',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => handleCopy(eachCoverage, checked, name),
          rejectLabel: 'Cancel',
          acceptLabel: 'Confirm',
          onHide: () => setCopyDialogProps(false),
        },
      });
    } else {
      setDialogProps({
        visible: false,
        props: {},
      });
    }
  };

  const actionTemplate = slot => {
    return (
      <div className="flex justify-content-left align-items-center">
        {loading ? (
          <Skeleton className="w-full" height="2rem" />
        ) : (
          <>
            <PFButton
              severity=" "
              icon="pi pi-pencil"
              size="small"
              tooltip="Edit"
              tooltipOptions={{ position: 'top' }}
              rounded={true}
              text={true}
              onClick={() => handleEdit(slot)}
            />
            <PFButton
              severity=" "
              icon="pi pi-trash"
              size="small"
              tooltip="Remove"
              tooltipOptions={{ position: 'top' }}
              className="text-red-700"
              rounded={true}
              text={true}
              onClick={() => handleDelete(slot)}
            />
          </>
        )}
      </div>
    );
  };
  const startTimeTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : slot?.start_time ? (
      dayjs(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${slot?.start_time}`
      ).format('hh:mm A')
    ) : (
      ''
    );
  };
  const slotNameTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : (
      slot?.slot_name
    );
  };
  const endTimeTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : slot?.end_time ? (
      dayjs(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${slot?.end_time}`
      ).format('hh:mm A')
    ) : (
      ''
    );
  };
  const copyToCoverageValues = useMemo(() => {
    return slotDetail?.copyToCoverageValues &&
      !Array.isArray(slotDetail?.copyToCoverageValues) &&
      typeof slotDetail?.copyToCoverageValues == 'object'
      ? Object.keys(slotDetail?.copyToCoverageValues).filter(
          val => val !== slotDetail.coverage_value
        )
      : [];
  }, [slotDetail]);
  const tableSlots = getPrevCoverageSlotsObj();
  const op = useRef(null);
  return (
    <Dialog
      header={`${currentSlot?.slot_id ? 'Edit' : 'Add'} User Job Coverage Slot`}
      visible={isVisible}
      onHide={handleHide}
      style={{
        overflow: 'auto',
      }}
      className="w-10 lg:w-8"
    >
      <Toast ref={toast} />
      <PFBlockUI blocked={loading}>
        <div className="w-full flex">
          <div className="w-full flex flex-column gap-3 flex-wrap mt-2 max-w-35rem">
            <div className="w-full p-float-label flex flex-column">
              <PFInputText
                className="w-full"
                id="day"
                name="day"
                value={currentSlot?.day}
                disabled
              />
              <label htmlFor="day">Day</label>
            </div>
            <div className="w-full p-float-label flex flex-column">
              <PFInputText
                autoComplete="off"
                id="slot_name"
                name="slot_name"
                value={formik.values?.slot_name || ''}
                onChange={e =>
                  formik.setFieldValue('slot_name', e.target.value)
                }
                className={`w-full ${formik.errors.slot_name ? 'border-red-300' : ''}`}
                aria-describedby="slot_name_error"
              />
              <label htmlFor="slot_name">
                Slot Name<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.slot_name && (
                <span
                  id="slot_name_error"
                  className="text-red-400 text-xs pl-2"
                >
                  {formik.errors.slot_name}
                </span>
              )}
            </div>
            <div className="w-full p-float-label flex flex-column">
              <Calendar
                id="start_time"
                name="start_time"
                timeOnly
                showTime
                hourFormat="12"
                value={formik.values?.start_time}
                onChange={e => formik.setFieldValue('start_time', e.value)}
                aria-describedby="start_time_error"
                className="w-full"
                inputClassName={
                  formik.errors.start_time ? 'border-red-300' : ''
                }
              />
              <label htmlFor="start_time">
                Start Time<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.start_time && (
                <span
                  id="start_time_error"
                  className="text-red-400 text-xs pl-2"
                >
                  {formik.errors.start_time}
                </span>
              )}
            </div>
            <div className="w-full p-float-label flex flex-column">
              <Calendar
                id="end_time"
                name="end_time"
                timeOnly
                showTime
                hourFormat="12"
                value={formik.values?.end_time}
                onChange={e => formik.setFieldValue('end_time', e.value)}
                // onBlur={() => validateInput('end_time', formik.values.end_time)}
                aria-describedby="end_time_error"
                className="w-full"
                inputClassName={formik.errors.end_time ? 'border-red-300' : ''}
              />
              <label htmlFor="end_time">
                End Time<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.end_time && (
                <span id="end_time_error" className="text-red-400 text-xs pl-2">
                  {formik.errors.end_time}
                </span>
              )}
            </div>
            {formik.errors.exist && (
              <span id="exist_error" className="w-full text-red-400 text-xs">
                {formik.errors.exist}
              </span>
            )}
            <div className="w-full flex justify-content-end gap-3">
              {currentSlot?.slot_id && (
                <PFButton
                  label="Cancel"
                  size="small"
                  outlined
                  onClick={() => setCurrentSlot(slotDetail)}
                  disabled={loading}
                />
              )}
              <PFButton
                label="Save"
                size="small"
                onClick={formik.handleSubmit}
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  loading ||
                  !formik.values.slot_name ||
                  !formik.values.start_time ||
                  !formik.values.end_time
                }
              />
            </div>
          </div>
          <Divider layout="vertical" />
          <DataTable
            value={tableSlots?.slots?.[slotDetail.day] || []}
            emptyMessage="No slots found"
            className="w-full"
            size="small"
            scrollHeight="flex"
            scrollable
            tableStyle={{
              minWidth: 500,
            }}
            header={
              <div className="flex justify-content-between align-items-center">
                <h3 className="w-20rem w-max-20rem">
                  {currentSlot?.coverage_name
                    ? `(${currentSlot?.coverage_name}) `
                    : ''}
                  Slot List
                </h3>
                <div className="flex gap-4 align-items-center ">
                  {(tableSlots?.slots?.[slotDetail.day] || []).length > 0 &&
                  copyToCoverageValues.length ? (
                    <>
                      <OverlayPanel ref={op}>
                        <div className="flex gap-2 align-items-center flex-column justify-content-start">
                          {copyToCoverageValues.map((eachCoverage, index) => {
                            return (
                              <div
                                key={`${index}_${eachCoverage}`}
                                className="flex w-full"
                              >
                                <Checkbox
                                  inputId={`${index}_${eachCoverage}`}
                                  name="coverage_value"
                                  onChange={e =>
                                    setCopyDialogProps(
                                      true,
                                      eachCoverage,
                                      e.checked,
                                      slotDetail.copyToCoverageValues[
                                        eachCoverage
                                      ]
                                    )
                                  }
                                  checked={
                                    copyToCoverageValuesCheck[eachCoverage]
                                  }
                                  disabled={
                                    copyToCoverageValuesCheck[eachCoverage]
                                  }
                                />
                                <label
                                  htmlFor={`${index}_${eachCoverage}`}
                                  className={`ml-2 ${copyToCoverageValuesCheck[eachCoverage] ? 'opacity-40' : ''}`}
                                >
                                  {
                                    slotDetail.copyToCoverageValues[
                                      eachCoverage
                                    ]
                                  }
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </OverlayPanel>
                      <i
                        className="material-icons-sharp md-30 md-content_copy hover:text-xl cursor-pointer ml-2 copy-day-slot"
                        onClick={e => op.current.toggle(e)}
                        data-pr-tooltip={`Copy ${slotDetail.day} slots To`}
                        data-pr-position="top"
                        data-pr-my="center right-8"
                        // style={{ fontSize: '2rem', cursor: 'pointer' }}
                      ></i>
                      <Tooltip target=".copy-day-slot" />
                    </>
                  ) : null}
                  {Array.isArray(workHours) && workHours.length > 0 ? (
                    <PFButton
                      className="slot-btn"
                      label="Copy From"
                      size="small"
                      onClick={() => setPullDialogProps(true)}
                      tooltip={`Copy All Slots from Work Schedule - ${currentSlot.day}`}
                      tooltipOptions={{ position: 'top' }}
                    />
                  ) : null}
                </div>
              </div>
            }
          >
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="action"
              header="Action"
              body={actionTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="slot_name"
              header="Slot Name"
              body={slotNameTemplate}
              style={{ maxWidth: 70, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="start_time"
              header="Start Time"
              body={startTimeTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="end_time"
              header="End Time"
              body={endTimeTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
          </DataTable>
          {dialogProps.visible && (
            <ConfirmDialog
              visible={dialogProps.visible}
              {...dialogProps.props}
            />
          )}
        </div>
      </PFBlockUI>
    </Dialog>
  );
};

export default AddEditUserJobCoverageSlotOfflineDialog;
