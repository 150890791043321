// **** React Imports ****
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
// **** External Utilities ****
import { Paper, Grid as MuiGrid, Checkbox, Button } from '@material-ui/core';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
  TableBandHeader,
} from '@devexpress/dx-react-grid-material-ui';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';

// **** Custom Components ****
import PageHeader from '../../shared/PageHeader/PageHeader';
import { Alert } from '../../shared/Alerts/Alert';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import {
  ROLE_EDIT_DISABLE_ID,
  PERMISSION_TYPES,
  ROLES,
} from '../../../constants';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import { getPermissionsList, saveRolePermissions } from './Permissions.service';
import { getRolesList } from './RoleManagement.service';

// **** Styles/Images/Icons ****
import { useStyles } from './Permissions.styles';

const getChildRows = (row, rootRows) => (row ? row.items : rootRows);
const DEFAULT_PERMISSION_IDS = [50, 52, 55, 194, 270, 284, 433, 434];
const Permissions = () => {
  const urlSearch = window.location.search;
  const urlParams = new URLSearchParams(urlSearch);
  const urlRoleName = urlParams.get('rolename');
  const classes = useStyles();
  const { role_id } = useParams();
  const [searchQuery] = useState({
    limit: null,
    offset: 0,
    sortDirection: '',
  });
  const [rolesCount, setRolesCount] = useState();
  const [rolesData, setRolesData] = useState([]);

  useEffect(() => {
    getRolesList(searchQuery, setLoading, setRolesData, setRolesCount);
  }, [searchQuery]);

  const roleArray = rolesData?.filter(item => item?.role_id == role_id)[0];
  const isSystemRole = roleArray?.is_system_role;
  const isSaveEnable = !new Set(ROLE_EDIT_DISABLE_ID).has(+role_id);
  const checkForState = (parent_id, key, key_status) => {
    let disabledStatus = true;
    if (parent_id === undefined) {
      disabledStatus =
        key === null ? false : key_status === true ? false : true;
    } else if (key === null || parent_id === true) {
      disabledStatus = false;
    }
    return disabledStatus;
  };

  const rolesBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'User Management',
      link: '/admin/usermanagement',
    },
    {
      text: 'Roles',
      link: '/admin/rolemanagement',
    },
    {
      text: urlRoleName ? urlRoleName : roleArray?.role_name,
      link: '/admin/rolemanagement',
    },
    {
      text: 'Permissions',
    },
  ];

  const [loading, setLoading] = React.useState(false);
  // State to populate service data
  const [permissionsData, setPermissionsData] = React.useState([]);
  const { alert, setAlert } = useAlerts();
  const [reloadList, setReloadList] = React.useState(false);
  const [inputTextValue, setInputTextValue] = React.useState('');
  const [textBoxField, setTextBoxField] = React.useState([]);
  const [itemResponse, setItemResponse] = React.useState([]);
  const toast = useRef(null);

  const childItems = items => {
    return items?.map(item => {
      const viewPermission = item?.menu_permissions?.find(
        it => it?.permission_name === 'view'
      );
      const addPermission = item?.menu_permissions?.find(
        it => it?.permission_name === 'add'
      );
      const modifyPermission = item?.menu_permissions?.find(
        it => it?.permission_name === 'modify'
      );
      const deletePermission = item?.menu_permissions?.find(
        it => it?.permission_name === 'delete'
      );
      const userAssignedPermission = item?.menu_permissions?.find(
        it => it?.permission_name === PERMISSION_TYPES.USER_ASSIGNED
      );

      const eleTypePermission = item?.menu_permissions?.find(
        it => it?.element_type === 'checkbox'
      );

      const permissionValuePermission = item?.menu_permissions?.find(
        it => it?.permission_value
      );
      return {
        menu_name: item?.menu_name,
        fe_menu_id: item?.fe_menu_id,
        parent_id: item?.parent_id,
        view: viewPermission?.permission_id ?? null,
        view_status: viewPermission?.permission_found > 0 ? true : false,
        add: addPermission?.permission_id ?? null,
        add_status: addPermission?.permission_found > 0 ? true : false,
        modify: modifyPermission?.permission_id ?? null,
        modify_status: modifyPermission?.permission_found > 0 ? true : false,
        delete: deletePermission?.permission_id ?? null,
        delete_status: deletePermission?.permission_found > 0 ? true : false,
        user_assigned: userAssignedPermission?.permission_id ?? null,
        user_assigned_status:
          userAssignedPermission?.permission_found > 0 ? true : false,
        element_type:
          eleTypePermission?.element_type === 'checkbox'
            ? 'checkbox'
            : 'textbox',
        permission_value: permissionValuePermission?.permission_value
          ? permissionValuePermission?.permission_value
          : 0,
        items: childItems(item?.children ? item?.children : []),
      };
    });
  };

  const parentItems = permissionsData.map(data => {
    const viewPermissionParent = data?.menu_permissions?.find(
      it => it?.permission_name === 'view'
    );
    const addPermissionParent = data?.menu_permissions?.find(
      it => it?.permission_name === 'add'
    );
    const modifyPermissionParent = data?.menu_permissions?.find(
      it => it?.permission_name === 'modify'
    );
    const deletePermissionParent = data?.menu_permissions?.find(
      it => it?.permission_name === 'delete'
    );

    const userAssignedPermission = data?.menu_permissions?.find(
      it => it?.permission_name === PERMISSION_TYPES.USER_ASSIGNED
    );

    const eleTypePermissionParent = data?.menu_permissions?.find(
      it => it?.element_type === 'checkbox'
    );
    const permissionValuePermissionParent = data?.menu_permissions?.find(
      it => it?.permission_value
    );
    return {
      menu_name: data?.menu_name,
      fe_menu_id: data?.fe_menu_id,
      parent_id: data?.parent_id,
      view: viewPermissionParent?.permission_id ?? null,
      view_status: viewPermissionParent?.permission_found > 0 ? true : false,
      add: addPermissionParent?.permission_id ?? null,
      add_status: addPermissionParent?.permission_found > 0 ? true : false,
      modify: modifyPermissionParent?.permission_id ?? null,
      modify_status:
        modifyPermissionParent?.permission_found > 0 ? true : false,
      delete: deletePermissionParent?.permission_id ?? null,
      delete_status:
        deletePermissionParent?.permission_found > 0 ? true : false,
      user_assigned: userAssignedPermission?.permission_id ?? null,
      user_assigned_status:
        userAssignedPermission?.permission_found > 0 ? true : false,
      element_type:
        eleTypePermissionParent?.element_type === 'checkbox'
          ? 'checkbox'
          : 'textbox',
      permission_value: permissionValuePermissionParent?.permission_value
        ? permissionValuePermissionParent?.permission_value
        : 0,
      items: childItems(data?.children),
    };
  });

  useEffect(() => {
    setItemResponse(parentItems);
  }, [permissionsData]);

  const handleInputChange = (e, data, permissionName) => {
    const permissionsObj = [...permissionsData];

    const parentElement = permissionsObj?.find(
      item => item?.fe_menu_id === data?.parent_id
    );
    const val = e?.value;

    if (parentElement >= 0) {
      const childElement = permissionsObj[parentElement].children?.findIndex(
        item => item?.fe_menu_id === data?.fe_menu_id
      );

      if (childElement >= 0) {
        for (
          let i = 0;
          i <
          permissionsObj[parentElement]?.children[childElement]
            ?.menu_permissions?.length;
          i++
        ) {
          if (
            permissionsObj[parentElement].children[childElement]
              .menu_permissions[i].permission_name === permissionName
          ) {
            permissionsObj[parentElement].children[
              childElement
            ].menu_permissions[i].permission_value = val;
          }
        }
      }
    }

    setPermissionsData(permissionsObj);
  };

  React.useEffect(() => {
    if (role_id) {
      getPermissionsList(role_id, setLoading, setPermissionsData);
    }
  }, [role_id]);
  // Table Columns with header value
  const [columns] = React.useState([
    { name: 'roleName', title: 'Role Name' },
    {
      name: 'view',
      title: (
        <MuiGrid container>
          <MuiGrid item>View / Allow</MuiGrid>
        </MuiGrid>
      ),
    },
    {
      name: 'add',
      title: (
        <MuiGrid container>
          <MuiGrid item>Add</MuiGrid>
        </MuiGrid>
      ),
    },
    {
      name: 'modify',
      title: (
        <MuiGrid container>
          <MuiGrid item>Modify</MuiGrid>
        </MuiGrid>
      ),
    },
    {
      name: 'delete',
      title: (
        <MuiGrid container>
          <MuiGrid item>Delete</MuiGrid>
        </MuiGrid>
      ),
    },
    {
      name: 'userAssigned',
      title: (
        <MuiGrid container>
          <MuiGrid item>User Assigned</MuiGrid>
        </MuiGrid>
      ),
    },
  ]);
  // Table title
  const [columnBands] = React.useState([
    {
      title: 'Permissions',
      children: [
        { columnName: 'roleName' },
        { columnName: 'view' },
        { columnName: 'add' },
        { columnName: 'delete' },
        { columnName: 'modify' },
        { columnName: 'userAssigned' },
      ],
    },
  ]);
  // State object to capture the value and action of each checkboxes
  const [checkBoxState, setCheckBoxState] = React.useState({});
  // Array to push all the existing permissions for a role
  var existingPermissionArray = [];
  // State array to set values if existing permissions are removed
  const [updatedExistingPermission, setUpdatedExistingArray] = React.useState(
    []
  );
  // Recursive function to create sub-menus
  const subMenuArray = (subMenuData, parentData, superData) => {
    const subMenuItemsArray = subMenuData.map((data, index) => {
      /** Initially, set all the existing permissions in a array  */
      data?.view &&
        data?.view_status === true &&
        existingPermissionArray.push(data?.view);
      data?.add &&
        data?.add_status === true &&
        existingPermissionArray.push(data?.add);
      data?.modify &&
        data?.modify_status === true &&
        existingPermissionArray.push(data?.modify);
      data?.delete &&
        data?.delete_status === true &&
        existingPermissionArray.push(data?.delete);
      data?.search &&
        data?.search_status === true &&
        existingPermissionArray.push(data?.search);

      data?.modify_filters &&
        data?.modify_filters_status === true &&
        existingPermissionArray.push(data?.modify_filters);

      data?.approval &&
        data?.approval_status === true &&
        existingPermissionArray.push(data?.approval);

      data?.user_assigned &&
        data?.user_assigned_status === true &&
        existingPermissionArray.push(data?.user_assigned);
      return {
        roleName: data.menu_name,
        view:
          data?.view !== null &&
          (data?.element_type === 'checkbox' ? (
            <Checkbox
              index={`${parentData?.view}_${data?.view}`}
              className={`${superData?.view}_${parentData?.view}_${data?.view}`}
              value={data?.view}
              checked={
                data?.view &&
                (checkBoxState[data?.view] !== undefined
                  ? checkBoxState[data?.view]
                  : data?.view_status === true
                    ? true
                    : false) &&
                !checkForState(
                  checkBoxState[parentData?.view],
                  parentData?.view,
                  parentData?.view_status
                )
              }
              onClick={(event, value) => {
                const childNode = document.querySelectorAll(
                  `span[class*='${data?.view}_']`
                );
                childNode.forEach(el =>
                  setCheckBoxState(prevState => {
                    return {
                      ...prevState,
                      [el?.attributes?.index?.value?.split('_')[1]]:
                        event.target.checked,
                    };
                  })
                );
                setCheckBoxState(prevState => {
                  return { ...prevState, [data?.view]: event.target.checked };
                });
                //This condition is to remove existing permission
                if (!event.target.checked) {
                  const newUpdatedArray =
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ) !== -1 &&
                    existingPermissionArray.splice(
                      existingPermissionArray?.indexOf(
                        parseInt(event.target.value)
                      ),
                      1
                    );
                  updatedExistingPermission.length && newUpdatedArray.length
                    ? setUpdatedExistingArray(prevState => [
                        ...prevState,
                        ...newUpdatedArray,
                      ])
                    : setUpdatedExistingArray(newUpdatedArray);
                }
              }}
              disabled={
                !data?.view ||
                checkForState(
                  checkBoxState[parentData?.view],
                  parentData?.view,
                  parentData?.view_status
                ) ||
                ((urlRoleName === 'Admin' ||
                  urlRoleName === 'Installer' ||
                  urlRoleName === 'Staff') &&
                  true)
              }
            />
          ) : (
            // As of now this input text field working for only one text box field. It will be manage from Backend in the future
            // data?.element_type
            <InputNumber
              value={data?.permission_value}
              onChange={e => {
                handleInputChange(e, data, 'view');
                setInputTextValue(e?.value);
                setTextBoxField(prevState => {
                  return { ...prevState, [data?.view]: e?.value };
                });
              }}
              inputId="integeronly"
              required
              disabled={isSystemRole === 1 ? true : false}
            />
          )),
        add:
          data?.add !== null &&
          (data?.element_type === 'checkbox' ? (
            <Checkbox
              value={data?.add}
              index={`${parentData?.add}_${data?.add}`}
              className={`${superData?.add}_${parentData?.add}_${data?.add}`}
              checked={
                data?.add &&
                (checkBoxState[data?.add] !== undefined
                  ? checkBoxState[data?.add]
                  : data?.add_status === true
                    ? true
                    : false) &&
                !checkForState(
                  checkBoxState[parentData?.add],
                  parentData?.add,
                  parentData?.add_status
                )
              }
              onClick={(event, value) => {
                const childNode = document.querySelectorAll(
                  `span[class*='${data?.add}_']`
                );
                childNode.forEach(el =>
                  setCheckBoxState(prevState => {
                    return {
                      ...prevState,
                      [el?.attributes?.index?.value?.split('_')[1]]:
                        event.target.checked,
                    };
                  })
                );
                setCheckBoxState(prevState => {
                  return { ...prevState, [data?.add]: event.target.checked };
                });
                //This condition is to remove existing permission
                if (!event.target.checked) {
                  const newUpdatedArray =
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ) !== -1 &&
                    existingPermissionArray.splice(
                      existingPermissionArray?.indexOf(
                        parseInt(event.target.value)
                      ),
                      1
                    );
                  updatedExistingPermission.length && newUpdatedArray.length
                    ? setUpdatedExistingArray(prevState => [
                        ...prevState,
                        ...newUpdatedArray,
                      ])
                    : setUpdatedExistingArray(newUpdatedArray);
                }
              }}
              disabled={
                !data?.add ||
                checkForState(
                  checkBoxState[parentData?.add],
                  parentData?.add,
                  parentData?.add_status
                ) ||
                ((urlRoleName === 'Admin' ||
                  urlRoleName === 'Installer' ||
                  urlRoleName === 'Staff') &&
                  true)
              }
            />
          ) : (
            <InputNumber
              value={data?.permission_value}
              onChange={e => {
                handleInputChange(e, data, 'add');
                setInputTextValue(e?.value);
                setTextBoxField(prevState => {
                  return { ...prevState, [data?.view]: e?.value };
                });
              }}
              inputId="integeronly"
              required
              min={0}
              max={90}
              disabled={isSystemRole === 1 ? true : false}
            />
          )),
        modify:
          data?.modify !== null &&
          (data?.element_type === 'checkbox' ? (
            <>
              <Checkbox
                index={`${parentData?.modify}_${data?.modify}`}
                className={`${superData?.modify}_${parentData?.modify}_${data?.modify}`}
                value={data?.modify}
                checked={
                  data?.modify &&
                  (checkBoxState[data?.modify] !== undefined
                    ? checkBoxState[data?.modify]
                    : data?.modify_status === true
                      ? true
                      : false) &&
                  !checkForState(
                    checkBoxState[parentData?.modify],
                    parentData?.modify,
                    parentData?.modify_status
                  )
                }
                onClick={(event, value) => {
                  const childNode = document.querySelectorAll(
                    `span[class*='${data?.modify}_']`
                  );
                  childNode.forEach(el =>
                    setCheckBoxState(prevState => {
                      return {
                        ...prevState,
                        [el?.attributes?.index?.value?.split('_')[1]]:
                          event.target.checked || null,
                      };
                    })
                  );
                  setCheckBoxState(prevState => {
                    return {
                      ...prevState,
                      [data?.modify]: event.target.checked,
                    };
                  });
                  //This condition is to remove existing permission
                  if (!event.target.checked) {
                    const newUpdatedArray =
                      existingPermissionArray?.indexOf(
                        parseInt(event.target.value)
                      ) !== -1 &&
                      existingPermissionArray.splice(
                        existingPermissionArray?.indexOf(
                          parseInt(event.target.value)
                        ),
                        1
                      );
                    updatedExistingPermission.length && newUpdatedArray.length
                      ? setUpdatedExistingArray(prevState => [
                          ...prevState,
                          ...newUpdatedArray,
                        ])
                      : setUpdatedExistingArray(newUpdatedArray);
                  }
                }}
                disabled={
                  !data?.modify ||
                  checkForState(
                    checkBoxState[parentData?.modify],
                    parentData?.modify,
                    parentData?.modify_status
                  ) ||
                  ((urlRoleName === 'Admin' ||
                    urlRoleName === 'Installer' ||
                    urlRoleName === 'Staff') &&
                    true)
                }
              />
            </>
          ) : (
            <InputNumber
              value={data?.permission_value}
              onChange={e => {
                handleInputChange(e, data, 'modify');
                setInputTextValue(e?.value);
                setTextBoxField(prevState => {
                  return { ...prevState, [data?.view]: e?.value };
                });
              }}
              inputId="integeronly"
              required
              min={0}
              max={90}
              disabled={isSystemRole === 1 ? true : false}
            />
          )),
        delete:
          data?.delete !== null &&
          (data?.element_type === 'checkbox' ? (
            <Checkbox
              index={`${parentData?.delete}_${data?.delete}`}
              className={`${superData?.delete}_${parentData?.delete}_${data?.delete}`}
              value={data?.delete}
              checked={
                data?.delete &&
                (checkBoxState[data?.delete] !== undefined
                  ? checkBoxState[data?.delete]
                  : data?.delete_status === true
                    ? true
                    : false) &&
                !checkForState(
                  checkBoxState[parentData?.delete],
                  parentData?.delete,
                  parentData?.delete_status
                )
              }
              onClick={(event, value) => {
                const childNode = document.querySelectorAll(
                  `span[class*='${data?.delete}_']`
                );
                childNode.forEach(el =>
                  setCheckBoxState(prevState => {
                    return {
                      ...prevState,
                      [el?.attributes?.index?.value?.split('_')[1]]:
                        event.target.checked,
                    };
                  })
                );
                setCheckBoxState(prevState => {
                  return { ...prevState, [data?.delete]: event.target.checked };
                });
                //This condition is to remove existing permission
                if (!event.target.checked) {
                  const newUpdatedArray =
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ) !== -1 &&
                    existingPermissionArray.splice(
                      existingPermissionArray?.indexOf(
                        parseInt(event.target.value)
                      ),
                      1
                    );
                  updatedExistingPermission.length && newUpdatedArray.length
                    ? setUpdatedExistingArray(prevState => [
                        ...prevState,
                        ...newUpdatedArray,
                      ])
                    : setUpdatedExistingArray(newUpdatedArray);
                }
              }}
              disabled={
                !data?.delete ||
                checkForState(
                  checkBoxState[parentData?.delete],
                  parentData?.delete,
                  parentData?.delete_status
                ) ||
                ((urlRoleName === 'Admin' ||
                  urlRoleName === 'Installer' ||
                  urlRoleName === 'Staff') &&
                  true)
              }
            />
          ) : (
            <InputNumber
              value={data?.permission_value}
              onChange={e => {
                handleInputChange(e, data, 'delete');
                setInputTextValue(e?.value);
                setTextBoxField(prevState => {
                  return { ...prevState, [data?.view]: e?.value };
                });
              }}
              inputId="integeronly"
              required
              min={0}
              max={90}
              disabled={isSystemRole === 1 ? true : false}
            />
          )),
        userAssigned:
          data.user_assigned !== null &&
          (data?.element_type === 'checkbox' ? (
            <>
              <Checkbox
                index={`${parentData?.user_assigned}_${data?.user_assigned}`}
                className={`${superData?.user_assigned}_${parentData?.user_assigned}_${data?.user_assigned}`}
                value={data?.user_assigned}
                checked={
                  data?.user_assigned &&
                  (checkBoxState[data?.user_assigned] !== undefined
                    ? checkBoxState[data?.user_assigned]
                    : data?.user_assigned_status === true
                      ? true
                      : false) &&
                  !checkForState(
                    checkBoxState[parentData?.user_assigned],
                    parentData?.user_assigned,
                    parentData?.user_assigned_status
                  )
                }
                onClick={(event, value) => {
                  const childNode = document.querySelectorAll(
                    `span[class*='${data?.user_assigned}_']`
                  );

                  childNode.forEach(el =>
                    setCheckBoxState(prevState => {
                      return {
                        ...prevState,
                        [el?.attributes?.index?.value?.split('_')[1]]:
                          event.target.checked,
                      };
                    })
                  );
                  setCheckBoxState(prevState => {
                    return {
                      ...prevState,
                      [data?.user_assigned]: event.target.checked,
                    };
                  });
                  //This condition is to remove existing permission
                  if (!event.target.checked) {
                    const newUpdatedArray =
                      existingPermissionArray?.indexOf(
                        parseInt(event.target.value)
                      ) !== -1 &&
                      existingPermissionArray.splice(
                        existingPermissionArray?.indexOf(
                          parseInt(event.target.value)
                        ),
                        1
                      );
                    updatedExistingPermission.length && newUpdatedArray.length
                      ? setUpdatedExistingArray(prevState => [
                          ...prevState,
                          ...newUpdatedArray,
                        ])
                      : setUpdatedExistingArray(newUpdatedArray);
                  }
                }}
                disabled={
                  !data?.modify ||
                  checkForState(
                    checkBoxState[parentData?.modify],
                    parentData?.modify,
                    parentData?.modify_status
                  ) ||
                  ((urlRoleName === ROLES.ADMIN ||
                    urlRoleName === ROLES.INSTALLER ||
                    urlRoleName === ROLES.STAFF) &&
                    true)
                }
              />
            </>
          ) : (
            <InputNumber
              value={data?.permission_value}
              onChange={e => {
                handleInputChange(e, data, PERMISSION_TYPES.MODIFY);
                setInputTextValue(e?.value);
                setTextBoxField(prevState => {
                  return { ...prevState, [data?.view]: e?.value };
                });
              }}
              inputId="integeronly"
              required
              min={0}
              max={90}
              disabled={isSystemRole === 1 ? true : false}
            />
          )),
        items: data?.items?.length
          ? subMenuArray(data?.items, data, parentData)
          : null,
      };
    });

    return subMenuItemsArray;
  };
  const permissionTabledata = itemResponse.map((data, index) => {
    /** Initially, set all the existing permissions in a array  */
    data?.view &&
      data?.view_status === true &&
      existingPermissionArray.push(data?.view);
    data?.add &&
      data?.add_status === true &&
      existingPermissionArray.push(data?.add);
    data?.modify &&
      data?.modify_status === true &&
      existingPermissionArray.push(data?.modify);
    data?.delete &&
      data?.delete_status === true &&
      existingPermissionArray.push(data?.delete);
    data?.search &&
      data?.search_status === true &&
      existingPermissionArray.push(data?.search);
    data?.modify_filters &&
      data?.modify_filters_status === true &&
      existingPermissionArray.push(data?.modify_filters);
    data?.approval &&
      data?.approval_status === true &&
      existingPermissionArray.push(data?.approval);
    // Return the object with checkboxes
    return {
      roleName: data.menu_name,
      view:
        data?.view !== null &&
        (data?.element_type === 'checkbox' ? (
          <Checkbox
            index={index}
            value={data?.view}
            checked={
              data?.view &&
              (checkBoxState[data?.view] !== undefined
                ? checkBoxState[data?.view]
                : data?.view_status === true
                  ? true
                  : false)
            }
            onClick={(event, value) => {
              const childNode = document.querySelectorAll(
                `span[class*='${data?.view}_']`
              );
              childNode.forEach(el =>
                setCheckBoxState(prevState => {
                  return {
                    ...prevState,
                    [el?.attributes?.index?.value?.split('_')[1]]:
                      event.target.checked,
                  };
                })
              );
              setCheckBoxState(prevState => {
                return { ...prevState, [data?.view]: event.target.checked };
              });
              //This condition is to remove existing permission
              if (!event.target.checked) {
                const newUpdatedArray =
                  existingPermissionArray?.indexOf(
                    parseInt(event.target.value)
                  ) !== -1 &&
                  existingPermissionArray.splice(
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ),
                    1
                  );
                updatedExistingPermission.length
                  ? setUpdatedExistingArray(prevState => [
                      ...prevState,
                      ...newUpdatedArray,
                    ])
                  : setUpdatedExistingArray(newUpdatedArray);
              }
            }}
            disabled={
              !data?.view ||
              ((urlRoleName === 'Admin' ||
                urlRoleName === 'Installer' ||
                urlRoleName === 'Staff') &&
                true)
            }
          />
        ) : (
          <InputNumber
            value={data?.permission_value}
            onChange={e => {
              handleInputChange(e, data, 'view');
              setInputTextValue(e?.value);
              setTextBoxField(prevState => {
                return { ...prevState, [data?.view]: e?.value };
              });
            }}
            inputId="integeronly"
            required
            min={0}
            max={90}
            disabled={isSystemRole === 1 ? true : false}
          />
        )),

      add:
        data?.add !== null &&
        (data?.element_type === 'checkbox' ? (
          <Checkbox
            index={index}
            value={data?.add}
            checked={
              data?.add &&
              (checkBoxState[data?.add] !== undefined
                ? checkBoxState[data?.add]
                : data?.add_status === true
                  ? true
                  : false)
            }
            onClick={(event, value) => {
              const childNode = document.querySelectorAll(
                `span[class*='${data?.add}_']`
              );
              childNode.forEach(el =>
                setCheckBoxState(prevState => {
                  return {
                    ...prevState,
                    [el?.attributes?.index?.value?.split('_')[1]]:
                      event.target.checked,
                  };
                })
              );
              setCheckBoxState(prevState => {
                return { ...prevState, [data?.add]: event.target.checked };
              });
              //This condition is to remove existing permission
              if (!event.target.checked) {
                const newUpdatedArray =
                  existingPermissionArray?.indexOf(
                    parseInt(event.target.value)
                  ) !== -1 &&
                  existingPermissionArray.splice(
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ),
                    1
                  );
                updatedExistingPermission.length
                  ? setUpdatedExistingArray(prevState => [
                      ...prevState,
                      ...newUpdatedArray,
                    ])
                  : setUpdatedExistingArray(newUpdatedArray);
              }
            }}
            disabled={
              !data?.add ||
              ((urlRoleName === 'Admin' ||
                urlRoleName === 'Installer' ||
                urlRoleName === 'Staff') &&
                true)
            }
          />
        ) : (
          <InputNumber
            value={data?.permission_value}
            onChange={e => {
              handleInputChange(e, data, 'add');
              setInputTextValue(e?.value);
              setTextBoxField(prevState => {
                return { ...prevState, [data?.view]: e?.value };
              });
            }}
            inputId="integeronly"
            required
            min={0}
            max={90}
            disabled={isSystemRole === 1 ? true : false}
          />
        )),
      modify:
        data?.modify !== null &&
        (data?.element_type === 'checkbox' ? (
          <Checkbox
            index={index}
            value={data?.modify}
            checked={
              data?.modify &&
              (checkBoxState[data?.modify] !== undefined
                ? checkBoxState[data?.modify]
                : data?.modify_status === true
                  ? true
                  : false)
            }
            onClick={(event, value) => {
              const childNode = document.querySelectorAll(
                `span[class*='${data?.modify}_']`
              );
              childNode.forEach(el =>
                setCheckBoxState(prevState => {
                  return {
                    ...prevState,
                    [el?.attributes?.index?.value?.split('_')[1]]:
                      event.target.checked,
                  };
                })
              );
              setCheckBoxState(prevState => {
                return { ...prevState, [data?.modify]: event.target.checked };
              });
              //This condition is to remove existing permission
              if (!event.target.checked) {
                const newUpdatedArray =
                  existingPermissionArray?.indexOf(
                    parseInt(event.target.value)
                  ) !== -1 &&
                  existingPermissionArray.splice(
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ),
                    1
                  );
                updatedExistingPermission.length
                  ? setUpdatedExistingArray(prevState => [
                      ...prevState,
                      ...newUpdatedArray,
                    ])
                  : setUpdatedExistingArray(newUpdatedArray);
              }
            }}
            disabled={
              !data?.modify ||
              ((urlRoleName === 'Admin' ||
                urlRoleName === 'Installer' ||
                urlRoleName === 'Staff') &&
                true)
            }
          />
        ) : (
          <InputNumber
            value={data?.permission_value}
            onChange={e => {
              handleInputChange(e, data, 'modify');
              setInputTextValue(e?.value);
              setTextBoxField(prevState => {
                return { ...prevState, [data?.view]: e?.value };
              });
            }}
            inputId="integeronly"
            required
            min={0}
            max={90}
            disabled={isSystemRole === 1 ? true : false}
          />
        )),
      delete:
        data?.delete !== null &&
        (data?.element_type === 'checkbox' ? (
          <Checkbox
            index={index}
            value={data?.delete}
            checked={
              data?.delete &&
              (checkBoxState[data?.delete] !== undefined
                ? checkBoxState[data?.delete]
                : data?.delete_status === true
                  ? true
                  : false)
            }
            onClick={(event, value) => {
              const childNode = document.querySelectorAll(
                `span[class*='${data?.delete}_']`
              );
              childNode.forEach(el =>
                setCheckBoxState(prevState => {
                  return {
                    ...prevState,
                    [el?.attributes?.index?.value?.split('_')[1]]:
                      event.target.checked,
                  };
                })
              );
              setCheckBoxState(prevState => {
                return { ...prevState, [data?.delete]: event.target.checked };
              });
              //This condition is to remove existing permission
              if (!event.target.checked) {
                const newUpdatedArray =
                  existingPermissionArray?.indexOf(
                    parseInt(event.target.value)
                  ) !== -1 &&
                  existingPermissionArray.splice(
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ),
                    1
                  );
                updatedExistingPermission.length
                  ? setUpdatedExistingArray(prevState => [
                      ...prevState,
                      ...newUpdatedArray,
                    ])
                  : setUpdatedExistingArray(newUpdatedArray);
              }
            }}
            disabled={
              !data?.delete ||
              ((urlRoleName === 'Admin' ||
                urlRoleName === 'Installer' ||
                urlRoleName === 'Staff') &&
                true)
            }
          />
        ) : (
          <InputNumber
            value={data?.permission_value}
            onChange={e => {
              handleInputChange(e, data, 'delete');
              setInputTextValue(e?.value);
              setTextBoxField(prevState => {
                return { ...prevState, [data?.view]: e?.value };
              });
            }}
            inputId="integeronly"
            required
            min={0}
            max={90}
            disabled={isSystemRole === 1 ? true : false}
          />
        )),

      userAssigned:
        data?.user_assigned !== null &&
        (data?.element_type === 'checkbox' ? (
          <>
            <Checkbox
              index={index}
              value={data?.user_assigned}
              checked={
                data?.user_assigned &&
                (checkBoxState[data?.user_assigned] !== undefined
                  ? checkBoxState[data?.user_assigned]
                  : data?.user_assigned_status === true
                    ? true
                    : false)
              }
              onClick={(event, value) => {
                const childNode = document.querySelectorAll(
                  `span[class*='${data?.user_assigned}_']`
                );
                childNode.forEach(el =>
                  setCheckBoxState(prevState => {
                    return {
                      ...prevState,
                      [el?.attributes?.index?.value?.split('_')[1]]:
                        event.target.checked,
                    };
                  })
                );
                setCheckBoxState(prevState => {
                  return {
                    ...prevState,
                    [data?.userAssigned]: event.target.checked,
                  };
                });
                //This condition is to remove existing permission
                if (!event.target.checked) {
                  const newUpdatedArray =
                    existingPermissionArray?.indexOf(
                      parseInt(event.target.value)
                    ) !== -1 &&
                    existingPermissionArray.splice(
                      existingPermissionArray?.indexOf(
                        parseInt(event.target.value)
                      ),
                      1
                    );
                  updatedExistingPermission.length
                    ? setUpdatedExistingArray(prevState => [
                        ...prevState,
                        ...newUpdatedArray,
                      ])
                    : setUpdatedExistingArray(newUpdatedArray);
                }
              }}
              disabled={
                !data?.user_assigned ||
                ((urlRoleName === ROLES.ADMIN ||
                  urlRoleName === ROLES.INSTALLER ||
                  urlRoleName === ROLES.STAFF) &&
                  true)
              }
            />
          </>
        ) : (
          <InputNumber
            value={data?.permission_value}
            onChange={e => {
              handleInputChange(e, data, PERMISSION_TYPES.USER_ASSIGNED);
              setInputTextValue(e?.value);
              setTextBoxField(prevState => {
                return { ...prevState, [data?.view]: e?.value };
              });
            }}
            inputId="integeronly"
            required
            min={0}
            max={90}
            disabled={isSystemRole === 1 ? true : false}
          />
        )),

      items: data?.items?.length ? subMenuArray(data?.items, data) : null,
    };
  });
  // Save button handler
  const savePermissions = () => {
    if (inputTextValue === null || inputTextValue < 0 || inputTextValue >= 91) {
      toast.current.show({
        severity: 'error',
        summary: `Allowed Future jobs date range is between 0-90 days.`,
        life: '3000',
      });
    } else {
      const newExistingPermissionArray = updatedExistingPermission.length
        ? existingPermissionArray.filter(
            data => !updatedExistingPermission.includes(data)
          )
        : existingPermissionArray;

      DEFAULT_PERMISSION_IDS.forEach(id => {
        const searchSubString = `"permission_id":${id},`;
        if (JSON.stringify(permissionsData).includes(searchSubString)) {
          newExistingPermissionArray.push(id);
        }
      });

      saveRolePermissions(
        role_id,
        checkBoxState,
        newExistingPermissionArray,
        setLoading,
        setAlert,
        setReloadList,
        textBoxField
      );
    }
  };

  return (
    <>
      <Toast className="taks-toast" ref={toast} />
      <MuiGrid container item direction="column" spacing={2}>
        <MuiGrid container direction="row" justifyContent="space-between">
          <MuiGrid item>
            <PageHeader pageTitle="Roles" breadCrumbArray={rolesBreadcrumb} />
          </MuiGrid>
          {isSaveEnable && (
            <MuiGrid item>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  !Object.keys(checkBoxState).length &&
                  !Object.keys(textBoxField).length
                }
                onClick={() => savePermissions()}
              >
                Save
              </Button>
            </MuiGrid>
          )}
        </MuiGrid>
        {alert.exists && (
          <MuiGrid item>
            {' '}
            <Alert />
          </MuiGrid>
        )}
        <MuiGrid item className="w-12 overflow-auto">
          <Paper classes={{ root: classes.gridWrapper }}>
            {loading ? (
              <PFTableLoader />
            ) : (
              <Grid rows={permissionTabledata} columns={columns}>
                <TreeDataState />
                <CustomTreeData getChildRows={getChildRows} />
                <Table
                  noDataCellComponent={() =>
                    !loading &&
                    permissionTabledata !== undefined &&
                    !permissionTabledata?.length && (
                      <td colSpan={11} style={{ textAlign: 'center' }}>
                        No data available
                      </td>
                    )
                  }
                />
                <TableHeaderRow />
                <TableBandHeader columnBands={columnBands} />
                <TableTreeColumn for="roleName" />
              </Grid>
            )}
          </Paper>
        </MuiGrid>
      </MuiGrid>
    </>
  );
};

export default Permissions;
