// **** External Utilities ****
import axios from 'axios';

import apiService from '../../services/api.service';
import { URL_CONSTANTS } from '../../constants/urlConstants';

// Get my dashboards list
export const getMyDashboardDetails = async (
  setLoading,
  setDashboardData,
  setValue
) => {
  try {
    // setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/list/my-dashboards`
    );
    let defaultDashboardId = 0;
    response?.data?.forEach(dashboard => {
      dashboard?.DashboardMappings?.forEach(mappingDetails => {
        if (mappingDetails?.is_default_dashboard) {
          defaultDashboardId = mappingDetails.dashboard_id;
        }
      });
    });
    setDashboardData(response?.data);

    if (defaultDashboardId) {
      setValue(defaultDashboardId?.toString());
    } else {
      setValue(response?.data[0]?.dashboard_id?.toString() || 'newTab');
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Region, District, Store No, Category dropdown values
export const getFiltersDropdown = async (
  setRegionOptions,
  setDistrictOptions,
  setStoreNoOptions,
  setCategoryOptions,
  setTypeOptions,
  setSourceOptions,
  setInstallerOptions,
  setStatusOptions,
  setWorkroomOptions,
  setInitialWorkroomOptions
) => {
  try {
    const districtRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/district`
    );
    const regionRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/region`
    );
    const sourceRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/source-system`
    );
    const storeNoRequest = axios.get(`${URL_CONSTANTS.API.BASE_URL}/stores`);
    const categoryRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-category`
    );
    const typeRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/master/project-type`
    );
    const installerRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers`
    );

    const statusRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/cubes/master?cube=Project&dimension=status_id`
    );
    const workroomRequest = apiService.get(`/system/workroom/list`);

    const responses = await Promise.all([
      districtRequest,
      regionRequest,
      storeNoRequest,
      categoryRequest,
      typeRequest,
      sourceRequest,
      installerRequest,
      statusRequest,
      workroomRequest,
    ]);

    setDistrictOptions(responses[0]?.data);
    setRegionOptions(responses[1]?.data);
    setStoreNoOptions(responses[2]?.data);
    setCategoryOptions(responses[3]?.data);
    setTypeOptions(responses[4]?.data);
    setSourceOptions(responses[5]?.data);
    if (responses[6].data) {
      const installerList = responses[6].data?.map(item => {
        return {
          installer_id: item.installer_id,
          installer_name: `${item?.first_name} ${item?.last_name}`,
        };
      });
      setInstallerOptions(installerList);
    }
    setStatusOptions(responses[7]?.data);
    setWorkroomOptions(responses[8]);
    setInitialWorkroomOptions(responses[8]);
  } catch (error) {
    console.log(error);
  }
};

// Unlink Dashboard
export const deleteUnlinkDashboard = async (
  dashboardId,
  setAttachRemoveDashboardDialogOpen,
  setLoading,
  setDashboardData,
  setValue,
  setAlert
) => {
  try {
    setLoading(true);

    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/un-link/${dashboardId}`
    );
    if (response) {
      getMyDashboardDetails(setLoading, setDashboardData, setValue);
      setAttachRemoveDashboardDialogOpen(false);
      setAlert('success', 'Dashboard removed successfully');
    }
  } catch (error) {
    console.log(error);
  }
};

// Link Dashboard
export const linkUserDashboard = async (
  dashboardId,
  setConfigureDialog,
  setLoading,
  setDashboardData,
  setValue,
  setAlert,
  sortOrder
) => {
  try {
    setLoading(true);

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/link/${dashboardId}/${sortOrder}`
    );
    if (response) {
      getMyDashboardDetails(setLoading, setDashboardData, setValue);
      setConfigureDialog(false);
      setAlert('success', 'Dashboard attached successfully');
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  }
};

export const saveHomeDashboardApi = async dashboardObj => {
  try {
    const response = await apiService.patch(
      `/dashboard/manage/add-dashboard-user`,
      dashboardObj
    );
    return response;
  } catch (error) {
    console.log(error);
    return {};
  }
};

// Update tab order
export const updateTabOrder = async (setLoading, tabData, setAlert) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/update/tab-order/`,
      { tabData: tabData }
    );
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  }
};
