import apiService from '../../../services/api.service';

export const getCategoryList = async () => {
  try {
    const response = await apiService.get(`/projects/master/project-category`);
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};
