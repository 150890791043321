import axios from 'axios';
import { isNumber } from 'lodash';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { REACT_APP_API_SCHEDULER_BASEURL } from '../../../constants/envConstants';
export const getCalendarResourceData = async params => {
  try {
    const queryParam = { params: {} };
    let body = {};
    for (const key in params) {
      if (Boolean(params[key]) || params[key] === 0) {
        queryParam.params[key] = params[key];
        if (key !== 'date' && key !== 'period') {
          if (key === 'isJobCoverage') {
            body[key] = params[key];
          } else {
            body[key] = isNumber(params[key])
              ? params[key].toString().split(',')
              : params[key]?.split(',');
          }
        }
      }
    }
    if (
      !('isJobCoverage' in queryParam.params) ||
      !queryParam.params.isJobCoverage
    ) {
      queryParam.params.isJobCoverage = true;
      body.isJobCoverage = true;
    }

    return await axios.post(
      `${REACT_APP_API_SCHEDULER_BASEURL}/api/scheduler/resources`,
      body
    );
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCalendarProjectData = async params => {
  try {
    const queryParam = { params: {} };
    let projectRecordBody = {};
    let timeOffBody = {};
    if (params.isFromCalendar) {
      projectRecordBody.isFromCalendar = params.isFromCalendar;
      timeOffBody.isFromCalendar = params.isFromCalendar;
      for (const key in params) {
        if (Boolean(params[key]) || params[key] === 0) {
          queryParam.params[key] = params[key];
          if (key !== 'isFromCalendar') {
            if (key === 'isJobCoverage' || key === 'date' || key == 'period') {
              projectRecordBody[key] = params[key];
              timeOffBody[key] = params[key];
            } else {
              projectRecordBody[key] = isNumber(params[key])
                ? [String(params[key])]
                : params[key]?.split(',');
              timeOffBody[key] = isNumber(params[key])
                ? [String(params[key])]
                : params[key]?.split(',');
            }
          }
        }
      }
      if (
        !('isJobCoverage' in queryParam.params) ||
        !queryParam.params.isJobCoverage
      ) {
        queryParam.params.isJobCoverage = true;
        projectRecordBody.isJobCoverage = true;
        timeOffBody.isJobCoverage = true;
      }
    } else {
      for (const key in params) {
        if (
          key == 'date' ||
          key == 'period' ||
          key == 'installerIds' ||
          key == 'isFromCalendar'
        ) {
          if (key == 'date' || key == 'period' || key == 'isFromCalendar') {
            projectRecordBody[key] = params[key];
            timeOffBody[key] = params[key];
          } else {
            projectRecordBody[key] = isNumber(params[key])
              ? [String(params[key])]
              : params[key]?.split(',');

            timeOffBody[key] = isNumber(params[key])
              ? [String(params[key])]
              : params[key]?.split(',');
          }
        }
      }
    }

    const timeOffRecords = axios.post(
      `${REACT_APP_API_SCHEDULER_BASEURL}/api/scheduler/get-time-off-records`,
      timeOffBody
    );
    const projectDetailsRecords = axios.post(
      `${REACT_APP_API_SCHEDULER_BASEURL}/api/scheduler/get-project-records`,
      projectRecordBody
    );
    const response = await Promise.all([timeOffRecords, projectDetailsRecords]);
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const updateInstallerSchedule = async updateObj => {
  try {
    return await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view/update`,
      updateObj
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllInstallersList = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers-for-global-configuration`
    );
    return response?.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getProjectStatusList = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=Project`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getProjectDataById = async projectId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/map/${projectId}/edit-schedule`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRescheduleReasonOptions = async sourceId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/reason?reasonType=Project&sourceSystemId=${sourceId}`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateProjectScheduleInfo = async (
  projectId,
  updatedScheduleData
) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`,
      updatedScheduleData
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const addProjectNotes = async (projectId, payload) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/notes`,
      payload
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addInstallerUnavailability = async (
  installerId,
  unavailabilityPayload
) => {
  const eventPayload = {
    ...unavailabilityPayload,
    full_day: false,
    is_recurring: false,
    is_recurring_every_other_week: 0,
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thus: false,
    fri: false,
    sat: false,
  };
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/availability`,
      eventPayload
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const distanceInMileage = async (source, destination) => {
  try {
    const response = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/address/distance/${source}/${destination}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return {};
  }
};
