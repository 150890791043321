//imports
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';

//External Components
import { Card } from 'primereact/card';

import { getSystemTenants } from '../../Admin/Configuration/RestrictAccessbyRole.service.js';
import {
  checkPermission,
  formatCurrency,
  formatNumber,
  isCrmEnabled,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import PFButton from '../../shared/PFPrime/PFButton.js';
import { setCRMInvoiceItems } from '../../../redux/slices/crmInvoiceItems.slice.js';
import { getProjectInvoiceItems } from '../../CRM/Invoice/helpers/Invoice.js';
import { CLIENT_DETAILS } from '../../../constants.js';

import ProjectItemsCostSummary from './ProjectItemsCostSummary';
import ProjectLaborItemsGrid from './ProjectLaborItemsGrid';
import ProjectMerchandiseItemsGrid from './ProjectMerchandiseItemsGrid';

//Services
import {
  calculateProjectLaborCost,
  getProjectCalculations,
  getProjectInstallers,
  getSystemStatuses,
} from './ProjectItemsService';
import { getUserProfile, fetchWarehouseList } from './ProjectItemsService';

//Permissions

import Pdfviewer from './Pdfviewer.js';

//Main
export default function ProjectItems(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useRef(null);
  const { projectId, customerId, opportunityId } = useParams();
  const projectNumber = props.formik?.values?.project_number;

  const [projectInstallers, setProjectInstallers] = useState([]);
  const [systemStatuses, setSystemStatuses] = useState([]);
  const [tennant, setTennant] = useState(null);

  const [laborItems, setLaborItems] = useState([]);
  const [merchandiseItems, setMerchandiseItems] = useState([]);
  const [updateAll, setUpdateAll] = useState(false);

  const [updateProjectItems, setUpdateProjectItems] = useState(false);
  const [updateMerchandiseItems, setUpdateMerchandiseProjectItems] =
    useState(false);
  const [projectInvoiceItems, setProjectInvoiceItems] = useState([]);
  const [CRMEnabled] = useState(isCrmEnabled());
  const [isTechnicianDailogOpen, setIsTechnicianDailogOpen] = useState(false);

  const [userIsInstaller, setUserIsInstaller] = useState(null);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [calculationData, setCalculationData] = useState([]);
  const [refereshLineItems, setRefereshLineItems] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props?.refereshLineItems) {
      setRefereshLineItems(true);
      props?.setRefereshLineItems(false);
    }
  }, [props?.refereshLineItems]);

  //mount
  useEffect(() => {
    getSystemTenants().then(response => {
      setTennant(response);
    });
  }, []);

  useEffect(() => {
    getProjectInstallers(projectId)
      .then(res => {
        setProjectInstallers(res.data ? res.data : []);
      })
      .catch(err => {
        setProjectInstallers([]);
      });
    getCalculations();
  }, [projectId]);

  useEffect(() => {
    getSystemStatuses()
      .then(res => {
        setSystemStatuses(res.data ? res.data : []);
      })
      .catch(err => {
        setSystemStatuses([]);
      });
    const invoiceItems = getProjectInvoiceItems(projectId);
    invoiceItems
      .then(invoiceItems => {
        if (invoiceItems?.status === 200) {
          setProjectInvoiceItems(invoiceItems?.data?.data);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (systemStatuses?.length > 0) {
      const clearIndex = systemStatuses.findIndex(
        item => item.status_id === -1
      );
      if (clearIndex === -1) {
        setSystemStatuses([
          ...systemStatuses,
          { status_id: -1, status: 'Clear Status' },
        ]);
      }
    }
  }, [systemStatuses]);

  useEffect(() => {
    if (updateAll) {
      setUpdateProjectItems(true);
      setUpdateMerchandiseProjectItems(true);
      setTimeout(() => {
        setUpdateProjectItems(false);
        setUpdateMerchandiseProjectItems(false);
      }, 5000);
      setUpdateAll(false);
    } else {
    }
  }, [updateAll]);

  useEffect(() => {
    try {
      props.setProjectItems(laborItems);
    } catch (error) {
      console.error(error);
    }
  }, [laborItems, merchandiseItems]);

  useEffect(() => {
    try {
      // handleGetLaborCostClick(false);
      getUserProfile().then(response => {
        const isInstaller = JSON.parse(response?.is_installer || 'false');
        setUserIsInstaller(isInstaller);
        setLoggedInUserId(response?.user_id);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);
  const getWarehouseList = async () => {
    try {
      const response = await fetchWarehouseList();
      if (response && response?.length) {
        setWarehouseList(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWarehouseList();
  }, []);
  const getCalculations = async () => {
    setIsLoading(true);
    try {
      const response = await getProjectCalculations(projectId);
      if (response && response.status) {
        setCalculationData(response.data);
      } else {
        setCalculationData(null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCalculationsCallBack = () => {
    getCalculations();
  };

  useEffect(() => {
    getCalculations();
  }, [laborItems, merchandiseItems]);

  const handleGetLaborCostClick = async (isShowAlert = true) => {
    getCalculationsCallBack();
    const response = await calculateProjectLaborCost(projectId);
    if (response?.data?.status) {
      isShowAlert && (await setUpdateAll(true));
      isShowAlert &&
        showAlert('success', 'Project Labor Cost Calculation Started');
    } else {
      isShowAlert &&
        showAlert(
          'error',
          response?.data?.message
            ? response?.data?.message
            : 'Unable To Calculate Labor Cost'
        );
    }
    isShowAlert && setRefereshLineItems(!refereshLineItems);
  };

  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  //methods

  //permissions
  //labor items
  const showLaborCostFields = checkPermission(
    permissions?.projectItems?.laborCostFields
  );
  const allLaborItemsCostField = checkPermission(
    permissions?.projectItems?.allCostFields
  );
  const addAllLaborCostFields = checkPermission(
    permissions?.projectItems?.addAllCostFields
  );
  const editAllLaborCostFields = checkPermission(
    permissions?.projectItems?.editAllCostFields
  );
  const editLaborItemUsers = checkPermission(
    permissions?.projectItems?.editLaborProfileItems
  );

  //merchandise items
  let viewAllMerchandiseCost = checkPermission(
    permissions?.projectItems?.viewAllMerchandiseCost
  );
  const addAllMerchandiseCost = checkPermission(
    permissions?.projectItems?.addAllMerchandiseCost
  );
  const editAllMerchandiseCost = checkPermission(
    permissions?.projectItems?.editAllMerchandiseCost
  );

  //overall
  const viewProjectLevelCostField = checkPermission(
    permissions?.projectItems?.viewProjectLevelCostField
  );

  //labor permissions view add edit delete
  const tabProjectItemsLaborView = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsLaborView
  );
  const tabProjectItemsLaborAdd = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsLaborAdd
  );
  const tabProjectItemsLaborModify = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsLaborModify
  );
  const tabProjectItemsLaborDelete = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsLaborDelete
  );

  //merchandise permission view add edit delete
  const tabProjectItemsMerchandiseView = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsMerchandiseView
  );
  const tabProjectItemsMerchandiseAdd = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsMerchandiseAdd
  );
  const tabProjectItemsMerchandiseModify = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsMerchandiseModify
  );
  const tabProjectItemsMerchandiseDelete = checkPermission(
    permissions?.viewEditProject?.tabProjectItemsMerchandiseDelete
  );

  //tennant permission
  if (
    tennant &&
    tennant.tenant_permissions[0]?.included_permissions?.includes(
      permissions?.projectItems?.viewAllMerchandiseCost
    )
  ) {
    // viewAllMerchandiseCost = true; can be set here
  } else if (
    tennant &&
    tennant.tenant_permissions[0]?.excluded_permissions?.includes(
      permissions?.projectItems?.viewAllMerchandiseCost
    )
  ) {
    viewAllMerchandiseCost = false;
  }

  //technician visibility
  const technicianVisibility = checkPermission(
    permissions?.projectItems?.technicianVisibility
  );

  //finance approval
  const financeApproval = checkPermission(
    permissions?.projectItems?.financeApproval
  );
  const editFinanceApproval = checkPermission(
    permissions?.projectItems?.editFinanceApproval
  );

  //paid approval
  const paidApproval = checkPermission(permissions?.projectItems?.paidApproval);
  const editPaidApproval = checkPermission(
    permissions?.projectItems?.editPaidApproval
  );

  //technician finance approval
  const technicianFinanceApproval = checkPermission(
    permissions?.projectItems?.technicianFinanceApproval
  );
  const editTechnicianFinanceApproval = checkPermission(
    permissions?.projectItems?.editTechnicianFinanceApproval
  );

  //technician paid approval
  const technicianPaidApproval = checkPermission(
    permissions?.projectItems?.technicianPaidApproval
  );
  const editTechnicianPaidApproval = checkPermission(
    permissions?.projectItems?.editTechnicianPaidApproval
  );

  // status
  const addProductItemStatus = checkPermission(
    permissions?.projectItems?.addProductItemStatus
  );

  const editProductItemStatus = checkPermission(
    permissions?.projectItems?.editProductItemStatus
  );

  const viewProductItemStatus = checkPermission(
    permissions?.projectItems?.viewProductItemStatus
  );

  const [invoiceItems, setInvoiceItems] = useState([]);

  const calculateRowPayment = values => {
    let payment = 0;
    let discountAmount = 0;
    let taxAmount = 0;
    let itemDescription = '';

    if (values?.quantity && values?.unitPrice) {
      payment = values?.quantity * values?.unitPrice;
    }
    if (values?.discount && values?.discountType) {
      if (values?.discountType === 'Percentage') {
        discountAmount = (payment * values?.discount) / 100;
        payment = payment - discountAmount;
      }
      if (values?.discountType === 'Amount') {
        discountAmount = values?.discount;
        payment = payment - discountAmount;
      }
    }
    if (values?.tax) {
      taxAmount = (payment * values?.tax) / 100;
      payment = payment + taxAmount;
    }
    return {
      payment: payment,
      discountAmount: discountAmount,
      taxAmount: taxAmount,
    };
  };

  const handleInvoiceItems = (data, checked) => {
    const itemObject = {
      project_id: data?.project_id,
      project_item_id: data?.project_item_id,
      quote_id: data?.quote_id,
      itemId: data?.item?.item_id,
      quantity: data?.item_quantity,
      discount: data?.discount
        ? parseFloat(data?.discount, 2)
        : parseFloat(0, 2),
      discountType: data?.discount_type || 'Amount',
      itemDescription: data?.item?.item_desc,
      tax: data?.tax,
      discountAmount: parseFloat(0, 2),
      taxAmount: parseFloat(0, 2),
      itemTypeId: data?.item_type_id,
      itemType: data?.item?.item_type?.type_name,
      itemNumber: data?.item?.item_number,
      totalPrice: data?.net_cost,
      item: (function () {
        const { inventory_managment, ...rest } = data?.item;
        return rest;
      })(),
    };

    if (itemObject?.itemType === 'Labor') {
      itemObject.unitPrice = data?.sell_price
        ? parseFloat(data?.sell_price, 2)
        : parseFloat(0, 2);
    } else {
      itemObject.unitPrice = data?.sell_price
        ? parseFloat(data?.sell_price, 2)
        : parseFloat(0, 2);
    }

    const calculation = calculateRowPayment(itemObject);
    itemObject.discountAmount = parseFloat(
      calculation.discountAmount.toFixed(2)
    );
    itemObject.taxAmount = parseFloat(calculation.taxAmount.toFixed(2));

    if (checked) {
      setInvoiceItems(preItems => {
        const isExist = preItems.find(
          item => item.project_item_id === itemObject?.project_item_id
        );

        if (isExist === undefined) {
          return [...preItems, itemObject];
        } else {
          return preItems;
        }
      });
    } else {
      setInvoiceItems(preItems => {
        const filteredArray =
          preItems?.filter(
            item => item.project_item_id !== itemObject?.project_item_id
          ) || [];
        return filteredArray;
      });
    }
  };

  const onConvertToInvoice = () => {
    const storeObject = {
      customerId: customerId,
      opportunityId: opportunityId,
      quoteId: invoiceItems?.[0]?.quote_id,
      quoteNumber: props?.formik?.values?.quote_number,
      opportunityNumber: props?.formik?.values?.opportunity_number,
      projectId: invoiceItems?.[0]?.project_id,
      projectNumber: projectNumber,
      items: [...invoiceItems],
    };

    dispatch(setCRMInvoiceItems(storeObject));
    history.push(`/crm/invoice/create/`);
  };

  return (
    <div className="surface-50" style={{ margin: '-20px', padding: '20px' }}>
      <Toast ref={toast}></Toast>
      {!viewProjectLevelCostField && (
        <Card
          className="mb-3"
          pt={{ content: { className: 'py-0' }, body: { className: 'p-3' } }}
        >
          <Pdfviewer
            projectId={projectId}
            projectInstallers={projectInstallers}
            setIsTechnicianDailogOpen={setIsTechnicianDailogOpen}
            isTechnicianDailogOpen={isTechnicianDailogOpen}
          />
          <div className="grid grid-nogutter w-full">
            <div className="col-12 md:col-4 flex align-items-center">
              <h3>Project Items</h3>
            </div>
            <div className="col-12 md:col-8 text-right">
              <PFButton
                type="button"
                severity="primary"
                onClick={() => setIsTechnicianDailogOpen(true)}
                size="small"
                label="Preview Technician PO"
                className="ml-2"
              />
            </div>
          </div>
        </Card>
      )}
      {viewProjectLevelCostField && loggedInUserId && (
        <Card
          className="mb-3"
          header={
            <div className="grid px-3 pt-3 flex align-items-center mb-3 mt-1">
              <div className="col-12 xl:col-6">
                <div className="grid grid-nogutter align-items-center">
                  {(allLaborItemsCostField || viewAllMerchandiseCost) && (
                    <>
                      <>
                        <h3 className="text-xl">Project Cost Summary</h3>
                      </>
                      {allLaborItemsCostField && (
                        <>
                          <div className="pr-3 pl-4 border-right-2 text-xs">
                            <span>Overall Profit</span>{' '}
                            <p className="text-primary my-0">{`${formatCurrency(
                              calculationData?.overall_profit
                                ? calculationData?.overall_profit
                                : 0
                            )}`}</p>
                          </div>
                          <div className="px-3 text-xs">
                            <span>Overall Margin</span>{' '}
                            <p className="text-primary my-0">{`${formatNumber(
                              calculationData?.overall_margin
                                ? calculationData?.overall_margin
                                : 0
                            )}%`}</p>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 lg:col-6 flex justify-content-end">
                {CRMEnabled && (
                  <PFButton
                    label={`Convert to Invoice`}
                    severity="primary"
                    outlined={true}
                    disabled={invoiceItems?.length > 0 ? false : true}
                    onClick={onConvertToInvoice}
                  />
                )}

                <PFButton
                  type="button"
                  severity="primary"
                  onClick={handleGetLaborCostClick}
                  size="small"
                  label="Calculate Labor Cost"
                  className="ml-2 text-left"
                />
                <PFButton
                  type="button"
                  severity="primary"
                  onClick={() => setIsTechnicianDailogOpen(true)}
                  size="small"
                  label="Preview Technician PO"
                  className="ml-2"
                />
              </div>
            </div>
          }
        >
          <ProjectItemsCostSummary
            projectId={projectId}
            permissions={{}}
            laborItems={laborItems}
            merchandiseItems={merchandiseItems}
            viewAllMerchandiseCost={viewAllMerchandiseCost}
            allLaborItemsCostField={allLaborItemsCostField}
            setUpdateAll={setUpdateAll}
            projectInstallers={projectInstallers}
            projectDetails={props.formik?.values}
            systemStatuses={systemStatuses}
            storeDetails={props?.storeDetails}
            setIsTechnicianDailogOpen={setIsTechnicianDailogOpen}
            isTechnicianDailogOpen={isTechnicianDailogOpen}
            calculationData={calculationData}
            getCalculationsCallBack={getCalculationsCallBack}
            setRefereshLineItems={setRefereshLineItems}
            refereshLineItems={refereshLineItems}
            totalSaleAmount={props?.totalSaleAmount}
            setTotalRevenue={props?.setTotalRevenue}
            isSalesCommissionOpted={props?.isSalesCommissionOpted}
          ></ProjectItemsCostSummary>
        </Card>
      )}
      {tabProjectItemsLaborView && loggedInUserId && (
        <Card
          className="mb-3"
          pt={{
            content: {
              className: 'p-0',
            },
          }}
        >
          <ProjectLaborItemsGrid
            projectId={projectId}
            projectNumber={projectNumber}
            projectInstallers={projectInstallers}
            systemStatuses={systemStatuses}
            updateParentLaborItems={setLaborItems}
            updateProjectItems={updateProjectItems}
            showLaborCostFields={showLaborCostFields}
            allLaborItemsCostField={allLaborItemsCostField}
            addAllLaborCostFields={addAllLaborCostFields}
            editAllLaborCostFields={editAllLaborCostFields}
            editLaborItemUsers={editLaborItemUsers}
            tabProjectItemsLaborAdd={tabProjectItemsLaborAdd}
            tabProjectItemsLaborModify={tabProjectItemsLaborModify}
            tabProjectItemsLaborDelete={tabProjectItemsLaborDelete}
            userIsInstaller={userIsInstaller}
            loggedInUserId={loggedInUserId}
            technicianVisibility={technicianVisibility}
            financeApproval={financeApproval}
            paidApproval={paidApproval}
            editFinanceApproval={editFinanceApproval}
            editPaidApproval={editPaidApproval}
            technicianFinanceApproval={technicianFinanceApproval}
            technicianPaidApproval={technicianPaidApproval}
            editTechnicianFinanceApproval={editTechnicianFinanceApproval}
            editTechnicianPaidApproval={editTechnicianPaidApproval}
            quoteNumber={props?.formik?.values?.quote_number}
            handleInvoiceItems={handleInvoiceItems}
            invoiceItems={invoiceItems}
            projectInvoiceItems={projectInvoiceItems}
            CRMEnabled={CRMEnabled}
            refereshLineItems={refereshLineItems}
            setRefereshLineItems={setRefereshLineItems}
            isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
            isSalesCommissionOpted={props?.isSalesCommissionOpted}
            warehouseList={warehouseList}
            isLinked={false}
          ></ProjectLaborItemsGrid>
        </Card>
      )}
      {tabProjectItemsMerchandiseView && loggedInUserId && (
        <Card
          className="mb-3"
          pt={{
            content: {
              className: 'p-0',
            },
          }}
        >
          <ProjectMerchandiseItemsGrid
            projectId={projectId}
            updateParentMerchandiseItems={setMerchandiseItems}
            updateMerchandiseItems={updateMerchandiseItems}
            viewAllMerchandiseCost={viewAllMerchandiseCost}
            addAllMerchandiseCost={addAllMerchandiseCost}
            editAllMerchandiseCost={editAllMerchandiseCost}
            tabProjectItemsMerchandiseAdd={tabProjectItemsMerchandiseAdd}
            tabProjectItemsMerchandiseModify={tabProjectItemsMerchandiseModify}
            tabProjectItemsMerchandiseDelete={tabProjectItemsMerchandiseDelete}
            userIsInstaller={userIsInstaller}
            loggedInUserId={loggedInUserId}
            quoteNumber={props?.formik?.values?.quote_number}
            handleInvoiceItems={handleInvoiceItems}
            invoiceItems={invoiceItems}
            projectInvoiceItems={projectInvoiceItems}
            CRMEnabled={CRMEnabled}
            isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
            isSalesCommissionOpted={props?.isSalesCommissionOpted}
            projectNumber={projectNumber}
            warehouseList={warehouseList}
            viewProductItemStatus={viewProductItemStatus}
            addProductItemStatus={addProductItemStatus}
            editProductItemStatus={editProductItemStatus}
          ></ProjectMerchandiseItemsGrid>
        </Card>
      )}

      {tabProjectItemsLaborView &&
        loggedInUserId &&
        CLIENT_DETAILS?.project_linkage?.enabled && (
          <Card
            className="mb-3"
            pt={{
              content: {
                className: 'p-0',
              },
            }}
          >
            <ProjectLaborItemsGrid
              projectId={projectId}
              projectNumber={projectNumber}
              projectInstallers={projectInstallers}
              systemStatuses={systemStatuses}
              updateParentLaborItems={setLaborItems}
              updateProjectItems={updateProjectItems}
              showLaborCostFields={showLaborCostFields}
              allLaborItemsCostField={allLaborItemsCostField}
              addAllLaborCostFields={addAllLaborCostFields}
              editAllLaborCostFields={editAllLaborCostFields}
              editLaborItemUsers={editLaborItemUsers}
              tabProjectItemsLaborAdd={tabProjectItemsLaborAdd}
              tabProjectItemsLaborModify={tabProjectItemsLaborModify}
              tabProjectItemsLaborDelete={tabProjectItemsLaborDelete}
              userIsInstaller={userIsInstaller}
              loggedInUserId={loggedInUserId}
              technicianVisibility={technicianVisibility}
              financeApproval={financeApproval}
              paidApproval={paidApproval}
              technicianFinanceApproval={technicianFinanceApproval}
              technicianPaidApproval={technicianPaidApproval}
              quoteNumber={props?.formik?.values?.quote_number}
              handleInvoiceItems={handleInvoiceItems}
              invoiceItems={invoiceItems}
              projectInvoiceItems={projectInvoiceItems}
              CRMEnabled={CRMEnabled}
              refereshLineItems={refereshLineItems}
              setRefereshLineItems={setRefereshLineItems}
              isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
              isSalesCommissionOpted={props?.isSalesCommissionOpted}
              warehouseList={warehouseList}
              isLinked={true}
              customerDetails={props?.customerDetails}
            ></ProjectLaborItemsGrid>
          </Card>
        )}

      {tabProjectItemsMerchandiseView &&
        loggedInUserId &&
        CLIENT_DETAILS?.project_linkage?.enabled && (
          <Card
            className="mb-3"
            pt={{
              content: {
                className: 'p-0',
              },
            }}
          >
            <ProjectMerchandiseItemsGrid
              projectId={projectId}
              updateParentMerchandiseItems={setMerchandiseItems}
              updateMerchandiseItems={updateMerchandiseItems}
              viewAllMerchandiseCost={viewAllMerchandiseCost}
              addAllMerchandiseCost={addAllMerchandiseCost}
              editAllMerchandiseCost={editAllMerchandiseCost}
              tabProjectItemsMerchandiseAdd={tabProjectItemsMerchandiseAdd}
              tabProjectItemsMerchandiseModify={
                tabProjectItemsMerchandiseModify
              }
              tabProjectItemsMerchandiseDelete={
                tabProjectItemsMerchandiseDelete
              }
              userIsInstaller={userIsInstaller}
              loggedInUserId={loggedInUserId}
              quoteNumber={props?.formik?.values?.quote_number}
              handleInvoiceItems={handleInvoiceItems}
              invoiceItems={invoiceItems}
              projectInvoiceItems={projectInvoiceItems}
              CRMEnabled={CRMEnabled}
              isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
              isSalesCommissionOpted={props?.isSalesCommissionOpted}
              projectNumber={projectNumber}
              warehouseList={warehouseList}
              viewProductItemStatus={viewProductItemStatus}
              addProductItemStatus={addProductItemStatus}
              editProductItemStatus={editProductItemStatus}
              isLinked={true}
              customerDetails={props?.customerDetails}
            ></ProjectMerchandiseItemsGrid>
          </Card>
        )}
    </div>
  );
}
