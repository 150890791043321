// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { Notifications } from '../../../constants/notification.constant';
import { updateNotificationViewed } from '../../shared/Topbar/Notification/Notification.service';
import { REACT_APP_ENABLE_PUSH_NOTIFICATION } from '../../../constants/envConstants';

// Get Audit Data
export const getProjectSMSData = async (
  projectId,
  setLoading,
  setSmsResponse
) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/sms/scheduledMessage`
    );

    setSmsResponse(response.data.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Audit Data
export const getProjectSMSHistory = async (projectId, setViewSMSLoading) => {
  try {
    setViewSMSLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/sms/history`
    );

    return response?.data?.data;
  } catch (error) {
    console.log(error);
  } finally {
    setViewSMSLoading(false);
  }
};

//Add Scheduled Message
export const createScheduledMessage = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setOpen,
  resetForm
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/sms/scheduledMessage`,
      values
    );
    if (response?.data?.status) {
      const notificationId = Array.isArray(response?.data?.data) ? response?.data?.data[0] : 0;
      resetForm();
      setReloadList(true);
      setAlert('success', 'Scheduled message added successfully.', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in adding Scheduled message.', false, true);
    setLoading(false);
  }
};

//Edit Scheduled Message
export const editScheduledMessage = async (
  projectId,
  values,
  sms_log_id,
  setLoading,
  setAlert,
  setReloadList,
  setOpen,
  resetForm
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/sms/${sms_log_id}/scheduledMessage`,
      values
    );

    if (response?.data?.status === true) {
      resetForm();
      setReloadList(true);
      setAlert('success', response?.data?.message, false, true);
    } else {
      setAlert('error', response?.data?.message, false, true);
      setLoading(false);
    }
  } catch (error) {
    setAlert('error', 'Error in updating Scheduled message.', false, true);
    setLoading(false);
  }
};

//Mark Message as read
export const markMessageAsRead = async (sms_log_id, clearAll) => {
  try {
    let query = `clearAll=${clearAll}`;

    if (sms_log_id) {
      query += `&id=${sms_log_id}`;
    }

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/customers/message/updateStatus?${query}`
    );
    if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
      await updateNotificationViewed(sms_log_id, Notifications.SMS, localStorage.getItem('user_id'), true, false);
    }
  } catch (error) {
    console.log(error);
  }
};

export const markMessageAsReviewed = async sms_log_id => {
  try {
    let query = `id=${sms_log_id}`;
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/customers/message/reviewed?${query}`
    );
    if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
      await updateNotificationViewed(sms_log_id, Notifications.SMS, localStorage.getItem('user_id'), false, true);
    }
  } catch (error) {
    console.log(error);
  }
};

//Delete Scheduled Message
export const deleteScheduledMessage = async (
  projectId,
  sms_log_id,
  setLoading,
  setAlert,
  setReloadList,
  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/sms/${sms_log_id}/scheduledMessage`
    );
    if (response) {
      setAlert('success', 'Message deleted successfully.', false, true);
      setReloadList(true);
    }
  } catch (error) {
    setAlert('error', 'Error in Deleting Message', false, true);
    setLoading(false);
  }
};

// Get Sms Count Data
export const getSMSCountData = async (
  projectId,
  setSMSCountLoading,
  setSmsCount
) => {
  try {
    setSMSCountLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/sms/sms-count`
    );

    if (response) {
      setSmsCount(response.data.data[0]);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setSMSCountLoading(false);
  }
};
