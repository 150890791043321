import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { AutoComplete } from 'primereact/autocomplete';
import { Skeleton } from 'primereact/skeleton';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import { InputSwitch } from 'primereact/inputswitch';
import axios from 'axios';
import { ContextMenu } from 'primereact/contextmenu';

import useToken from '../../../hooks/useToken';
import Task from '../../../components/TaskManagement/Task';
import PFLogo from '../../../assets/images/PFLogo.jpg';
import PFWhiteLogo from '../../../assets/images/PF-white-logo.png';
import {
  getUserProfile,
  searchProjects,
  storeUpdateReadStatus,
  installerReviewRead,
  storeReviewRead,
  installerUpdateReadStatus,
  getInstallerNotifications as getInstallerNotificationsLegacy,
  getSMSNotifications as getSMSNotificationsLegacy,
  getEmailNotifications as getEmailNotificationsLegacy,
  getStoreNotifications as getStoreNotificationsLegacy,
} from '../../../components/ProjectManagement/ProjectInfo/ProjectDetails.service';
import {
  checkPermission,
  disabledLoadMore,
  ellipsisString,
  redirectPage,
  lockGlobalBar,
  getLoginUserId,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { Limit, GLOBAL_SEARCH_PARAMETERS_OPTIONS } from '../../../constants';
import {
  setPermission,
  setUserTenants,
} from '../../../redux/slices/permission.slice';
import { emailReviewRead } from '../../ProjectManagement/EmailNotifications/EmailNotifications.service';
import {
  markMessageAsRead,
  markMessageAsReviewed,
} from '../../ProjectManagement/SMS/SmsNotifications.service';
import { citiesData } from '../../../utils/CitiesListData';
import BulletinMarquee from '../../Admin/Bulletin/BulletinMarquee';
import {
  setTabs,
  setClientId,
  setCallNotification,
} from '../../../redux/slices/page-header.slice';
import clearMasterDataHook from '../../../redux/slices/master/clearMasterDataHook';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import GlobalFilters from '../../GlobalFilters/GlobalFilters';
import UserSettingsDialog from '../../Admin/Settings/UserSettingsDialog';
import { Notifications } from '../../../constants/notification.constant';
import {
  REACT_APP_ENABLE_PUSH_NOTIFICATION,
  REACT_APP_ENABlE_ONLY_FOR_PF_USER,
} from '../../../constants/envConstants';

import {
  getInstallerNotifications,
  getStoreNotifications,
  updateNotificationViewed,
  getEmailNotifications,
  getSMSNotifications,
} from './Notification/Notification.service';
import Notification from './Notification/Notification';
import PanelMenuList from './SideMenu/PanelMenuList';
import CxScheduleAlert from './CxScheduleAlert';
import SideMenuList from './SideMenu/SideMenuList';
import Tanent from './Notification/Tenant';

// Start check brower size
const checkWindowWidth = () => {
  const browserWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return browserWidth;
};
window.addEventListener('resize', checkWindowWidth);

if (checkWindowWidth() < 1200) {
  localStorage.setItem('sidebar-layout', 0);
}

const Topbar = ({ DrawerWidths, slideDrawer }) => {
  const cm = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [visibleRight, setVisibleRight] = useState(false);
  const { accessToken, logout } = useToken();
  const [userProfile, setUserProfile] = React.useState({});
  const [value, setValue] = useState('');
  const [items, setItems] = useState([]);

  const [visible, setVisible] = useState(false);
  const [searchParameter, setSearchParameter] =
    React.useState('project_number');
  const toast = useRef(null);
  const autoRef = useRef(null);
  const [options, setOptions] = React.useState([]);
  const [installerNotificationsList, setInstallerNotificationsList] =
    React.useState([]);
  const [storeNotificationsList, setStoreNotificationsList] = React.useState(
    []
  );
  const [notificationsList, setNotificationsList] = React.useState([]);
  const [emailList, setEmailList] = React.useState([]);
  const [showInstallerLoadMore, setShowInstallerLoadMore] =
    React.useState(true);
  const [SMSLoading, setSMSLoading] = React.useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [globalSearchLoader, setGlobalSearchLoader] = useState(false);

  const [storeLoading, setStoreLoading] = React.useState(false);
  const [installerLoading, setInstallerLoading] = React.useState(false);
  const [emailLoading, setEmailLoading] = React.useState(false);

  const [installerOffset, setInstallerOffset] = React.useState(0);
  const [storeOffset, setStoreOffset] = React.useState(0);
  const [smsOffset, setSmsOffset] = React.useState(0);
  const [emailOffset, setEmailOffset] = React.useState(0);
  const isPFUser = JSON.parse(localStorage.getItem('isPFUser')) ?? false;
  const [pushNotificationEnabled, setPushNotificationEnabled] = useState(
    REACT_APP_ENABlE_ONLY_FOR_PF_USER === false
      ? REACT_APP_ENABLE_PUSH_NOTIFICATION
      : isPFUser
  );
  const [enableBeta, setEnableBeta] = React.useState(
    localStorage.getItem('enableBeta')
      ? JSON.parse(localStorage.getItem('enableBeta'))
      : pushNotificationEnabled
  );
  // Tenant
  const [user_id, setUserId] = useState('');

  const [firstTenantsName, setFirstTenantsName] = useState('');
  const [tenants, setTenants] = useState([]);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [searchLength, setSearchLength] = useState(0);
  const menuRight = useRef(null);
  const [selectedCityName, setSelectedCityName] = useState(null);
  const [addressSearch, setAddressSearch] = useState('');
  const [isHardRefresh, setIsHardRefresh] = useState(0);
  const [searchSuggession, setSearchSuggession] = useState(
    localStorage.getItem('search-suggession')
      ? parseInt(localStorage.getItem('search-suggession'))
      : 0
  );
  const getClientId = localStorage.getItem('client_id');
  const [sideBarLayout, setSideBarLayout] = React.useState(
    localStorage.getItem('sidebar-layout') || 1
  );
  const [imgError, setImgError] = useState(false);

  const [searchTenants, setSearchTenants] = useState('');
  const [headerLoader, setHeaderLoader] = useState(true);
  const [storeCount, setStoreCount] = useState();
  const [emailCount, setEmailCount] = useState();
  const [smsCount, setSmsCount] = useState();
  const currentURL = window.location.href;

  const urlRegExp = /[\?&]searchType=([^&]+)&searchValue=([^&]+)/;

  const match = currentURL.match(urlRegExp);

  // total notification counts hooks
  const [totalInstallerNotificationCount, setTotalInstallerNotificationCount] =
    useState(0);

  // Check if there is a match
  const [totalInstallercount, setTotalInstallercount] = useState(1);
  const [totalStoreCount, setTotalStoreCount] = useState(1);
  const [totalEmailCount, setTotalEmailCount] = useState(1);
  const [totalSmsCount, setTotalSmsCount] = useState(1);
  const [showUserSettings, setshowUserSettings] = useState(false);

  const { tabs, callNotification } = useSelector(state => state.pageHeader);
  const clearMasterData = clearMasterDataHook();
  React.useEffect(() => {
    if (match) {
      const searchType = match[1];
      const searchValue = match[2];
      let decodedAddress = decodeURIComponent(searchValue.replace(/%20/g, ' '));

      if (currentURL) {
        setValue(decodedAddress);
        setSearchParameter(searchType);
      } else {
        setValue('');
        setSearchParameter('');
      }
    }
  }, [currentURL]);

  const show = () => {
    value && toast.current.show({ severity: 'info', summary: 'No data found' });
  };
  const clearToast = () => {
    toast.current.clear();
  };

  const handleLogoError = () => {
    setImgError(true);
  };
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  const onSearchSuggessionHandler = param => {
    setSearchSuggession(param ? 1 : 0);
    localStorage.setItem('search-suggession', param ? 1 : 0);
  };

  const onSideBarLayoutChange = param => {
    setSideBarLayout(param ? 1 : 0);
    localStorage.setItem('sidebar-layout', param ? 1 : 0);
  };

  useEffect(() => {
    if (options.length === 0 && searchLength >= 3) {
      clearToast();
      show();
    }
  }, [options]);
  useEffect(() => {
    autoRef.current?.show();
  }, [isHardRefresh]);

  const getLegacyNotificationData = () => {
    getInstallerNotificationsLegacy(
      setInstallerLoading,
      setInstallerNotificationsList,
      installerOffset,
      10,
      installerNotificationsList,
      setShowInstallerLoadMore,
      setTotalInstallerNotificationCount
    );
    getStoreNotificationsLegacy(
      setStoreLoading,
      setStoreNotificationsList,
      storeOffset,
      10,
      setStoreCount
    );
    getSMSNotificationsLegacy(
      setSMSLoading,
      setNotificationsList,
      smsOffset,
      10,
      setSmsCount
    );
    getEmailNotificationsLegacy(
      setEmailLoading,
      setEmailList,
      emailOffset,
      10,
      setEmailCount
    );
  };

  const getNotificationData = () => {
    setInstallerLoading(true);
    getInstallerNotifications(
      0,
      Limit.MAX_LIMIT,
      installerNotificationsList
    ).then(res => {
      setInstallerNotificationsList(res.installerList);
      setTotalInstallerNotificationCount(res.count);
      if (res.installerList < Limit.MAX_LIMIT) {
        setShowInstallerLoadMore(false);
      }
    });
    setInstallerLoading(false);
    setStoreLoading(true);
    getStoreNotifications(0, Limit.MAX_LIMIT).then(res => {
      setStoreNotificationsList(res.storeList);
      setStoreCount(res.count);
      setStoreLoading(false);
    });
    setSMSLoading(true);
    getSMSNotifications(0, Limit.MAX_LIMIT).then(res => {
      setNotificationsList(res.data);
      setSmsCount(res.count);
      setSMSLoading(false);
    });
    setEmailLoading(true);
    getEmailNotifications(0, Limit.MAX_LIMIT).then(res => {
      setEmailList(res.emailList);
      setEmailCount(res.count);
      setEmailLoading(false);
    });
  };

  const fetchNotification = () => {
    if (enableBeta) getNotificationData();
    else getLegacyNotificationData();
  };

  React.useEffect(() => {
    if (axios.defaults.headers.common['client_id']) {
      getUserProfile(setUserProfile);
      fetchNotification();
    }
  }, [axios.defaults.headers.common['client_id']]);

  React.useEffect(() => {
    if (callNotification === true) {
      fetchNotification();
      dispatch(setCallNotification(false));
    }
  }, [callNotification]);

  React.useEffect(() => {
    if (axios.defaults.headers.common['client_id'] && userId) {
      fetchNotification();
      setInstallerOffset(0);
      setStoreOffset(0);
      setSmsOffset(0);
      setEmailOffset(0);
    }
  }, [enableBeta]);

  const installerNotificationsLength = totalInstallerNotificationCount;

  useEffect(() => {
    if (accessToken) {
      setUserId(getLoginUserId());
      getAllUserTenants();
    }
  }, [user_id]);
  useEffect(() => {
    setFirstTenantsName(localStorage.getItem('client_name'));
  }, [localStorage.getItem('client_name')]);

  const getAllUserTenants = async () => {
    if (user_id) {
      const response = await axios.get(
        `${URL_CONSTANTS.LOGIN.baseUrl}/get-all-user-tenants?user_id=${user_id}`
      );
      if (response?.data) {
        setTenants(response?.data);
        dispatch(setUserTenants(response?.data));
        let firstTenantsId = response?.data[0]?.client_id;
        let firstTenantsName = response?.data[0]?.client_name;
        if (
          localStorage.getItem('client_id') != undefined ||
          localStorage.getItem('client_id') != null
        ) {
          firstTenantsId = localStorage.getItem('client_id');
          firstTenantsName = localStorage.getItem('client_name');
        } else {
          localStorage.setItem('client_id', firstTenantsId);
          localStorage.setItem('client_name', firstTenantsName);
        }

        dispatch(setClientId(firstTenantsId));
        getAllUserPermissionsForTenant(firstTenantsId);

        setFirstTenantsName(firstTenantsName);
      } else {
        logout();
      }
    }
  };

  const getAllUserPermissionsForTenant = async tenant_id => {
    if (user_id) {
      try {
        const response = await axios.get(
          `${URL_CONSTANTS.LOGIN.baseUrl}/get-all-user-permissions-for-tenant?client_id=${tenant_id}&user_id=${user_id}`
        );
        if (response?.data) {
          dispatch(setPermission(response.data));
          localStorage.setItem('permissions', JSON.stringify(response?.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const switchTenant = async (client_id, client_name) => {
    clearMasterData();
    localStorage.setItem('client_id', client_id);
    localStorage.setItem('client_name', client_name);
    dispatch(setClientId(String(client_id)));
    getAllUserPermissionsForTenant(client_id).then(() => {
      if (!tabs?.[String(client_id)])
        dispatch(setTabs({ ...tabs, [String(client_id)]: [] }));

      if (history.location.pathname !== '/dashboard') {
        history.push('/dashboard');
        window.location.reload();
        setVisibleRight(false);
      } else {
        window.location.reload();
      }
    });
  };

  const removeElementByNoteId = (arr, note_id) => {
    return arr.filter(item => item.note_id != note_id);
  };

  const setViewStatusByNoteId = (arr, note_id) => {
    return arr.map(item => {
      if (item.note_id === note_id) {
        item.is_read = 1;
      }
      return item;
    });
  };

  const handleInstallerNotificationClick = (project_id, note_id, type) => {
    if (type === 'review') {
      installerReviewRead(note_id, type, localStorage.getItem('user_id'));
      if (pushNotificationEnabled === true) {
        updateNotificationViewed(
          note_id,
          Notifications.INSTALLER,
          localStorage.getItem('user_id'),
          false,
          true
        ).then(() => {
          dispatch(setCallNotification(true));
        });
      }
      setInstallerNotificationsList(
        removeElementByNoteId(installerNotificationsList, note_id)
      );
    } else if (type === 'read') {
      installerUpdateReadStatus(note_id, type, localStorage.getItem('user_id'));
      if (pushNotificationEnabled === true) {
        updateNotificationViewed(
          note_id,
          Notifications.INSTALLER,
          localStorage.getItem('user_id'),
          true,
          false
        ).then(() => {
          dispatch(setCallNotification(true));
        });
      }
      setInstallerNotificationsList(
        setViewStatusByNoteId(installerNotificationsList, note_id)
      );
    }
    redirectPage(`/project/view/${project_id}/#tab=notes`);
  };

  const handleStoreNotificationClick = (project_id, note_id, type) => {
    if (type === 'review') {
      storeReviewRead(note_id, type, localStorage.getItem('user_id'));
      if (pushNotificationEnabled === true) {
        updateNotificationViewed(
          note_id,
          Notifications.STORE,
          localStorage.getItem('user_id'),
          false,
          true
        ).then(() => {
          dispatch(setCallNotification(true));
        });
      }
      setStoreNotificationsList(
        removeElementByNoteId(storeNotificationsList, note_id)
      );
    } else if (type === 'read') {
      storeUpdateReadStatus(note_id, type, localStorage.getItem('user_id'));
      if (pushNotificationEnabled === true) {
        updateNotificationViewed(
          note_id,
          Notifications.STORE,
          localStorage.getItem('user_id'),
          true,
          false
        ).then(() => {
          dispatch(setCallNotification(true));
        });
      }
      setStoreNotificationsList(
        setViewStatusByNoteId(storeNotificationsList, note_id)
      );
    }
    redirectPage(`/project/view/${project_id}/#tab=notes`);
  };

  const handleNotificationClick = async (project_id, sms_response_log_id) => {
    await markMessageAsRead(sms_response_log_id, false);
    dispatch(setCallNotification(true));
    redirectPage(`/project/view/${project_id}/#tab=sms`);
  };

  const handleNotificationClickReview = async (
    project_id,
    sms_response_log_id
  ) => {
    await markMessageAsReviewed(sms_response_log_id);
    dispatch(setCallNotification(true));
    redirectPage(`/project/view/${project_id}/#tab=sms`);
  };

  const handleEmailNotificationOnReview = async (project_id, message_id) => {
    await emailReviewRead(message_id, 'review');
    dispatch(setCallNotification(true));
    redirectPage(`/project/view/${project_id}/#tab=email`);
  };

  const handleEmailNotificationClick = async (project_id, message_id) => {
    await emailReviewRead(message_id, 'read');
    dispatch(setCallNotification(true));
    redirectPage(`/project/view/${project_id}/#tab=email`);
  };

  const handleUserSettingsVisibility = value => {
    setshowUserSettings(value);
  };

  const search = () => {
    setItems(
      options?.map(item => {
        return (
          <div>{item?.project_number ? item?.project_number : item?.city}</div>
        );
      })
    );
  };

  const contextMenuItems = [
    {
      label: 'Open In New Tab',
      icon: 'pi pi-directions',
      command: () => {
        handleSearch('_blank');
      },
    },
  ];

  const itemTemplate = item => {
    return (
      <div className="text-xs border-top-none border-left-none border-right-none border-cyan-50 py-0">
        <div className="flex justify-content-between align-items-center">
          {item?.city ? (
            <p className="my-0 font-semibold  first_name">{`${item?.city}, ${item?.state_name}`}</p>
          ) : (
            ''
          )}
          {item?.project_number ? (
            <p className="my-0 font-semibold project_number-name pt-1 pr-2">
              {item?.project_number}
            </p>
          ) : (
            <p className="my-0 font-semibold project_number-name">
              {item?.customer_name}
            </p>
          )}

          {item?.mobile_phone ? (
            <p className="my-0 mobile_phone" style={{ color: '#014784' }}>
              {item?.mobile_phone}
            </p>
          ) : (
            ''
          )}

          {item?.project_type?.project_type || item?.status?.status ? (
            <div className="text-center">
              {item?.status?.status ? (
                item?.status?.status === 'Scheduled' ? (
                  <Tag
                    severity="success"
                    className="justify-content-center font-light"
                  >
                    {item?.status?.status}
                  </Tag>
                ) : (
                  <Tag
                    severity="info"
                    className="justify-content-center font-light"
                  >
                    {item?.status?.status}
                  </Tag>
                )
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>

        {item?.po_number ? (
          <div className="flex justify-content-between align-items-center mt-1">
            {item?.po_number ? (
              <p className="my-0 po_number">PO# {item?.po_number}</p>
            ) : (
              ''
            )}

            <Tag className="justify-content-center font-light">
              {item?.project_type?.project_type}
            </Tag>
          </div>
        ) : (
          ''
        )}

        {/*  */}
        {item?.customer?.first_name ? (
          <p className="my-1 first_name">
            {item?.customer?.first_name} {item?.customer?.last_name}
          </p>
        ) : (
          ''
        )}

        {item?.billing_address ? (
          <p className="my-0 billing_address">{item?.billing_address}</p>
        ) : (
          ''
        )}

        {item?.installation_address?.address1 ? (
          <p className="my-0 address1">
            {item?.installation_address?.address1}
          </p>
        ) : (
          ''
        )}

        {item?.created_user?.google_email_address ||
          item?.created_user?.contact_no ? (
          item?.created_user?.google_email_address ? (
            <p className="google_email_address">
              {item?.created_user?.google_email_address}
            </p>
          ) : (
            <p className="contact_no">Ph: {item?.created_user?.contact_no}</p>
          )
        ) : (
          ''
        )}
      </div>
    );
  };

  // #### load More Start
  const loadMoreInstallerHandler = () => {
    setTotalInstallercount(totalInstallercount + 1);
    const newOffset = installerOffset + Limit?.MAX_LIMIT;
    if (enableBeta === true && newOffset < 100) {
      setInstallerLoading(true);
      getInstallerNotifications(
        newOffset,
        Limit?.MAX_LIMIT,
        installerNotificationsList
      ).then(res => {
        setInstallerNotificationsList([
          ...installerNotificationsList,
          ...res.installerList,
        ]);
        setTotalInstallerNotificationCount(res.count);
        if (res.installerList < Limit?.MAX_LIMIT) {
          setShowInstallerLoadMore(false);
        }
        setInstallerLoading(false);
      });
    } else {
      getInstallerNotificationsLegacy(
        setInstallerLoading,
        setInstallerNotificationsList,
        newOffset,
        Limit?.MAX_LIMIT,
        installerNotificationsList,
        setShowInstallerLoadMore,
        setTotalInstallerNotificationCount
      );
    }
    setInstallerOffset(newOffset);
  };

  const loadMoreStoreHandler = () => {
    setTotalStoreCount(totalStoreCount + 1);
    const newOffset = storeOffset + Limit?.MAX_LIMIT;
    if (enableBeta === true && newOffset < 100) {
      setStoreLoading(true);
      getStoreNotifications(newOffset, Limit?.MAX_LIMIT, setStoreCount).then(
        res => {
          setStoreNotificationsList([
            ...storeNotificationsList,
            ...res.storeList,
          ]);
          setStoreCount(res.count);
          setStoreLoading(false);
        }
      );
    } else {
      getStoreNotificationsLegacy(
        setStoreLoading,
        setStoreNotificationsList,
        newOffset,
        Limit?.MAX_LIMIT,
        setStoreCount
      );
    }
    setStoreOffset(newOffset);
  };

  const loadMoreEmailHandler = () => {
    setTotalEmailCount(totalEmailCount + 1);
    const newOffset = emailOffset + Limit?.MAX_LIMIT;
    if (enableBeta === true && newOffset < 100) {
      setEmailLoading(true);
      getEmailNotifications(newOffset, Limit?.MAX_LIMIT).then(res => {
        setEmailList([...emailList, ...res.emailList]);
        setEmailCount(res.count);
        setEmailLoading(false);
      });
    } else {
      getEmailNotificationsLegacy(
        setEmailLoading,
        setEmailList,
        newOffset,
        Limit?.MAX_LIMIT,
        setEmailCount
      );
    }
    setEmailOffset(newOffset);
  };

  const loadMoreSmsHandler = () => {
    setTotalSmsCount(totalSmsCount + 1);
    const newOffset = smsOffset + Limit?.MAX_LIMIT;
    if (enableBeta === true && newOffset < 100) {
      getSMSNotifications(newOffset, Limit?.MAX_LIMIT).then(res => {
        setNotificationsList([...notificationsList, ...res.data]);
        setSmsCount(res.count);
      });
    } else {
      getSMSNotificationsLegacy(
        setSMSLoading,
        setNotificationsList,
        newOffset,
        Limit?.MAX_LIMIT,
        setSmsCount
      );
    }
    setSmsOffset(newOffset);
  };
  // #### load More End

  // #### Installer notification start
  const mapInstallerNotifications = () => {
    const data =
      installerNotificationsList && installerNotificationsList?.length > 0
        ? installerNotificationsList?.map((row, index) => {
          return {
            template: (item, options) => {
              return renderInstallerStoreTemplate(
                false,
                'store',
                row?.note_id,
                row?.name,
                `${row?.customer_first_name} ${row?.customer_last_name}`,
                row?.note,
                row?.is_read,
                row?.project_id,
                index,
                installerNotificationsList?.length
              );
            },
          };
        })
        : [
          {
            icon: 'pi pi-search',
            label: 'No result found',
          },
        ];
    return data;
  };

  const renderInstallerStoreTemplate = (
    isEmpty,
    source,
    id,
    from,
    customer,
    message,
    is_read,
    project_id,
    index,
    length
  ) => {
    return isEmpty ? (
      <div
        className="flex px-3 py-2 align-items-center"
        key={'notify_' + source + '_' + id}
      >
        <div className="px-3 text-xs">
          <strong>No new notifications</strong>
        </div>
      </div>
    ) : (
      <>
        <div
          className={`flex pl-3 py-2 align-items-center w-12 ${Number(is_read) ? 'bg-white' : 'surface-100'
            }`}
          key={'notify_' + source + '_' + id}
        >
          <div className="text-xs col-7 p-0 pr-2">
            {from !== 'null null' ? (
              <>
                <i className="pi pi-send mr-2 text-xs"></i>
                <strong>Sent From: {from}</strong>
              </>
            ) : (
              ''
            )}

            {customer !== null ? (
              <>
                <p className="mb-0 mt-1">
                  <i className="pi pi-user mr-2 text-xs"></i>Cust. Name:{' '}
                  {customer}
                </p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="flex col-5 pl-0">
            <Button
              onClick={() =>
                handleInstallerNotificationClick(project_id, id, 'review')
              }
              label="Review"
              className="text-xs p-2 font-bold bg-transparent focus:shadow-none"
              pt={{
                label: {
                  className: 'text-primary',
                },
              }}
              text
            />
            <Button
              onClick={() =>
                handleInstallerNotificationClick(project_id, id, 'read')
              }
              label="View"
              className="text-xs p-2 font-bold bg-transparent focus:shadow-none"
              pt={{
                label: {
                  className: 'text-primary',
                },
              }}
              text
            />
          </div>
        </div>
        <div
          className={`${parseInt(index) == parseInt(length - 1)
            ? 'block  flex justify-content-center surface-200 p-2'
            : 'hidden'
            }`}
        >
          <Button
            size="small"
            outlined
            severity="info"
            className="py-1 px-2"
            onClick={loadMoreInstallerHandler}
            disabled={
              disabledLoadMore(totalInstallerNotificationCount) ==
              totalInstallercount || installerLoading
            }
          >
            Load more
            {installerLoading ? (
              <i
                className="pi pi-spin pi-spinner ml-2"
                style={{ fontSize: '10px' }}
              ></i>
            ) : (
              ''
            )}
          </Button>
        </div>
      </>
    );
  };
  // #### Installer notification end

  // #### Store notification start
  const mapStoreNotificationsList = () => {
    const data =
      storeNotificationsList && storeNotificationsList?.length > 0
        ? storeNotificationsList?.map((row, index) => {
          return {
            template: (item, options) => {
              return renderStoreTemplate(
                false,
                'store',
                row?.note_id,
                row?.name,
                `${row?.customer_first_name} ${row?.customer_last_name}`,
                row?.note,
                row?.is_read,
                row?.project_id,
                index,
                storeNotificationsList?.length
              );
            },
          };
        })
        : [
          {
            icon: 'pi pi-search',
            label: 'No result found',
          },
        ];
    return data;
  };

  const renderStoreTemplate = (
    isEmpty,
    source,
    id,
    from,
    customer,
    message,
    is_read,
    project_id,
    index,
    length
  ) => {
    return isEmpty ? (
      <div
        className="flex px-3 py-2 align-items-center"
        key={'notify_' + source + '_' + id}
      >
        <div className="px-3 text-xs">
          <strong>No new notifications</strong>
        </div>
      </div>
    ) : (
      <>
        <div
          className={`flex px-3 py-2 align-items-center w-12 ${Number(is_read) ? 'bg-white' : 'surface-100'
            }`}
          key={'notify_' + source + '_' + id}
        >
          <div className="text-xs col-8 p-0 pr-2">
            {from !== 'null null' ? (
              <>
                <i className="pi pi-send mr-2 text-xs"></i>
                <strong>Sent From: {from}</strong>
              </>
            ) : (
              ''
            )}

            {customer !== null ? (
              <>
                <p className="mb-0 mt-1">
                  <i className="pi pi-user mr-2 text-xs"></i>Cust. Name:{' '}
                  {customer}
                </p>
              </>
            ) : (
              ''
            )}
          </div>
          {/* <div className="flex col-4"> */}
          <div className="flex col-4 pl-0">
            <Button
              onClick={() =>
                handleStoreNotificationClick(project_id, id, 'review')
              }
              label="Review"
              className="text-xs p-2 font-bold bg-transparent focus:shadow-none"
              text
            />
            <Button
              onClick={() =>
                handleStoreNotificationClick(project_id, id, 'read')
              }
              label="View"
              className="text-xs p-2 font-bold bg-transparent focus:shadow-none"
              text
            />
          </div>
        </div>
        <div
          className={`${parseInt(index) == parseInt(length - 1)
            ? 'block  flex justify-content-center surface-200 p-2'
            : 'hidden'
            }`}
        >
          <Button
            size="small"
            outlined
            severity="info"
            className="py-1 px-2"
            onClick={loadMoreStoreHandler}
            disabled={
              disabledLoadMore(storeCount) == totalStoreCount || storeLoading
            }
          >
            Load more
            {storeLoading ? (
              <i
                className="pi pi-spin pi-spinner ml-2"
                style={{ fontSize: '10px' }}
              ></i>
            ) : (
              ''
            )}
          </Button>
        </div>
      </>
    );
  };
  // #### Store notification end ----------------------------

  // #### Email notification start
  const mapEmailNotificationsList = () => {
    const data =
      emailList && emailList?.length > 0
        ? emailList?.map((row, index) => {
          return {
            template: (item, options) => {
              return renderEmailTemplate(
                false,
                'email',
                row?.client_id,
                row?.LastEmail?.from[0]?.name,
                `${row?.customer_first_name} ${row?.customer_last_name}`,
                row?.subject,
                row?.total_email,
                row?.project_id,
                row?.id,
                row?.LastEmail?.read,
                index,
                emailList?.length
              );
            },
          };
        })
        : [
          {
            icon: 'pi pi-search',
            label: 'No email found',
          },
        ];
    return data;
  };

  const renderEmailTemplate = (
    isEmpty,
    source,
    id,
    fromName,
    toName,
    subject,
    total_email,
    project_id,
    message_id,
    is_read,
    index,
    length
  ) => {
    return isEmpty ? (
      <div
        className="flex px-3 py-2 align-items-center"
        key={'notify_' + source + '_' + id}
      >
        <div className="px-3 text-xs">
          <strong>No new notifications</strong>
        </div>
      </div>
    ) : (
      <>
        <div
          className={`flex px-3 py-2 align-items-center w-12 ${Number(is_read) ? 'bg-white' : 'surface-100'
            }`}
          key={'notify_' + source + '_' + id}
        >
          <div className="col-1 pl-0 text-xs">
            <Badge
              value={total_email ? total_email : '0'}
              severity="primary"
            ></Badge>
          </div>
          <div className="text-xs col-6 p-0 pr-2">
            {fromName !== 'null null' ? (
              <>
                <i className="pi pi-send mr-2 text-xs"></i>
                <strong>Sent From: {fromName}</strong>
              </>
            ) : (
              ''
            )}

            {toName !== 'null null' ? (
              <>
                <p className="mb-0 mt-1">
                  <i className="pi pi-user mr-2 text-xs"></i>Cust. Name:{' '}
                  {toName}
                </p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="flex col-4 pl-0">
            <Button
              onClick={() =>
                handleEmailNotificationOnReview(project_id, message_id)
              }
              label="Review"
              className="text-xs p-2 font-bold bg-transparent focus:shadow-none"
              text
            />
            <Button
              onClick={() =>
                handleEmailNotificationClick(project_id, message_id)
              }
              label="View"
              className="text-xs p-2 font-bold bg-transparent focus:shadow-none"
              text
            />
          </div>
          <div className="flex px-3 pb-3 text-xs">
            {subject && subject === null ? (
              <div className="col-12 p-0">Subject: {subject}</div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div
          className={`${parseInt(index) == parseInt(length - 1)
            ? 'block  flex justify-content-center surface-200 p-2'
            : 'hidden'
            }`}
        >
          <Button
            size="small"
            outlined
            severity="info"
            className="py-1 px-2"
            onClick={loadMoreEmailHandler}
            disabled={
              disabledLoadMore(emailCount) == totalEmailCount || emailLoading
            }
          >
            Load more
            {emailLoading ? (
              <i
                className="pi pi-spin pi-spinner ml-2"
                style={{ fontSize: '10px' }}
              ></i>
            ) : (
              ''
            )}
          </Button>
        </div>
      </>
    );
  };
  // #### Email notification end

  // #### SMS notification start  ----------------
  const mapSmsNotificationsList = () => {
    const data =
      notificationsList && notificationsList?.length > 0
        ? notificationsList?.map((row, index) => {
          return {
            template: (item, options) => {
              return renderSmsTemplate(
                false,
                'sms',
                row?.client_id,
                row?.customer_first_name,
                row?.customer_last_name,
                row?.subject,
                row?.total_email,
                row?.sender_number,
                row?.project_id,
                row?.sms_response_log_id,
                row?.is_read,
                row?.response_message,
                index,
                notificationsList?.length
              );
            },
          };
        })
        : [
          {
            icon: 'pi pi-search',
            label: 'No result found',
          },
        ];
    return data;
  };

  const renderSmsTemplate = (
    isEmpty,
    source,
    id,
    customer_first_name,
    customer_last_name,
    subject,
    total_email,
    sender_number,
    project_id,
    sms_response_log_id,
    is_read,
    response_message,
    index,
    length
  ) => {
    return isEmpty ? (
      <div
        className="flex px-3 py-2 align-items-center"
        key={'notify_' + source + '_' + id}
      >
        <div className="px-3 text-xs">
          <strong>No new notifications</strong>
        </div>
      </div>
    ) : (
      <>
        <div
          className={`pl-3 pr-1 pt-2 align-items-center w-12 ${Number(is_read) ? 'bg-white' : 'surface-100'
            }`}
          key={'notify_' + source + '_' + id}
        >
          <div className="grid m-0">
            <div className="col-8 flex text-xs pl-0">
              <i className="pi pi-user mr-2 text-xs"></i>
              <strong>
                CX Name: {customer_first_name} {customer_last_name}
              </strong>
            </div>
            <div className="col-4 flex p-0 w-0 justify-content-end">
              <Button
                onClick={() =>
                  handleNotificationClickReview(project_id, sms_response_log_id)
                }
                label="Review"
                className="text-xs p-1 font-bold bg-transparent focus:shadow-none"
                text
              />
              <Button
                onClick={() =>
                  handleNotificationClick(project_id, sms_response_log_id)
                }
                label="View"
                className="text-xs p-1 font-bold bg-transparent focus:shadow-none"
                text
              />
            </div>
          </div>
          <div className="text-xs col-12 p-0 pr-2">
            <i className="pi pi-send mr-2 text-xs"></i>
            {ellipsisString(response_message, 45)}
          </div>
          <div className="flex px-3 pb-3 text-xs">
            {subject && subject === null ? (
              <div className="col-12 p-0">Subject: {subject}</div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div
          className={`${parseInt(index) == parseInt(length - 1)
            ? 'block  flex justify-content-center surface-200 p-2'
            : 'hidden'
            }`}
        >
          <Button
            size="small"
            outlined
            severity="info"
            className="py-1 px-2"
            onClick={loadMoreSmsHandler}
            disabled={disabledLoadMore(smsCount) == totalSmsCount || SMSLoading}
          >
            Load more
            {SMSLoading ? (
              <i
                className="pi pi-spin pi-spinner ml-2"
                style={{ fontSize: '10px' }}
              ></i>
            ) : (
              ''
            )}
          </Button>
        </div>
      </>
    );
  };
  // #### SMS notification end -----------------
  const renderProfileImage = () => {
    return imageLoadError ? (
      <img
        src="/images/avatar.png"
        className="cursor-pointer shadow-4"
        alt="avatar"
        height={36}
        width={36}
      />
    ) : (
      <Avatar
        label={userProfile?.first_name?.charAt(0).toUpperCase()}
        shape="circle"
        pt={{
          root: {
            className:
              'blue-bg-100 text-primary font-bold hidden lg:inline-flex',
          },
        }}
      />
    );
  };

  const handleSearch = (redirectionMethod = '_self', searchValue = value) => {
    if (searchValue) {
      const searchLength = searchValue?.length;
      const searchResultLength = searchValue?.length;
      setSearchLength(searchValue?.length);
      if (searchResultLength >= 3) {
        searchProjects(
          searchValue,
          searchParameter,
          redirectionMethod,
          history,
          setOptions,
          undefined,
          undefined,
          setGlobalSearchLoader,
          show
        );
      }
      let val = searchValue;
      if (typeof searchValue !== 'string') {
        if (['project_number', 'po_number'].includes(searchParameter)) {
          val = searchValue?.project_number;
        } else {
          val = searchValue?.customer_name;
        }
      }
      setValue(val ? val?.trim() : '');

      if (searchValue?.customer_id || searchValue?.project_id) {
        const url = !['project_number', 'po_number', 'address'].includes(
          searchParameter
        )
          ? `/crm/view/customer/${searchValue?.customer_id}`
          : `/project/view/${searchValue?.project_id}`;
        redirectPage(
          `${url}?searchType=${searchParameter}&searchValue=${val}`,
          redirectionMethod
        );
      }
    }
  };

  const filteredTenant = tenants?.filter(item =>
    item?.client_name?.toLowerCase()?.includes(searchTenants?.toLowerCase())
  );

  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    if (userId) {
      setHeaderLoader(false);
    } else {
      setHeaderLoader(false);
    }
  }, [userId]);

  const headerStyle = useMemo(() => {
    return lockGlobalBar();
  }, [clientDetails]);

  const handleBetaChange = e => {
    localStorage.setItem('enableBeta', !enableBeta);
    setEnableBeta(!enableBeta);
  };
  return (
    <>
      <Toast
        life={3000}
        className="search-notification"
        ref={toast}
        position="top-right"
      />
      {headerLoader ? (
        <div
          className="flex justify-content-around mt-1 justify-content-between"
          style={{ paddingLeft: '230px' }}
        >
          <Skeleton width="18%" className="mb-2 h-4rem"></Skeleton>
          <Skeleton width="60%" className="mb-2 h-4rem"></Skeleton>
          <Skeleton width="18%" className="mb-2 h-4rem"></Skeleton>
        </div>
      ) : (
        <header
          className={`grid grid-nogutter align-items-center transition-ease-in-out transition-duration-500 sm:h-4rem ${headerStyle ? 'fixed w-full' : ''
            }`}
          style={{
            paddingLeft: slideDrawer ? '0px' : '230px',
            backgroundColor: '#0062B8',
            zIndex: headerStyle ? '1000' : {},
          }}
        >
          <div className="row flex w-full align-items-center ">
            <div className="col-6 sm:col-3 md:col-3 xl:col-3 inline-flex align-items-center flex-order-0 max-h-2rem object-fit-cover">
              {checkWindowWidth() > 1199 ? (
                imgError || clientDetails?.logo === null ? (
                  <Image
                    alt="Projects Force"
                    src={PFWhiteLogo}
                    className={`ml-2 flex align-items-center hidden cursor-pointer ${parseInt(sideBarLayout) ? 'hidden' : 'block'
                      }`}
                    onClick={() => history.push('/dashboard')}
                    pt={{
                      image: {
                        className: 'p-1 max-w-7rem w-7rem max-h-3rem',
                      },
                    }}
                  />
                ) : (
                  <a href="/dashboard" rel="noreferrer" className="mr-3">
                    <Image
                      alt={clientDetails?.client_name}
                      className={`max-h-3rem max-w-7rem ml-2 hidden ${parseInt(sideBarLayout) ? 'hidden' : 'block'
                        }`}
                      src={clientDetails?.logo}
                      onError={handleLogoError}
                      pt={{
                        image: {
                          className: 'max-w-7rem max-h-3rem',
                        },
                      }}
                    />
                  </a>
                )
              ) : null}

              <Button
                icon="pi pi-bars"
                onClick={() => {
                  parseInt(sideBarLayout)
                    ? DrawerWidths(true)
                    : setMenuVisible(true);
                }}
                pt={{
                  root: {
                    className: `focus:shadow-none`,
                  },
                }}
              ></Button>
              <p className="capitalize font-bold text-xs sm:text-sm text-center vertical-align-middle m-0 w-auto text-white">
                {firstTenantsName}
              </p>
            </div>
            <div className="col-12 sm:col-6 md:col-7 xl:col-5 flex-order-2 sm:flex-order-1 px-2 md:px-0">
              {/* Search bar start */}
              {checkPermission(permissions?.globalSearch?.menu) && (
                <div className="p-inputgroup p-1">
                  <Dropdown
                    value={value}
                    onChange={(event, value) => {
                      clearToast();
                      setSearchParameter(event.target.value);
                      setOptions([]);
                      setValue('');
                      setSearchSuggession(
                        ['phone', 'client', 'address'].includes(
                          event.target.value
                        )
                          ? 1
                          : parseInt(searchSuggession)
                      );
                      localStorage.setItem(
                        'search-suggession',
                        ['phone', 'client'].includes(event.target.value)
                          ? 1
                          : parseInt(searchSuggession)
                      );
                      setSelectedCityName(null);
                      setAddressSearch('');
                    }}
                    options={GLOBAL_SEARCH_PARAMETERS_OPTIONS}
                    optionLabel="label"
                    placeholder={`${GLOBAL_SEARCH_PARAMETERS_OPTIONS.find(
                      (item, index) => item.value === searchParameter
                    ).label
                      }`}
                    className="border-none border-round-md w-3 hover:border-none focus:shadow-none align-items-center height-40 line-height-1"
                    pt={{
                      input: {
                        className: 'px-2 text-sm w-4rem sm:w-5rem',
                      },
                      root: {
                        className: 'shadow-none',
                      },
                      trigger: {
                        className: 'w-2rem',
                      },
                      wrapper: {
                        className: 'max-h-full',
                      },
                    }}
                  />

                  <AutoComplete
                    ref={autoRef}
                    className="border-noround w-9"
                    inputClassName="p-inputtext-sm border-none focus:shadow-none border-round-md ml-2 mr-1"
                    suggestions={parseInt(searchSuggession) ? options : ''}
                    completeMethod={search}
                    value={value}
                    forceSelection={true}
                    loadingIcon={true}
                    onFocus={() => {
                      searchParameter === 'address' &&
                        isHardRefresh &&
                        setIsHardRefresh(Math.random());
                    }}
                    onBlur={() => {
                      setValue(value);
                    }}
                    onChange={e => {
                      const searchResultLength = e?.value?.length;
                      const query = e?.value;
                      if (searchSuggession) {
                        const searchLength = e?.value?.length;
                        setSearchLength(e?.value?.length);
                        if (searchResultLength === 0) {
                          setOptions([]);
                          setSelectedCityName(null);
                          setIsHardRefresh(0);
                        }
                        if (searchParameter === 'address') {
                          if (searchResultLength >= 2 || e?.value?.city) {
                            if (selectedCityName || e?.value?.city) {
                              const searchValue = query?.city
                                ? `${query?.city} ${query?.state_name}`
                                : query;

                              searchProjects(
                                searchValue,
                                searchParameter,
                                '_self',
                                history,
                                setOptions,
                                searchSuggession,
                                setIsHardRefresh
                              );
                            } else {
                              const filtered = citiesData?.filter(item =>
                                Object.values(item).some(value =>
                                  String(value || '')
                                    .toLowerCase()
                                    .includes((query || '').toLowerCase())
                                )
                              );
                              if (filtered?.length === 0) {
                                if (searchResultLength >= 3) {
                                  searchProjects(
                                    query,
                                    searchParameter,
                                    '_self',
                                    history,
                                    setOptions,
                                    searchSuggession,
                                    setIsHardRefresh
                                  );
                                }
                              } else {
                                setOptions(filtered);
                                if (isHardRefresh)
                                  setIsHardRefresh(Math.random());
                              }
                            }
                          }
                        } else {
                          if (searchResultLength >= 3) {
                            searchProjects(
                              e?.value?.trim(),
                              searchParameter,
                              '_self',
                              history,
                              setOptions,
                              searchSuggession
                            );
                          }
                        }

                        let val = e?.value;
                        if (typeof e?.value !== 'string') {
                          if (searchParameter === 'project_number') {
                            val = e?.value?.project_number;
                          } else if (searchParameter === 'po_number') {
                            val = e?.value?.po_number;
                          } else if (searchParameter === 'address') {
                            val = e?.value?.city
                              ? `${e?.value?.city} ${e.value?.state_name}`
                              : addressSearch;
                            setSelectedCityName(e?.value?.city);
                          } else if (searchParameter === 'client') {
                            val = e?.value?.customer_name;
                          } else if (searchParameter === 'phone') {
                            val = e?.value?.mobile_phone;
                          }
                        }
                        setAddressSearch(val);
                        setValue(val);
                        if (e.value?.customer_id || e.value?.project_id) {
                          const url = ![
                            'project_number',
                            'po_number',
                            'address',
                          ].includes(searchParameter)
                            ? '/crm/view/customer/' + e.value.customer_id
                            : '/project/view/' +
                            e.value.project_id +
                            '/#tab=scheduleInfo';
                          const redirectURL = `${url}?searchType=${searchParameter}&searchValue=${val}`;
                          history.push(redirectURL);
                        }
                      } else {
                        setValue(e.target.value);
                        if (searchResultLength >= 3) {
                          searchProjects(
                            e?.value?.trim(),
                            searchParameter,
                            '_self',
                            history,
                            setOptions,
                            searchSuggession,
                            setIsHardRefresh,
                            setGlobalSearchLoader
                          );
                        }
                      }
                    }}
                    delay={500}
                    onKeyPress={e => {
                      if (!parseInt(searchSuggession) && e.key === 'Enter') {
                        handleSearch('_self', e.target.value?.trim());
                      }
                    }}
                    placeholder={`Type at least 3 chars. to search`}
                    itemTemplate={itemTemplate}
                    pt={{
                      inputText: {
                        className: 'border-none',
                      },
                    }}
                  />
                  {parseInt(searchSuggession) === 0 &&
                    searchParameter !== 'address' ? (
                    <>
                      <ContextMenu
                        model={contextMenuItems}
                        ref={cm}
                        breakpoint="767px"
                        pt={{
                          icon: {
                            className: 'text-lg',
                          },
                        }}
                      />
                      <Button
                        icon={
                          globalSearchLoader
                            ? 'pi pi-spin pi-spinner text-white'
                            : 'pi pi-search'
                        }
                        onClick={() => handleSearch()}
                        className="border-noround text-white"
                        onContextMenu={e => cm.current.show(e)}
                        disabled={globalSearchLoader}
                      />
                    </>
                  ) : (
                    ''
                  )}

                  {['project_number', 'po_number'].includes(searchParameter) ? (
                    <div className="inline-flex align-items-center">
                      <InputSwitch
                        checked={parseInt(searchSuggession) ? true : false}
                        onChange={e => onSearchSuggessionHandler(e.value)}
                        pt={{
                          slider: {
                            className: 'shadow-none',
                          },
                        }}
                        tooltip="Suggestions"
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
              {/* Search bar End */}
            </div>

            <div className="col-4 sm:col-4 md:col-2 xl:col-2 hidden xl:inline-flex align-items-center px-1 flex-order-3 sm:flex-order-2">
              {/* Open TopBar Notification */}
              {checkPermission(permissions?.pullDownMenu?.view) && (
                <>
                  {mapInstallerNotifications() &&
                    mapInstallerNotifications()?.length > 0 ? (
                    <Notification
                      StoreNotificationItem={mapInstallerNotifications()}
                      icon="pi pi-user"
                      notificationsLength={installerNotificationsLength}
                      label={'Installer'}
                    />
                  ) : (
                    setTimeout(() => {
                      <Notification
                        StoreNotificationItem={mapInstallerNotifications()}
                        icon="pi pi-user"
                        notificationsLength={installerNotificationsLength}
                        label={'Installer'}
                      />;
                    }, 1500)
                  )}

                  {mapStoreNotificationsList() &&
                    mapStoreNotificationsList()?.length > 0 ? (
                    <Notification
                      StoreNotificationItem={mapStoreNotificationsList()}
                      icon="pi pi-building"
                      notificationsLength={storeCount}
                      label={'Store'}
                    />
                  ) : (
                    setTimeout(() => {
                      <Notification
                        StoreNotificationItem={mapStoreNotificationsList()}
                        icon="pi pi-building"
                        notificationsLength={storeCount}
                        label={'Store'}
                      />;
                    }, 1500)
                  )}

                  {mapEmailNotificationsList() &&
                    mapEmailNotificationsList()?.length > 0 ? (
                    <Notification
                      StoreNotificationItem={mapEmailNotificationsList()}
                      icon="pi pi-envelope"
                      notificationsLength={emailCount}
                      label={'Email'}
                    />
                  ) : (
                    setTimeout(() => {
                      <Notification
                        StoreNotificationItem={mapEmailNotificationsList()}
                        icon="pi pi-envelope"
                        notificationsLength={emailCount}
                        label={'Email'}
                      />;
                    }, 1500)
                  )}
                  {mapSmsNotificationsList() &&
                    mapSmsNotificationsList()?.length > 0 ? (
                    <Notification
                      StoreNotificationItem={mapSmsNotificationsList()}
                      icon="pi pi-comments"
                      notificationsLength={smsCount}
                      label={'SMS'}
                    />
                  ) : (
                    setTimeout(() => {
                      <Notification
                        StoreNotificationItem={mapSmsNotificationsList()}
                        icon="pi pi-comments"
                        notificationsLength={smsCount}
                        label={'SMS'}
                      />;
                    }, 1500)
                  )}
                  <div className="inline-flex align-items-center">
                    {pushNotificationEnabled && (
                      <InputSwitch
                        checked={enableBeta}
                        onChange={handleBetaChange}
                        className="mr-1"
                        pt={{
                          slider: {
                            className: 'shadow-none',
                          },
                        }}
                        tooltip="Push Notifications Beta"
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    )}
                  </div>
                </>
              )}
              {/* {axios.defaults.headers.common['client_id'] && <GlobalFilters />} */}

              {/* Close Right Tanent and Logout Drawer */}
            </div>

            {/* Close TopBar Notification */}
            <div className="col-6 sm:col-3 md:col-2 xl:col-2  flex align-items-center justify-content-end  pr-3 pl-2 flex-order-1 sm:flex-order-3">
              <div className="mr-1">
                {axios.defaults.headers.common['client_id'] && (
                  <GlobalFilters />
                )}
              </div>
              <div
                className=" flex align-items-center justify-content-end cursor-pointer"
                onClick={
                  tenants.length === 1
                    ? event => menuRight.current.toggle(event)
                    : () => setVisibleRight(true)
                }
              >
                {renderProfileImage()}
                <span className="cursor-pointer font-bold capitalize ml-3 text-white">
                  {userProfile.fullname}
                </span>
              </div>
            </div>
            {/* Open Right Tanent and Logout Drawer */}
            <Sidebar
              visible={visibleRight}
              className="right-drawer"
              position="right"
              onHide={() => {
                setVisibleRight(false);
                setSearchTenants('');
              }}
              icons={() => (
                <React.Fragment>
                  <div className="w-12 flex align-items-center pl-2">
                    {imageLoadError ? (
                      <img
                        src="/images/avatar.png"
                        className="mr-3 shadow-4"
                        alt="avatar"
                        height={36}
                        width={36}
                      />
                    ) : (
                      <Avatar
                        label={userProfile?.first_name?.charAt(0).toUpperCase()}
                        shape="circle"
                        className="blue-bg-100"
                      />
                    )}
                    <div className="ml-2 font-bold capitalize">
                      {userProfile?.fullname}
                      {/* <p
                        className="block text-xs text-color m-0 cursor-pointer hover:text-cyan-700"
                        onClick={event => {
                          event.preventDefault();
                          setVisibleRight(false);
                          history.push('/change-password');
                        }}
                      >
                        Change Password
                      </p> */}
                      <p
                        className="block text-xs text-color m-0 cursor-pointer hover:text-cyan-700"
                        onClick={event => {
                          event.preventDefault();
                          handleUserSettingsVisibility(true);
                        }}
                      >
                        Settings
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )}
              pt={{
                header: {
                  className: 'p-2 justify-content-between',
                },
                content: {
                  className: 'flex flex-column p-0 overflow-hidden',
                },
                closeIcon: {
                  className: 'text-primary',
                },
                closeButton: {
                  className: '',
                },
                root: {
                  className: 'w-20rem',
                },
                icons: {
                  className: 'w-12',
                },
              }}
            >
              <hr className="mt-0"></hr>
              {tenants?.length > 1 ? (
                <div className="p-input-icon-left w-full px-3 mt-3 relative">
                  <div className="fixed" style={{ width: '270px' }}>
                    <InputText
                      placeholder="Search Tenants"
                      value={searchTenants}
                      onChange={e => setSearchTenants(e?.target?.value)}
                      className="w-12 p-inputtext-sm pl-4"
                    />
                  </div>
                </div>
              ) : null}
              <ul className="tanent-name flex-grow-1 px-3 list-none scroll mt-7 h-100 overflow-auto ">
                {filteredTenant?.length === 0 ? (
                  <p className="mt-0">Tenant not found.</p>
                ) : (
                  filteredTenant?.map((item, index) => {
                    return (
                      <li
                        className={`w-12 ${getClientId === item.client_id
                          ? 'bg-primary text-white'
                          : ''
                          }`}
                        key={`side-menu-${index}`}
                      >
                        <Button
                          onClick={() => {
                            localStorage.removeItem('common_filters');
                            switchTenant(item.client_id, item.client_name);
                          }}
                          link
                          className={`uppercase text-sm p-2 text-white font-bold text-left w-full ${getClientId === item.client_id
                            ? 'text-white'
                            : 'text-color'
                            }`}
                          pt={{
                            root: {
                              className: `focus:shadow-none text-white`,
                            },
                          }}
                        >
                          {item.client_name}
                        </Button>
                      </li>
                    );
                  })
                )}
              </ul>
              <div className="w-full">
                <div className="cursor-pointer w-12 py-1">
                  <hr className="mt-0 w-12" />
                  <span className="font-bold"></span>
                  <Dialog
                    header="Confirm"
                    visible={visible}
                    style={{ width: '400px' }}
                    onHide={() => setVisible(false)}
                  >
                    <p className="m-0">
                      <i className="p-confirm-dialog-icon pi pi-exclamation-triangle text-lg"></i>{' '}
                      Are you sure you want to proceed?
                    </p>
                    <div className="text-right mt-3">
                      <Button
                        text
                        size="small"
                        className="mr-3 text-sm"
                        onClick={() => setVisible(false)}
                      >
                        No
                      </Button>
                      <Button
                        onClick={() => logout()}
                        label="Yes"
                        size="small"
                        outlined
                        severity="primary"
                      />
                    </div>
                  </Dialog>
                  <Button
                    link
                    onClick={() => setVisible(true)}
                    className="focus:shadow-none"
                  >
                    <i className="pi pi-power-off mr-2"> </i> Logout
                  </Button>
                </div>
              </div>
            </Sidebar>

            <Menu
              model={[
                {
                  items: [
                    // {
                    //   label: 'Change Password',
                    //   icon: 'pi pi-pencil',
                    //   command: () => {
                    //     history.push('/change-password');
                    //   },
                    // },
                    {
                      label: 'Settings',
                      icon: 'pi pi-gear',
                      command: () => {
                        handleUserSettingsVisibility(true);
                      },
                    },
                    {
                      label: 'Logout',
                      icon: 'pi pi-power-off',
                      command: () => {
                        logout();
                      },
                    },
                  ],
                },
              ]}
              popup
              ref={menuRight}
              id="logout-Menu"
              popupAlignment="right"
              pt={{
                submenuHeader: {
                  className: 'hidden',
                },
                root: {
                  className: 'right-0 left-auto',
                },
              }}
            />
          </div>
          <div className="w-full light-grey-bg-100">
            <BulletinMarquee />
          </div>
          <div
            className="w-full light-grey-bg-100 mt-30rem"
            style={{ marginTop: '25px !important' }}
          >
            <CxScheduleAlert slideDrawer={slideDrawer} />
          </div>
        </header>
      )}

      {parseInt(sideBarLayout) ? (
        <aside
          className="bg-white w-14rem top-0 bottom-0 shadow-2 fixed transition-ease-in-out transition-duration-500 overflow-y-auto overflow-x-hidden"
          style={{
            left: slideDrawer ? '-220px' : '0',
            zIndex: headerStyle ? '1000' : '1000',
          }}
        >
          <div className="grid grid-nogutter h-4rem pl-2 py-1 align-items-center">
            <div className="col-10 flex justify-content-center">
              {imgError || clientDetails?.logo === null ? (
                <a href="/dashboard" rel="noreferrer" className="mr-3">
                  <Image
                    alt="Projects Force"
                    className="w-7rem my-2"
                    src={PFLogo}
                    pt={{
                      image: {
                        className: 'object-fit-cover max-w-7rem max-h-3rem',
                      },
                    }}
                  />
                </a>
              ) : (
                <a href="/dashboard" rel="noreferrer" className="mr-3">
                  <Image
                    alt={clientDetails?.client_name}
                    className="max-h-3rem object-fit-cover"
                    src={clientDetails?.logo}
                    onError={handleLogoError}
                    pt={{
                      image: {
                        className: 'object-fit-cover max-w-7rem max-h-3rem',
                      },
                    }}
                  />
                </a>
              )}
            </div>
            <div className="col-2">
              {checkWindowWidth() > 1199 ? (
                <img
                  alt="Menu Pin"
                  className="bg-white pr-2 cursor-pointer"
                  style={{ transform: 'rotateZ(317deg)', width: '1.8rem' }}
                  src={'/images/menupinfill.svg'}
                  onClick={() => {
                    onSideBarLayoutChange(0);
                    DrawerWidths(false);
                    setMenuVisible(true);
                  }}
                />
              ) : null}
            </div>
          </div>
          {imgError || clientDetails?.logo === null ? null : (
            <h4 className="text-8 my-0 pl-2 text-center pr-5">
              Powered By{' '}
              <span className="text-primary text-10">ProjectsForce</span>
            </h4>
          )}

          <PanelMenuList
            sideBarLayout={sideBarLayout}
            onSideBarLayoutChange={onSideBarLayoutChange}
            DrawerWidths={DrawerWidths}
            setMenuVisible={setMenuVisible}
          />
        </aside>
      ) : (
        <SideMenuList
          visible={menuVisible}
          setMenuVisible={setMenuVisible}
          sideBarLayout={sideBarLayout}
          onSideBarLayoutChange={onSideBarLayoutChange}
          DrawerWidths={DrawerWidths}
        />
      )}
      <Task />
      <UserSettingsDialog
        showDialog={showUserSettings}
        handleCloseDialogue={handleUserSettingsVisibility}
      />
    </>
  );
};

export default Topbar;
