//Common Imports
import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Knob } from 'primereact/knob';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'primereact/tooltip';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Link } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { AutoComplete } from 'primereact/autocomplete';

import {
  checkPermission,
  getUserTimeZone,
  getConvertedDate,
  formatNumber,
  floatParser,
  formatDateTimeMDYHM,
  dateFormatter,
  showFirst30Characters,
  formatNagativeCurrency,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

//Custom Style
import './project-item.css';

//Assets
import { parseISO } from 'date-fns';

import technicianDeleteIcon from '../../../assets/images/technician_delete.svg';
import technicianIcon from '../../../assets/images/technician.svg';

//Services
import AddEditLaborItemDialog from '../Items/AddEditLaborItemDialog';
import { setProjectInfo } from '../Inventory/reducers/ProductDetailsSlice';
import Inventory from '../Inventory/Inventory.view';
import { getLaborItemsList } from '../Items/Items.service.js';
import TableLoader from '../../shared/Loader/TableLoader.js';
import PFCalendar from '../../shared/PFPrime/PFCalendar.js';
import PFButton from '../../shared/PFPrime/PFButton.js';
import { CLIENT_DETAILS, Colors } from '../../../constants.js';

import {
  calculateProjectItemLaborCost,
  getLaborItems,
  updateProjectItemUser,
  updateLaborItem,
  updateLaborProjectItems,
  getLinkedItemsList,
} from './ProjectItemsService.js';

//Components
import AddProjectLaborItemDialog from './AddProjectLaborItemDialog.js';
import AssignTechnicianDialog from './AssignTechnicianDialog.js';
import ItemUserNoteDialog from './ItemUserNoteDialog.js';
import DeleteProjectItemDialog from './DeleteProjectItemDialog';
import EditProjectLaborItemDialog from './EditProjectLaborItemDialog.js';
import EditAssignedTechnicianDialog from './EditAssignedTechnicianDialog.js';
import DeleteProjectItemUserDialog from './DeleteProjectItemUserDialog.js';
import ShowLogsDialog from './ShowLogsDialog';
import VisibilityControlsDialog from './VisibilityControlsDialog';

// Helpers

export default function ProjectLaborItemsGrid(props) {
  const userAssignedLaborItems = checkPermission(
    permissions?.projectItems.userAssignedLaborItems
  );
  //displatch
  const dispatch = useDispatch();
  //permissions
  const showLaborCostFields = props.showLaborCostFields
    ? props.showLaborCostFields
    : false;
  const allLaborItemsCostField = props.allLaborItemsCostField
    ? props.allLaborItemsCostField
    : false;
  const addAllLaborCostFields = props.addAllLaborCostFields
    ? props.addAllLaborCostFields
    : false;
  const editAllLaborCostFields = props.editAllLaborCostFields
    ? props.editAllLaborCostFields
    : false;
  const editLaborItemUsers = props.editLaborItemUsers
    ? props.editLaborItemUsers
    : false;
  const isUserInstaller = props.userIsInstaller;
  const userId = props.loggedInUserId;
  const isSalesCommissionOpted = !!props.isSalesCommissionOpted;

  //CRUD Permissions
  const tabProjectItemsLaborAdd = props.tabProjectItemsLaborAdd
    ? props.tabProjectItemsLaborAdd
    : false;
  const tabProjectItemsLaborModify = props.tabProjectItemsLaborModify
    ? props.tabProjectItemsLaborModify
    : false;
  const tabProjectItemsLaborDelete = props.tabProjectItemsLaborDelete
    ? props.tabProjectItemsLaborDelete
    : false;

  //technician Visibility
  const technicianVisibility = props.technicianVisibility || false;

  //financeApproval
  const financeApproval = props.financeApproval || false;
  const editFinanceApproval = props.editFinanceApproval || false;

  //paidApproval
  const paidApproval = props.paidApproval || false;
  const editPaidApproval = props.editPaidApproval || false;

  //technicianFinanceApproval
  const technicianFinanceApproval = props.technicianFinanceApproval || false;
  const editTechnicianFinanceApproval =
    props.editTechnicianFinanceApproval || false;

  //technicianPaidApproval
  const technicianPaidApproval = props.technicianPaidApproval || false;
  const editTechnicianPaidApproval = props.editTechnicianPaidApproval || false;

  //states and other data members
  const projectId = props.projectId;
  const projectNumber = props.projectNumber || null;
  const projectInstallers = props.projectInstallers;
  const statusTypes = props.systemStatuses;
  const [isLoading, setIsLoading] = useState(false);
  const [itemMenuOptions, setItemMenuOptions] = useState([]);
  const [updateLaborItems, setUpdateLaborItems] = useState(true);

  const [laborItems, setLaborItems] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemUser, setSelectedItemUser] = useState(null);
  const [systemItems, setSystemItems] = useState([]);
  const [reloadSystemItems, setReloadSystemItems] = useState(true);
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [openedTimes, setOpenedTimes] = useState(1000);
  const [childrenItem, setChildrenItem] = useState([]);
  const [selectedJob, setSelectedJob] = React.useState();
  const [items, setItems] = useState([]);
  const [isAddLaborItemDialogVisible, setIsAddLaborItemDialogVisible] =
    useState(false);
  const [
    isAddProjectLaborItemDialogVisible,
    setIsAddProjectLaborItemDialogVisible,
  ] = useState(false);
  const [isAssignItemUserDialogVisible, setIsAssignItemUserDialogVisible] =
    useState(false);
  const [isItemNoteDialogVisible, setIsItemNoteDialogVisible] = useState(false);
  const [isDeleteMerchandiseItemVisible, setIsDeleteMerchandiseItemVisible] =
    useState(false);
  const [
    isEditProjectLaborItemDialogVisible,
    setIsEditProjectLaborItemDialogVisible,
  ] = useState(false);
  const [isLogsDialogVisible, setIsLogsDialogVisible] = useState(false);
  const [
    isVisibilityControlsDialogVisible,
    setIsVisibilityControlsDialogVisible,
  ] = useState(false);
  const [isUnAssginItem, setIsUnAssginItem] = useState(false);

  const [isEditAssignedTechnicianDialog, setIsEditAssignedTechnicianDialog] =
    useState(false);
  const [
    isDeleteProjectItemUserDialogVisible,
    setIsDeleteProjectItemUserDialogVisible,
  ] = useState(false);

  const [timeZone, setTimeZone] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [isFromClone, setIsFromClone] = useState(false);
  const itemOptionsMenuRef = useRef(null);
  const itemUserOptionsMenuRef = useRef(null);
  const menuRef = useRef(null);
  const toast = useRef(null);
  const [selectAll, setSelectAll] = useState({
    reqFrom: 'self',
    value: false,
  });

  const [nonInvoicedItems, setNonInvoicedItems] = useState(true);
  const [cloneItem, setCloneItem] = useState(false);
  const [transactionIDShow, setTransactionIDShow] = useState(false);
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    if (props?.projectId && props?.projectNumber) {
      dispatch(
        setProjectInfo({
          projectId: projectId || null,
          projectNumber: projectNumber || null,
          projectNotes: selectedItem?.note_text || null,
          itemId: selectedItem?.item_id || null,
        })
      );
    }
  }, [props]);

  useEffect(() => {
    if (selectAll?.reqFrom === 'self' && laborItems?.length > 0) {
      laborItems.map(rowData => {
        const invoiceGenerated = props?.projectInvoiceItems.find(
          item =>
            item.project_item_id === rowData.project_item_id &&
            item?.invoice?.status !== 'Cancelled'
        );
        !invoiceGenerated &&
          props?.handleInvoiceItems(rowData, selectAll?.value);
      });
    }
  }, [selectAll?.value, laborItems]);

  const handleTransactionID = projectItemId => {
    const txnCheck = laborItems?.some(item => {
      return (
        item.project_item_id === projectItemId &&
        item.project_item_user?.qb_bill_txn_id
      );
    });
    setTransactionIDShow(txnCheck);
  };

  const laborMenuOptions = [
    {
      label: 'Add Labor Item',
      icon: 'pi pi-user-plus',
      command: () => {
        setIsAddLaborItemDialogVisible(true);
      },
    },
    ...(tabProjectItemsLaborAdd
      ? [
          {
            label: 'Assign Labor Item',
            icon: 'pi pi-users',
            command: () => {
              setCloneItem(false);
              setIsAddProjectLaborItemDialogVisible(true);
            },
          },
        ]
      : []),
  ];

  const itemMenuOptionsBlueprint = [
    {
      label: 'Calculate Cost',
      icon: 'pi pi-calculator',
      command: () => {
        calculateProjectItemLaborCostMethod();
      },
    },
  ];

  const itemUserMenuOptions = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        setIsEditAssignedTechnicianDialog(true);
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        setIsDeleteProjectItemUserDialogVisible(true);
      },
    },
  ];

  //Mount
  useEffect(() => {
    if (!props.isLinked && (updateLaborItems || !inventoryOpen)) {
      setLoading(true);
      getLaborItems(projectId)
        .then(res => {
          setLaborItems(res?.data?.data);
          setLoading(false);
        })
        .catch(err => {
          console.error('Error Setting Labor Items: ', err);
          setLaborItems([]);
          setLoading(false);
        });
      setUpdateLaborItems(false);
    }
    if (filteredData?.child_projects?.[0]?.project_id) {
      getLinkedLaborItem(filteredData?.child_projects?.[0]?.project_id);
    }
    setLoading(false);
  }, [updateLaborItems, inventoryOpen]);

  useEffect(() => {
    if (!props.isLinked) {
      setLoading(true);
      getLaborItems(projectId)
        .then(res => {
          setLaborItems(res?.data?.data);
          setLoading(false);
          props?.setRefereshLineItems(false);
        })
        .catch(err => {
          console.error('Error Setting Labor Items: ', err);
          setLaborItems([]);
          setLoading(false);
        });
      setLoading(false);
      setUpdateLaborItems(false);
    }
  }, [props.refereshLineItems]);

  useEffect(() => {
    if (reloadSystemItems) {
      getLaborItemsList(
        {},
        setReloadSystemItems,
        result => result?.count && setSystemItems(result?.items)
      );
    }
  }, [reloadSystemItems]);

  const getLinkedLaborItem = async projectId => {
    try {
      setLoading(true);
      const res = await getLaborItems(projectId);
      setLaborItems(res?.data?.data);
      setLoading(false);
      props?.setRefereshLineItems(false);
    } catch (err) {
      console.error('Error Setting Labor Items: ', err);
      setLaborItems([]);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (laborItems?.length > 0) {
      let itemExistsInInvoice = false;
      for (const rowData of laborItems) {
        const invoiceGenerated = props?.projectInvoiceItems.find(
          item =>
            item?.project_item_id === rowData?.project_item_id &&
            item?.invoice?.status !== 'Cancelled'
        );
        if (invoiceGenerated) {
          itemExistsInInvoice = true;
          break;
        }
      }
      setNonInvoicedItems(itemExistsInInvoice);
      props.updateParentLaborItems(laborItems);
    } else {
      props.updateParentLaborItems([]);
    }
  }, [laborItems]);

  useEffect(() => {
    getList();
    setTimeZone(getUserTimeZone());
  }, []);

  const getList = () => {
    const customerId = props.customerDetails?.[0]?.customer_id;

    if (customerId) {
      setLoading(true);

      const linkProjects = getLinkedItemsList(customerId, props.project);

      linkProjects
        .then(project => {
          if (project?.status) {
            const projectsData = project?.data?.data;
            const groupedProjects = projectsData?.reduce((acc, item) => {
              const parentProject = item?.data;
              const parentProjectId = parentProject?.project_id;
              let existingParent = acc?.find(
                p => p?.parent_project_id === parentProjectId
              );
              const linkedChildData = projectsData.find(
                child =>
                  child?.data?.child_linkage?.linked_project_id ==
                  props?.projectId
              );

              if (
                linkedChildData?.data?.child_linkage?.project_id &&
                props.isLinked
              ) {
                getLinkedLaborItem(
                  linkedChildData?.data?.child_linkage?.project_id
                );
              }
              if (!existingParent && !linkedChildData) {
                existingParent = {
                  parent_project_id: parentProjectId,
                  child_projects: [],
                };
                acc?.push(existingParent);
              }
              if (item?.children) {
                item?.children.forEach(child => {
                  existingParent?.child_projects?.push({
                    project_id: child?.data?.project_id,
                    project_number: child?.data?.project_number,
                    project_type: child?.data?.project_type?.project_type,
                  });
                });
              }
              return acc;
            }, []);
            setChildrenItem(groupedProjects);
          }
        })
        .catch(error => {
          console.error('Error fetching projects:', error);
        });
    }
  };

  const filteredData = childrenItem?.find(
    item => item?.parent_project_id == props?.projectId
  );

  const search = event => {
    let filteredItems = filteredData?.child_projects
      ?.filter(project =>
        project?.project_number
          ?.toLowerCase()
          .includes(event?.query?.toLowerCase())
      )
      ?.map(item => ({
        label: item?.project_number,
        value: item?.project_id,
      }));
    setItems(filteredItems);
  };

  useEffect(() => {
    if (filteredData && filteredData?.child_projects?.length > 0) {
      const data = filteredData?.child_projects?.[0];
      setSelectedJob({
        label: data?.project_number,
        value: data?.project_id,
      });
      getLinkedLaborItem(filteredData?.child_projects?.[0]?.project_id);
    }
  }, [filteredData]);

  //Methods
  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  const handleInventory = data => {
    const inventoryEnabled = data?.item?.inventory_managment;
    if (inventoryEnabled) {
      let item = data?.item;
      item['projectId'] = projectId || null;
      item['projectNumber'] = projectNumber || null;
      setInventoryOpen(true);
    }
  };

  const calculateAllocationPercentage = projectItemId => {
    try {
      let sum = 0;
      for (
        let projectIndex = 0;
        projectIndex < laborItems.length;
        projectIndex++
      ) {
        const pi = laborItems[projectIndex];
        if (pi.project_item_id === projectItemId) {
          sum += pi.project_item_user?.percentage
            ? pi.project_item_user?.percentage
            : 0;
        }
      }
      return sum;
    } catch (error) {
      return 0;
    }
  };

  const calculateProjectItemLaborCostMethod = async () => {
    setIsLoading(true);
    const response = await calculateProjectItemLaborCost(
      selectedItem?.project_item_id,
      projectId
    );
    setIsLoading(false);
    if (response?.data?.status) {
      setUpdateLaborItems(true);
      showAlert('success', 'Labor Cost Calculated');
    } else {
      showAlert('error', 'Unable To Calculate Labor Cost');
    }
  };

  const onEditProjectLaborItemDialogHide = projectItemModified => {
    setSelectedItem(null);
    setIsEditProjectLaborItemDialogVisible(false);
    if (projectItemModified && projectItemModified === true) {
      setUpdateLaborItems(true);
    }
  };

  const onDeleteProjectItemDialogHide = projectItemDeleted => {
    setIsDeleteMerchandiseItemVisible(false);
    if (projectItemDeleted) {
      setUpdateLaborItems(true);
    }
  };

  const onDeleteProjectItemUserDialogHide = projectItemDeleted => {
    setIsDeleteProjectItemUserDialogVisible(false);
    if (projectItemDeleted) {
      setUpdateLaborItems(true);
    }
  };

  const onShowLogsDialogHide = () => {
    setIsLogsDialogVisible(false);
  };

  const onVisibilityControlsDialogVisible = (status = false) => {
    setIsVisibilityControlsDialogVisible(false);
    if (status) {
      setUpdateLaborItems(true);
    }
  };

  const onAddLaborItemsDialogHide = systemItem => {
    setIsAddLaborItemDialogVisible(false);
    if (systemItem?.item_id && systemItem?.item_id > 0) {
      setReloadSystemItems(true);
    }
  };

  const onAddProjectLaborItemDialogHide = projectItemAdded => {
    setIsAddProjectLaborItemDialogVisible(false);
    if (projectItemAdded) {
      setUpdateLaborItems(true);
    }
  };

  const onAssignTechnicianDialogHide = projectItemUserAdded => {
    setIsAssignItemUserDialogVisible(false);
    if (projectItemUserAdded) {
      setUpdateLaborItems(true);
    }
  };

  const onEditAssignedTechnicianDialogHide = projectItemUserUpdated => {
    setSelectedItemUser(null);
    setIsEditAssignedTechnicianDialog(false);
    if (projectItemUserUpdated) {
      setUpdateLaborItems(true);
    }
  };

  const onItemUserNoteDialogHide = projectItem => {
    setSelectedItem(null);
    setIsItemNoteDialogVisible(false);
    if (projectItem?.project_item_id && projectItem?.project_item_id > 0) {
      const existingItems = laborItems;
      for (let i = 0; i < laborItems?.length; i++) {
        if (existingItems[i].project_item_id === projectItem.project_item_id) {
          existingItems[i].note_text = projectItem.note_text;
        }
      }
      setLaborItems(existingItems);
    }
  };

  const setItemStatus = async (projectItemUserId, projectItemId, e) => {
    try {
      setIsLoading(true);
      const updateResp = await updateProjectItemUser(
        projectItemUserId,
        projectId,
        {
          project_item_id: projectItemId,
          status_id: e.value > -1 ? e.value : null,
        }
      );
      setIsLoading(false);
      if (updateResp?.data?.status && updateResp.data.status === true) {
        showAlert('success', 'Item Technician Status Updated Successfully');
        try {
          const laborItemIndex = laborItems.findIndex(
            item =>
              item?.project_item_user?.project_item_user_id ===
              projectItemUserId
          );
          if (laborItemIndex > -1) {
            const laborItemsToUpdate = [...laborItems];
            if (
              laborItemsToUpdate[laborItemIndex] &&
              laborItemsToUpdate[laborItemIndex].project_item_user
            ) {
              laborItemsToUpdate[laborItemIndex].project_item_user.status_id =
                e.value;
              laborItemsToUpdate[
                laborItemIndex
              ].project_item_user.completion_date = '';

              const statusIndex = statusTypes.findIndex(
                item => item.status_id === e.value
              );
              if (statusIndex > -1) {
                const statusType = statusTypes[statusIndex];
                if (statusType.status === 'Completed') {
                  laborItemsToUpdate[
                    laborItemIndex
                  ].project_item_user.completion_date =
                    new Date().toISOString();
                } else if (e.value === -1) {
                  laborItemsToUpdate[
                    laborItemIndex
                  ].project_item_user.status_id = null;
                }
              }
              setLaborItems(laborItemsToUpdate);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        showAlert('error', 'Unable To Update Technician Status');
      }
    } catch (error) {
      console.error(error);
      showAlert('error', 'Unable To Update Status');
    }
  };

  const setItemReviewed = async (rowData, reviewStatus) => {
    try {
      setSelectedItem(rowData);
      setIsLoading(true);

      const requestReviewStatus = reviewStatus ? 1 : 0;
      const updateResp = await updateLaborItem(
        rowData?.project_item_id,
        rowData?.project_id,
        {
          review_status: requestReviewStatus,
        }
      );
      setIsLoading(false);
      if (updateResp?.data?.status && updateResp.data.status === true) {
        showAlert('success', 'Finance Review status updated successfully');
        setUpdateLaborItems(true);
        const laborItemsArr = laborItems;
        for (let i = 0; i < laborItemsArr.length; i++) {
          if (laborItemsArr[i].project_item_id === rowData?.project_item_id) {
            laborItemsArr[i].review_status = requestReviewStatus;
          }
        }
        setLaborItems(laborItemsArr);
      } else {
        showAlert('error', 'Unable to update finance review status');
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      showAlert('error', 'Unable To update finance review status');
    }
  };

  const setItemPaid = async (rowData, reviewStatus) => {
    try {
      setSelectedItem(rowData);
      setIsLoading(true);

      const requestReviewStatus = reviewStatus ? 1 : 0;
      const updateResp = await updateLaborItem(
        rowData?.project_item_id,
        rowData?.project_id,
        {
          paid_status: requestReviewStatus,
        }
      );
      setIsLoading(false);
      if (updateResp?.data?.status && updateResp.data.status === true) {
        showAlert('success', 'Paid status updated successfully');
        setUpdateLaborItems(true);
        const laborItemsArr = laborItems;
        for (let i = 0; i < laborItemsArr.length; i++) {
          if (laborItemsArr[i].project_item_id === rowData?.project_item_id) {
            laborItemsArr[i].paid_status = requestReviewStatus;
          }
        }
        setLaborItems(laborItemsArr);
      } else {
        showAlert('error', 'Unable to update paid status');
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      showAlert('error', 'Unable To update paid status');
    }
  };

  const setItemTechnicianPayrollReviewed = async (rowData, reviewStatus) => {
    try {
      setIsLoading(true);
      const requestReviewStatus = reviewStatus ? 1 : 0;
      const updateResp = await updateProjectItemUser(
        rowData?.project_item_user?.project_item_user_id,
        rowData?.project_id,
        {
          project_item_id: rowData?.project_item_id,
          review_status: requestReviewStatus,
        }
      );
      setIsLoading(false);
      if (updateResp?.data?.status && updateResp.data.status === true) {
        showAlert(
          'success',
          'Technician Finance Review status updated successfully'
        );
        setUpdateLaborItems(true);
        const laborItemsArr = laborItems;
        for (let i = 0; i < laborItemsArr.length; i++) {
          if (
            laborItemsArr[i].project_item_user?.project_item_user_id ===
            rowData?.project_item_user?.project_item_user_id
          ) {
            laborItemsArr[i].project_item_user.review_status =
              requestReviewStatus;
          }
        }
        setLaborItems(laborItemsArr);
      } else {
        showAlert('error', 'Unable to update review status');
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      showAlert('error', 'Unable To update review status');
    }
  };

  const setItemTechnicianPaid = async (rowData, reviewStatus) => {
    try {
      setIsLoading(true);
      const requestReviewStatus = reviewStatus ? 1 : 0;
      const updateResp = await updateProjectItemUser(
        rowData?.project_item_user?.project_item_user_id,
        rowData?.project_id,
        {
          project_item_id: rowData?.project_item_id,
          paid_status: requestReviewStatus,
        }
      );
      setIsLoading(false);
      if (updateResp?.data?.status && updateResp.data.status === true) {
        showAlert('success', 'Technician Paid status updated successfully');
        setUpdateLaborItems(true);
        const laborItemsArr = laborItems;
        for (let i = 0; i < laborItemsArr.length; i++) {
          if (
            laborItemsArr[i].project_item_user?.project_item_user_id ===
            rowData?.project_item_user?.project_item_user_id
          ) {
            laborItemsArr[i].project_item_user.paid_status =
              requestReviewStatus;
          }
        }
        setLaborItems(laborItemsArr);
      } else {
        showAlert('error', 'Unable to update review status');
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      showAlert('error', 'Unable To update review status');
    }
  };

  const setCompletionDate = async (rowData, date) => {
    try {
      setIsLoading(true);
      const updateResp = await updateProjectItemUser(
        rowData?.project_item_user?.project_item_user_id,
        rowData?.project_id,
        {
          project_item_id: rowData?.project_item_id,
          completion_date: date,
        }
      );
      setIsLoading(false);
      if (updateResp?.data?.status && updateResp.data.status === true) {
        showAlert('success', 'Completion date updated successfully');
        setUpdateLaborItems(true);
        const laborItemsArr = laborItems;
        for (let i = 0; i < laborItemsArr.length; i++) {
          if (
            laborItemsArr[i].project_item_user?.project_item_user_id ===
            rowData?.project_item_user?.project_item_user_id
          ) {
            laborItemsArr[i].project_item_user.completion_date = date;
          }
        }
        setLaborItems(laborItemsArr);
      } else {
        showAlert('error', 'Unable to update completion date');
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      showAlert('error', 'Unable To update completion date');
    }
  };
  //Templates
  const itemTotalCostTemplate = rowData => {
    if (rowData?.cost_ea) {
      return (
        parseFloat(rowData?.cost_ea) * parseFloat(rowData?.item_quantity)
      ).toFixed(2);
    } else {
      return 0;
    }
  };

  const laborItemHeaderTemplate = (rowData, index) => {
    const allocationPercentage = calculateAllocationPercentage(
      rowData?.project_item_id
    );
    let itemExistsInInvoice = props?.invoiceItems.some(
      item => item.project_item_id === rowData.project_item_id
    );

    const invoiceGenerated = props?.projectInvoiceItems.find(
      item =>
        item.project_item_id === rowData.project_item_id &&
        item?.invoice?.status !== 'Cancelled'
    );

    let invoiceNumber = null;
    if (invoiceGenerated) {
      itemExistsInInvoice = true;
      invoiceNumber = {
        no: invoiceGenerated?.invoice?.invoice_no,
        id: invoiceGenerated?.invoice?.invoice_id,
        created_at: invoiceGenerated?.invoice?.created_at,
        status: invoiceGenerated?.invoice?.status,
      };
    }

    const selectable = allocationPercentage === 100 ? false : true;

    let reviewCheckBoxTooltip = 'Not Reviewed';
    let reviewerExists = false;
    if (rowData?.reviewed_user_meta?.first_name?.length > 0) {
      reviewerExists = true;
      reviewCheckBoxTooltip = `${rowData?.reviewed_user_meta?.first_name || ''} ${
        rowData?.reviewed_user_meta?.last_name || ''
      }`;
      const reviewDate = formatDateTimeMDYHM(rowData?.reviewed_date);
      if (reviewDate) {
        reviewCheckBoxTooltip = `${reviewCheckBoxTooltip} ${reviewDate}`;
      }
    }

    let paidCheckBoxTooltip = 'Not Reviewed';
    let paidReviewerExists = false;
    if (rowData?.paid_marked_by_user_id?.length > 0) {
      paidReviewerExists = true;
      paidCheckBoxTooltip = `${rowData?.item_paid_user_meta?.first_name || ''} ${
        rowData?.item_paid_user_meta?.last_name || ''
      }`;
      const paidDate = formatDateTimeMDYHM(rowData?.paid_marked_at);
      if (paidDate) {
        paidCheckBoxTooltip = `${paidCheckBoxTooltip} ${paidDate}`;
      }
    }

    let hideLaborCostFromTechnicians = false;
    if (isUserInstaller) {
      let existingTechnicians = 0;
      for (let i = 0; i < laborItems.length; i++) {
        const item = laborItems[i];
        if (item.project_item_id === rowData.project_item_id) {
          existingTechnicians += 1;
        }
        if (existingTechnicians >= 2) break;
      }

      if (existingTechnicians >= 2) hideLaborCostFromTechnicians = true;
    }
    let gridRowColor = ' ';

    if (rowData?.hide_from_all_technician) {
      gridRowColor = Colors?.PF_SILVER_PINK || '';
    }

    if (parseFloat(rowData?.refunded_quantity || 0) > 0) {
      gridRowColor = Colors?.PF_VERY_SOFT_BLUE || '';
    }

    return (
      <div
        className={`grid m-0 w-12 ${rowData?.item?.exclude_from_revenue ? 'bg-red-50 ' : ''} ${
          rowData.skip_technician_assignment ? 'light-blue-100' : ''
        } ${
          rowData?.item?.inventory_managment &&
          rowData?.project_item_inventory_adjustment
            ? 'bg-green-50 '
            : ''
        }${rowData?.old_item_quantity ? 'bg-yellow-50 ' : ''}`}
        style={{ backgroundColor: gridRowColor }}
      >
        <div className="col p-0 flex align-items-center max-w-8rem">
          {!props.isLinked && (
            <Button
              rounded
              text
              disabled={props.isLinked}
              icon={'pi pi-clone'}
              tooltip="Clone"
              onClick={() => {
                setSelectedItem(rowData);
                setCloneItem(true);
                setIsAddProjectLaborItemDialogVisible(true);
                setIsFromClone(true);
              }}
              pt={{
                icon: {
                  className: 'text-color-secondary test-md text-lg',
                },
              }}
              className="w-2rem h-2rem p-0 mr-1 focus:shadow-none bg-transparent"
            ></Button>
          )}
          <Button
            rounded
            text
            icon={
              index != activeIndex
                ? 'pi pi-chevron-circle-right'
                : 'pi pi-chevron-circle-down'
            }
            onClick={() => {
              handleTransactionID(rowData?.project_item_id);
              setActiveIndex(activeIndex === index ? null : index);
            }}
            pt={{
              icon: {
                className: 'text-color-secondary test-md text-lg',
              },
            }}
            className="w-2rem h-2rem p-0 focus:shadow-none bg-transparent"
          />
          <div className="grid m-0 w-12 pt-1">
            <div className="col-5 flex align-items-center">
              {!props.isLinked && (
                <Button
                  type="button"
                  icon="pi pi-ellipsis-v"
                  disabled={props.isLinked}
                  tooltipOptions={{ position: 'bottom' }}
                  appendTo="self"
                  onClick={e => {
                    setSelectedItem(rowData);
                    const itemMenuOptionsArr = [
                      ...(tabProjectItemsLaborModify &&
                      !paidReviewerExists &&
                      !rowData?.is_commission_item
                        ? [
                            {
                              label: 'Edit',
                              icon: 'pi pi-pencil',
                              command: () => {
                                setIsEditProjectLaborItemDialogVisible(true);
                              },
                            },
                          ]
                        : []),
                      ...(editLaborItemUsers &&
                      !paidReviewerExists &&
                      !rowData?.is_commission_item
                        ? [
                            {
                              label: 'Assign Technician',
                              icon: 'pi pi-user-plus',
                              className: `${!parseInt(CLIENT_DETAILS?.assign_technician_on_revenue_excluded_items) && rowData?.item?.exclude_from_revenue ? 'opacity-20' : ''}`,
                              command: () => {
                                !parseInt(
                                  CLIENT_DETAILS?.assign_technician_on_revenue_excluded_items
                                ) && rowData?.item?.exclude_from_revenue
                                  ? null
                                  : setIsAssignItemUserDialogVisible(true);
                              },
                            },
                          ]
                        : []),
                      ...itemMenuOptionsBlueprint,
                      ...(tabProjectItemsLaborDelete &&
                      !paidReviewerExists &&
                      !rowData?.is_commission_item
                        ? [
                            {
                              label: 'Delete',
                              icon: 'pi pi-trash',

                              command: () => {
                                setIsDeleteMerchandiseItemVisible(true);
                              },
                            },
                          ]
                        : []),
                      ...(technicianVisibility
                        ? [
                            {
                              label: 'Visibility Controls',
                              icon: 'pi pi-eye-slash',
                              command: () => {
                                setIsVisibilityControlsDialogVisible(true);
                                setIsUnAssginItem(
                                  !rowData?.project_item_user
                                    ?.project_item_user_id
                                );
                              },
                            },
                          ]
                        : []),
                    ];
                    setItemMenuOptions(itemMenuOptionsArr);
                    itemOptionsMenuRef.current?.toggle(e);
                  }}
                  text
                  rounded
                  size="small"
                  className="mr-auto border-none focus:shadow-none bg-transparent"
                  pt={{
                    icon: {
                      className: 'text-color',
                    },
                  }}
                />
              )}
            </div>
            <div className="col-7 flex align-items-center pl-0">
              <Knob
                value={allocationPercentage}
                size={50}
                rangeColor="var(--primary-100)"
                valueColor="var(--green-400)"
                pt={{
                  label: {
                    className: 'font-bold text-xl text-green-400',
                  },
                }}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-8 align-items-center flex">
          <div className="grid w-12 my-0 flex justify-content-between flex-nowrap">
            <div className="col py-0 px-1 text-xs pl-3 border-right-2 border-200">
              <small className="text-gray-600">Item#:</small>
              <h4 className="text-primary my-1 text-xs line-height-1 text-break-anywhere">
                {rowData?.item?.item_number}
              </h4>
              <small className="text-gray-600">Desc:</small>

              {rowData?.item?.item_desc?.length > 30 ? (
                <PFButton
                  label={
                    rowData?.item?.item_desc?.length > 30
                      ? showFirst30Characters(rowData?.item?.item_desc)
                      : rowData?.item?.item_desc
                  }
                  type="button"
                  size="small"
                  text
                  className="bg-transparent focus:shadow-none p-0 text-left text-xs border-none"
                  data-pr-my="left center-2"
                  tooltip={rowData?.item?.item_desc}
                  tooltipOptions={{
                    position: 'right',
                    mouseTrack: true,
                    mouseTrackTop: 0,
                    className: 'w-15rem md:w-26rem',
                  }}
                  pt={{
                    label: {
                      className: 'line-height-1 font-bold word-break',
                    },
                  }}
                />
              ) : (
                <p className="text-primary text-xs my-0 line-height-1 font-bold word-break">
                  {rowData?.item?.item_desc}
                </p>
              )}
            </div>
            <div
              className={`col py-0  pr-1 text-xs ${props?.CRMEnabled ? 'pl-2' : 'pl-3'}`}
            >
              <small className="text-gray-600">Quantity:</small>
              <h4
                className={`my-1 text-xs text-blue-900 ${rowData?.old_item_quantity && 'quantity_tooltip'}`}
                data-pr-tooltip={`Old Quantity: ${rowData?.old_item_quantity}`}
              >
                {rowData?.item_quantity}
                {rowData?.item_quantity &&
                rowData?.original_qty &&
                rowData?.item_quantity !== rowData?.original_qty ? (
                  <>
                    <i
                      className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                      data-pr-tooltip={
                        rowData?.original_qty === null
                          ? ''
                          : rowData?.original_qty
                      }
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{
                        fontSize: '0.8rem',
                        cursor: 'pointer',
                        marginLeft: '5px',
                      }}
                    ></i>
                    <Tooltip target=".custom-target-icon" />
                  </>
                ) : null}
                {rowData?.old_item_quantity ? (
                  <Tooltip target={'.quantity_tooltip'} position="bottom" />
                ) : null}
              </h4>

              {allLaborItemsCostField && (
                <>
                  <small className="text-gray-600">Margin:</small>
                  <h4 className="mb-0 mt-1 text-xs text-blue-900">
                    {rowData?.margin && +rowData.margin
                      ? `${formatNumber(rowData.margin)}%`
                      : '-'}
                  </h4>
                </>
              )}
            </div>
            <div className="col py-0 px-1 text-xs">
              {allLaborItemsCostField && (
                <>
                  <small className="text-gray-600">Revenue Ea $:</small>
                  <h4 className="my-1 text-xs text-blue-900">
                    {rowData?.cost_ea && +rowData.cost_ea
                      ? formatNagativeCurrency(
                          formatNumber(rowData.cost_ea, 2, true)
                        )
                      : '-'}
                    {rowData?.cost_ea &&
                    rowData?.original_cost &&
                    rowData?.cost_ea !== rowData?.original_cost ? (
                      <>
                        <i
                          className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                          data-pr-tooltip={
                            rowData?.original_cost === null
                              ? '$ 0.00'
                              : `$ ${rowData?.original_cost}`
                          }
                          data-pr-position="right"
                          data-pr-at="right+5 top"
                          data-pr-my="left center-2"
                          style={{
                            fontSize: '0.8rem',
                            cursor: 'pointer',
                            marginLeft: '5px',
                          }}
                        ></i>
                        <Tooltip target=".custom-target-icon" />
                      </>
                    ) : null}
                  </h4>
                </>
              )}
              {(showLaborCostFields || allLaborItemsCostField) &&
                !hideLaborCostFromTechnicians && (
                  <>
                    <small className="text-gray-600">Labor Cost Ea $:</small>
                    <h4 className="mb-0 mt-1 text-xs text-blue-900">
                      {rowData?.labor_cost_ea && +rowData.labor_cost_ea
                        ? formatNagativeCurrency(
                            formatNumber(rowData.labor_cost_ea, 2, true)
                          )
                        : '-'}
                    </h4>
                  </>
                )}
            </div>
            <div className="col py-0 px-1 text-xs">
              {allLaborItemsCostField && (
                <>
                  <small className="text-gray-600">Revenue $:</small>
                  <h4 className="my-1 text-xs text-blue-900">
                    {rowData?.cost_ea && +rowData.cost_ea
                      ? formatNagativeCurrency(
                          formatNumber(itemTotalCostTemplate(rowData), 2, true)
                        )
                      : '-'}
                  </h4>
                </>
              )}
              {(showLaborCostFields || allLaborItemsCostField) &&
                !hideLaborCostFromTechnicians && (
                  <>
                    <small className="text-gray-600">Labor Cost $:</small>
                    <h4 className="mb-0 mt-1 text-xs text-blue-900">
                      {rowData?.labor_cost_ea_value &&
                      +rowData.labor_cost_ea_value
                        ? formatNagativeCurrency(
                            formatNumber(rowData.labor_cost_ea_value, 2, true)
                          )
                        : '-'}
                    </h4>
                  </>
                )}
            </div>
            <div className="col py-0 px-1 text-xs">
              {allLaborItemsCostField && (
                <>
                  <small className="text-gray-600">Retail Ea $:</small>
                  <h4 className="my-1 text-xs text-blue-900">
                    {rowData?.sell_price && +rowData.sell_price
                      ? `$${formatNumber(rowData.sell_price, 2, true)}`
                      : '-'}
                  </h4>
                  <small className="text-gray-600">Retail $:</small>
                  <h4 className="my-1 text-xs text-blue-900">
                    {rowData?.sell_price && +rowData.sell_price
                      ? `$${formatNumber(rowData.sell_price * rowData.item_quantity, 2, true)}`
                      : '-'}
                  </h4>
                </>
              )}
            </div>
            {props?.CRMEnabled && props?.quoteNumber && (
              <>
                <div className="col py-0 px-1 text-xs">
                  <small className="text-gray-600">Discount Type</small>
                  <h4 className="my-1 text-xs text-blue-900">
                    {rowData?.discount_type || ''}
                  </h4>
                  <small className="text-gray-600">Discount Amount</small>
                  <h4 className="mb-0 mt-1 text-xs text-blue-900">
                    {rowData?.discount_type === 'Amount'
                      ? `$${floatParser(rowData?.discount)}`
                      : `${floatParser(rowData?.discount)}%`}
                  </h4>
                </div>
                <div className="col py-0 px-1 text-xs">
                  <small className="text-gray-600">Tax</small>
                  <h4 className="my-1 text-xs text-blue-900">{`${floatParser(rowData?.tax)}%`}</h4>
                  <small className="text-gray-600">Net Cost</small>
                  <h4 className="mb-0 mt-1 text-xs text-blue-900">{`$${floatParser(
                    rowData?.net_cost
                  )}`}</h4>
                </div>
                {invoiceNumber && (
                  <>
                    <div className="col text-xs p-0 ">
                      <small className="text-gray-600 flex">Invoice#</small>
                      <Link
                        to={`/crm/invoice/view/${invoiceNumber?.id}`}
                        className="no-underline text-primary"
                      >
                        <i className="pi pi-check-circle mr-1 text-green-400 text-xs"></i>
                        {invoiceNumber?.no || ''}
                      </Link>

                      <small className="text-gray-600 flex mt-2">
                        Invoice Status
                      </small>
                      <h4 className="my-1 text-xs text-blue-900">
                        {invoiceNumber?.status || ''}
                      </h4>

                      <small className="text-gray-600 flex mt-2">
                        Invoice Date
                      </small>
                      <h4 className="text-xs my-1">
                        {invoiceNumber?.created_at
                          ? moment(invoiceNumber?.created_at).format(
                              'MM-DD-YYYY'
                            )
                          : ''}
                      </h4>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="col py-0 px-1 text-xs">
              <small className="text-gray-600">Delivery Type:</small>
              <h4 className="mb-0 mt-1 text-xs text-blue-900">
                {rowData?.delivery_type || '-'}
              </h4>
              {isSalesCommissionOpted && (
                <>
                  <small className="text-gray-600">Sale Commission $:</small>
                  <h4 className="mb-0 mt-1 text-xs text-blue-900">
                    {rowData?.sales_commission && +rowData.sales_commission
                      ? `$${formatNumber(rowData.sales_commission, 2, true)}`
                      : '-'}
                  </h4>
                </>
              )}
            </div>
            {(rowData?.item_po || rowData?.expected_arrival_date) && (
              <div className="col py-0 px-1 text-xs">
                {rowData?.item_po && (
                  <>
                    <small className="text-gray-600">Item PO# :</small>
                    <h4 className="mb-0 mt-1 text-xs text-blue-900">
                      {rowData?.item_po}
                    </h4>
                  </>
                )}
                {rowData?.expected_arrival_date && (
                  <>
                    <small className="text-gray-600">
                      Expected Arrival Date:
                    </small>
                    <h4 className="mb-0 mt-1 text-xs text-blue-900">
                      {rowData?.expected_arrival_date
                        ? moment(rowData?.expected_arrival_date)?.format(
                            'MM-DD-YYYY'
                          )
                        : ''}
                    </h4>
                  </>
                )}
              </div>
            )}

            <div className="col py-0 px-1 text-xs">
              <div className="grid w-full">
                <div>
                  {rowData?.masterWarehouse &&
                    rowData?.masterWarehouse?.name && (
                      <div className="col py-0 px-1 text-xs mt-2">
                        <small className="text-gray-600">Warehouse:</small>
                        <h4 className="mb-0 mt-1 text-xs text-blue-900">
                          {rowData?.masterWarehouse?.name}
                        </h4>
                      </div>
                    )}
                </div>
                <div>
                  {rowData?.inventory_bin && rowData?.inventory_bin?.code && (
                    <div className="col py-0 px-1 text-xs  mt-2">
                      <small className="text-gray-600">Bin:</small>
                      <h4 className="mb-0 mt-1 text-xs text-blue-900">
                        {rowData?.inventory_bin?.code}-
                        {rowData?.inventory_bin?.name}
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {rowData?.refunded_quantity && rowData?.refunded_quantity > 0 ? (
              <div className="col py-0 px-1 text-xs">
                <div className="grid w-full">
                  <div>
                    <div className="col py-0 px-1 text-xs mt-2">
                      <small className="text-gray-600">Refunded Quantity</small>
                      <h4 className="mb-0 mt-1 text-xs text-blue-900">
                        {parseFloat(rowData?.refunded_quantity || 0).toFixed(2)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col flex align-items-center flex-wrap pl-0">
          <div className="grid flex align-items-center flex-wrap w-12 m-0">
            <div className="col-11 px-0">
              <div className="flex align-items-center mb-2">
                {financeApproval &&
                  (reviewerExists ? (
                    <div className="flex align-items-center">
                      <Tooltip
                        target=".finance-review"
                        position="left"
                        mouseTrack
                        mouseTrackTop={15}
                        disabled={props.isLinked}
                      />
                      <i
                        className="pi pi-check-square finance-review text-xl text-green-600"
                        data-pr-tooltip={reviewCheckBoxTooltip}
                        data-pr-position="left"
                        data-pr-my="left center-2"
                      ></i>
                      <label className="ml-2 text-xs">Finance Reviewed</label>
                    </div>
                  ) : (
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="financeReview"
                        onChange={e => {
                          setItemReviewed(rowData, e.target.checked);
                        }}
                        checked={rowData.review_status === 1 ? true : false}
                        disabled={
                          !editFinanceApproval || isLoading || props.isLinked
                        }
                      />
                      <label htmlFor="financeReview" className="ml-2 text-xs">
                        Finance Reviewed
                      </label>
                    </div>
                  ))}
              </div>
              <div className="flex align-items-center mb-2">
                {paidApproval &&
                  (paidReviewerExists ? (
                    <div className="flex align-items-center">
                      <Tooltip
                        target=".paid-review"
                        position="left"
                        mouseTrack
                        mouseTrackTop={15}
                      />
                      <i
                        className="pi pi-check-square paid-review text-xl text-green-600"
                        data-pr-tooltip={paidCheckBoxTooltip}
                        data-pr-position="left"
                        data-pr-my="left center-2"
                      ></i>
                      <label className="ml-2 text-xs">Paid</label>
                    </div>
                  ) : (
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="paidReview"
                        onChange={e => {
                          setItemPaid(rowData, e.target.checked);
                        }}
                        checked={rowData.paid_status === 1 ? true : false}
                        disabled={
                          !editPaidApproval || isLoading || props.isLinked
                        }
                      />
                      <label htmlFor="paidReview" className="ml-2 text-xs">
                        Paid
                      </label>
                    </div>
                  ))}
              </div>

              <div className="flex align-items-center">
                {props?.CRMEnabled && !rowData.is_commission_item && (
                  <div className="flex align-items-center">
                    {!invoiceGenerated && (
                      <>
                        <Checkbox
                          inputId="invoice"
                          onChange={e => {
                            props?.handleInvoiceItems(rowData, e.checked);
                            e.checked === false &&
                              setSelectAll(preState => ({
                                reqFrom: 'indirect',
                                value: e.checked,
                              }));
                          }}
                          checked={itemExistsInInvoice || false}
                          disabled={
                            invoiceGenerated ? true : false || props.isLinked
                          }
                        ></Checkbox>
                        <label htmlFor="invoice" className="ml-2 text-xs">
                          Invoice
                        </label>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!props.isLinked && (
              <div className="col-1 px-0 flex justify-content-center flex-wrap">
                {noteTemplate(rowData)}
                {rowData?.item?.inventory_managment && (
                  <Button
                    text
                    type="button"
                    tooltip="Inventory Management"
                    tooltipOptions={{
                      position: 'left',
                      mouseTrack: true,
                      mouseTrackTop: 15,
                    }}
                    disabled={props.isLinked}
                    rounded
                    onClick={() => {
                      handleInventory(rowData);
                      setSelectedItem(rowData);
                    }}
                    outlined
                    severity="primary"
                    className="border-none w-1rem p-0 focus:shadow-none"
                    size="small"
                    icon="pi pi-box"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const installerTemplate = rowData => {
    const fname =
      rowData?.project_item_user?.installer_details?.meta?.first_name?.length >
      0
        ? rowData.project_item_user.installer_details.meta.first_name
        : '';
    const mname =
      rowData?.project_item_user?.installer_details?.meta?.middle_name?.length >
      0
        ? ' ' + rowData.project_item_user.installer_details.meta.middle_name
        : '';
    const lname =
      rowData?.project_item_user?.installer_details?.meta?.last_name?.length > 0
        ? ' ' + rowData.project_item_user.installer_details.meta.last_name
        : '';
    if (fname.length > 0 || mname.length > 0 || lname.length > 0) {
      return `${fname}${mname}${lname}`;
    } else {
      return `Technician Not assigned`;
    }
  };

  const handleJobSelection = project => {
    getLinkedLaborItem(project?.value);
  };

  const costTemplate = rowData => {
    if (
      rowData?.project_item_user?.cost_ea &&
      +rowData.project_item_user.cost_ea
    ) {
      return formatNagativeCurrency(
        formatNumber(rowData.project_item_user.cost_ea, 2, true)
      );
    } else {
      return `-`;
    }
  };

  const totalCostTemplate = rowData => {
    if (
      rowData?.project_item_user?.cost_ea &&
      +rowData.project_item_user.cost_ea
    ) {
      return formatNagativeCurrency(
        formatNumber(
          parseFloat(rowData.project_item_user.cost_ea) *
            parseFloat(rowData.project_item_user.quantity),
          2,
          true
        )
      );
    } else {
      return `-`;
    }
  };

  const laborCostTemplate = rowData => {
    if (
      rowData?.project_item_user?.labor_cost_ea &&
      +rowData.project_item_user.labor_cost_ea
    ) {
      if (isUserInstaller) {
        if (
          rowData.project_item_user?.installer_details?.meta?.user_id !== userId
        ) {
          return '-';
        }
      }

      return formatNagativeCurrency(
        formatNumber(
          rowData.project_item_user.labor_cost_ea
            ? rowData.project_item_user.labor_cost_ea
            : 0,
          2,
          true
        )
      );
    } else {
      return `-`;
    }
  };

  const totalLaborCostTemplate = rowData => {
    if (
      rowData?.project_item_user?.labor_cost_ea_value &&
      +rowData.project_item_user.labor_cost_ea_value
    ) {
      if (isUserInstaller) {
        if (
          rowData.project_item_user?.installer_details?.meta?.user_id !== userId
        ) {
          return '-';
        }
      }
      return formatNagativeCurrency(
        formatNumber(
          !isNaN(parseFloat(rowData?.project_item_user?.labor_cost_ea_value))
            ? rowData?.project_item_user?.labor_cost_ea_value
            : 0,
          2,
          true
        )
      );
    } else {
      return `-`;
    }
  };

  const statusTemplate = rowData => {
    if (rowData?.project_item_user?.project_item_user_id) {
      let hideStatusFromTechnician = false;
      try {
        if (isUserInstaller) {
          if (
            userId !==
            rowData?.project_item_user?.installer_details?.meta?.user_id
          ) {
            hideStatusFromTechnician = true;
          }
        }
        if (
          rowData?.project_item_user?.project_item_user_id &&
          rowData.project_item_user.project_item_user_id > 0 &&
          (rowData?.project_item_user?.paid_marked_by_user_id?.length ||
            rowData?.project_item_user?.technician_payroll_reviewed_user_meta
              ?.first_name) &&
          rowData?.paid_marked_by_user_id
        ) {
          hideStatusFromTechnician = true;
        }
      } catch (error) {
        console.error(error);
      }

      return (
        <Dropdown
          className="p-inputtext-sm w-full p-0"
          value={rowData?.project_item_user?.status_id || null}
          optionLabel="status"
          optionValue="status_id"
          options={statusTypes}
          placeholder="No Status"
          onChange={e =>
            setItemStatus(
              rowData?.project_item_user?.project_item_user_id,
              rowData?.project_item_id,
              e
            )
          }
          pt={{
            input: { className: 'py-2' },
            item: { className: 'py-2 px-1' },
          }}
          disabled={hideStatusFromTechnician || props.isLinked}
        ></Dropdown>
      );
    } else {
      return null;
    }
  };

  const noteTemplate = rowData => {
    if (rowData?.project_item_id && rowData?.project_item_id > 0) {
      return (
        <>
          <Button
            icon="pi pi-file-edit"
            type="button"
            rounded
            size="small"
            text
            disabled={props.isLinked}
            className="bg-transparent focus:shadow-none paid-review text-green-600"
            aria-label="Update Note"
            onClick={() => {
              setSelectedItem(rowData);
              setIsItemNoteDialogVisible(true);
            }}
            pt={{
              icon: {
                className: `${rowData?.note_text?.length > 0 ? 'text-green-600' : 'text-primary'}`,
              },
            }}
            data-pr-my="left center-2"
            tooltip={
              rowData?.note_text?.length > 0 ? rowData?.note_text : 'Add Note'
            }
            tooltipOptions={{
              position: 'left',
              mouseTrack: true,
              mouseTrackTop: 0,
              className: `${rowData?.note_text?.length > 0 ? 'w-15rem md:w-26rem' : ''}`,
            }}
          />
        </>
      );
    }
  };

  const projectItemUserTemplates = rowData => {
    return (
      <div className="flex align-items-center">
        {rowData?.project_item_user?.project_item_user_id &&
        rowData.project_item_user.project_item_user_id > 0 &&
        (!rowData?.project_item_user?.paid_marked_by_user_id?.length ||
          !rowData?.project_item_user?.technician_payroll_reviewed_user_meta
            ?.first_name) &&
        !rowData?.paid_marked_by_user_id &&
        !rowData?.is_commission_item ? (
          <Button
            type="button"
            icon="pi pi-ellipsis-v"
            onClick={e => {
              setSelectedItemUser(rowData);
              itemUserOptionsMenuRef.current?.toggle(e);
            }}
            text
            size="small"
            outlined
            rounded
            className="border-none focus:shadow-none bg-transparent active:shadow-none"
            disabled={!editLaborItemUsers || props.isLinked}
            pt={{
              icon: {
                className: 'text-color',
              },
            }}
          ></Button>
        ) : (
          <Button
            type="button"
            icon="pi pi-ellipsis-v"
            outlined
            rounded
            text
            size="small"
            className="border-none focus:shadow-none"
            disabled={true}
            pt={{
              icon: {
                className: 'text-color',
              },
            }}
          ></Button>
        )}
        {modifiedAtDateTemplate(rowData) === null &&
        userMetaTemplate(rowData) === null ? (
          ''
        ) : (
          <>
            <i
              data-pr-tooltip={` ${
                userMetaTemplate(rowData) === null
                  ? ''
                  : `${userMetaTemplate(rowData)}`
              } ${
                modifiedAtDateTemplate(rowData) === null
                  ? ''
                  : `${modifiedAtDateTemplate(rowData)}`
              } `}
              data-pr-position="right"
              data-pr-my="left center-2"
              className="pi pi-exclamation-circle modifued-by mr-2 text-xs"
            ></i>

            <Tooltip target=".modifued-by" />
          </>
        )}

        {rowData?.project_item_user?.labor_item_visibility === 1 ? (
          <img
            src={technicianIcon}
            className="ml-1"
            alt="Technician"
            width={18}
            height={18}
          />
        ) : (
          <img
            src={technicianDeleteIcon}
            className="ml-1"
            alt="Technician"
            width={18}
            height={18}
          />
        )}
      </div>
    );
  };

  const isStatusIdCompleted = statusId => {
    if (statusId === -1) return '';
    try {
      const statusIndex = statusTypes.findIndex(
        item => item.status_id == statusId
      );
      if (statusIndex >= 0) {
        if (statusTypes[statusIndex].status === 'Completed') {
          return true;
        } else return false;
      } else {
        return false;
      }
    } catch (error) {
      return true;
    }
  };

  const completionDateTemplate = rowData => {
    if (
      rowData?.project_item_user?.project_item_user_id &&
      rowData?.project_item_user?.completion_date &&
      isStatusIdCompleted(
        rowData?.project_item_user?.status_id
          ? rowData?.project_item_user?.status_id
          : -1
      )
    ) {
      let hideDateFromTechnician = false;
      try {
        if (isUserInstaller) {
          if (
            userId !==
            rowData?.project_item_user?.installer_details?.meta?.user_id
          ) {
            hideDateFromTechnician = true;
          }
        }
        if (
          rowData?.project_item_user?.project_item_user_id &&
          rowData.project_item_user.project_item_user_id > 0 &&
          (rowData?.project_item_user?.paid_marked_by_user_id?.length ||
            rowData?.project_item_user?.technician_payroll_reviewed_user_meta
              ?.first_name) &&
          rowData?.paid_marked_by_user_id
        ) {
          hideDateFromTechnician = true;
        }
      } catch (error) {
        console.error(error);
      }
      if (!tabProjectItemsLaborModify) hideDateFromTechnician = true;
      return (
        <PFCalendar
          appendTo={null}
          id="completion_date"
          className="w-12"
          onChange={e =>
            !hideDateFromTechnician &&
            e?.target?.value &&
            setCompletionDate(
              rowData,
              `${moment(e?.target?.value).format('YYYY-MM-DD')}T00:00:00.000Z`
            )
          }
          dateFormat={'mm-dd-yy'}
          value={
            rowData?.project_item_user?.completion_date &&
            parseISO(rowData?.project_item_user?.completion_date?.slice(0, 10))
          }
          pt={{
            input: {
              root: { className: 'p-inputtext-sm' },
            },
          }}
          disabled={hideDateFromTechnician || props.isLinked}
          readOnlyInput
        />
      );
    } else {
      return null;
    }
  };

  const percentTemplate = rowData => {
    if (rowData?.project_item_user?.percentage) {
      return `${formatNumber(rowData.project_item_user.percentage)}%`;
    } else {
      return null;
    }
  };

  const quantityTemplate = rowData => {
    if (rowData?.project_item_user?.quantity) {
      return rowData.project_item_user.quantity;
    } else {
      return null;
    }
  };

  const transactionTemplate = rowData => {
    return rowData?.project_item_user?.qb_bill_txn_id || null;
  };

  const transactionDateTemplate = rowData => {
    return rowData?.project_item_user?.qb_bill_txn_date || null;
  };

  const confirmPaid = (flag, status) => {
    confirmDialog({
      message:
        'This action would update all the line items, which cannot be rolled back. Are you sure you want to continue ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          setIsLoading(true);

          const requestReviewStatus = status ? 1 : 0;
          const updateResp = await updateLaborProjectItems(projectId, {
            [flag]: requestReviewStatus,
          });
          setIsLoading(false);
          if (updateResp?.status && updateResp.status === true) {
            showAlert('success', updateResp.message);
            setUpdateLaborItems(true);
          } else {
            showAlert('error', 'Unable to update Project Item');
          }
        } catch (error) {
          setIsLoading(false);
          console.error(error);
          showAlert('error', 'Unable To update Project Item');
        }
      },
    });
  };

  const laborCardHeaderTemplate = laborItems => {
    return (
      <div className="flex justify-content-start align-content-center align-items-center mb-3">
        <h3>{props.isLinked ? 'Linked Labor Items' : 'Labor Items'}</h3>
        {!props.isLinked && (
          <div className="ml-auto flex gap-4 align-items-center">
            {props?.CRMEnabled ? (
              <div className="gap-2 flex">
                <Checkbox
                  onChange={e =>
                    setSelectAll(preState => ({
                      reqFrom: 'self',
                      value: !preState?.value,
                    }))
                  }
                  checked={selectAll?.value || false}
                  disabled={props.isLinked}
                ></Checkbox>
                <label>Select All Invoice</label>
              </div>
            ) : null}

            {paidApproval && (
              <div className="gap-2 flex">
                {laborItems?.length &&
                laborItems?.filter(
                  val =>
                    val?.paid_status === 1 &&
                    val?.paid_marked_by_user_id?.length > 0
                )?.length === laborItems?.length ? (
                  <>
                    {' '}
                    <i className="pi pi-check-square paid-review text-xl text-green-600"></i>
                    <label>Mark all as Paid</label>
                  </>
                ) : (
                  <>
                    <Checkbox
                      disabled={!laborItems?.length || props.isLinked}
                      onChange={e => {
                        if (e?.target?.checked) {
                          confirmPaid('paid_status', e?.target?.checked);
                        }
                      }}
                    ></Checkbox>
                    <label>Mark all as Paid</label>
                  </>
                )}
              </div>
            )}
            {financeApproval && (
              <div className="gap-2 flex">
                {laborItems?.length &&
                laborItems?.filter(
                  val =>
                    val?.review_status === 1 &&
                    val?.reviewer_user_id?.length > 0
                )?.length === laborItems?.length ? (
                  <>
                    {' '}
                    <i className="pi pi-check-square paid-review text-xl text-green-600"></i>
                    <label>Mark all as Finance Reviewed</label>
                  </>
                ) : (
                  <>
                    <Checkbox
                      disabled={!laborItems?.length || props.isLinked}
                      onChange={e => {
                        if (e?.target?.checked) {
                          confirmPaid('review_status', e?.target?.checked);
                        }
                      }}
                    ></Checkbox>
                    <label>Mark all as Finance Reviewed</label>
                  </>
                )}
              </div>
            )}

            {!props.isLinked && (
              <Button
                type="button"
                onClick={event => {
                  menuRef.current?.toggle(event);
                }}
                icon="pi pi-plus"
                outlined
                label="Labor Item"
                size="small"
              ></Button>
            )}
            <ConfirmDialog />
          </div>
        )}
        <div className="flex"></div>
      </div>
    );
  };

  const userMetaTemplate = rowData => {
    if (typeof rowData?.project_item_user?.user_meta === 'undefined') {
      return null;
    } else {
      const first_name = rowData?.project_item_user?.user_meta?.first_name;
      const last_name = rowData?.project_item_user?.user_meta?.last_name;
      let name = '';
      if (first_name && first_name?.trim().length > 0) {
        name = first_name;
      }
      if (last_name && last_name?.trim().length > 0) {
        name = `${name} ${last_name}`;
      }
      if (name.length > 0) return name;
      else return null;
    }
  };

  const itemTechnicianPayrollApproved = rowData => {
    if (!rowData?.project_item_user?.project_item_user_id) return <></>;
    let reviewCheckBoxTooltip = 'Not Reviewed';
    let reviewerExists = false;
    if (
      rowData?.project_item_user?.technician_payroll_reviewed_user_meta
        ?.first_name?.length > 0
    ) {
      reviewerExists = true;
      reviewCheckBoxTooltip = `${
        rowData?.project_item_user?.technician_payroll_reviewed_user_meta
          ?.first_name || ''
      } ${rowData?.project_item_user?.technician_payroll_reviewed_user_meta?.last_name || ''}`;
      const reviewDate = formatDateTimeMDYHM(
        rowData?.project_item_user.reviewed_date
      );
      if (reviewDate) {
        reviewCheckBoxTooltip = `${reviewCheckBoxTooltip} ${reviewDate}`;
      }
    }

    if (reviewerExists) {
      return (
        <div className="flex align-items-center ml-2 mr-3 mr-auto">
          <Tooltip
            target=".tooltipTarget"
            position="left"
            mouseTrack
            mouseTrackTop={15}
          />
          <i
            className="pi pi-check-square tooltipTarget text-xl text-green-600"
            data-pr-tooltip={reviewCheckBoxTooltip}
            data-pr-position="left"
            data-pr-at="right+5 top"
            data-pr-my="left center-2"
          ></i>
        </div>
      );
    } else {
      return (
        <div className="flex align-items-center ml-2 mr-3 mr-auto">
          <Checkbox
            inputId="reviewCheckbox"
            onChange={e => {
              setItemTechnicianPayrollReviewed(rowData, e.target.checked);
            }}
            checked={
              rowData?.project_item_user?.review_status === 1 ? true : false
            }
            disabled={
              !editTechnicianFinanceApproval ||
              isLoading ||
              !!rowData?.is_commission_item ||
              props.isLinked
            }
          />
        </div>
      );
    }
  };

  const itemTechnicianPaidStatusApproved = rowData => {
    if (!rowData?.project_item_user?.project_item_user_id) return <></>;
    let paidCheckBoxTooltip = 'Not Reviewed';
    let paidReviewerExists = false;
    if (rowData?.project_item_user?.paid_marked_by_user_id?.length > 0) {
      paidReviewerExists = true;
      paidCheckBoxTooltip = `${
        rowData?.project_item_user?.technician_paid_user_meta?.first_name || ''
      } ${rowData?.project_item_user?.technician_paid_user_meta?.last_name || ''}`;
      const paidDate = formatDateTimeMDYHM(
        rowData?.project_item_user?.paid_marked_at
      );
      if (paidDate) {
        paidCheckBoxTooltip = `${paidCheckBoxTooltip} ${paidDate}`;
      }
    }

    if (paidReviewerExists) {
      return (
        <div className="flex align-items-center ml-2 mr-3 mr-auto">
          <Tooltip
            target=".tooltipTarget"
            position="left"
            mouseTrack
            mouseTrackTop={15}
          />
          <i
            className="pi pi-check-square tooltipTarget text-xl text-green-600"
            data-pr-tooltip={paidCheckBoxTooltip}
            data-pr-position="left"
            data-pr-at="right+5 top"
            data-pr-my="left center-2"
          ></i>
        </div>
      );
    } else {
      return (
        <div className="flex align-items-center ml-2 mr-3 mr-auto">
          <Checkbox
            inputId="itemTechnicianPaidStatusCheckbox"
            onChange={e => {
              setItemTechnicianPaid(rowData, e.target.checked);
            }}
            checked={
              rowData?.project_item_user?.paid_status === 1 ? true : false
            }
            disabled={
              !editTechnicianPaidApproval ||
              isLoading ||
              !!rowData?.is_commission_item ||
              props.isLinked
            }
          />
        </div>
      );
    }
  };

  const modifiedAtDateTemplate = rowData => {
    if (rowData?.project_item_user?.modified_at) {
      return formatDateTimeMDYHM(rowData?.project_item_user?.modified_at);
    } else {
      return null;
    }
  };

  const columns = [
    {
      header: 'Action',
      body: projectItemUserTemplates,
      frozen: true,
      alignFrozen: 'left',
      pt: {
        headerCell: {
          style: { backgroundColor: '#242db1' },
          className: 'text-white	',
        },
      },
      style: { minWidth: '120px', maxWidth: '120px' },
    },
    {
      header: 'Technician',
      body: installerTemplate,
      frozen: true,
      pt: {
        headerCell: {
          style: { backgroundColor: '#242db1' },
          className: 'text-white	',
        },
      },
      style: { minWidth: '150px', maxWidth: '150px' },
    },
    {
      header: 'Percentage',
      body: percentTemplate,
      frozen: true,
      pt: {
        headerCell: {
          style: { backgroundColor: '#242db1' },
          className: 'text-white	',
        },
      },
      style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
      header: 'Quantity',
      body: quantityTemplate,
      className: '',
      frozen: true,
      pt: {
        headerCell: {
          style: { backgroundColor: '#242db1' },
          className: 'text-white	',
        },
      },
      style: { minWidth: '100px', maxWidth: '100px' },
    },

    ...(allLaborItemsCostField
      ? [
          {
            header: 'Labor Revenue Ea $',
            body: costTemplate,

            style: { minWidth: '150px', maxWidth: '150px' },
          },
          {
            header: 'Labor Revenue $',
            body: totalCostTemplate,

            style: { minWidth: '150px', maxWidth: '150px' },
          },
        ]
      : []),

    ...(showLaborCostFields || allLaborItemsCostField
      ? [
          {
            header: 'Labor Cost Ea $',
            body: laborCostTemplate,

            style: { minWidth: '150px', maxWidth: '150px' },
          },
          {
            header: 'Labor Cost $',
            body: totalLaborCostTemplate,

            style: { minWidth: '150px', maxWidth: '150px' },
          },
        ]
      : []),
    {
      header: 'Status',
      body: statusTemplate,
      className: '',
      style: { minWidth: '180px', maxWidth: '180px' },
    },
    {
      header: 'Completion Date',
      body: completionDateTemplate,
      style: { minWidth: '150px', maxWidth: '150px' },
    },

    ...(transactionIDShow
      ? [
          {
            header: 'Bill Txn ID',
            body: transactionTemplate,
            style: { minWidth: '150px', maxWidth: '150px' },
          },
        ]
      : []),

    ...(transactionIDShow
      ? [
          {
            header: 'Bill Txn Date',
            body: transactionDateTemplate,
            style: { minWidth: '150px', maxWidth: '150px' },
          },
        ]
      : []),

    ...(technicianFinanceApproval
      ? [
          {
            header: 'Finance Reviewed',
            body: itemTechnicianPayrollApproved,
            disabled: props.isLinked,
          },
        ]
      : []),
    ...(technicianPaidApproval
      ? [
          {
            header: 'Paid ',
            body: itemTechnicianPaidStatusApproved,
          },
        ]
      : []),
  ];

  let laborItemList = {};
  laborItems?.forEach(item => {
    laborItemList[item.project_item_id] = item;
  });
  let filteredLaborItem = Object.values(laborItemList);
  if (userAssignedLaborItems) {
    filteredLaborItem = filteredLaborItem.filter(
      item =>
        item?.project_item_user?.installer_details?.meta?.user_id === userId
    );
  }

  return (
    <Card
      header={laborCardHeaderTemplate(laborItems)}
      className="shadow-none"
      pt={{
        body: { className: 'p-0 overflow-auto' },
        content: {
          className: 'py-0',
        },
      }}
    >
      <Toast ref={toast} />
      {props.isLinked && filteredData?.child_projects?.length > 1 && (
        <div className="flex mb-5">
          <AutoComplete
            value={selectedJob}
            suggestions={items}
            completeMethod={search}
            onChange={e => setSelectedJob(e.value)}
            onSelect={e => handleJobSelection(e.value)}
            dropdown
            field="label"
          />
        </div>
      )}
      <Menu
        model={laborMenuOptions}
        popup
        ref={menuRef}
        pt={{
          action: { className: 'py-2' },
          root: {
            className: 'w-12rem',
          },
          label: {
            className: 'text-sm',
          },
        }}
      />
      <Menu
        model={itemMenuOptions}
        popup
        ref={itemOptionsMenuRef}
        pt={{
          action: { className: 'py-2' },
          root: {
            className: 'w-12rem',
          },
          label: {
            className: 'text-sm',
          },
        }}
      />
      {!props.isLinked && (
        <Menu
          model={itemUserMenuOptions}
          popup
          ref={itemUserOptionsMenuRef}
          pt={{
            action: { className: 'py-2' },
            root: {
              className: 'w-12rem',
            },
            label: {
              className: 'text-sm',
            },
          }}
        />
      )}
      {isAddLaborItemDialogVisible && (
        <AddEditLaborItemDialog
          visible={isAddLaborItemDialogVisible}
          setIsAddLaborItemDialogVisible={setIsAddLaborItemDialogVisible}
          itemId={null}
          itemsReload={setReloadSystemItems}
          setLaborLoading={() => {}}
          action="add"
          rowData={null}
          showAlert={showAlert}
        ></AddEditLaborItemDialog>
      )}

      {isAddProjectLaborItemDialogVisible && (
        <AddProjectLaborItemDialog
          visible={isAddProjectLaborItemDialogVisible}
          onHide={onAddProjectLaborItemDialogHide}
          projectId={projectId}
          itemsList={systemItems}
          addAllCostFields={addAllLaborCostFields}
          quoteNumber={props?.quoteNumber}
          CRMEnabled={props?.CRMEnabled}
          isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
          selectedProjectItem={cloneItem && selectedItem}
          isFromClone={isFromClone}
          setIsFromClone={setIsFromClone}
        ></AddProjectLaborItemDialog>
      )}

      {isAssignItemUserDialogVisible && selectedItem && (
        <AssignTechnicianDialog
          visible={isAssignItemUserDialogVisible}
          onHide={onAssignTechnicianDialogHide}
          existingItems={laborItems}
          technicians={projectInstallers}
          selectedItem={selectedItem}
          projectId={projectId}
          showAlert={showAlert}
        ></AssignTechnicianDialog>
      )}

      {isItemNoteDialogVisible && selectedItem && (
        <ItemUserNoteDialog
          visible={isItemNoteDialogVisible}
          onHide={onItemUserNoteDialogHide}
          selectedItem={selectedItem}
          projectId={projectId}
        ></ItemUserNoteDialog>
      )}

      {isDeleteMerchandiseItemVisible && selectedItem && (
        <DeleteProjectItemDialog
          visible={isDeleteMerchandiseItemVisible}
          projectId={projectId}
          selectedItem={selectedItem}
          onHide={onDeleteProjectItemDialogHide}
          showAlert={showAlert}
        ></DeleteProjectItemDialog>
      )}

      {isEditProjectLaborItemDialogVisible && selectedItem && (
        <EditProjectLaborItemDialog
          visible={isEditProjectLaborItemDialogVisible}
          projectId={projectId}
          selectedProjectItem={selectedItem}
          onHide={onEditProjectLaborItemDialogHide}
          itemsList={systemItems}
          addAllCostFields={editAllLaborCostFields}
          quoteNumber={props?.quoteNumber}
          CRMEnabled={props?.CRMEnabled}
          isMeasureSquareEnabled={props?.isMeasureSquareEnabled}
          warehouseList={props?.warehouseList}
        ></EditProjectLaborItemDialog>
      )}

      {isEditAssignedTechnicianDialog && selectedItemUser && (
        <EditAssignedTechnicianDialog
          visible={isEditAssignedTechnicianDialog}
          onHide={onEditAssignedTechnicianDialogHide}
          existingItems={laborItems}
          technicians={projectInstallers}
          selectedItem={selectedItemUser}
          projectId={projectId}
        ></EditAssignedTechnicianDialog>
      )}

      {isDeleteProjectItemUserDialogVisible && selectedItemUser && (
        <DeleteProjectItemUserDialog
          visible={isDeleteProjectItemUserDialogVisible}
          selectedItem={selectedItemUser}
          onHide={onDeleteProjectItemUserDialogHide}
          projectId={projectId}
          showAlert={showAlert}
        ></DeleteProjectItemUserDialog>
      )}

      {isLogsDialogVisible && selectedItem && (
        <ShowLogsDialog
          visible={isLogsDialogVisible}
          selectedItem={selectedItem}
          onHide={onShowLogsDialogHide}
        ></ShowLogsDialog>
      )}

      {isVisibilityControlsDialogVisible && selectedItem && (
        <VisibilityControlsDialog
          visible={isVisibilityControlsDialogVisible}
          selectedItem={selectedItem}
          laborItems={laborItems}
          onHide={onVisibilityControlsDialogVisible}
          isUnAssginItem={isUnAssginItem}
          projectId={projectId}
        ></VisibilityControlsDialog>
      )}
      {inventoryOpen && (
        <Inventory
          // key={`inv_lab_${openedTimes}`}
          className="ml-1"
          inventoryOpen={inventoryOpen}
          setInventoryOpen={setInventoryOpen}
          openedTimes={openedTimes}
          inventoryItem={selectedItem?.item ? selectedItem?.item : null}
        />
      )}

      {/* Accordion */}

      <div
        className={`card labor-items  overflow ${laborItems?.length > 3 ? 'max-h-25rem' : ''}`}
      >
        {laborItems?.length > 0 ? (
          loading ? (
            <>
              <TableLoader columnCount={7} noOfRow={3} mTopBottom={0} />
            </>
          ) : (
            <Accordion
              onTabChange={event => event?.preventDefault}
              activeIndex={activeIndex}
              className="minWidth-970"
            >
              {filteredLaborItem?.map((rowData, index) => (
                <AccordionTab
                  key={`acc_${index}`}
                  tabIndex={0}
                  headerTemplate={laborItemHeaderTemplate(rowData, index)}
                  pt={{
                    root: {
                      className: 'mb-0 border-bottom-1 border-200',
                    },
                    headeraction: {
                      className: 'border-none bg-transparent p-0 cursor-auto',
                    },
                    content: {
                      className: 'p-0 border-none',
                    },
                    headertitle: {
                      className: 'w-12',
                    },
                    headericon: {
                      className: 'hidden',
                    },
                  }}
                >
                  <DataTable
                    value={laborItems?.filter(
                      item => item?.project_item_id === rowData?.project_item_id
                    )}
                    disabled={props.isLinked}
                    expandedRows={expandedRows}
                    size="small"
                    pt={{
                      column: { className: 'p-0' },
                      table: { className: 'width-1200' },
                      root: { className: 'border-none' },
                    }}
                    scrollable
                  >
                    {columns?.map((col, index) => (
                      <Column
                        key={index}
                        field={col.field ? col.field : ''}
                        header={col.header ? col.header : ''}
                        body={col.body ? col.body : () => {}}
                        className={col.className ? col.className : ''}
                        style={col.style ? col.style : {}}
                        pt={{
                          headerCell: {
                            className:
                              'bg-primary text-white bg-transparent border-none	',
                          },
                          bodycell: {
                            className: col.frozen ? 'z-1' : '',
                          },
                        }}
                        frozen={col.frozen ? col.frozen : false}
                        alignFrozen={col.alignFrozen ? col.alignFrozen : 'left'}
                      />
                    ))}
                  </DataTable>
                </AccordionTab>
              ))}
            </Accordion>
          )
        ) : (
          'No results found.'
        )}
      </div>
    </Card>
  );
}
