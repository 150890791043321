// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { Notifications } from '../../../constants/notification.constant';
import { updateNotificationViewed } from '../../shared/Topbar/Notification/Notification.service';
import { REACT_APP_ENABLE_PUSH_NOTIFICATION } from '../../../constants/envConstants';

export const getProjectEmailData = async (
  projectId,
  setEmailList,
  setApiRunning,
  searchQuery,
  setAlert
) => {
  try {
    setApiRunning(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      ?.filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/email/manage/project/${projectId}?${newQueryString}`
    );
    if (response?.data && response?.data?.items?.length) {
      setEmailList(response?.data);
    }
  } catch (error) {
    setAlert('error', 'No Email Found.', false, true);
  } finally {
    setApiRunning(false);
  }
};

export const getMailDetails = async (
  messageId,
  setMessageData,
  setApiRunning,
  setAlert
) => {
  try {
    setApiRunning(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/email/manage/conversation/${messageId}`
    );
    if (response?.data) {
      setMessageData(response?.data);
    }
  } catch (error) {
    setAlert('error', 'Error loading email details.', false, true);
  } finally {
    setApiRunning(false);
  }
};

export const getMailAttachementDetails = async (
  messageId,
  attachementId,
  fileName,
  setAttachementApiRunning,
  setAlert
) => {
  try {
    setAttachementApiRunning(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/email/manage/${messageId}/attachment/${attachementId}`
    );
    if (response?.data) {
      var blob = new Blob([response?.data]);
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      setAttachementApiRunning(false);
    }
  } catch (error) {
    setAlert('error', 'Error fetching mail attachement details.', false, true);
  }
};

export const sendEmail = async (
  projectId,
  messageData,
  setApiRunning,
  setShowPanel,
  setAlert
) => {
  try {
    setApiRunning(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/email/manage/${projectId}/create`,
      messageData
    );
    if (response?.data) {
      setAlert('success', 'Email has sent successfully.', false, true);
      setShowPanel('listView');
    }
  } catch (error) {
    console.error(error)
    setAlert('error', 'Error sending email.', false, true);
  } finally {
    setApiRunning(false);
  }
};

export const emailReviewRead = async (messageId, type = 'review') => {
  try {
    await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/email/manage/${messageId}/${type}`
    );
    let viewed = false;
    let reviewed = false;
    if (type === 'review') {
      reviewed = true;
    } else {
      viewed = true;
    }
    if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true) {
      await updateNotificationViewed(messageId, Notifications.EMAIL, localStorage.getItem('user_id'), viewed, reviewed);
    }
  } catch (error) {
    console.log('Review Email Error', error);
  }
};
