import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { debounce } from 'lodash';

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import Inventory from '../Inventory/Inventory.view';
import PFTableLoader from '../../shared/Loader/PFTableLoader.js';
import PFDataTable from '../../shared/PFPrime/PFDataTable.js';
import PFInputText from '../../shared/PFPrime/PFInputText.js';
import PFButton from '../../shared/PFPrime/PFButton.js';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import { ParentItemFilterOptions } from '../../../constants.js';

import AddEditLaborItemDialog from './AddEditLaborItemDialog.js';
import { getLaborItemsList } from './Items.service.js';
import { useStyles } from './Items.styles.js';
import { unlinkGroupItems } from './GroupItems/group-items.service';

import './styles.css';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';
const LaborTableNew = ({ itemId }) => {
  const classes = useStyles();
  const [, setLoadingTable] = useState(false);
  const [laborSearchQuery, setLaborSearchQuery] = useState({
    limit: 10,
    offset: 0,
  });
  const [filters, setFilters] = useState({});
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [inventoryItem, setInventoryItem] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isAddLaborItemDialogVisible, setIsAddLaborItemDialogVisible] =
    useState(false);
  const [action, setAction] = useState('add');
  const [rowData, setRowData] = useState('');
  const [laborLoading, setLaborLoading] = useState(true);
  const [itemReload, setItemReload] = useState(false);
  const [eventRow, setEventRow] = useState(null);
  const [eventFirst, setEventFirst] = useState(null);
  const [laborItemsList, setLaborItemsList] = useState([]);

  const toast = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (!inventoryOpen)
      getLaborItemsList(laborSearchQuery, setLaborLoading, setLaborItemsList);
  }, [eventRow, eventFirst, laborSearchQuery, itemReload, inventoryOpen]);

  const viewEditLaborItems = (action, index, rowData) => {
    if (
      laborItemsList.items[index]?.inventory_managment ||
      laborItemsList.items[index]?.capacity
    ) {
      setInventoryItem(laborItemsList.items[index]);
      setInventoryOpen(true);
    } else {
      setInventoryItem({});
      setInventoryOpen(false);
      setRowData(rowData);
      setAction(action);
      setIsAddLaborItemDialogVisible(true);
    }
  };

  const handleUnlinkItems = async rowData => {
    setLaborLoading(true);
    try {
      const response = await unlinkGroupItems(rowData?.item);
      if (response) {
        toast.current.show({
          severity: 'success',
          summary: 'Item Unlinked',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLaborLoading(false);
      setItemReload(true);
    }
  };
  const getRowClassName = rowData => {
    if (rowData?.item_is_child) {
      return 'highlight-children';
    } else if (rowData?.item_is_parent) {
      return 'highlight-parent';
    }
    return '';
  };
  const handleUnlinkClick = rowData => {
    confirmDialog({
      message: `Are you sure you want to unlink the ${!rowData?.item_parent_id ? 'parent' : ''} item "${rowData?.item_number} - ${rowData?.item_desc}"? ${!rowData?.item_parent_id ? 'This action will also remove all its associated child items.' : ''}`,
      header: 'Unlink Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleUnlinkItems(rowData),
      rejectLabel: 'Cancel',
      acceptLabel: 'Unlink',
      pt: {
        root: {
          className: 'w-30rem',
        },
      },
    });
  };
  // Labor Generic Table
  const dataTableColumns = [
    {
      field: '',
      sortable: false,
      style: { width: '75px' },
      ...(!laborLoading
        ? {
            body: rowData => {
              const dataIndex = rowData?.index;
              return checkPermission(permissions?.items?.editLaborItem) &&
                laborItemsList.items[dataIndex]?.user?.username !== 'system' ? (
                <div
                  id={`labor-icons-${dataIndex}`}
                  className="flex justify-content-between align-items-center"
                >
                  <Tooltip target=".editItem" mouseTrack mouseTrackLeft={10} />
                  <span
                    className="cursor-pointer ml-3 editItem"
                    data-pr-tooltip="Edit"
                    data-pr-position="top"
                  >
                    <i
                      className="pi pi-pencil text-color-900"
                      onClick={() =>
                        viewEditLaborItems('edit', dataIndex, rowData)
                      }
                    ></i>
                  </span>
                  {rowData?.item_is_parent ? (
                    <span className="cursor-pointer ml-3">
                      <Tooltip target=".ejectItems" />
                      <i
                        className="pi pi-list text-color-900 ejectItems"
                        data-pr-tooltip="Show Linked Items"
                        data-pr-position="top"
                        onClick={() =>
                          history.push({
                            pathname: '/project/items/group-items/add',
                            state: {
                              type: 'labor',
                              is_child: true,
                              item_number: rowData?.item_number,
                              item_desc: rowData?.item_desc,
                              item_id: rowData?.item,
                            },
                          })
                        }
                      ></i>
                    </span>
                  ) : null}
                  {rowData?.item_is_parent || rowData?.item_is_child ? (
                    <span className="cursor-pointer ml-3">
                      <Tooltip target=".unlinkItem" />
                      <img
                        className="unlinkItem color-red"
                        src="/images/UnlinkIcon.svg"
                        alt="Unlink Icon"
                        data-pr-tooltip="Unlink Item"
                        data-pr-position="top"
                        onClick={() => handleUnlinkClick(rowData)}
                      />
                    </span>
                  ) : (
                    <span className="cursor-pointer ml-3">
                      <Tooltip target=".linkItem" />
                      <i
                        className="pi pi-link text-color-900 linkItem"
                        alt="Link Icon"
                        data-pr-tooltip="Link Item"
                        data-pr-position="top"
                        onClick={() => {
                          history.push({
                            pathname: '/project/items/group-items/add',
                            state: {
                              type: 'labor',
                              is_child: false,
                              item_number: rowData?.item_number,
                              item_desc: rowData?.item_desc,
                              item_id: rowData?.item,
                            },
                          });
                        }}
                      ></i>
                    </span>
                  )}
                </div>
              ) : null;
            },
          }
        : {}),
    },
    {
      field: 'item_number',
      header: 'Item #',
      sortable: true,
      style: { width: '150px' },
    },
    {
      field: 'item_desc',
      header: 'Item Description',
      sortable: true,
      style: { width: '35%', whiteSpace: 'normal', wordBreak: 'break-word' },
    },
    {
      field: 'item_parent_id',
      header: 'Parent Item',
      filter: true,
      showFilterMatchModes: false,
      showFilterMenuOptions: false,
      showFilterOperator: false,
      showClearButton: true,
      showAddButton: false,
      filterClear: (
        <PFButton
          size="small"
          label="Clear"
          onClick={() => {
            setFilters({});
            setLaborSearchQuery(prevState => ({
              ...prevState,
              is_parent: undefined,
              is_child: undefined,
            }));
          }}
          severity=" "
          outlined={true}
        />
      ),
      pt:
        Array.isArray(filters?.item_parent_id?.constraints) &&
        filters?.item_parent_id?.constraints.length
          ? {
              filtermenubutton: {
                style: { color: 'green' },
              },
            }
          : {},
      filterElement: options => {
        if (
          Array.isArray(filters?.item_parent_id?.constraints) &&
          filters?.item_parent_id?.constraints.length &&
          !options.value
        ) {
          options.value = filters?.item_parent_id?.constraints[0]?.value;
        }
        return (
          <PFDropdown
            value={options?.value}
            name="filter"
            optionLabel="label"
            optionValue="value"
            options={ParentItemFilterOptions}
            onChange={(name, value) => {
              options.filterCallback(value);
            }}
            placeholder="Select"
          />
        );
      },
      ...(!laborLoading
        ? {
            body: rowData => {
              if (rowData?.item_parent_id && rowData?.parent) {
                return (
                  <span
                    style={{ cursor: 'pointer', color: 'green' }}
                    onClick={() => {
                      history.push({
                        pathname: '/project/items/group-items/add',
                        state: {
                          type: 'labor',
                          is_child: true,
                          item_number: rowData.parent?.item_number,
                          item_desc: rowData.parent?.item_desc,
                          item_id: rowData.parent?.item_id,
                        },
                      });
                    }}
                  >
                    {`${rowData?.parent?.item_number}`}
                  </span>
                );
              } else {
                return '-';
              }
            },
          }
        : {}),
      style: { width: '150px' },
    },
    {
      field: 'retail_price',
      header: 'Retail Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'revenue_price',
      header: 'Revenue Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'cost_price',
      header: 'Cost Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'inventory_managment',
      header: 'Has Inventory',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'exclude_from_commission',
      header: 'Excluded From Commission',
      sortable: true,
      style: { width: '200px' },
    },
    {
      field: 'exclude_from_revenue',
      header: 'Excluded From Revenue',
      sortable: true,
      style: { width: '200px' },
    },
    {
      field: 'is_sum_for_total_quantity',
      header: 'Sum For Total Quantity',
      sortable: true,
      style: { width: '200px' },
    },
    {
      field: 'is_basic_labor',
      header: 'Basic Labor',
      sortable: true,
      style: { width: '200px' },
    },
  ];
  const onFilter = e => {
    setFilters(e.filters);
    if (
      Array.isArray(e.filters?.item_parent_id?.constraints) &&
      e.filters?.item_parent_id?.constraints.length
    ) {
      const value = e.filters?.item_parent_id?.constraints[0]?.value;

      if (value === ParentItemFilterOptions[0].value) {
        setLaborSearchQuery(prevState => ({
          ...prevState,
          is_parent: 1,
          is_child: undefined,
        }));
      } else if (value === ParentItemFilterOptions[1].value) {
        setLaborSearchQuery(prevState => ({
          ...prevState,
          is_parent: undefined,
          is_child: 1,
        }));
      }
    }
  };

  const dataArray = useMemo(() => {
    if (laborItemsList?.items?.length) {
      return laborItemsList?.items?.map((val, index) => {
        return {
          index: index,
          item: val?.item_id || '',
          parent: val?.parent || null,
          children: val?.children || [],
          item_is_parent: val?.parent_id === 0 || false,
          item_parent_id: val?.parent_id || '',
          item_is_child: !!val?.parent_id,
          item_number: val?.item_number || '',
          item_desc: val?.item_desc || '',
          retail_price: val.retail_price || 'N/A',
          revenue_price: val.revenue_price || 'N/A',
          cost_price: val.cost_price || 'N/A',
          inventory_managment: val.inventory_managment ? 'Yes' : 'No',
          manufacturer: val?.meta_type_manufacturer,
          vendor: val?.meta_type_vendor,
          exclude_from_commission: val?.exclude_from_commission ? 'Yes' : 'No',
          exclude_from_revenue: val?.exclude_from_revenue ? 'Yes' : 'No',
          is_sum_for_total_quantity: val?.is_sum_for_total_quantity
            ? 'Yes'
            : 'No',
          is_basic_labor: val?.is_basic_labor ? 'Yes' : 'No',
        };
      });
    }
    return [];
  }, [laborItemsList?.items]);

  const onChangeRowsPerPage = event => {
    setEventRow(event?.rows);
    setEventFirst(event?.first);
    setLoadingTable(true);
    setLaborSearchQuery(prevState => ({
      ...prevState,
      limit: event?.rows,
      offset: event?.first,
    }));
  };

  const onColumnSortChange = (changedColumn, direction) => {
    setLoadingTable(true);
    setLaborSearchQuery(prevState => ({
      ...prevState,
      sortKey: changedColumn,
      sortDirection: direction === 1 ? 'asc' : 'desc',
    }));
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    setEventRow(null);
    setEventFirst(null);
    const query = searchInputVal?.trim();
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    if (!query) {
      setLaborSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        query: '',
      }));
      return;
    }
    if (query.length < 3) return;
    if (query.length >= 3) {
      setLaborSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        query: query,
      }));
    }
  };

  const debouncedKeywordSearch = useCallback(debounce(handleSearch, 500), []);

  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-content-between">
          <h3 className="text-900 pt-2 text-lg">Labor Items</h3>
          <div className="flex gap-2">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <PFInputText
                value={globalFilterValue}
                onChange={e => {
                  setGlobalFilterValue(e.target.value);
                  debouncedKeywordSearch(e.target.value);
                }}
                placeholder="Keyword Search"
              />
            </span>
            {checkPermission(permissions?.items?.addLaborItem) && (
              <PFButton
                size="small"
                label="Add Labor Item"
                classes={{ root: classes.buttonRoot }}
                onClick={() => {
                  setIsAddLaborItemDialogVisible(true), setAction('add');
                }}
                disabled={!checkPermission(permissions?.items?.addLaborItem)}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const dataTableParams = { header: renderHeader() };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: laborSearchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  // Label Generic Table Ends Here.
  return (
    <div className="inventory-container">
      <Toast
        ref={toast}
        appendTo={null}
        pt={{
          root: {
            className: 'toast-index',
          },
        }}
      />
      <ConfirmDialog />

      <>
        {inventoryOpen && (
          <Inventory
            className="ml-1"
            inventoryOpen={inventoryOpen}
            setInventoryOpen={setInventoryOpen}
            inventoryItem={inventoryItem}
            itemsReload={setItemReload}
            showAlert={showAlert}
          />
        )}
        <div>
          <PFDataTable
            columns={dataTableColumns}
            data={laborLoading ? tableLoader : dataArray}
            paginator={!laborLoading}
            dataKey="item_id"
            rows={laborSearchQuery?.limit}
            rowsPerPageOptions={[10, 20, 50, 100]}
            tableStyle={{ minWidth: '80rem' }}
            dataTableParams={dataTableParams}
            rowClassName={getRowClassName}
            onSort={event => {
              onColumnSortChange(event?.sortField, event?.sortOrder);
            }}
            filter={filters}
            onFilter={event => onFilter(event)}
            sortField={laborSearchQuery?.sortKey}
            sortOrder={laborSearchQuery?.sortDirection === 'asc' ? 1 : -1}
            totalRecords={laborItemsList?.count || 0}
            lazy={true}
            first={laborSearchQuery?.offset}
            onPage={onChangeRowsPerPage}
            pt={{
              wrapper: {
                style: { height: '500px' },
              },
            }}
          />
        </div>
      </>

      {isAddLaborItemDialogVisible && (
        <AddEditLaborItemDialog
          visible={isAddLaborItemDialogVisible}
          setIsAddLaborItemDialogVisible={setIsAddLaborItemDialogVisible}
          itemId={itemId}
          itemsReload={setItemReload}
          setLaborLoading={setLaborLoading}
          action={action}
          rowData={rowData}
          showAlert={showAlert}
        />
      )}
    </div>
  );
};

export default LaborTableNew;
