// **** React Imports ****
import React, { useEffect, useMemo } from 'react';

// **** Utilities ****
import {
  Grid,
  TextField,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import { parseISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

import {
  checkPermission,
  formatDate,
  sendTentativeScheduleNoteToSource,
} from '../../../utils/Helpers';
// **** Custom Components ****
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import {
  VIEW_NOTE_TYPES,
  SOURCE_SYSTEMS,
  HD_CATEGORY,
  SOURCE_STATUS,
} from '../../../constants';
import permissions from '../../../config/permissions';
import PFButton from '../../shared/PFPrime/PFButton.js';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import {
  getNotesData,
  getNotesTypeOptions,
  addNotes,
  updateNote,
  deleteNoteById,
  changeNoteVisibilityStatus,
} from './Notes.service';
import { useStyles } from './Notes.styles';
const NOTES_FORM_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  ADD: 'add',
  REVIEW: 'review',
};

const NOTE_TYPE = {
  INSTALLER: 1,
  RETAILER: 2,
  STORE: 3,
  INTERNAL: 4,
  ITEM: 5,
  RESCHEDULE: 6,
  BUSTED: 7,
  SCHEDULED: 8,
  DELIVERY: 25,
};

const Notes = ({ formik, schedulerEditPermission }) => {
  const [notesList, setNotesList] = React.useState({ count: 0, items: [] });
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Notes',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [formAction, setFormAction] = React.useState('add');

  const [typeOptions, setTypeOptions] = React.useState();
  const { setAlert } = useAlerts();
  const [reloadList, setReloadList] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });

  useEffect(() => {
    if (formik?.values?.project_id || reloadList) {
      getNotesData(
        formik?.values?.project_id,
        searchQuery,
        setLoading,
        setNotesList
      );
    }
  }, [formik?.values?.project_id, reloadList, searchQuery]);

  useEffect(() => {
    if (isOpen) {
      getNotesTypeOptions(setTypeOptions);
    }
  }, [isOpen]);

  const isItems = Array.isArray(notesList?.items);
  const dataArray = useMemo(() => {
    return isItems
      ? notesList?.items?.map((val, index) => {
        const notes = {
          reviewed_by: `${val?.reviewed_by_user && val?.reviewed_by_user !== null
            ? `${val?.reviewed_by_user?.first_name} ${val?.reviewed_by_user?.last_name}`
            : '-'
            } `,
          viewed_by: `${val?.viewed_by_user && val?.viewed_by_user !== null
            ? `${val?.viewed_by_user?.first_name} ${val?.viewed_by_user?.last_name}`
            : '-'
            } `,
          project_note_id: val.project_note_id,
          note_category_id: val.note_category_id,
          category_name: val.category?.category_name
            ? val.category?.category_name
            : '',
          note_text: val.note_text,
          note_created_by:
            val.external_note_created_by &&
              val.external_note_created_by !== null
              ? val.external_note_created_by
              : val.created_by && val.created_by !== null
                ? `${val?.created_by?.first_name} ${val?.created_by?.last_name}`
                : '',
          note_created_at:
            (val?.note_created_at &&
              moment(val?.note_created_at).format('MM-DD-YYYY hh:mm A')) ||
            null,
          follow_up_date: formatDate(val?.follow_up_date) || null,
          follow_up_date_value: val?.follow_up_date || null,
          status: (
            <div className="flex flex-column align-items-start">
              {val?.viewed_by_user && (
                <Typography
                  variant="subtitle2"
                  className={classes.statusColorAcknowledged}
                >
                  <i className="pi pi-check"></i> Viewed
                </Typography>
              )}
              {val?.reviewed_by_user && (
                <Typography
                  variant="subtitle2"
                  className={classes.statusColorAcknowledged}
                >
                  <i className="pi pi-check"></i> Reviewed
                </Typography>
              )}
              {!val?.viewed_by_user && !val?.reviewed_by_user ? <>-</> : null}
            </div>
          ),
          reviewed_at: `${val?.reviewed_by_user && val?.reviewed_by_user !== null
            ? moment(val?.reviewed_at).format('MM-DD-YYYY hh:mm A')
            : '-'
            } `,
          viewed_at: `${val?.viewed_by_user && val?.viewed_by_user !== null
            ? moment(val?.viewed_at).format('MM-DD-YYYY hh:mm A')
            : '-'
            } `,
        };
        notes.source_status = val.source_status || '-';
        return notes;
      })
      : [];
  }, [notesList]);

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const search = searchInputVal?.trim();

    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search: search,
      }));
    }, 500);
  };

  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    search: true,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: notesList?.count,
    serverSide: true,
    textLabels: {
      body: {
        noMatch:
          (loading && '') ||
          (!loading && !dataArray.length && 'No records found'),
      },
    },
    setRowProps: (row, dataIndex) => {
      const notesTypeId = notesList?.items?.[dataIndex]?.note_category_id;
      const isViewed = notesList?.items?.[dataIndex]?.viewed_by_user;
      const isReviewed = notesList?.items?.[dataIndex]?.reviewed_by_user;
      if (
        !isViewed &&
        !isReviewed &&
        notesTypeId === VIEW_NOTE_TYPES[2].note_category_id
      ) {
        return {
          style: { backgroundColor: '#FFEBEE' },
        };
      }
      return {};
    },
    onChangeRowsPerPage: numberOfRows => {
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction.toUpperCase(),
      }));
    },
    onSearchChange: searchText => {
      if (searchText?.length > 2) {
        handleSearch(searchText);
      }
    },
    onSearchClose: () => {
      handleSearch('');
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    pagination: !loading,
  };

  // **** Form Validation Schema ****
  const NotesFormikSchema = Yup.object().shape({
    type: Yup.number().required('Type is required.'),
    note_text: Yup.string().trim().required('Notes is required.'),
  });

  const notesFormik = useFormik({
    initialValues: {
      type: '',
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: NotesFormikSchema,
    onSubmit: async values => {
      if (formAction === 'add') {
        addNotes(
          formik?.values?.project_id,
          values,
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      }
      if (formAction === NOTES_FORM_MODE.EDIT) {
        try {
          setLoading(true);
          const projectId = formik?.values?.project_id;
          const noteId = values?.project_note_id;
          const payload = {
            note_category_id: values?.type,
            note_text: values?.note_text,
            follow_up_date: values?.follow_up_date,
          };
          const updateResponse = await updateNote(projectId, noteId, payload);
          if (updateResponse?.status === 200) {
            setAlert('success', 'Note Updated Successfully.', false, true);
            setIsOpen(false);
            setLoading(false);
            setReloadList(true);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    enableReinitialize: true,
  });
  const viewNotes = async (action, index) => {
    if (
      action === NOTES_FORM_MODE.VIEW &&
      notesList?.items?.[index]?.viewed_by
    ) {
      return;
    }
    if (
      action === NOTES_FORM_MODE.REVIEW &&
      notesList?.items?.[index]?.reviewed_by
    ) {
      return;
    }
    setReloadList(false);
    try {
      setLoading(true);
      const statusResponse = await changeNoteVisibilityStatus(
        action,
        notesList?.items?.[index]?.project_id,
        notesList?.items?.[index]?.project_note_id,
        notesList?.items?.[index]?.note_category_id,
      );
      if (statusResponse?.status === 200) {
        setAlert(
          'success',
          'Note visibility status updated Successfully.',
          false,
          true
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setReloadList(true);
    }
  };

  const deleteNote = async index => {
    setReloadList(false);
    try {
      setLoading(true);
      const deleteResponse = await deleteNoteById(
        formik?.values?.project_id,
        notesList?.items?.[index]?.project_note_id
      );
      if (deleteResponse?.status === 200) {
        setAlert('success', 'Note Deleted Successfully.', false, true);
        setReloadList(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeNotes = async index => {
    setReloadList(false);
    try {
      const projectId = formik?.values?.project_id;
      const noteId = notesList?.items?.[index]?.project_note_id;
      const payload = {
        source_status: SOURCE_STATUS.CLOSED,
      };

      const updateResponse = await updateNote(projectId, noteId, payload);
      if (updateResponse?.status === 200) {
        setAlert('success', 'Notes Closed Successfully.', false, true);
        setIsOpen(false);
        setLoading(false);
        setReloadList(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const checkDeletePermission = noteCategoryId => {
    let deletableNoteTypes = [];
    if (checkPermission(permissions?.viewEditProject?.deleteRetailerNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.RETAILER);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteInstallerNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.INSTALLER);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteInternalNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.INTERNAL);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteScheduledNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.SCHEDULED);
    }
    if (checkPermission(permissions?.viewEditProject?.deleteDeliveryNotes)) {
      deletableNoteTypes.push(NOTE_TYPE.DELIVERY);
    }
    if (deletableNoteTypes?.includes(noteCategoryId)) {
      return true;
    }
    return false;
  };

  /*Columns required in Generic DataTable*/
  let dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        viewColumns: false,
        ...(!loading
          ? {
            customBodyRenderLite: dataIndex => {
              return (
                <span id={`labor-icons-${dataIndex}`} className="ml-3 flex">
                  <span className="flex align-items-center">
                    {isItems &&
                      Object.values(NOTE_TYPE).includes(
                        notesList?.items?.at(dataIndex)?.note_category_id
                      ) &&
                      !notesList?.items?.at(dataIndex)?.viewed_by_user ? (
                      <PFButton
                        icon="pi pi-eye"
                        type="button"
                        tooltip="Mark Viewed"
                        tooltipOptions={{
                          position: 'top',
                          mouseTrack: true,
                          mouseTrackTop: 10,
                        }}
                        outlined
                        className="border-none px-0 text-500"
                        onClick={() =>
                          viewNotes(NOTES_FORM_MODE.VIEW, dataIndex)
                        }
                      />
                    ) : null}
                    {isItems &&
                      Object.values(NOTE_TYPE).includes(
                        notesList.items.at(dataIndex).note_category_id
                      ) &&
                      !notesList?.items?.at(dataIndex)?.reviewed_by_user ? (
                      <PFButton
                        size="small"
                        icon="pi pi-eye"
                        type="button"
                        tooltip="Mark Reviewed"
                        tooltipOptions={{
                          position: 'top',
                          mouseTrack: true,
                          mouseTrackTop: 10,
                        }}
                        outlined
                        className="border-none px-0 text-500"
                        onClick={() => {
                          viewNotes(NOTES_FORM_MODE.REVIEW, dataIndex);
                        }}
                      />
                    ) : null}

                    {isItems &&
                      checkDeletePermission(
                        notesList?.items?.at(dataIndex)?.note_category_id
                      ) ? (
                      <PFButton
                        icon="pi pi-trash"
                        type="button"
                        tooltip="Delete"
                        tooltipOptions={{
                          position: 'top',
                          mouseTrack: true,
                          mouseTrackTop: 10,
                        }}
                        outlined
                        className="border-none px-0 text-500"
                        onClick={() =>
                          confirmDialog({
                            message: (
                              <div className="flex flex-column align-items-start gap-2">
                                <span className="text-semibold">
                                  Are you sure you want to delete below note?
                                </span>
                                <code>
                                  {notesList?.items?.at(dataIndex)?.note_text}
                                </code>
                              </div>
                            ),
                            header: 'Delete Confirmation',
                            icon: 'pi pi-info-circle',
                            acceptClassName: 'p-button-danger',
                            accept: () => deleteNote(dataIndex),
                            rejectLabel: 'Cancel',
                            acceptLabel: 'Delete',
                            pt: {
                              root: {
                                className: 'w-30rem',
                              },
                            },
                          })
                        }
                      />
                    ) : null}

                    {isItems &&
                      notesList?.items?.at(dataIndex)?.source_status ===
                      SOURCE_STATUS.OPEN &&
                      formik.values?.source_system?.source_system_id ===
                      SOURCE_SYSTEMS.HOME_DEPOT &&
                      formik.values?.hd_category === HD_CATEGORY.BLINDS && (
                        <PFButton
                          icon="pi pi-lock-open p-0"
                          type="button"
                          tooltip="Close"
                          tooltipOptions={{
                            position: 'top',
                            mouseTrack: true,
                            mouseTrackTop: 10,
                          }}
                          outlined
                          className="border-none px-0 text-500"
                          onClick={() => closeNotes(dataIndex)}
                        />
                      )}
                  </span>
                </span>
              );
            },
          }
          : {}),
      },
    },
    {
      name: 'category_name',
      label: 'Type',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'note_text',
      label: 'Note',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: '500px' },
        }),
      },
    },
    {
      name: 'follow_up_date',
      label: 'Follow up Date',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'note_created_by',
      label: 'Created By',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '15%' },
        }),
      },
    },
    {
      name: 'note_created_at',
      label: 'Created at',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { width: '12%' },
        }),
      },
    },
    {
      name: 'status',
      label: 'Visibility Status',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'viewed_by',
      label: 'Viewed By',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'viewed_at',
      label: 'Viewed At',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'reviewed_by',
      label: 'Reviewed By',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
    {
      name: 'reviewed_at',
      label: 'Reviewed At',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: '10%' },
        }),
      },
    },
  ];

  if (
    formik.values?.source_system?.source_system_id ===
    SOURCE_SYSTEMS.HOME_DEPOT &&
    formik.values?.hd_category === HD_CATEGORY.BLINDS
  ) {
    dataTableColumns = [
      ...dataTableColumns,
      {
        name: 'source_status',
        label: 'Source Status',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({
            style: { width: '10%' },
          }),
        },
      },
    ];
  }

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  return (
    <>
      {formik.values.project_id && (
        <Grid container direction="column" spacing={4}>
          <ConfirmDialog />
          {checkPermission(permissions?.viewEditProject?.tabNotesAdd) && (
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end">
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  disableRipple
                  classes={{ root: classes.buttonRoot }}
                  onClick={() => {
                    notesFormik.setValues({
                      type: '',
                      note_text: '',
                      follow_up_date: null,
                    });
                    setDialogSettings(prevState => ({
                      ...prevState,
                      title: 'Add Notes',
                      showButton2: true,
                    }));
                    setFormAction('add');
                    setIsOpen(true);
                    setReloadList(false);
                    notesFormik?.handleReset();
                  }}
                  disabled={schedulerEditPermission}
                >
                  Add Item
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <GenericDataTable
              title="Notes"
              columns={dataTableColumns}
              data={loading ? tableLoader : dataArray}
              options={dataTableOptions}
            />
            <form>
              <GenericDialog
                fullwidth
                isOpen={isOpen}
                handleClose={() => {
                  setIsOpen(false);
                  notesFormik.handleReset();
                }}
                disabledButton2={!notesFormik.dirty || !notesFormik.isValid}
                disabledButton1={false}
                dialogSettings={dialogSettings}
                handleSave={() => notesFormik.handleSubmit()}
              >
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Autocomplete
                      id="type"
                      name="type"
                      options={
                        formAction === NOTES_FORM_MODE.VIEW ||
                          formAction === NOTES_FORM_MODE.REVIEW
                          ? VIEW_NOTE_TYPES || []
                          : typeOptions || []
                      }
                      getOptionLabel={option => option && option.category_name}
                      renderInput={params => (
                        <TextField {...params} label="Type" required />
                      )}
                      value={
                        formAction === NOTES_FORM_MODE.VIEW ||
                          formAction === NOTES_FORM_MODE.REVIEW
                          ? VIEW_NOTE_TYPES?.find(
                            type =>
                              type.note_category_id ===
                              notesFormik?.values?.type
                          )
                          : typeOptions?.find(
                            type =>
                              type.note_category_id ===
                              notesFormik?.values?.type
                          ) || null
                      }
                      onChange={(event, value) => {
                        if (value != null) {
                          notesFormik.setFieldValue(
                            'type',
                            value?.note_category_id
                          );
                        } else {
                          notesFormik.setFieldValue('type', null);
                        }
                      }}
                      onBlur={notesFormik.handleBlur}
                      disabled={
                        formAction === NOTES_FORM_MODE.VIEW ||
                        formAction === NOTES_FORM_MODE.EDIT ||
                        formAction === NOTES_FORM_MODE.REVIEW
                      }
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      required
                      id="note_text"
                      name="note_text"
                      label="Note"
                      multiline
                      onChange={notesFormik.handleChange}
                      onBlur={notesFormik.handleBlur}
                      InputProps={{
                        readOnly: formAction === 'view' ? true : false,
                      }}
                      value={notesFormik?.values?.note_text}
                      error={
                        notesFormik?.touched?.note_text &&
                        notesFormik.errors.note_text
                      }
                      helperText={
                        notesFormik.touched.note_text &&
                        notesFormik.errors.note_text
                      }
                    />
                  </Grid>
                  <Grid item className="mui-calendar">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <MuiKeyboardDatePicker
                        id="follow_up_date"
                        autoOk
                        label="Follow up Date"
                        format="MM-dd-yyyy"
                        value={
                          (notesFormik?.values?.follow_up_date?.split('T')
                            ?.length &&
                            parseISO(
                              notesFormik?.values?.follow_up_date?.slice(0, 10)
                            )) ||
                          null
                        }
                        onChange={(date, value) =>
                          notesFormik?.setFieldValue(
                            'follow_up_date',
                            `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                          )
                        }
                        disablePast={formAction !== 'view'}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                          style: { display: formAction === 'view' && 'none' },
                        }}
                        disabled={
                          formAction === NOTES_FORM_MODE.VIEW ||
                          formAction === NOTES_FORM_MODE.EDIT ||
                          formAction === NOTES_FORM_MODE.REVIEW
                        }
                        onBlur={notesFormik.handleBlur}
                        variant="inline"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {notesFormik?.values?.review_by &&
                    notesFormik?.values?.review_at && (
                      <Typography variant="subtitle2">
                        <span className={classes.statusColorAcknowledged}>
                          Reviewed By : {notesFormik.values.review_by}
                        </span>
                        <br />
                        On
                        {moment(notesFormik.values.review_at).format(
                          'MM-DD-YYYY hh:mm A'
                        )}
                      </Typography>
                    )}
                </Grid>
              </GenericDialog>
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Notes;
