import React, { useRef } from 'react';
import '../css/index.css';
import { OverlayPanel } from 'primereact/overlaypanel';

import { PROJECT_REPORT } from '../../../constants';
import { checkPermission, ellipsisString } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

const EventToolTipContent = ({ eventInfo, time, view }) => {
  const popover = useRef(null);

  return (
    <>
      <i
        className="pi pi-exclamation-circle absolute right-0 mt-2 mr-3 z-5 cursor-pointer"
        onMouseEnter={e => popover.current.show(e)}
        onMouseLeave={e => popover.current.hide(!e)}
      ></i>
      <OverlayPanel
        appendTo={null}
        ref={popover}
        pt={{
          root: {
            className: 'z-index-9999 w-22rem',
          },
        }}
      >
        <table className="w-full vertical-align-top text-xs overlay-table-collapsed overlay-striped-table">
          {view == PROJECT_REPORT.FULL_CALENDAR && eventInfo?.installer_name ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Technician Name</strong>
              </td>
              <td>{eventInfo?.installer_name}</td>
            </tr>
          ) : null}
          {eventInfo?.basic_labor_sum ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Qty</strong>
              </td>
              <td>{eventInfo?.basic_labor_sum}</td>
            </tr>
          ) : null}
          {time ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Time</strong>
              </td>
              <td>{time}</td>
            </tr>
          ) : null}
          {eventInfo?.store_number ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Store #</strong>
              </td>
              <td>{eventInfo?.store_number}</td>
            </tr>
          ) : null}
          {eventInfo?.client_name ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Client Name</strong>
              </td>
              <td>{eventInfo?.client_name}</td>
            </tr>
          ) : null}
          {eventInfo?.category ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Category</strong>
              </td>
              <td className="word_break">{eventInfo?.category}</td>
            </tr>
          ) : null}
          {eventInfo?.address ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 word_break p-2">
                <strong>Address</strong>
              </td>
              <td>{eventInfo?.address}</td>
            </tr>
          ) : null}
          {eventInfo?.total_sale_amount &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr className="border-1 border-gray-200">
                <td className="minWidth-130 p-2">
                  <strong>Total Sale $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_sale_amount}`}</td>
              </tr>
            )}
          {eventInfo?.total_revenue &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr className="border-1 border-gray-200">
                <td className="minWidth-130 p-2">
                  <strong>Total Revenue $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_revenue}`} </td>
              </tr>
            )}
          {eventInfo?.project_desc ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Description</strong>
              </td>
              <td className="word_break">
                {ellipsisString(eventInfo?.project_desc, 45)}
              </td>
            </tr>
          ) : null}
          {eventInfo?.project_type ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Type</strong>
              </td>
              <td>{eventInfo?.project_type}</td>
            </tr>
          ) : null}
          {eventInfo?.project_status ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Status</strong>
              </td>
              <td>{eventInfo?.project_status}</td>
            </tr>
          ) : null}
          {eventInfo?.project_number ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Project No</strong>
              </td>
              <td>{eventInfo?.project_number}</td>
            </tr>
          ) : null}
          {eventInfo?.mobile_phone ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Phone</strong>
              </td>
              <td>{eventInfo?.mobile_phone}</td>
            </tr>
          ) : null}
          {eventInfo?.confirmation_status ? (
            <tr className="border-1 border-gray-200">
              <td className="minWidth-130 p-2">
                <strong>Confirmation Status</strong>
              </td>
              <td>{eventInfo?.confirmation_status}</td>
            </tr>
          ) : null}
        </table>
      </OverlayPanel>
    </>
  );
};
export default EventToolTipContent;
