import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useParams } from 'react-router';
import { confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import _ from 'lodash';
import { Card } from 'primereact/card';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import { initialValues, itemSubmit } from '../helpers/Items';
import { floatParser } from '../../../../utils/Helpers';
import usePFDataTable from '../../../../hooks/PFDatatable';
import SkeletonLoader from '../../../shared/Loader/skeleton';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import { discountTypes } from '../constants/constants';
import { formateNumber } from '../helpers/Quotes';
import {
  costCalculator,
  retailCalculator,
  subTotalCalculator,
  revenueCalculator,
  marginCalculator,
  retailerPerCalculator,
  onChangeMarginCalculator,
  onQtyBlur,
  onBlurUnitPrice,
  onBlurRetailEach,
  onBlurMargin,
  onBlurDiscount,
  onBlurDiscountType,
  onBlurTax,
} from '../helpers/item-amount-calculation.helper';
import { lineItemList } from '../services/item.service';

import ItemEditor from './ItemEditor';
import ItemDescriptionEditor from './ItemDescriptionEditor';
import QtyEditor from './QtyEditor';
import CostEachEditor from './CostEachEditor';
import RetailEachEditor from './RetailEachEditor';
import DiscountTypeEditor from './DiscountTypeEditor';
import DiscountEditor from './DiscountEditor';
import TaxEditor from './TaxEditor';
import MarginEditor from './MarginEditor';
import RevenueEditor from './RevenueEditor';
import TotalPriceEditor from './TotalPriceEditor';

const Items = ({
  reference,
  setItems,
  laborItems = [],
  merchandiseItems = [],
  isMeasureSquareEnabled,
  retailerPercentage,
  isForm,
  setIsForm,
  title = '',
  addBtnLabel = '',
  retailRate = 0,
}) => {
  const { mode } = useParams();
  const [formInitValue, setFormInitValue] = useState(initialValues);
  const [isMeasureSquare, setIsMeasureSqure] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editItemDesc, seteditItemDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [selectedlineItem, setSelectedlineItem] = useState(null);
  const [calculatedMargin, setCalculatedMargin] = useState(0);
  const [editingRows, setEditingRows] = useState({});
  const [revenueEach, setRevenueEach] = useState(0);
  const [onChangeMargin, setOnChangeMargin] = useState(false);
  const [previousRetailerRate, setPreviousRetailerRate] = useState(0);

  const submitAction = itemSubmit(
    setGridData,
    setIsForm,
    formInitValue,
    setEditingRows,
    setFormInitValue,
    setItems
  );

  const {
    handleSubmit,
    resetForm,
    values,
    setFieldValue,
    touched,
    errors,
    setValues,
  } = submitAction;

  const accept = indexToDelete => {
    const newArray = gridData.filter((_, index) => index !== indexToDelete);
    const resultArray = newArray.map((item, index) => ({ ...item, index }));
    setItems(resultArray);
    setGridData(resultArray);
  };

  useEffect(() => {
    if (gridData?.length === 0 && mode !== 'create') {
      let data =
        reference === 'Labor' ? laborItems || [] : merchandiseItems || [];
      if (data?.length > 0) {
        data = data?.map((item, index) => {
          item.rowIndex = index + 1;
          return item;
        });
      }

      if (reference === 'Labor') {
        setGridData(data);
      }
      if (reference === 'Merchandise') {
        setGridData(data);
      }
    }
  }, [laborItems, merchandiseItems]);

  useEffect(() => {
    setIsMeasureSqure(isMeasureSquareEnabled);
  }, [isMeasureSquareEnabled]);

  useEffect(() => {
    if (selectedlineItem) {
      setValues(pre => ({
        ...pre,
        itemDescription: selectedlineItem?.item_desc || '',
        itemNumber: selectedlineItem?.item_number || '',
        itemId: selectedlineItem?.item_id || '',
        itemTypeId: selectedlineItem?.item_type_id || '',
        unitPrice: formateNumber(selectedlineItem?.cost_price) || '',
        retailEach: formateNumber(selectedlineItem?.retail_price) || '',
      }));
    }
  }, [selectedlineItem]);

  const getItemList = _.debounce(async (setterFn, search = null) => {
    try {
      const typeId = reference === 'Merchandise' ? 6 : 2;
      const list = await lineItemList(search, typeId);

      if (list.status === 200 && list?.data?.items?.length > 0) {
        setterFn && setterFn(list.data.items);
      }
    } catch (err) {
      console.error(err);
    }
  }, 300);

  useEffect(() => {
    getItemList(setLineItems);
  }, []);

  const onCancel = rowData => {
    if (isForm && rowData.rowIndex === 0) {
      const removeFirstIndex = gridData?.filter(i => i?.rowIndex != 0);
      setItems(removeFirstIndex);
      setGridData(removeFirstIndex);
      resetForm();

      setEditingRows({});
      setIsForm(false);
    } else {
      resetForm();
      setEditingRows({});
      setIsForm(false);
    }
  };

  const onEdit = rowData => {
    getItemList(setLineItems, (rowData?.itemDescription || '').substring(0, 3));
    setEditingRows({
      [rowData.rowIndex]: true,
    });
    setIsForm(true);
    setFormInitValue(rowData);
  };

  const onDelete = indexToDelete => {
    confirmDialog({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this item?',
      icon: 'pi pi-trash',
      acceptClassName: 'p-button-primary p-button-sm',
      accept: () => accept(indexToDelete),
      reject: () => false,
      header: 'Confirmation',
      pt: { content: { className: 'py-2' } },
    });
  };

  const itemGridColumns = () => {
    let dataValue = {};
    let main = [
      {
        header: 'Actions',
        rowEditor: true,
        body: (rowData, options) => {
          const isEditing = editingRows[rowData.rowIndex];
          return !isEditing ? (
            <div className="flex vertical-align-top ">
              <PFButton
                icon="pi pi-trash"
                outlined
                text
                severity="primary"
                aria-label="Cancel"
                className="text-white border-none"
                onClick={() => onDelete(options?.rowIndex)}
                disabled={isForm || mode === 'view'}
              />
              <PFButton
                icon="pi pi-pencil"
                outlined
                text
                className={`text-white border-none`}
                aria-label="Edit"
                onClick={() => onEdit(rowData)}
                disabled={isForm || mode === 'view'}
              />
            </div>
          ) : (
            <div className="flex w-max">
              <PFButton
                icon="pi pi-check"
                outlined
                text
                className="text-white border-none"
                aria-label="Save"
                onClick={handleSubmit}
              />
              <PFButton
                icon="pi pi-ban"
                outlined
                text
                aria-label="Cancel"
                className="text-white border-none"
                onClick={() => onCancel(rowData)}
              />
            </div>
          );
        },
        className: 'vertical-align-top w-1',
      },
      {
        header: 'Item',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <ItemEditor
            rowData={rowData}
            options={options}
            getItemList={getItemList}
            setSelectedlineItem={setSelectedlineItem}
            setLineItems={setLineItems}
            editingRows={editingRows}
            lineItems={lineItems}
            submitAction={submitAction}
          />
        ),

        className: 'vertical-align-top',
      },
      {
        header: 'Description',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <ItemDescriptionEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Qty',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <QtyEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
            onQtyBlur={onQtyBlur}
            retailerPercentage={retailerPercentage}
          />
        ),

        className: 'vertical-align-top',
      },
      {
        header: `Cost`,
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <CostEachEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
            onBlurUnitPrice={onBlurUnitPrice}
            retailerPercentage={retailerPercentage}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Retail',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <RetailEachEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
            onBlurRetailEach={onBlurRetailEach}
            retailerPercentage={retailerPercentage}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Discount Type',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <DiscountTypeEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
            onBlurDiscountType={onBlurDiscountType}
            retailerPercentage={retailerPercentage}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Discount',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <DiscountEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
            onBlurDiscount={onBlurDiscount}
            retailerPercentage={retailerPercentage}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Tax',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <TaxEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
            onBlurTax={onBlurTax}
            retailerPercentage={retailerPercentage}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Margin',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <MarginEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
            setOnChangeMargin={setOnChangeMargin}
            onBlurMargin={onBlurMargin}
            retailerPercentage={retailerPercentage}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Revenue',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <RevenueEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
          />
        ),
        className: 'vertical-align-top',
      },
      {
        header: 'Sub Total',
        sortable: false,
        filter: false,
        body: (rowData, options) => (
          <TotalPriceEditor
            rowData={rowData}
            options={options}
            editingRows={editingRows}
            submitAction={submitAction}
          />
        ),
        className: 'vertical-align-top',
      },
    ];

    return main;
  };
  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setFirst,
    setDefaultTableProps,
  } = usePFDataTable();

  const onAdd = () => {
    getItemList(setLineItems);
    setSelectedlineItem(null);
    setFormInitValue(initialValues);
    setGridData(pre => [initialValues, ...pre]);
    setEditingRows({ 0: true });
    setIsForm(true);
    setFirst(0);
  };

  useEffect(() => {
    setDefaultTableProps(pre => ({
      ...pre,
      editMode: 'row',
      dataKey: 'rowIndex',
      onRowEditComplete: () => {},
      lazy: false,
      scrollable: true,
    }));
    DataTableColumns(itemGridColumns());
  }, []);

  useEffect(() => {
    setDefaultTableProps(pre => ({
      ...pre,
    }));
    DataTableColumns(itemGridColumns());
  }, [
    editingRows,
    values,
    lineItems,
    errors,
    touched,
    isForm,
    gridData,
    formInitValue,
    mode,
  ]);

  useEffect(() => {
    tableData(gridData);
  }, [gridData]);

  useEffect(() => {
    let retailerRate = formateNumber(retailRate);
    retailerRate = retailerRate === 0 ? 100 : retailerRate;
    setPreviousRetailerRate(retailerRate);

    if (previousRetailerRate !== retailerRate && gridData?.length > 0) {
      const data = gridData?.map((item, i) => {
        item.rowIndex = i + 1;

        item.totalRetail = formateNumber(item.totalRetail || 0);
        if (retailerRate === 100) {
          item.totalRetail = formateNumber(item.totalRevenue || 0);
        }

        item.totalRetail = item.totalRevenue / (retailerRate / 100);
        item.quantity = formateNumber(item.quantity || 0);
        item.retailEach = formateNumber(item.totalRetail / item.quantity);

        const discountType = item?.discountType || 'Amount';
        const discount = formateNumber(item?.discount || 0);
        let totalDiscount = formateNumber(discount || 0);
        if (discountType === 'Percentage') {
          totalDiscount = formateNumber(
            (item.totalRetail || 0) * ((discount || 0) / 100)
          );
        }

        item.totalDiscount = totalDiscount;
        item.tax = formateNumber(item?.tax || 0);
        item.taxAmount = formateNumber(
          (item.totalRetail || 0) * ((item.tax || 0) / 100)
        );

        item.totalPrice = formateNumber(
          item.totalRetail - item.totalDiscount + item.taxAmount
        );

        return item;
      });
      setGridData(data);
    }
  }, [retailRate]);

  return (
    <div className="p-2">
      <Card>
        <div className="grid w-full flex">
          <div className="col-6 md:col-6 lg:col-6">
            <span className="font-bold p-1">{`${title}`}</span>
          </div>
          <div className="sm:col-6 md:col-6 lg:col-6">
            {mode !== 'view' ? (
              <div className="align-items-end justify-content-end flex">
                <PFButton
                  label={`${addBtnLabel}`}
                  onClick={onAdd}
                  icon="pi pi-plus"
                  outlined={false}
                  disabled={isForm}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {loading ? <SkeletonLoader /> : <TableLayout />}
      </Card>
    </div>
  );
};

export default Items;
