import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { RadioButton } from 'primereact/radiobutton';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import PFInputNumber from '../../shared/PFPrime/PFInputNumber';
import {
  PROJECT_REPORT,
  scheduleInfoUserCustomAction,
  scheduleInfoUserFillAction,
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
} from '../../../constants';
import { getPercentageValue } from '../../../utils/Helpers';
import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import {
  getAllInstallersList,
  getProjectStatusList,
  updateInstallerSchedule,
} from '../services/calendar.service';

const Loader = () => (
  <div className="w-full h-full flex flex-column justify-content-center align-items-center">
    <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
    <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
    <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
    <Skeleton height="3rem" className="mb-6 w-6"></Skeleton>
  </div>
);
const ScheduleDropDialog = ({
  calenderRefreshHandler,
  reschedule,
  parentData,
}) => {
  const dispatch = useDispatch();
  const {
    isDropDialogOpen,
    dropInfo,
    dropDialogData,
    view,
    droppedResourceDetails,
  } = useSelector(state => state.newScheduler);
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [projectStatusList, setProjectStatusList] = useState([]);
  const [projectInstallerList, setProjectInstallerList] = useState([]);
  const [technicianAction, setTechnicianAction] = useState(
    parentData?.technicianAction
  );
  const [schedulePercentage, setSchedulePercentage] = useState(
    parentData?.schedulePercentage
  );
  const handleFormValidate = values => {
    let error = {};
    if (values?.start && values?.end && values?.end < values?.start) {
      error.end = 'End Time must be greater than Start Time.';
    }
    return error;
  };

  const scheduleDropValidationSchema = Yup.object().shape({
    installer: Yup.object()
      .shape({
        installerUserId: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        id: Yup.number().required('Required'),
      })
      .required('Select a technician'),
    start: Yup.string().required('Required'),
    end: Yup.string().required('Required'),
    status: Yup.object()
      .shape({
        status_id: Yup.number().required('Required'),
        status: Yup.string().required('Required'),
      })
      .required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      installer: null,
      start: null,
      end: null,
      status: null,
    },
    validationSchema: scheduleDropValidationSchema,
    validate: handleFormValidate,
    onSubmit: async values => {
      setIsLoading(true);
      let updateObj = {
        installerId: values?.installer?.id,
        projectId: dropDialogData?.project_id,
        projectStartDateTime: values?.start?.toISOString(),
        projectEndDateTime: values?.end?.toISOString(),
        old_installerId: null,
        old_startDateTime: null,
        old_endDateTime: null,
        status_id: values?.status?.status_id,
      };
      if (view === PROJECT_REPORT.CALENDAR || view === PROJECT_REPORT.SPLIT) {
        updateObj = {
          ...updateObj,
          userAction: technicianAction,
          percentage:
            technicianAction === scheduleInfoUserCustomAction
              ? schedulePercentage
              : 0,
        };
      }
      reschedule(updateObj, async action => {
        try {
          if (action) {
            const response = await updateInstallerSchedule(updateObj);
            if (response?.status === 200) {
              dispatch(
                setNewSchedulerComponent({
                  isDropDialogOpen: false,
                })
              );
              toast.current.show({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Schedule successful',
                life: 3000,
              });
              calenderRefreshHandler();
            } else {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Something went wrong',
                life: 3000,
              });
            }
          }
        } catch (error) {
          console.log(error, '...error ScheduleDropDialog');
        } finally {
          setIsLoading(false);
          setSchedulePercentage(0);
        }
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isDropDialogOpen) {
      setIsLoading(true);
      Promise.all([getAllInstallersList(), getProjectStatusList()])
        .then(([installerList, statusList]) => {
          if (Array.isArray(installerList)) {
            setProjectInstallerList(
              installerList.map(item => {
                return {
                  name: `${item?.first_name} ${item?.last_name}`,
                  id: item?.installer_id,
                  installerUserId: item?.user_id,
                };
              })
            );
          }
          if (Array.isArray(statusList)) {
            setProjectStatusList(statusList);
            formik.setValues({
              start: new Date(dropInfo?.start),
              end: new Date(dropInfo?.end),
              status: statusList?.find(
                status => status?.status === 'Scheduled'
              ),
              ...((view === PROJECT_REPORT.CALENDAR ||
                view === PROJECT_REPORT.SPLIT) && {
                installer: droppedResourceDetails
                  ? {
                      name: droppedResourceDetails?.title,
                      id: droppedResourceDetails?.id,
                      installerUserId: droppedResourceDetails?.installerUserId,
                    }
                  : {},
              }),
            });
          }
        })
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [dropInfo]);

  const handleDialogOnHide = () => {
    dispatch(setNewSchedulerComponent({ isDropDialogOpen: false }));
  };

  const viewScheduleReport = () => {
    window.open(`/project/view/${dropDialogData?.project_id}`, '_blank');
  };

  return (
    <>
      <Dialog
        header="Schedule Job"
        pt={{
          header: {
            className: 'border-bottom-1 border-400 py-4 bg-teal-50',
          },
          content: { className: 'overflow-visible pb-1' },
        }}
        visible={isDropDialogOpen}
        className="w-10  xl:w-7"
        onHide={handleDialogOnHide}
        draggable={false}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="grid col-12">
            <div className="grid col-12 text-sm flex-wrap">
              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4">Store #</span>
                <span className="w-1">:</span>
                <span className="w-7">{dropDialogData?.store_number}</span>
              </div>
              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4 ">Client Name</span>
                <span className="w-1">:</span>
                <span className="w-7">{dropDialogData?.client_name}</span>
              </div>
              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4 ">Category</span>
                <span className="w-1">:</span>
                <span className="w-7"> {dropDialogData?.category}</span>
              </div>

              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4 ">Type</span>
                <span className="w-1">:</span>
                <span className="w-7">{dropDialogData?.project_type}</span>
              </div>
              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4">Source Status</span>
                <span className="w-1">:</span>
                <span className="w-7">{dropDialogData?.IMS_status}</span>
              </div>
              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4">Project No</span>
                <span className="w-1">:</span>
                <span className="w-7">{dropDialogData?.project_number}</span>
              </div>
              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4">Mobile Number</span>
                <span className="w-1">:</span>
                <span className="w-7">{dropDialogData?.home_phone}</span>
              </div>
              <div className="col-12 md:col-6 lg:col-6 flex flex-row">
                <span className="w-4">Alternate Number</span>
                <span className="w-1">:</span>
                <span className="w-7">{dropDialogData?.mobile_phone}</span>
              </div>
              <div className="col-12 flex flex-row">
                <span className="w-2">Address</span>
                <span>:</span>
                <span className="w-9 pl-5">
                  {dropDialogData?.installation_address}
                </span>
              </div>
            </div>
            <div className="col-12 flex flex-column flex-wrap">
              {view == PROJECT_REPORT.FULL_CALENDAR ? (
                <div className="col-12 flex flex-row px-0 text-sm">
                  <span className="col-2 px-0">Select Technician</span>

                  <span className="col-12 md:col-6 lg:col-6 px-0">
                    <Dropdown
                      id="installer"
                      value={formik.values.installer}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      options={projectInstallerList}
                      optionLabel="name"
                      className="w-full"
                    />
                  </span>
                </div>
              ) : null}

              {view == PROJECT_REPORT.CALENDAR ||
              view == PROJECT_REPORT.SPLIT ? (
                <div className="flex flex-column text-sm">
                  <div>Default Technician Action :</div>
                  <div className="flex flex-column md:flex-row lg:flex-row justify-content-evenly">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="fullAllocation"
                        value={scheduleInfoUserWipeAction}
                        onChange={e => {
                          setTechnicianAction(e.value);
                          formik.setFieldValue('userAction', e.value);
                        }}
                        checked={
                          technicianAction === scheduleInfoUserWipeAction
                        }
                      />
                      <label htmlFor="fullAllocation" className="ml-2">
                        Full Allocation
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="customAllocation"
                        value={scheduleInfoUserCustomAction}
                        onChange={e => {
                          setTechnicianAction(e.value);
                          formik.setFieldValue('userAction', e.value);
                        }}
                        checked={
                          technicianAction === scheduleInfoUserCustomAction
                        }
                      />
                      <label htmlFor="customAllocation" className="ml-2">
                        Custom Allocation
                      </label>
                      {technicianAction === scheduleInfoUserCustomAction && (
                        <PFInputNumber
                          id="percentage"
                          value={schedulePercentage}
                          onChange={(event, value) => {
                            const percentage = getPercentageValue(value);
                            setSchedulePercentage(percentage);
                            formik.setFieldValue('percentage', percentage);
                          }}
                          suffix={'%'}
                          placeholder="%"
                          className="w-36 ml-2"
                          pt={{
                            root: {
                              className: 'h-2rem pb-2 pt-1',
                            },
                            input: {
                              root: {
                                className:
                                  'w-full border-noround border-bottom-1 border-top-none border-x-none p-0  shadow-none border-gray-500',
                              },
                            },
                          }}
                          min={0}
                          maxLength={6}
                          max={100}
                        />
                      )}
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="allocateRemaining"
                        value={scheduleInfoUserFillAction}
                        onChange={e => {
                          setTechnicianAction(e.value);
                          formik.setFieldValue('userAction', e.value);
                        }}
                        checked={
                          technicianAction === scheduleInfoUserFillAction
                        }
                      />
                      <label htmlFor="allocateRemaining" className="ml-2">
                        Allocate Remaining
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="leaveUnassigned"
                        value={scheduleInfoUserSkipAction}
                        onChange={e => {
                          setTechnicianAction(e.value);
                          formik.setFieldValue('userAction', e.value);
                        }}
                        checked={
                          technicianAction === scheduleInfoUserSkipAction
                        }
                      />
                      <label htmlFor="leaveUnassigned" className="ml-2">
                        Leave Unassigned
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-12 flex flex-wrap px-0">
              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label">
                  <Calendar
                    id="start"
                    className="w-full"
                    value={formik.values.start}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    showTime
                    hourFormat="12"
                  />
                  <label htmlFor="start">Start</label>
                </span>
              </div>

              <div className="col-12 md:col-4 lg:col-4 flex flex-column pr-1">
                <span className="p-float-label">
                  <Calendar
                    id="end"
                    className="w-full"
                    value={formik.values.end}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    showTime
                    hourFormat="12"
                  />
                  <label htmlFor="end">End</label>
                </span>
                {formik.errors?.end && (
                  <div className="mt-1 text-red-600">{formik.errors?.end}</div>
                )}
              </div>
              <div className="col-12 md:col-4 lg:col-4">
                <span className="p-float-label">
                  <Dropdown
                    id="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={projectStatusList}
                    optionLabel="status"
                    className="w-full"
                  />
                  <label htmlFor="status">Status</label>
                </span>
              </div>
            </div>

            <div className="col-12 flex flex-wrap md:flex-row lg:flex-row flex-column gap-3 justify-content-end">
              <Button
                severity="primary"
                size="small"
                label="GoTo Job"
                onClick={viewScheduleReport}
              />
              <Button
                label="Save"
                onClick={formik.handleSubmit}
                disabled={!formik.dirty || !formik.isValid}
                size="small"
                severity="primary"
              />
              <Button
                size="small"
                severity="primary"
                label="Cancel"
                onClick={handleDialogOnHide}
              />
            </div>
          </div>
        )}
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default ScheduleDropDialog;
