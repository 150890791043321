// **** React Imports ****
import React, { useState, useMemo } from 'react';

// **** External Utilities ****
import {
  Grid,
  TextField,
  CircularProgress,
  Input,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { parseISO } from 'date-fns';
import { useFormik } from 'formik';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as Yup from 'yup';
import moment from 'moment';

// **** Custom Components ****
import NumberFormat from 'react-number-format';

import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import permissions from '../../../config/permissions';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import {
  getAdditionalCharges,
  createAdditionalCharge,
  updateAdditionalCharge,
  deleteAdditionalChargeById,
  getExpenses,
  createExpense,
  updateExpense,
  deleteExpenseById,
  getPrTypes,
  getExpenseSources,
} from './AdditionalFee.service';

// **** Styles *****
import { useStyles } from './AdditionalFee.styles.js';

const AdditionalFee = ({ projectId }) => {
  const [loading, setLoading] = useState(true);
  const [reloadAdditionalList, setReloadAdditionalList] = useState(false);
  const [reloadExpenseList, setReloadExpenseList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [additionalChargeId, setAdditionalChargeId] = useState();
  const [expenses, setExpenses] = useState([]);
  const [expenseId, setExpenseId] = useState();
  const [prTypes, setPrTypes] = useState([]);
  const [expenseSources, setExpenseSources] = useState([]);
  const [actionOriginTable, setActionOriginTable] = useState();
  const classes = useStyles();
  const { setAlert } = useAlerts();
  const [formAction, setFormAction] = useState();

  const [dialogSettings, setDialogSettings] = useState({
    title: '',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    header: '',
    title: '',
    subtitle: '',
  });

  const additionalChargeTableColumns = [
    {
      id: 'dateCreated',
      value: 'Date',
    },
    {
      id: 'prNumber',
      value: 'PR#',
    },
    {
      id: 'dollarAmount',
      value: 'Dollar Amount',
    },
    {
      id: 'storeAssociate',
      value: 'Store Associate',
    },
    {
      id: 'notes',
      value: 'Notes',
    },
    {
      id: 'createdBy',
      value: 'Created By',
    },
  ];

  const expensesTableColumns = [
    {
      id: 'expenseSourceName',
      value: 'Expense Source',
    },
    {
      id: 'dollarAmount',
      value: 'Dollar Amount',
    },
    {
      id: 'description',
      value: 'Description',
    },
    {
      id: 'notes',
      value: 'Notes',
    },
    {
      id: 'createBy',
      value: 'Created By',
    },
  ];

  //additionalCharge ValidationSchema
  const additionalChargeValidationSchema = Yup.object().shape({
    dateCreated: Yup.date()
      .typeError('Choose a valid Date')
      .required('Required')
      .label('Date'),
    prNumber: Yup.string().trim().required('Required').label('PR#'),
    dollarAmount: Yup.number()
      .typeError('Dollar Amount must be a number')
      .min(1)
      .max(99999999.99)
      .required('Required')
      .label('Dollar Amount'),
    storeAssociate: Yup.string().trim().label('Store Associate'),
    prType: Yup.number()
      .typeError('Choose a PT Type')
      .required('Required')
      .label('PR Type'),
    notes: Yup.string().trim().required('Required').label('Notes'),
  });
  //Expense validationSchema
  const expenseValidationSchema = Yup.object().shape({
    expenseSource: Yup.number()
      .typeError('Choose a Expense Source')
      .required('Required')
      .label('Expense Source'),
    description: Yup.string().trim().required('Required').label('Description'),
    dollarAmount: Yup.number()
      .typeError('Dollar Amount must be a number')
      .min(1)
      .max(99999999.99)
      .required('Required')
      .label('Dollar Amount'),
    notes: Yup.string().trim().required('Required').label('Notes'),
  });

  React.useEffect(() => {
    if (reloadAdditionalList !== null) {
      getAdditionalCharges(
        projectId,
        setAdditionalCharges,
        setLoading,
        setAlert
      );
    }
  }, [projectId, reloadAdditionalList]);

  React.useEffect(() => {
    if (reloadExpenseList) {
      getExpenses(projectId, setExpenses, setLoading, setAlert);
    }
  }, [projectId, reloadExpenseList]);

  React.useEffect(() => {
    getPrTypes(setPrTypes, setAlert);
    getExpenseSources(setExpenseSources, setAlert);
    getAdditionalCharges(projectId, setAdditionalCharges, setLoading, setAlert);
    getExpenses(projectId, setExpenses, setLoading, setAlert);
  }, []);

  const additonalChargeformik = useFormik({
    initialValues: {
      dateCreated: moment(new Date()).format('YYYY-MM-DD'),
      prNumber: '',
      dollarAmount: '',
      storeAssociate: '',
      prType: '',
      notes: '',
      dateCreatedValue: '',
      created_by_fullname: '',
    },
    validationSchema: additionalChargeValidationSchema,
    onSubmit: values => {
      setLoading(true);
      setReloadAdditionalList(false);
      if (formAction === 'edit') {
        updateAdditionalCharge(
          projectId,
          values,
          setLoading,
          setAlert,
          setReloadAdditionalList
        );
      }
      if (formAction === 'add') {
        createAdditionalCharge(
          projectId,
          values,
          setLoading,
          setAlert,
          setReloadAdditionalList
        );
      }
    },
    enableReinitialize: true,
  });
  const expenseformik = useFormik({
    initialValues: {
      expenseId: '',
      expenseSource: '',
      description: '',
      dollarAmount: '',
      notes: '',
      createBy: '',
    },
    validationSchema: expenseValidationSchema,
    onSubmit: values => {
      setLoading(true);
      setReloadExpenseList(false);
      if (formAction === 'edit') {
        updateExpense(
          projectId,
          values,
          setLoading,
          setAlert,
          setReloadExpenseList
        );
      }
      if (formAction === 'add') {
        createExpense(
          projectId,
          values,
          setLoading,
          setAlert,
          setReloadExpenseList
        );
      }
    },
    enableReinitialize: true,
  });

  const handleAdditionalChargeSave = () => {
    additonalChargeformik.handleSubmit();
    setIsOpen(false);
  };

  const handleExpenseSave = () => {
    expenseformik.handleSubmit();
    setIsOpen(false);
  };

  //add Additional charge Item
  const addAdditionalChargeItem = () => {
    setFormAction('add');
    setActionOriginTable('charge');
    additonalChargeformik?.handleReset();
    additonalChargeformik.setValues(additonalChargeformik?.initialValues);

    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Add Charge',
      showButton1: true,
      showButton2: true,
      button1Text: 'Cancel',
      button2Text: 'Add',
    }));
    setIsOpen(true);
  };

  //View inspect Item
  const viewAdditionalCharge = index => {
    setActionOriginTable('charge');
    additonalChargeformik?.handleReset();
    let itemDetails = {
      dateCreated: additionalCharges[index].dateCreatedValue.value,
      prNumber: additionalCharges[index].prNumber.value,
      dollarAmount: additionalCharges[index].dollarAmount.value.replace(
        '$',
        ''
      ),
      storeAssociate: additionalCharges[index].storeAssociate.value,
      prType: additionalCharges[index].prType.value.pr_type_id,
      notes: additionalCharges[index].notes.value,
    };
    additonalChargeformik.setValues(itemDetails);
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Additional Charge',
      showButton2: false,
      button1Text: 'Go Back',
    }));

    setFormAction('view');
    setIsOpen(true);
  };

  //Edit inspect Item
  const EditAdditionalCharge = index => {
    setActionOriginTable('charge');
    additonalChargeformik?.handleReset();
    let itemDetails = {
      dateCreated: additionalCharges[index].dateCreatedValue.value,
      prNumber: additionalCharges[index].prNumber.value,
      dollarAmount: additionalCharges[index].dollarAmount.value.replace(
        '$',
        ''
      ),
      storeAssociate: additionalCharges[index].storeAssociate.value,
      prType: additionalCharges[index].prType.value.pr_type_id,
      notes: additionalCharges[index].notes.value,
      additionalChargeId: parseInt(
        additionalCharges[index].additionalChargeId.value
      ),
    };

    additonalChargeformik.setValues(itemDetails);
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Edit Additional Charge',
      showButton1: true,
      showButton2: true,
      button1Text: 'Cancel',
      button2Text: 'Save',
    }));

    setFormAction('edit');
    setIsOpen(true);
  };

  //confirm delete
  const confirmAdditionalChargeDelete = index => {
    setActionOriginTable('charge');
    setAdditionalChargeId(additionalCharges[index].additionalChargeId.value);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'Are you sure you want delete the Additional Charge item? You will not be able to recover this again',
      header: 'Delete Additional Charge',
    });
  };

  //delete additional charge Item
  const deleteAdditionalCharge = () => {
    setLoading(true);
    setReloadAdditionalList(false);
    deleteAdditionalChargeById(
      projectId,
      additionalChargeId,
      setLoading,
      setReloadAdditionalList,
      setAlert
    );
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const addExpense = () => {
    setFormAction('add');
    setActionOriginTable('expense');
    expenseformik?.handleReset();
    expenseformik.setValues(expenseformik?.initialValues);

    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Add Item',
      showButton1: true,
      showButton2: true,
      button1Text: 'Cancel',
      button2Text: 'Add',
    }));
    setIsOpen(true);
  };

  //View inspect Item
  const viewExpense = index => {
    setActionOriginTable('expense');
    let expenseItemDetails = {
      expenseSource: expenses[index].expenseSource.value.expense_source_id,
      description: expenses[index].description.value,
      dollarAmount: expenses[index].dollarAmount.value.replace('$', ''),
      notes: expenses[index].notes.value,
    };
    expenseformik.setValues(expenseItemDetails);
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Expense',
      showButton2: false,
      button1Text: 'Go Back',
    }));

    setFormAction('view');
    setIsOpen(true);
  };

  //Edit inspect Item
  const EditExpense = index => {
    setActionOriginTable('expense');
    expenseformik?.handleReset();
    let editExpenseItemDetails = {
      projectExpenseId: expenses[index].projectExpenseId.value,
      expenseSource: expenses[index].expenseSource.value.expense_source_id,
      description: expenses[index].description.value,
      dollarAmount: expenses[index].dollarAmount.value.replace('$', ''),
      notes: expenses[index].notes.value,
    };

    expenseformik.setValues(editExpenseItemDetails);
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Edit Expense',
      showButton1: true,
      showButton2: true,
      button1Text: 'Cancel',
      button2Text: 'Save',
    }));

    setFormAction('edit');
    setIsOpen(true);
  };

  //confirm delete
  const confirmExpenseDelete = index => {
    setActionOriginTable('expense');
    setExpenseId(expenses[index].projectExpenseId.value);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'Are you sure to delete the Expense? You will not be able to recover this again',
      header: 'Delete Additional Charge',
    });
  };

  //delete additional charge Item
  const deleteExpense = () => {
    setLoading(true);
    setReloadExpenseList(false);
    deleteExpenseById(
      projectId,
      expenseId,
      setLoading,
      setReloadExpenseList,
      setAlert
    );
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };
  const capitalizedTypes = prTypes?.map(type => {
    const words = type?.type_name?.split(' ');
    const capitalizedWords = words?.map(word => {
      return word?.charAt(0).toUpperCase() + word?.slice(1);
    });
    const capitalizedType = capitalizedWords?.join(' ');
    return { pr_type_id: type?.pr_type_id, type_name: capitalizedType };
  });

  const getJsxOfForm = () => {
    if (actionOriginTable == 'charge') {
      return (
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiKeyboardDatePicker
                autoOk
                required
                fullWidth
                id="dateCreated"
                name="dateCreated"
                variant="inline"
                label="Date"
                format="MM-dd-yyyy"
                disabled={(formAction === 'view' && true) || false}
                disablePast={formAction !== 'view'}
                showtodayButton={true}
                value={
                  (additonalChargeformik?.values?.dateCreated?.split('T')
                    ?.length &&
                    parseISO(
                      additonalChargeformik?.values?.dateCreated?.slice(0, 10)
                    )) ||
                  null
                }
                onChange={(date, value) =>
                  additonalChargeformik?.setFieldValue(
                    'dateCreated',
                    `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                  )
                }
                onBlur={additonalChargeformik.handleBlur}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  style: { display: formAction === 'view' && 'none' },
                }}
                error={
                  additonalChargeformik.touched.dateCreated &&
                  additonalChargeformik.errors.dateCreated
                }
                helperText={additonalChargeformik.errors.dateCreated}
                InputProps={{
                  readOnly: formAction === 'view' ? true : false,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="prNumber"
              label="PR#"
              name="prNumber"
              required
              onChange={additonalChargeformik.handleChange}
              onBlur={additonalChargeformik.handleBlur}
              InputProps={{
                readOnly: formAction === 'view' ? true : false,
              }}
              value={additonalChargeformik?.values?.prNumber}
              InputLabelProps={{ shrink: true }}
              className={classes.input}
              error={
                additonalChargeformik.touched.prNumber &&
                additonalChargeformik.errors.prNumber
              }
              helperText={
                additonalChargeformik.touched.prNumber &&
                additonalChargeformik.errors.prNumber
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel required shrink htmlFor="dollar-amount">
              Dollar Amount
            </InputLabel>
            <NumberFormat
              id="dollar-amount"
              name="dollarAmount"
              onChange={event => {
                const value = event.target.value;
                const dollar_amount_value = parseFloat(value.replace('$', ''));
                additonalChargeformik.setFieldValue(
                  'dollarAmount',
                  dollar_amount_value
                );
                additonalChargeformik.handleChange;
              }}
              onBlur={event => {
                const value = event.target.value;
                const dollar_amount_value = parseFloat(value.replace('$', ''));
                additonalChargeformik.setFieldValue(
                  'dollarAmount',
                  dollar_amount_value
                );
                additonalChargeformik.handleBlur;
              }}
              inputProps={{ maxLength: 15 }}
              allowNegative={false}
              prefix="$"
              decimalScale={2}
              customInput={TextField}
              inputmode="numeric"
              disabled={formAction === 'view'}
              value={additonalChargeformik?.values?.dollarAmount}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: formAction === 'view',
              }}
              error={
                additonalChargeformik.touched.dollarAmount &&
                additonalChargeformik.errors.dollarAmount
              }
              helperText={
                additonalChargeformik.touched.dollarAmount &&
                additonalChargeformik.errors.dollarAmount
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="store-associate"
              label="Store Associate"
              name="storeAssociate"
              onChange={additonalChargeformik.handleChange}
              onBlur={additonalChargeformik.handleBlur}
              InputProps={{
                readOnly: formAction === 'view' ? true : false,
              }}
              value={additonalChargeformik?.values?.storeAssociate}
              InputLabelProps={{ shrink: true }}
              className={classes.input}
              error={
                additonalChargeformik.touched.storeAssociate &&
                additonalChargeformik.errors.storeAssociate
              }
              helperText={
                additonalChargeformik.touched.storeAssociate &&
                additonalChargeformik.errors.storeAssociate
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              required
              shrink
              id="pr-type-label"
              className={classes.label}
            >
              PR Type
            </InputLabel>
            <Select
              labelId="pr-type-label"
              id="pr-type-label"
              required
              value={additonalChargeformik?.values?.prType}
              name="prType"
              onChange={additonalChargeformik.handleChange}
              onBlur={additonalChargeformik.onBlur}
              displayEmpty={true}
              variant="standard"
              className={classes.select}
              disabled={formAction === 'view' ? true : false}
              error={
                additonalChargeformik.touched.prType &&
                additonalChargeformik.errors.prType
              }
            >
              <MenuItem
                disabled
                value=""
                classes={{ root: classes.menuItemSelected }}
              >
                Select
              </MenuItem>
              {capitalizedTypes.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.pr_type_id}
                  classes={{ root: classes.menuItemSelected }}
                >
                  {item.type_name}
                </MenuItem>
              ))}
            </Select>
            {additonalChargeformik.touched.installerId &&
              additonalChargeformik.errors.installerId && (
                <FormHelperText error>
                  {additonalChargeformik.errors.installerId}
                </FormHelperText>
              )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notes"
              label="Notes"
              multiline
              required
              onChange={additonalChargeformik.handleChange}
              onBlur={additonalChargeformik.handleBlur}
              InputProps={{
                readOnly: formAction === 'view' ? true : false,
              }}
              value={additonalChargeformik?.values?.notes}
              InputLabelProps={{ shrink: true }}
              error={
                additonalChargeformik.touched.notes &&
                additonalChargeformik.errors.notes
              }
              helperText={
                additonalChargeformik.touched.notes &&
                additonalChargeformik.errors.notes
              }
            />
          </Grid>
        </Grid>
      );
    } else if (actionOriginTable == 'expense') {
      return (
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <InputLabel
              required
              shrink
              id="expense-source-label"
              className={classes.label}
            >
              Expense Source
            </InputLabel>
            <Select
              labelId="expense-source-label"
              id="expense-source-label"
              required
              value={expenseformik?.values?.expenseSource}
              name="expenseSource"
              onChange={expenseformik.handleChange}
              onBlur={expenseformik.onBlur}
              displayEmpty={true}
              variant="standard"
              className={classes.select}
              disabled={formAction === 'view' ? true : false}
              error={
                expenseformik.touched.expenseSource &&
                expenseformik.errors.expenseSource
              }
            >
              <MenuItem
                disabled
                value=""
                classes={{ root: classes.menuItemSelected }}
              >
                Select
              </MenuItem>
              {expenseSources.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.expense_source_id}
                  classes={{ root: classes.menuItemSelected }}
                >
                  {item.source_name}
                </MenuItem>
              ))}
            </Select>
            {expenseformik.touched.expenseSource &&
              expenseformik.errors.expenseSource && (
                <FormHelperText error>
                  {expenseformik.errors.expenseSource}
                </FormHelperText>
              )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              label="Description"
              name="description"
              required
              onChange={expenseformik.handleChange}
              onBlur={expenseformik.handleBlur}
              InputProps={{
                readOnly: formAction === 'view' ? true : false,
              }}
              value={expenseformik?.values?.description}
              InputLabelProps={{ shrink: true }}
              className={classes.input}
              error={
                expenseformik.touched.description &&
                expenseformik.errors.description
              }
              helperText={
                expenseformik.touched.description &&
                expenseformik.errors.description
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel required shrink htmlFor="dollar-amount">
              Dollar Amount
            </InputLabel>
            <NumberFormat
              id="dollar-amount"
              name="dollarAmount"
              required
              fullwidth
              inputProps={{ maxLength: 15 }}
              allowNegative={false}
              prefix="$"
              decimalScale={2}
              customInput={TextField}
              inputmode="numeric"
              value={expenseformik?.values?.dollarAmount}
              onChange={event => {
                const value = event.target.value;
                const dollar_amount_value = parseFloat(value.replace('$', ''));
                expenseformik.setFieldValue(
                  'dollarAmount',
                  dollar_amount_value
                );
                expenseformik.handleChange;
              }}
              onBlur={event => {
                const value = event.target.value;
                const dollar_amount_value = parseFloat(value.replace('$', ''));
                expenseformik.setFieldValue(
                  'dollarAmount',
                  dollar_amount_value
                );
                expenseformik.handleBlur;
              }}
              readOnly={formAction === 'view' ? true : false}
              error={
                expenseformik.touched.dollarAmount &&
                expenseformik.errors.dollarAmount
              }
            />
            {expenseformik.touched.dollarAmount &&
              expenseformik.errors.dollarAmount && (
                <FormHelperText error>
                  {expenseformik.errors.dollarAmount}
                </FormHelperText>
              )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notes"
              label="Notes"
              multiline
              required
              onChange={expenseformik.handleChange}
              onBlur={expenseformik.handleBlur}
              InputProps={{
                readOnly: formAction === 'view' ? true : false,
              }}
              value={expenseformik?.values?.notes}
              InputLabelProps={{ shrink: true }}
              error={expenseformik.touched.notes && expenseformik.errors.notes}
              helperText={
                expenseformik.touched.notes && expenseformik.errors.notes
              }
            />
          </Grid>
        </Grid>
      );
    }
  };
  const additionalTableColumnData = loading
    ? [
        {
          id: '',
          value: '',
          style: { width: '85px' },
        },
        ...additionalChargeTableColumns,
      ]
    : additionalChargeTableColumns;

  const expensesTableColumnData = loading
    ? [{ id: '', value: '', style: { width: '60px' } }, ...expensesTableColumns]
    : expensesTableColumns;

  const additionalTableLoader = useMemo(() => {
    return TableColumnsLoader(additionalTableColumnData, {
      rows: 4,
      isValue: true,
    });
  }, [additionalTableColumnData]);

  const expensesTableLoader = useMemo(() => {
    return TableColumnsLoader(expensesTableColumnData, {
      rows: 4,
      isValue: true,
    });
  }, [expensesTableColumnData]);

  return (
    <>
      <Grid container spacing={4} direction="column">
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <GenericTable
            title="Additional Charge"
            headerLinks={[
              {
                label: 'Add Item',
                handler: addAdditionalChargeItem,
                add: permissions?.viewEditProject?.tabAdditionalFeeAdd,
              },
            ]}
            columnData={additionalTableColumnData}
            rowData={loading ? additionalTableLoader : additionalCharges}
            {...(!loading
              ? {
                  showActions: { view: true, edit: true, delete: true },
                  handleView: index => viewAdditionalCharge(index),
                  handleEdit: index => EditAdditionalCharge(index),
                  handleDelete: index => confirmAdditionalChargeDelete(index),
                }
              : {})}
            permissions={{
              view: permissions?.viewEditProject?.tabAdditionalFeeView,
              edit: permissions?.viewEditProject?.tabAdditionalFeeEdit,
              delete: permissions?.viewEditProject?.tabAdditionalFeeDelete,
            }}
          />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <GenericTable
            title="Expenses"
            headerLinks={[
              {
                label: 'Add Item',
                handler: addExpense,
                add: permissions?.viewEditProject?.tabAdditionalFeeExpensesAdd,
              },
            ]}
            columnData={expensesTableColumnData}
            rowData={loading ? expensesTableLoader : expenses}
            {...(!loading
              ? {
                  showActions: { view: true, edit: true, delete: true },
                  handleView: index => viewExpense(index),
                  handleEdit: index => EditExpense(index),
                  handleDelete: index => confirmExpenseDelete(index),
                }
              : {})}
            permissions={{
              view: permissions?.viewEditProject?.tabAdditionalFeeExpensesView,
              edit: permissions?.viewEditProject?.tabAdditionalFeeExpensesEdit,
              delete:
                permissions?.viewEditProject?.tabAdditionalFeeExpensesDelete,
            }}
          />
        </Grid>
      </Grid>

      {/* Dialog form for Add,edit form */}
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          actionOriginTable == 'charge'
            ? additonalChargeformik?.handleReset()
            : expenseformik?.handleReset();
          setIsOpen(false);
        }}
        handleSave={
          actionOriginTable === 'charge'
            ? handleAdditionalChargeSave
            : handleExpenseSave
        }
        dialogSettings={dialogSettings}
        disabledButton2={
          actionOriginTable === 'charge'
            ? !additonalChargeformik.dirty || !additonalChargeformik.isValid
            : !expenseformik.dirty || !expenseformik.isValid
        }
        disabledButton1={false}
      >
        {getJsxOfForm()}
      </GenericDialog>

      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={
          actionOriginTable == 'charge' ? deleteAdditionalCharge : deleteExpense
        }
      />
    </>
  );
};

export default AdditionalFee;
