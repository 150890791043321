import ApiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import { COVERAGE_TYPE } from '../Helper';
export const getUserCoveragePreferences = async (
  userId,
  paginator,
  type,
  category,
  coverage_type,
  coverage_value
) => {
  try {
    const storeCoveragesRequest = ApiService.get(`/stores`);
    const typeCoveragesRequest = ApiService.get(
      `/projects/master/project-type`
    );
    const categoryRequest = ApiService.get(`/projects/master/project-category`);

    // const userDetailsRequest = ApiService.get(
    //   `/auth/user/manage/coverage-preference/${userId}/${paginator.first}/${paginator.rows}`
    // );
    let baseListURL = `/auth/user/manage/coverage-preference/${userId}/${paginator.first}/${paginator.rows}`;
    let queryParams = [];
    if (type) {
      queryParams.push(`type=${type?.project_type_id}`);
    }
    if (category && category.length > 0) {
      const categoryIds = category
        .map(cat => cat.project_category_id)
        .join(',');
      queryParams.push(`&category=${categoryIds}`);
    }
    if (coverage_type) {
      queryParams.push(`&coverage_type=${coverage_type?.id}`);
    }
    if (coverage_value?.length) {
      queryParams.push(`&coverage_values=${coverage_value}`);
    }
    if (queryParams.length > 0) {
      paginator.first = 0;
      baseListURL += '?' + queryParams;
    }
    const userDetailsRequest = ApiService.get(baseListURL);

    const [storesList, typesList, categoryList, userDetailsResponse] =
      await Promise.all([
        storeCoveragesRequest,
        typeCoveragesRequest,
        categoryRequest,
        userDetailsRequest,
      ]);
    const userCoveragePreference =
      userDetailsResponse?.data?.queryResponse?.map(item => {
        return {
          type_coverage_id: item.project_type_id,
          category_coverage_ids: item.project_category_ids,
          coverage: {
            type: item.coverage_by,
            values: item.coverage_values,
          },
          rotation_percentage: item?.rotation_percentage,
          service_time: item?.service_time,
          user_coverage_preference_id: item?.user_coverage_preference_id,
          user_id: item.user_id,
        };
      });
    const userCoveragePreferenceData = userDetailsResponse?.data?.queryResponse;
    return {
      storeList: storesList,
      typesList: typesList,
      categoryList: categoryList,
      userCoveragePreference: userCoveragePreference,
      totalCount: userDetailsResponse?.data?.count,
      isFilter: userDetailsResponse?.data?.isFilter,
      userCoveragePreferenceData: userCoveragePreferenceData,
    };
  } catch (error) {
    console.error(error);
  }
};

export const addAndCloneCoveragePreference = async (userId, payload) => {
  try {
    const response = await ApiService.post(
      `${URL_CONSTANTS.CopyUserCoverage.addCoverage}/${userId}`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const updateGroupDelete = async (userId, payload) => {
  try {
    const response = await ApiService.delete(
      `/auth/user/manage/coverage-preference/${userId}/group-delete?preferenceIds=[${payload}]`
    );
    return response.status;
  } catch (error) {
    console.error(error);
  }
};
export const checkValidRotationPercentage = async (
  userId,
  rotation_percentage,
  user_coverage_preference_id,
  project_type_id,
  project_category_ids
) => {
  try {
    const response = await ApiService.post(
      `/auth/user/manage/coverage-preference/validate/${userId}`,
      {
        rotation_percentage,
        user_coverage_preference_id,
        project_type_id,
        project_category_ids,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteCoveragePreference = async (userId, recordId) => {
  try {
    const response = await ApiService.delete(
      `/auth/user/manage/coverage-preference/${userId}/${recordId}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const checkDuplicateTypeAndCategory = async (
  typeId,
  categoryId,
  userId,
  isNewEntry,
  recordId
) => {
  try {
    const response = await ApiService.get(
      `/auth/user/manage/coverage-preference/${typeId}/${categoryId}/${userId}/${isNewEntry}/${recordId}`
    );

    return response.status;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAllPreferences = async userId => {
  try {
    const response = await ApiService.delete(
      `/auth/user/manage/coverage-preference/${userId}`
    );
    return response.status;
  } catch (error) {
    console.error(error);
  }
};

export const searchDistrict = async searchString => {
  try {
    const districtUrl = searchString
      ? `/stores/district?searchString=${searchString}`
      : `/stores/district`;
    const response = await ApiService.get(districtUrl);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const searchStoreName = async searchString => {
  try {
    const response = await ApiService.get(
      `/stores?searchString=${searchString}&offset=0&limit=10`
    );
    return response.length
      ? response.map(item => {
        return {
          ...item,
          store_number_name: `${item.store_number} - ${item.store_name}`,
        };
      })
      : [];
  } catch (error) {
    console.error(error);
  }
};

export const getUserList = async () => {
  try {
    const userList = ApiService.get(
      '/auth/user/report/list?limit=500&offset=0'
    );
    return userList;
  } catch (error) {
    console.error(error);
  }
};
export const FilteredUserList = async searchString => {
  try {
    const userList = ApiService.get(
      `/auth/user/report/list?limit=500&offset=0&searchString=${searchString}`
    );
    return userList;
  } catch (error) {
    console.error(error);
  }
};
export const getFilteredCoverageList = async (userId, type) => {
  try {
    const userList = ApiService.get(
      `/auth/user/manage/coverage-preference-list/${userId}/${type}`
    );
    return userList;
  } catch (error) {
    console.error(error);
  }
};
export const copyCoverageToUser = async (userId, copyToUsers) => {
  try {
    const copyedCoverageUserList = await ApiService.put(
      `${URL_CONSTANTS.CopyUserCoverage.copyUser}/${userId}`,
      copyToUsers
    );
    return copyedCoverageUserList;
  } catch (error) {
    throw error;
  }
};
export const updateCoveragePreference = async (userId, payload) => {
  try {
    const response = await ApiService.put(
      `/auth/user/manage/coverage-preference/${userId}`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const groupCloneCoveragePreference = async (userId, payload) => {
  try {
    const response = await ApiService.post(
      `/auth/user/manage/coverage-preference/${userId}/group-clone`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
