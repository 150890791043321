import React, { useRef } from 'react';
import '../css/index.css';
import { OverlayPanel } from 'primereact/overlaypanel';

import { ellipsisString } from '../../../utils/Helpers';

import TechnicianAvatar from './TechnicianAvatar';
const ResourceTemplate = ({ resourceInfo }) => {
  const popover = useRef(null);

  const emailTemplate = (email, isFlagEnabled) => {
    return (
      email && (
        <div className="flex mt-1 w-full align-items-center mb-1 text-xs w-full">
          <i className="pi pi-envelope text-xs mr-1" />
          <a
            href={`mailto:${email}`}
            className="text-color w-full white-space-normal word-break no-underline"
          >
            {isFlagEnabled ? ellipsisString(email, 25) : email}
          </a>
        </div>
      )
    );
  };

  const phoneTemplate = (phone, isFlagEnabled) => {
    return (
      phone && (
        <>
          <div className="flex w-full align-items-center mb-1 text-xs">
            <i className="pi pi-phone text-xs mr-1" />
            <a href={`tel:${phone}`} className="text-color no-underline">
              {isFlagEnabled ? ellipsisString(phone, 25) : phone}
            </a>
          </div>
        </>
      )
    );
  };

  const companyTemplate = (companyName, isFlagEnabled) => {
    return (
      companyName && (
        <>
          <div className="flex w-full align-items-center mb-1 text-xs">
            <i className="pi pi-building text-xs mr-1" />
            {isFlagEnabled ? ellipsisString(companyName, 22) : companyName}
          </div>
        </>
      )
    );
  };

  const installerNameTemplate = (title, isFlagEnabled) => {
    return (
      <div className="w-14rem p-0 text-blue-600 text-base font-bold">
        {isFlagEnabled ? ellipsisString(title, 18) : title}
      </div>
    );
  };

  return (
    <>
      <div className="resource-card flex align-items-center">
        <div className="h-full mt-2 resource-avatar">
          <TechnicianAvatar
            src={resourceInfo?.resourceData?.avatarIcon}
            alt={resourceInfo?.resourceData?.title}
          />
        </div>
        <div className="ml-2 grid m-0 align-items-center relative">
          <i
            className="pi pi-exclamation-circle absolute top-0 right-0 mr-3 z-5 cursor-pointer text-primary"
            onMouseEnter={e => popover.current.show(e)}
            onMouseLeave={e => popover.current.hide(!e)}
          ></i>
          <div className="line-height-2 grid m-0 align-items-center relative scrollable-container">
            {installerNameTemplate(resourceInfo?.resourceData?.title, true)}
            {emailTemplate(resourceInfo?.resourceData?.email, true)}
            {phoneTemplate(resourceInfo?.resourceData?.phone, true)}
            {companyTemplate(resourceInfo?.resourceData?.companyName, true)}
            <OverlayPanel
              appendTo={null}
              ref={popover}
              pt={{
                root: {
                  className: 'z-index-9999 w-19rem',
                },
              }}
            >
              <div className="w-16rem">
                <div className="w-5rem">
                  <TechnicianAvatar
                    src={resourceInfo?.resourceData?.avatarIcon}
                    alt={resourceInfo?.resourceData?.title}
                  />
                </div>
                {installerNameTemplate(
                  resourceInfo?.resourceData?.title,
                  false
                )}
                {emailTemplate(resourceInfo?.resourceData?.email, false)}
                {phoneTemplate(resourceInfo?.resourceData?.phone, false)}
                {companyTemplate(
                  resourceInfo?.resourceData?.companyName,
                  false
                )}
                {resourceInfo?.resourceData?.installerbio && (
                  <div className="flex white-space text-xs">
                    <i className="pi pi-address-book mt-1"></i>
                    <p className="my-0 ml-1">
                      {resourceInfo?.resourceData?.installerbio}
                    </p>
                  </div>
                )}
              </div>
            </OverlayPanel>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResourceTemplate;
