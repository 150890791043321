import React, {
  useEffect,
  memo,
  useState,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { Toast } from 'primereact/toast';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

import {
  HD_DOC_NUMBERS_FOR_DOCUMENTS,
  HD_DOC_NUMBERS_FOR_PHOTOS,
  LL_DOC_TYPE_FOR_DOCUMENTS,
  LL_DOC_TYPE_FOR_PHOTOS,
  SOURCE_SYSTEMS,
} from '../../../constants';
import permissions from '../../../config/permissions';
import { checkPermission } from '../../../utils/Helpers';
import PFDialog from '../../shared/PFPrime/PFDialog';
import { PFDynamicDropdown } from '../../shared/PFPrime/PFDynamicDropdown';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFCheckbox from '../../shared/PFPrime/PFCheckbox';
import PFButton from '../../shared/PFPrime/PFButton';

import { categoryCreateService, categoryListService } from './Docs.service';
import { UploadActionContext } from './context/context';

const UploadStoreTypeDialog = ({
  type = 'Document',
  docInputRef,
  sourceSystemId,
  onUploadHandler = () => {},
  bulkUpdateHandler = () => {},
  projectDetailsData,
  completeButtonDisable,
  setCompleteButtonDisable,
  clientDetails,
  photoCount,
  orderConfigData,
  editDocsPermission,
  completeDisableforOrderValidation,
  setCompleteDisableforOrderValidation,
}) => {
  const [installerVisible] = useState(
    !checkPermission(permissions?.userManagement?.userManagementModifyInstaller)
  );
  const [alertOnFocus, setAlertOnFocus] = useState(false);
  const { uploadContextValue, setUploadContextValue } =
    useContext(UploadActionContext);

  const handleUpload = () => {
    if (docInputRef) {
      docInputRef.current.click();
    }
    if (onUploadHandler) {
      onUploadHandler();
    }
  };

  const docNumberOptions =
    type === 'Photo'
      ? HD_DOC_NUMBERS_FOR_PHOTOS
      : type === 'Document'
        ? HD_DOC_NUMBERS_FOR_DOCUMENTS
        : [];

  const llDocTypeOptions =
    type === 'Photo'
      ? LL_DOC_TYPE_FOR_PHOTOS
      : type === 'Document'
        ? LL_DOC_TYPE_FOR_DOCUMENTS
        : [];

  const isReviewPermission = useMemo(
    () =>
      checkPermission(
        type === 'Photo'
          ? permissions?.documentCenter?.reviewPhoto
          : permissions?.documentCenter?.reviewDoc
      ),
    []
  );
  const isSendPermission = useMemo(
    () =>
      checkPermission(
        type === 'Photo'
          ? permissions?.documentCenter?.sendToSourcePhoto
          : permissions?.documentCenter?.sendToSourceDoc
      ),
    []
  );
  const isCompletePermission = useMemo(
    () =>
      checkPermission(
        type === 'Photo'
          ? permissions?.documentCenter?.completePhoto
          : permissions?.documentCenter?.completeDoc
      ),
    []
  );

  const statusOptions = () => {
    const res = [
      {
        label: 'Internal Document',
        value: 'INTERNAL',
      },
    ];

    isReviewPermission &&
      res.push({
        label: 'Needs Review',
        value: 'NEEDS_REVIEW',
      });
    isSendPermission &&
      res.push({
        label: 'Send to Source',
        value: 'SOURCE',
      });

    isCompletePermission &&
      res.push({
        label: 'Complete',
        value: 'COMPLETE',
        disabled:
          completeButtonDisable || completeDisableforOrderValidation
            ? true
            : false,
      });

    return res;
  };

  useEffect(() => {}, []);

  const toast = useRef(null);
  const categoryList = async (event = null, setItems) => {
    const query = event?.query || '';
    const res = await categoryListService(query);
    if (res?.status) {
      setItems(res?.data || []);
    }
  };

  const categoryCreate = async (name, setValue, setItems) => {
    const res = await categoryCreateService({ category_name: name });
    if (res?.status) {
      setValue(res?.data);
      categoryList(name, setItems);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Category added successfully',
      });
    }
  };

  const [actionCheck, setActionCheck] = useState({
    is_installer_visibility: 0,
    is_proj_docu_visibility: 0,
    is_customer_visible: 0,
    reviewed_status: 0,
  });

  useEffect(() => {
    if (uploadContextValue?.req_from === 'bulk_upload') {
      setUploadContextValue(preValue => ({
        ...preValue,
        data: { ...preValue?.data, document_store_type: null },
      }));
    }
  }, [uploadContextValue?.req_from]);
  const showIsLeadRequiredAlertLowes = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.project_lead_response_reason === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showIsLeadRequiredAlertHD = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.epa_override_reason_code_id === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <PFDialog
        draggable={false}
        show={uploadContextValue?.show}
        hide={() => {
          uploadContextValue?.onHide();
          setAlertOnFocus(false);
        }}
        className="w-8 lg:w-4"
        header={`What would you like to do with this ${type}?`}
        BodyComponent={
          <div>
            {completeButtonDisable && (
              <span className="alert-dialog">
                *This project cannot be completed until the EPA details have
                been updated.
              </span>
            )}
            {completeDisableforOrderValidation && alertOnFocus && (
              <span className="alert-dialog">
                *`You must upload at least {orderConfigData?.count} photo(s) to
                complete this job.`
              </span>
            )}

            <div className="grid py-3">
              <div className="col-12 md:col-12 lg:col-12">
                <PFDynamicDropdown
                  listCallback={categoryList}
                  field="category_name"
                  storeCallback={categoryCreate}
                  label="Category"
                  defaultValue={
                    uploadContextValue?.data?.document_category_id || ''
                  }
                  selectedData={value =>
                    value?.document_category_id &&
                    uploadContextValue?.handleChange({
                      document_category_id: value,
                    })
                  }
                  buttonVisibility={
                    uploadContextValue?.permissions?.addCategoryPermission
                  }
                  disabled={
                    uploadContextValue?.permissions?.editCategoryPermission
                  }
                />
              </div>
              <div className="col-12 md:col-12 lg:col-12">
                <span className="p-float-label">
                  <PFDropdown
                    name="upload-store-type"
                    value={
                      uploadContextValue?.data?.document_store_type || null
                    }
                    options={statusOptions()}
                    optionLabel="label"
                    optionValue="value"
                    appendTo={null}
                    onChange={(name, value) =>
                      uploadContextValue?.handleChange({
                        document_store_type: value,
                      })
                    }
                    onFocus={() => {
                      setAlertOnFocus(true);
                      if (clientDetails?.epa_guardrails_workflow === 1) {
                        const isLeadRequiredShowAlert =
                          projectDetailsData.source_system_id === 2
                            ? showIsLeadRequiredAlertHD()
                            : showIsLeadRequiredAlertLowes();
                        if (isLeadRequiredShowAlert) {
                          setCompleteButtonDisable(true);
                          return;
                        }
                      }
                      if (
                        clientDetails?.order_completion_validation?.enabled ===
                          1 &&
                        orderConfigData &&
                        orderConfigData?.count > photoCount
                      ) {
                        setCompleteDisableforOrderValidation(true);
                        return;
                      }
                    }}
                    showClear={
                      uploadContextValue?.req_from === 'bulk_upload'
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="Status">Status</label>
                </span>
              </div>
            </div>
            <div className="grid">
              {sourceSystemId === 2 &&
                uploadContextValue?.req_from !== 'bulk_upload' && (
                  <div className="col-12 md:col-12 lg:col-12">
                    <span className="p-float-label">
                      <PFDropdown
                        name="doc_number"
                        value={uploadContextValue?.data?.hd_doc_cd_number || ''}
                        options={docNumberOptions}
                        optionLabel="name"
                        optionValue="id"
                        appendTo={null}
                        filter={true}
                        onChange={(name, value) => {
                          uploadContextValue?.handleChange({
                            hd_doc_cd_number: value,
                          });
                        }}
                      />
                      <label htmlFor="docNumber">
                        Doc Number <span style={{ color: 'red' }}>*</span>{' '}
                      </label>
                    </span>
                  </div>
                )}
              {sourceSystemId === SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
                <div className="col-12 md:col-12 lg:col-12">
                  <span className="p-float-label">
                    <PFDropdown
                      name="doc_type"
                      value={uploadContextValue?.data?.ll_document_type || ''}
                      options={llDocTypeOptions}
                      optionLabel="label"
                      optionValue="value"
                      appendTo={null}
                      filter={true}
                      onChange={(name, value) => {
                        uploadContextValue?.handleChange({
                          ll_document_type: value,
                        });
                      }}
                    />
                    <label htmlFor="docNumber">Doc Type</label>
                  </span>
                </div>
              )}
            </div>

            <>
              <div className="grid mt-1 py-1">
                {uploadContextValue?.document_type_id === 1 && (
                  <>
                    <div className="col-12 md:col-12 lg:col-6">
                      <div className="grid">
                        <>
                          <div className="col-2 md:col-1 lg:col-2">
                            <PFCheckbox
                              name="is_installer_visibility"
                              onChange={e => {
                                uploadContextValue?.handleChange({
                                  is_installer_visibility: e.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                uploadContextValue?.data
                                  ?.is_installer_visibility === 0
                                  ? false
                                  : true
                              }
                            />
                          </div>
                          <div className="col-10 md:col-10 lg:col-10">
                            <label htmlFor="viewAsTechnician">
                              Viewable to Technician
                            </label>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="col-12 md:col-12 lg:col-6">
                      <div className="grid">
                        <>
                          <div className="col-2 md:col-1 lg:col-2">
                            <PFCheckbox
                              name="is_proj_docu_visibility"
                              onChange={e => {
                                uploadContextValue?.handleChange({
                                  is_proj_docu_visibility: e.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                uploadContextValue?.data
                                  ?.is_proj_docu_visibility === 0
                                  ? false
                                  : true
                              }
                            />
                          </div>
                          <div className="col-10 md:col-10 lg:col-10">
                            <label htmlFor="viewAsOffline">
                              Viewable in Offline
                            </label>
                          </div>
                        </>
                      </div>
                    </div>
                    {installerVisible && (
                      <div className="col-12 md:col-12 lg:col-6">
                        <div className="grid">
                          <>
                            <div className="col-2 md:col-1 lg:col-2">
                              <PFCheckbox
                                name="is_customer_visible"
                                onChange={e => {
                                  uploadContextValue?.handleChange({
                                    is_customer_visible: e.checked ? 1 : 0,
                                  });
                                }}
                                checked={
                                  uploadContextValue?.data
                                    ?.is_customer_visible === 0
                                    ? false
                                    : true
                                }
                              />
                            </div>
                            <div className="col-10 md:col-10 lg:col-10">
                              <label htmlFor="showToCX">Viewable to CX</label>
                            </div>
                          </>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {uploadContextValue?.permissions?.reviewPermission && (
                  <div className="col-12 md:col-12 lg:col-6">
                    <div className="grid">
                      <>
                        <div className="col-2 md:col-1 lg:col-2">
                          <PFCheckbox
                            name="reviewed_status"
                            onChange={e => {
                              uploadContextValue?.handleChange({
                                reviewed_status: e.checked ? 1 : 0,
                              });
                            }}
                            checked={
                              uploadContextValue?.data?.reviewed_status === 0
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="col-10 md:col-10 lg:col-10">
                          <label htmlFor="reviewed">Mark as Reviewed</label>
                        </div>
                      </>
                    </div>
                  </div>
                )}

                {type === 'Document' &&
                  uploadContextValue?.req_from !== 'bulk_upload' && (
                    <div className="col-12 md:col-12 lg:col-6">
                      <div className="grid">
                        <>
                          <div className="col-2 md:col-1 lg:col-2">
                            <PFCheckbox
                              name="is_editable"
                              onChange={e => {
                                uploadContextValue?.handleChange({
                                  is_editable: e.checked ? 1 : 0,
                                });
                              }}
                              checked={
                                uploadContextValue?.data?.is_editable === 0
                                  ? false
                                  : true
                              }
                              disabled={!editDocsPermission}
                            />
                          </div>
                          <div className="col-10 md:col-10 lg:col-10">
                            <label htmlFor="showToCX">Editable Document</label>
                          </div>
                        </>
                      </div>
                    </div>
                  )}
              </div>
            </>
          </div>
        }
        footer={
          <>
            <PFButton
              onClick={() => {
                uploadContextValue?.onHide();
                setAlertOnFocus(false);
              }}
              color="primary"
              variant="outlined"
              outlined
            >
              Cancel
            </PFButton>

            {uploadContextValue?.req_from === 'bulk_upload' ||
            uploadContextValue?.req_from === 'template' ? (
              <PFButton
                onClick={bulkUpdateHandler}
                color="primary"
                variant="contained"
                autoFocus
                className="mx-0"
              >
                Save
              </PFButton>
            ) : (
              <PFButton
                onClick={handleUpload}
                color="primary"
                variant="contained"
                autoFocus
                className="mx-0"
                disabled={
                  sourceSystemId === 2 &&
                  !uploadContextValue?.data?.hd_doc_cd_number
                }
              >
                Upload
              </PFButton>
            )}
          </>
        }
      />
    </>
  );
};
export default memo(UploadStoreTypeDialog);
