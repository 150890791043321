import { useRef, useState } from 'react';
import { Dialog, Toast } from 'primereact';

import PFButton from '../../../shared/PFPrime/PFButton';
import {
  deleteCoveragePreference,
  updateGroupDelete,
} from '../services/user-form-coverage.service';
import {
  bulkDelete,
  deleteJobCoverage,
} from '../services/user-form-job-assign-coverage.service';
import { CLIENT_DETAILS } from '../../../../constants';
const CoverageDelete = ({
  userId,
  closeDeleteConfirmationPopup,
  groupDelete,
  setRefreshCoverageData,
  refreshCoverageData,
  itemToDelete,
  isJob,
}) => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const deleteRow = async () => {
    try {
      let deleteData;
      if (isJob) {
        if (!groupDelete && itemToDelete?.data?.user_coverage_preference_id) {
          setLoading(true);
          deleteData = await deleteJobCoverage(
            userId,
            itemToDelete.data.user_coverage_preference_id
          );
        } else if (groupDelete) {
          const prefIdsToDelete = itemToDelete.children.map(
            child => child?.data?.user_coverage_preference_id
          );
          setLoading(true);
          deleteData = await bulkDelete(userId, prefIdsToDelete);
        }
      } else {
        if (!groupDelete && itemToDelete?.data?.user_coverage_preference_id) {
          setLoading(true);
          deleteData = await deleteCoveragePreference(
            userId,
            itemToDelete.data.user_coverage_preference_id
          );
        } else if (groupDelete) {
          const prefIdsToDelete = itemToDelete.children.map(
            child => child?.data?.user_coverage_preference_id
          );
          setLoading(true);
          deleteData = await updateGroupDelete(userId, prefIdsToDelete);
        }
      }

      if (deleteData) {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted',
          detail: 'The record has been successfully deleted.',
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while deleting the row.',
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while deleting the row.',
        life: 3000,
      });
    } finally {
      closeDeleteConfirmationPopup(false);
      setRefreshCoverageData(!refreshCoverageData);
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        header="Delete Confirmation"
        visible={true}
        style={{ width: '450px' }}
        onHide={closeDeleteConfirmationPopup}
        footer={
          <div>
            <PFButton
              label="No"
              text
              outlined
              onClick={closeDeleteConfirmationPopup}
              disabled={loading}
            />
            <PFButton
              label={loading ? 'Please wait' : 'Yes'}
              onClick={deleteRow}
              loading={loading}
              disabled={loading}
            />
          </div>
        }
        draggable={false}
      >
        <div>
          Are you sure you want to delete this coverage{' '}
          {groupDelete ? 'combination' : 'record'}?
        </div>

        {(Number(itemToDelete?.data?.rotationPercentage) > 0 ||
          Number(itemToDelete?.data?.rotation_percentage) > 0) &&
          CLIENT_DETAILS?.auto_assign_technicians === 1 &&
          CLIENT_DETAILS?.auto_assign_technicians_value ===
            'Rotation Percentage' && (
            <div className="flex gap-2 mt-1">
              <i className="pi pi-exclamation-triangle text-xl text-primary-500 mt-1"></i>
              <span>
                Please ensure that the Rotation% is set to 100% for this
                coverage combination to enable auto-assignment to work as
                expected.
              </span>
            </div>
          )}
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default CoverageDelete;
