import { useRef, useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { Toast } from 'primereact/toast';

import smsIconGray from '../../../../assets/images/sms-icon-inactive.svg';
import smsIconGreen from '../../../../assets/images/sms-icon-active.svg';
import { generateDocumentUrl } from '../Docs.service';

const SmsIcon = ({ docDetails }) => {
  const [details, setDetails] = useState(docDetails);
  const toast = useRef(null);
  const sendDocument = async () => {
    setDetails({ ...details, is_sms_loading: true });
    const response = await generateDocumentUrl(
      docDetails?.projectId,
      docDetails?.documentId
    );
    if (response?.status) {
      setDetails({
        ...details,
        is_sms_loading: false,
        is_signed_sms_sent: true,
      });
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Document sent successfully.',
      });
    } else {
      setDetails({
        ...details,
        is_sms_loading: false,
      });
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to sent Document.',
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Tooltip
        title={details?.is_signed_sms_sent ? 'SMS Sent' : 'Send via SMS'}
      >
        <Grid>
          {details?.is_sms_loading ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{
                fontSize: '1rem',
                color: '#6B6B6B',
                marginLeft: '9px',
              }}
            ></i>
          ) : (
            <img
              src={details.is_signed_sms_sent ? smsIconGreen : smsIconGray}
              className={`${
                details?.is_signature_required == 1 &&
                details?.is_customer_visible == 1 &&
                details?.is_signed == 0
                  ? 'cursor-pointer ml-2'
                  : 'ml-2'
              }`}
              alt="copy"
              width={18}
              height={18}
              onClick={() =>
                details?.is_signature_required == 1 &&
                details?.is_customer_visible == 1 &&
                details?.is_signed == 0 &&
                sendDocument()
              }
            />
          )}
        </Grid>
      </Tooltip>
    </>
  );
};

export default SmsIcon;
