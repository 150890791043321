// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Dashboards List
export const getDashboardList = async (
  searchQuery,
  setLoading,
  setDashboardListData = () => {},
  source = null,
  updateDefaultDashboardId = () => {}
) => {
  try {
    setLoading(true);
    let newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    if (source === 'home') {
      newQueryString = `${newQueryString}&isFromDashboard=1`;
    }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/list/available-dashboards?${newQueryString}`
    );
    setDashboardListData(response.data);
    const defaultDashboard = response?.data?.items?.find(
      record => record.is_default_dashboard
    );

    if (defaultDashboard) {
      updateDefaultDashboardId(defaultDashboard.dashboard_id);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Create Dashboard
export const createDashboard = async (
  values,
  history,
  setLoading,
  setAlert
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage`,
      values
    );

    history.push(`/admin/dashboard/edit/${response?.data?.dashboard_id}`);
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
  } finally {
    setLoading(false);
  }
};

// Clone Dashboard
export const cloneDashboard = async (
  dashboard_id,
  values,
  setLoading,
  setIsClone,
  setDialogOpen,
  setReloadList,
  setAlert
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/${dashboard_id}/clone`,
      values
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Dashboard cloned successfully', false, true);
    }
    setDialogOpen(false);
    setIsClone(false);
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
  } finally {
    setLoading(false);
  }
};

// Edit Dashboard
export const editDashboard = async (
  dashboard_id,
  values,
  setLoading,
  setIsEdit,
  setDialogOpen,
  setReloadList,
  setAlert
) => {
  try {
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/${dashboard_id}`,
      values
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Dashboard cloned successfully', false, true);
    }
    setDialogOpen(false);
    setIsEdit(false);
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
  } finally {
    setLoading(false);
  }
};

// Get Widgets List
export const getWidgetsList = async (
  searchQuery,
  setLoading,
  setWidgetsListData
) => {
  try {
    setLoading(true);
    const newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates/list/available-widgets?${newQueryString}`
    );
    setWidgetsListData(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Dashboard Details
export const getDashboardDetails = async (
  dashboardId,
  setLoading,
  setDashboardData,
  setWidgetRendererData,
  setWidgetQuery,
  setWidgetFilterQuery,
  setApplyFilters,
  setDashboardName
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/${dashboardId}`
    );

    setDashboardData(response?.data?.widgets);
    setWidgetQuery(response?.data?.widgets);
    setDashboardName && setDashboardName(response?.data);
    if (response?.data?.widgets?.length && response.status === 200) {
      let cubeQuery = response?.data?.widgets
        .map(widget =>
          widget?.widget_template?.widget_display_type !==
            'external-button-link' &&
          widget?.widget_template?.data_source?.length
            ? {
                data_source: widget?.widget_template?.data_source?.[0],
                widget_template_id: widget?.widget_template?.widget_template_id,
              }
            : null
        )
        .filter(query => query !== null);

      cubeQuery?.length
        ? getWidgetRendererData(
            cubeQuery,
            setWidgetRendererData,
            setLoading,
            setApplyFilters
          )
        : setLoading(false);
    }
    setWidgetFilterQuery && setWidgetFilterQuery(response?.data?.widgets);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Cube Data
export const getWidgetRendererData = async (
  widgetQuery,
  setWidgetRendererData,
  setLoading,
  setApplyFilters
) => {
  try {
    setLoading(true);
    let widgetRendererData = [];
    let widgetDataSource;
    if (widgetQuery?.length) {
      widgetDataSource = widgetQuery?.map(widget => widget.data_source);
    }
    widgetDataSource[0].limit = 50000;
    widgetRendererData = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/cube/data-multi-queries`,
      widgetDataSource,
      {
        headers: {
          Authorization: localStorage.getItem('cubejsAuthorizationToken'),
        },
      }
    );

    let widgetData = widgetRendererData?.data?.map((rendererData, index) => {
      return {
        data: rendererData,
        widgetTemplateId: widgetQuery[index]?.widget_template_id,
      };
    });

    setWidgetRendererData(widgetData);
    return widgetData;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    setApplyFilters && setApplyFilters(false);
  }
};

// Get Cube Data and add widget to dashboard
export const addWidgetWithCubeData = async (
  cubeQuery,
  dashboardId,
  history,
  setWidgetRendererData,
  setWidgetQuery,
  setDialogOpen,
  setLoading,
  deleteWidgetIds = []
) => {
  setLoading(true);

  try {
    const newcubeQuery = cubeQuery.filter(
      item => !deleteWidgetIds.includes(item?.widget_id)
    );
    setWidgetQuery(newcubeQuery);
    if (newcubeQuery?.length) {
      let updatedCubeQuery = newcubeQuery
        .map(widget => {
          if (
            widget?.chart_type !== 'external-link-button' &&
            widget?.widget_template?.data_source !== undefined &&
            widget?.widget_template?.data_source?.length
          ) {
            return {
              data_source: widget?.widget_template?.data_source[0],
              widget_template_id: widget?.widget_template?.widget_template_id,
            };
          } else {
            null;
          }
        })
        .filter(query => query !== null && query !== undefined);

      updatedCubeQuery?.length
        ? getWidgetRendererData(
            updatedCubeQuery,
            setWidgetRendererData,
            setLoading
          )
        : setLoading(false);
    } else {
      setLoading(false);
    }

    history.push(`/admin/dashboard/edit/${dashboardId}`);
  } catch (error) {
    console.log(error);
  } finally {
    setDialogOpen(false);
  }
};

// Delete Dashboard
export const deleteDashboard = async (
  dashboardId,
  searchQuery,
  setLoading,
  setConfirmDialog,
  setDashboardListData,
  setAlert
) => {
  try {
    setLoading(true);
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/manage/${dashboardId}`
    );
    if (response) {
      getDashboardList(searchQuery, setLoading, setDashboardListData);
      setAlert('success', 'Dashboard deleted successfully', false, true);
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error, false, true);
    setLoading(false);
  }
};

// Update Widgets
export const updateWidgets = async (
  isLayoutUpdate,
  layout,
  widget,
  history,
  setLoading,
  setDashboardData,
  setWidgetRendererData,
  setWidgetQuery,
  setUpdatedLayout,
  setDialogOpen,
  setAlert
) => {
  try {
    setLoading(true);
    let patchObj;
    let widgetTemplateIds;

    if (isLayoutUpdate) {
      widgetTemplateIds = widget?.map(
        val =>
          val?.widget_template?.widget_template_id || val?.widget_template_id
      );
      const addWidgetResponse = await axios.post(
        `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets`,
        {
          dashboard_id: widget[0]?.dashboard_id,
          widget_templates: widgetTemplateIds,
        }
      );
      if (addWidgetResponse?.data?.widgets) {
        patchObj = layout.map((lay, index) => {
          return {
            widget_id: addWidgetResponse?.data?.widgets?.filter(
              wid =>
                wid?.widget_template?.widget_template_id ===
                widget[index]?.widget_template?.widget_template_id
            )?.[0]?.widget_id,
            dashboard_id: widget[index]?.dashboard_id,
            name: widget[index]?.name,
            description: widget[index]?.description,
            width: isLayoutUpdate ? lay?.w : widget[index]?.width,
            height: isLayoutUpdate ? lay?.h : widget[index]?.height,
            x_axis_position: isLayoutUpdate
              ? lay?.x
              : widget[index]?.x_axis_position,
            y_axis_position: isLayoutUpdate
              ? lay?.y
              : widget[index]?.y_axis_position,
            index: isLayoutUpdate ? lay?.i : widget[index]?.index,
            chart_type: widget[index]?.chart_type || 'bar_chart',
            custom_properties: widget[index]?.custom_properties || {},
          };
        });
      }
    } else {
      widgetTemplateIds = [widget?.widget_template?.widget_template_id];
      const addWidgetResponse = await axios.post(
        `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets`,
        {
          dashboard_id: widget?.dashboard_id,
          widget_templates: widgetTemplateIds,
        }
      );
      if (addWidgetResponse?.data?.widgets) {
        patchObj = [
          {
            widget_id: addWidgetResponse?.data?.widgets?.filter(
              wid =>
                wid?.widget_template?.widget_template_id ===
                widget?.widget_template?.widget_template_id
            )?.[0]?.widget_id,
            dashboard_id: widget?.dashboard_id,
            name: widget?.name,
            description: widget?.description,
            width: layout[0]?.w,
            height: layout[0]?.h,
            x_axis_position: layout[0]?.x,
            y_axis_position: layout[0]?.y,
            index: widget?.index,
            chart_type: widget?.chart_type,
            custom_properties: widget?.custom_properties,
          },
        ];
      }
    }

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets/multi-edit`,
      patchObj
    );

    if (response?.data?.status) {
      if (isLayoutUpdate) {
        history.push(`/admin/dashboard/view/${patchObj[0]?.dashboard_id}`);
      } else {
        setDialogOpen(false);
        getDashboardDetails(
          widget?.dashboard_id,
          setLoading,
          setDashboardData,
          setWidgetRendererData,
          setWidgetQuery,
          ''
        );
      }
    } else {
      setAlert('error', response?.data?.message, false, true);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setUpdatedLayout([]);
    setLoading(false);
  }
};

// Delete Widget
export const deleteWidget = async (
  widgetId,
  setAlert,
  setDialogOpen,
  id,
  setLoading,
  setDashboardData,
  setWidgetRendererData,
  setWidgetQuery
) => {
  setDialogOpen(false);
  setLoading(true);
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets/${widgetId}`
    );
    if (response) {
      setAlert('success', 'Widget deleted successfully', false, true);
      getDashboardDetails(
        id,
        setLoading,
        setDashboardData,
        setWidgetRendererData,
        setWidgetQuery
      );
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
