// **** React Imports ****
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// **** External Utilities ****
import {
  Grid,
  IconButton,
  CircularProgress,
  Button,
  TextField,
  FormHelperText,
  TextareaAutosize,
  InputLabel,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  FileCopy as CopyIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Custom Components ****
import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { Alert } from '../../shared/Alerts/Alert';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import {
  getRolesList,
  createNewRole,
  deleteRoleById,
  cloneRole,
} from './RoleManagement.service';

// **** Styles/Images/Icons ****
import { useStyles } from './RoleManagement.styles';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control) control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const RoleManagement = () => {
  const rolesBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'User Management',
      link: '/admin/usermanagement',
    },
    {
      text: 'Roles',
    },
  ];
  const [loading, setLoading] = React.useState(true);
  const [reloadList, setReloadList] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const { alert, setAlert, clearAlert } = useAlerts();

  const addDialogSettings = {
    title: (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          Add Role
        </Grid>
        {loading && (
          <Grid container item xs={6} justifyContent="flex-start">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    ),
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  };
  const cloneDialogSettings = {
    title: (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          Copy Role
        </Grid>
        {loading && (
          <Grid container item xs={6} justifyContent="flex-start">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    ),
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  };
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    sortDirection: '',
  });
  const history = useHistory();
  const classes = useStyles();
  const [rolesData, setRolesData] = React.useState([]);
  const [roleId, setRoleId] = React.useState();
  const [rolesCount, setRolesCount] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });

  const dataTableColumns = [
    {
      name: 'role_name',
      label: 'Role Name',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: 'role_description',
      label: 'Description',
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '120px', maxWidth: '120px' },
        }),
        ...(!loading
          ? {
              customBodyRenderLite: dataIndex => {
                return (
                  <span style={{ display: 'none' }} id={dataIndex}>
                    {checkPermission(
                      permissions?.userManagement?.userManagementManageRolesView
                    ) && (
                      <span>
                        <IconButton
                          classes={{ root: classes.actionIcons }}
                          onClick={() => {
                            history.push(
                              `/admin/rolemanagement/${
                                dataArray && dataArray[dataIndex]?.role_id
                              }/permissions?rolename=${dataArray[dataIndex]?.role_name}`
                            );
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </span>
                    )}
                    {checkPermission(
                      permissions?.userManagement
                        ?.userManagementManageRolesDelete
                    ) &&
                      dataArray[dataIndex].is_system_role === 0 && (
                        <span>
                          <IconButton
                            classes={{ root: classes.actionIcons }}
                            onClick={() => confirmDelete(dataArray[dataIndex])}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      )}
                    {checkPermission(
                      permissions?.userManagement?.userManagementManageRolesCopy
                    ) && (
                      <span>
                        <IconButton
                          classes={{ root: classes.actionIcons }}
                          onClick={() => openCloneDialog(dataArray[dataIndex])}
                        >
                          <CopyIcon />
                        </IconButton>
                      </span>
                    )}
                  </span>
                );
              },
            }
          : {}),
      },
    },
  ];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        searchStr: searchString,
      }));
    }, 500);
  };

  React.useEffect(() => {
    getRolesList(searchQuery, setLoading, setRolesData, setRolesCount);
    setReloadList(false);
  }, [searchQuery, reloadList]);

  let dataArray =
    rolesData?.map((val, index) => {
      return {
        role_id: val.role_id,
        role_name: val.role_name,
        role_description: val.role_description,
        is_system_role: val.is_system_role,
      };
    }) || [];
  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    resizableColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: rolesCount || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortDirection: direction.toUpperCase(),
      }));
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          handleSearch(e.target.defaultValue);
        }
      },
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchStr: '',
      }));
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        onDoubleClick: () => {
          history.push(
            `/admin/rolemanagement/${
              dataArray && dataArray[dataIndex]?.role_id
            }/permissions?rolename=${dataArray[dataIndex]?.role_name}`
          );
        },
      };
    },
    pagination: !loading,
  };

  // ****Validation Schema ****
  const roleValidationSchema = Yup.object().shape({
    roleName: Yup.string().trim().required('Required'),
    roleDesc: Yup.string().trim().required('Required'),
  });
  // **** Formik Form Values ****
  const roleFormik = useFormik({
    initialValues: {
      roleName: '',
      roleDesc: '',
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (formAction === 'add') {
        createNewRole(
          roleFormik.values,
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      } else {
        cloneRole(
          roleId,
          roleFormik.values,
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen
        );
      }
    },
    validationSchema: roleValidationSchema,
    enableReinitialize: true,
  });

  const addRole = () => {
    setFormAction('add');
    roleFormik?.setValues({
      roleName: '',
      roleDesc: '',
    });
    clearAlert();
    setIsOpen(true);
  };

  const getRoleForm = () => (
    <Grid container spacing={2} direction="column">
      {alert.exists && (
        <Grid item>
          {' '}
          <Alert />
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          id="roleName"
          name="roleName"
          label="Role Name"
          required
          onChange={roleFormik?.handleChange}
          onBlur={roleFormik?.handleBlur}
          value={roleFormik?.values?.roleName}
          InputLabelProps={{ shrink: true }}
          error={roleFormik.touched.roleName && roleFormik.errors.roleName}
          helperText={roleFormik.touched.roleName && roleFormik.errors.roleName}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel required shrink className={classes.label} id="roleDesc">
          Description
        </InputLabel>
        <TextareaAutosize
          minRows={3}
          id="roleDesc"
          labelId="roleDesc"
          onChange={roleFormik.handleChange}
          onBlur={roleFormik.handleBlur}
          value={roleFormik?.values?.roleDesc}
          InputLabelProps={{ shrink: true }}
          style={{ width: '100%' }}
        />
        {roleFormik.touched.roleDesc && roleFormik.errors.roleDesc && (
          <FormHelperText error>{roleFormik.errors.roleDesc}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );

  //confirm delete
  const confirmDelete = roleData => {
    setRoleId(roleData?.role_id);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'Are you sure you want to delete this role? This action will not be taken back',
      header: 'Delete Role',
    });
  };

  //delete labor
  const deleteRole = () => {
    setLoading(true);
    deleteRoleById(roleId, setLoading, setReloadList, setAlert);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  //Open Dialog to select Installer
  const openCloneDialog = roleData => {
    setRoleId(roleData.role_id);
    setFormAction('clone');
    roleFormik?.setValues({
      roleName: roleData.role_name + ' (Copy)',
      roleDesc: roleData.role_description,
    });
    clearAlert();
    setIsOpen(true);
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  return (
    <>
      <Grid container spacing={2} direction="column">
        {alert.exists && !isOpen && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader pageTitle="Roles" breadCrumbArray={rolesBreadcrumb} />
          </Grid>
          {checkPermission(
            permissions?.userManagement?.userManagementManageRolesAdd
          ) && (
            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid item>
                <Button color="primary" variant="contained" onClick={addRole}>
                  Add New
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item>
          <GenericDataTable
            columns={dataTableColumns}
            data={loading ? tableLoader : dataArray}
            options={dataTableOptions}
          />
        </Grid>
      </Grid>
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          roleFormik?.handleReset();
        }}
        handleSave={roleFormik?.handleSubmit}
        dialogSettings={
          formAction == 'add' ? addDialogSettings : cloneDialogSettings
        }
        disabledButton2={
          !roleFormik?.touched ||
          !roleFormik?.dirty ||
          (roleFormik?.dirty && !roleFormik?.isValid)
        }
        disabledButton1={false}
      >
        <form> {getRoleForm()}</form>
      </GenericDialog>
      {/* Confirmation dialog for withdraw/acknowledge/cancel/contactoffice */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={deleteRole}
      />
    </>
  );
};

export default RoleManagement;
