import { makeStyles } from '@material-ui/core/styles';

import './Calendar.css';

const PF_TEXT_BLACK = '#171F28';

export const useStyles = makeStyles(theme => ({
  installerName: {
    marginTop: '2px',
    height: '22px',
    color: '#212B36',
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'normal',
    height: 'auto',
    wordBreak: 'break-all',
  },
  installerImage: { width: '32px', height: '32px', marginTop: '10px' },
  title: { color: 'black', textAlign: 'center', fontSize: '10px' },
  calendarLoadingIndicator: {
    position: 'absolute',
    marginTop: '14px',
  },
  eventLoadingIndicator: {
    position: 'absolute',
    display: 'none',
  },
  detailLabel: {
    width: '105px',
  },
  detailLabelModal: {
    width: '25%',
  },
  colon: {
    margin: '0px 20px',
  },
  detail: {
    color: PF_TEXT_BLACK,
  },
  tentativeTimeOff: {
    background:
      'repeating-linear-gradient(135deg,#dbdbdb 10px,#dbdbdb 12px,transparent 12px,transparent 20px)!important',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxWidth: {
    backgroundColor: '#ffffff',
    padding: '15px',
    maxWidth: '40%',
    flexBasis: '40% !important',
    '& .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon':
      {
        width: '50%',
      },
    '@media (max-width: 992px)': {
      maxWidth: '95%',
      flexBasis: '95% !important',
    },
    height: '90vh',
    overflowY: 'auto',
  },
}));
