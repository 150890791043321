import { DASHBOARD_FILTERS } from '../constants/global-filter.constants';

interface IFilterData {
  [key: string]: object | string | number;
}
export const checkDashboardGlobalFilterApplied = (
  globalFilters: IFilterData
) => {
  const filterDetails = { isFilterApplied: false, type: '' };
  if (globalFilters?.isDashboardApplicable) {
    if (Object.keys(globalFilters?.defaultFilter || {}).length > 0) {
      filterDetails.isFilterApplied = true;
      filterDetails.type = 'default';
    } else if (
      DASHBOARD_FILTERS.find(
        key =>
          globalFilters &&
          Array.isArray(globalFilters[key]) &&
          globalFilters[key]?.length
      )
    ) {
      filterDetails.isFilterApplied = true;
      filterDetails.type = 'custom';
    }
  }
  return filterDetails;
};
