import { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Chips, Toast, Dialog } from 'primereact';
import { Skeleton } from 'primereact/skeleton';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../../shared/PFPrime/PFMultiSelect';
import PFDropDown from '../../../shared/PFPrime/PFDropdown';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import {
  COVERAGE_TYPE,
  prepareDataForPreview,
  COVERAGE_VALUES,
  ACTION,
  areCoverageValuesEqual,
} from '../Helper';
import {
  addAndCloneCoveragePreference,
  groupCloneCoveragePreference,
  updateCoveragePreference,
} from '../services/user-form-coverage.service';
import {
  addAndCloneJobCoverage,
  editJobCoverage,
  groupCloneJobCoverage,
  userJobCoverageSlots,
} from '../services/user-form-job-assign-coverage.service';
import TableLoader from '../../../shared/Loader/TableLoader';
import { CLIENT_DETAILS } from '../../../../constants';

import PreviewTable from './PreviewTable';

const createCoverage = async (userId, values) => {
  return await addAndCloneCoveragePreference(userId, {
    type_coverage_id: values?.type,
    category_coverage_ids: values?.category?.join(','),
    coverage: {
      type: values.coverageBy,
      values: values?.coverageValues,
    },
    rotation_percentage: '',
    service_time: '',
  });
};

const groupCloneCoverage = async (userId, values) => {
  const groupClonePayload = values?.category?.map((record, index) => {
    return {
      type_coverage_id: values?.type,
      category_coverage_ids: `${record}`,
      coverage: {
        type: values.coverageBy || values?.differentCoverageBy?.[index],
        values: values?.coverageValues?.length
          ? values.coverageValues
          : values.differentCoverageValues?.[index][
              values?.differentCoverageBy?.[index]
            ] || [],
      },
    };
  });
  return await groupCloneCoveragePreference(userId, groupClonePayload);
};

const rowCloneCoverage = async (userId, values) => {
  return await addAndCloneCoveragePreference(userId, {
    type_coverage_id: values?.type,
    category_coverage_ids: values?.category?.join(','),
    coverage: {
      type: values?.coverageBy,
      values: values?.coverageValues,
    },
    rotation_percentage: '',
    service_time: '',
  });
};

const groupEditCoverage = async (userId, values, originalData) => {
  const groupClonePayload = values?.category?.map((record, index) => {
    const updateRecord = originalData?.children?.find(child => {
      if (child?.data?.project_categoryId == record) {
        return child?.data?.project_categoryId;
      }
    });
    return {
      type_coverage_id: values?.type,
      category_coverage_ids: `${record}`,
      coverage: {
        type: values?.coverageBy || values?.differentCoverageBy[index],
        values: values?.coverageValues?.length
          ? values?.coverageValues
          : values?.differentCoverageValues?.[index]?.[
              values?.differentCoverageBy?.[index]
            ],
      },
      user_coverage_preference_id:
        updateRecord?.data?.user_coverage_preference_id || undefined,
    };
  });

  return await updateCoveragePreference(userId, groupClonePayload);
};

const rowEditCoverageCoverage = async (userId, values, originalData) => {
  const updateRecord = originalData?.children?.find(child => {
    if (child.data.project_categoryId == values?.category?.[0]) {
      return child?.data?.project_categoryId;
    }
  });
  return await addAndCloneCoveragePreference(userId, {
    type_coverage_id: values?.type,
    category_coverage_ids: values?.category?.join(','),
    coverage: {
      type: values?.coverageBy,
      values: values?.coverageValues,
    },
    rotation_percentage: '',
    service_time: '',
    // user_coverage_preference_id: updateRecord?.data?.user_coverage_preference_id || undefined,
    user_coverage_preference_id:
      updateRecord?.data?.user_coverage_preference_id ||
      originalData?.data?.user_coverage_preference_id,
  });
};

//JobAssgin
const createJobCoverage = async (userId, values) => {
  const coverageSlotsHashMap = {};
  for (const eachRow in values.slots) {
    const coverageCategory = eachRow.split('_')[1];
    coverageSlotsHashMap[coverageCategory] = structuredClone(
      values.slots[eachRow]
    );
  }
  values.slots = coverageSlotsHashMap;
  return await addAndCloneJobCoverage(userId, [
    {
      type_coverage_id: values?.type,
      category_coverage_ids: values?.category?.join(','),
      coverage: {
        type: values?.coverageBy,
        values: values?.coverageValues,
      },
      rotation_percentage: '',
      service_time: '',
      slots: values?.slots || undefined,
    },
  ]);
};

const groupJobCloneCoverage = async (userId, values) => {
  const coverageSlotsHashMap = {};
  for (const eachRow in values.slots) {
    const coverageCategory = eachRow.split('_')[1];
    coverageSlotsHashMap[coverageCategory] = structuredClone(
      values.slots[eachRow]
    );
  }
  values.slots = coverageSlotsHashMap;
  const groupClonePayload = values?.category?.map((record, index) => {
    return {
      type_coverage_id: values?.type,
      category_coverage_ids: `${record}`,
      coverage: {
        type: values?.coverageBy || values?.differentCoverageBy?.[index],
        values: values?.coverageValues?.length
          ? values?.coverageValues
          : values?.differentCoverageValues?.[index][
              values?.differentCoverageBy?.[index]
            ] || [],
      },
      slots: { [record]: values.slots[record] },
    };
  });
  return await groupCloneJobCoverage(userId, groupClonePayload);
};

const rowCloneJobCoverage = async (userId, values) => {
  const coverageSlotsHashMap = {};
  for (const eachRow in values.slots) {
    const coverageCategory = eachRow.split('_')[1];
    coverageSlotsHashMap[coverageCategory] = structuredClone(
      values.slots[eachRow]
    );
  }
  values.slots = coverageSlotsHashMap;
  return await addAndCloneJobCoverage(userId, [
    {
      type_coverage_id: values?.type,
      category_coverage_ids: values?.category?.join(','),
      coverage: {
        type: values?.coverageBy,
        values: values?.coverageValues,
      },
      rotation_percentage: values?.rotation_percentage,
      service_time: values?.service_time,
      slots: values?.slots || undefined,
    },
  ]);
};

const groupEditJobCoverage = async (userId, values, originalData) => {
  const groupClonePayload = values?.category?.map((record, index) => {
    const updateRecord = originalData?.children.find(child => {
      if (child.data.project_categoryId == record) {
        return child?.data?.project_categoryId;
      }
    });

    return {
      type_coverage_id: values.type,
      category_coverage_ids: `${record}`,
      coverage: {
        type: values.coverageBy || values?.differentCoverageBy?.[index],
        values: values?.coverageValues?.length
          ? values?.coverageValues
          : values?.differentCoverageValues?.[index][
              values?.differentCoverageBy?.[index]
            ] || [],
      },
      user_job_coverage_id:
        updateRecord?.data?.user_coverage_preference_id || undefined,
    };
  });

  return await editJobCoverage(userId, groupClonePayload);
};

const rowEditJobCoverageCoverage = async (userId, values, originalData) => {
  const updateRecord = originalData?.children?.find(child => {
    if (child.data.project_categoryId == values?.category?.[0]) {
      return child?.data?.project_categoryId;
    }
  });

  return await addAndCloneJobCoverage(userId, [
    {
      type_coverage_id: values?.type,
      category_coverage_ids: values?.category?.join(','),
      coverage: {
        type: values?.coverageBy,
        values: values?.coverageValues,
      },
      rotation_percentage: '',
      service_time: '',
      user_job_coverage_id:
        updateRecord?.data?.user_coverage_preference_id ||
        originalData?.data?.user_coverage_preference_id,
    },
  ]);
};
const validationSchema = Yup.object().shape({
  type: Yup.number().required('Type is required'),
  category: Yup.array()
    .of(Yup.number().required())
    .min(1, 'At least one category is required')
    .required('Category is required'),
  coverageBy: Yup.number().notRequired(), // coverageBy is optional, but if present, coverageValues should be required
  coverageValues: Yup.array()
    .min(1, 'At least one category is required')
    .required('Category is required'),
});

const CoverageClone = ({
  selectedRecord,
  typesList,
  categoryList,
  coverageTypeList,
  storeList,
  districtList,
  setSelectedRecord,
  isCloneOrEdit,
  setVisible,
  userId,
  setRefreshCoverageData,
  refreshCoverageData,
  isJob,
}) => {
  const toast = useRef(null);
  const isRequiredConfirmation = isCloneOrEdit == ACTION.GROUP_EDIT;
  const [loading, setLoading] = useState(false);
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [isCancelConfirmationPopup, setIsCancelConfirmationPopup] =
    useState(false);
  const mode =
    isCloneOrEdit == ACTION.GROUP_CLONE ||
    isCloneOrEdit == ACTION.ROW_CLONE ||
    isCloneOrEdit == ACTION.GROUP_EDIT ||
    isCloneOrEdit == ACTION.ROW_EDIT
      ? true
      : false;
  const selectedCategories = [];
  const selectedCoverageValues = [];
  let differentCoverageBy = [];
  let differentCoverageValues = [];
  let coverageByValue = null;
  if (mode && selectedRecord) {
    coverageByValue = selectedRecord?.children?.[0]?.data?.coverage_by;
    const isAllCoverageValuesSame = areCoverageValuesEqual(
      selectedRecord.children
    );
    selectedRecord?.children?.forEach(record => {
      if (coverageByValue != record?.data?.coverage_by) {
        coverageByValue = null;
      }

      selectedCategories.push(record?.data?.project_categoryId);

      if (
        isAllCoverageValuesSame &&
        Array.isArray(record?.data?.coverage_values)
      )
        selectedCoverageValues.push(...record.data.coverage_values);
      differentCoverageBy.push(record.data.coverage_by);
      differentCoverageValues.push({
        [record.data.coverage_by]: [...record.data.coverage_values],
      });
    });
  }
  const onSaveUpdateHandler = async values => {
    let apiResponse = null;
    try {
      if (isJob) {
        if (isCloneOrEdit == ACTION.CREATE) {
          apiResponse = await createJobCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_CLONE) {
          apiResponse = await groupJobCloneCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.ROW_CLONE) {
          apiResponse = await rowCloneJobCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_EDIT) {
          apiResponse = await groupEditJobCoverage(
            userId,
            values,
            selectedRecord
          );
        } else if (isCloneOrEdit == ACTION.ROW_EDIT) {
          apiResponse = await rowEditJobCoverageCoverage(
            userId,
            values,
            selectedRecord
          );
        }
      } else {
        if (isCloneOrEdit == ACTION.CREATE) {
          apiResponse = await createCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_CLONE) {
          apiResponse = await groupCloneCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.ROW_CLONE) {
          apiResponse = await groupCloneCoverage(userId, values);
        } else if (isCloneOrEdit == ACTION.GROUP_EDIT) {
          apiResponse = await groupEditCoverage(userId, values, selectedRecord);
        } else if (isCloneOrEdit == ACTION.ROW_EDIT) {
          apiResponse = await rowEditCoverageCoverage(
            userId,
            values,
            selectedRecord
          );
        }
      }

      if (apiResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Created',
          detail: `The record has been successfully ${
            isCloneOrEdit === ACTION.GROUP_EDIT ||
            isCloneOrEdit === ACTION.ROW_EDIT
              ? `updated.`
              : isCloneOrEdit === ACTION.ROW_CLONE ||
                  isCloneOrEdit === ACTION.GROUP_CLONE
                ? `copied`
                : `created.`
          } `,
          life: 3000,
        });
        setTimeout(() => {
          setLoading(false);
          setRefreshCoverageData(!refreshCoverageData);
          setSelectedRecord(null);
          setVisible(false);
        }, 1000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: apiResponse.message || 'Something went wrong.',
          life: 3000,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      if (apiResponse?.message) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong.',
          life: 3000,
        });
        setLoading(false);
      }
    }
  };
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: mode ? selectedRecord.data.project_typeId : null,
      category: selectedCategories,
      coverageBy: coverageByValue,
      coverageValues: !coverageByValue
        ? []
        : [...new Set(selectedCoverageValues)],
      differentCoverageBy: differentCoverageBy,
      differentCoverageValues: differentCoverageValues,
      ...(isJob ? { slots: {} } : {}),
    },
    onSubmit: async values => {
      try {
        onSaveUpdateHandler(values);
      } catch (error) {
        console.error(error);
      }
    },
  });
  const callUserCoverageSlotOnCloneOrEdit = async () => {
    if (
      Array.isArray(selectedRecord?.children) &&
      selectedRecord?.children.length
    ) {
      if (
        formik?.values?.slots &&
        !Array.isArray(formik?.values?.slots) &&
        typeof formik?.values?.slots === 'object' &&
        Object.keys(formik?.values?.slots).length
      ) {
        const slotsCopy = JSON.parse(JSON.stringify(formik.values.slots));
        const updatedSlots = {};
        const slotKeys = [];
        for (const eachSlotKey in slotsCopy) {
          let eachIdSplit = eachSlotKey.split('_');
          eachIdSplit[0] = formik.values.type;
          if (formik.values.category.includes(+eachIdSplit[1])) {
            slotKeys.push(+eachIdSplit[1]);
            updatedSlots[eachIdSplit.join('_')] = JSON.parse(
              JSON.stringify(slotsCopy[eachSlotKey])
            );
          }
        }
        if (
          Array.isArray(formik.values?.category) &&
          formik.values?.category.length &&
          Array.isArray(formik.values?.coverageValues) &&
          formik.values?.coverageValues.length
        ) {
          for (const eachCategory of formik.values.category) {
            if (!slotKeys.includes(eachCategory)) {
              const uniqueKey = `${formik.values.type}_${eachCategory}_${formik.values.coverageBy}`;
              updatedSlots[uniqueKey] = formik.values.coverageValues.map(
                eachCoverage => {
                  return {
                    key: eachCoverage,
                    slots: {
                      Monday: [],
                      Tuesday: [],
                      Wednesday: [],
                      Thursday: [],
                      Friday: [],
                      Saturday: [],
                      Sunday: [],
                    },
                  };
                }
              );
            }
          }
        }
        formik.setFieldValue('slots', updatedSlots);
      } else {
        setLoading(true);
        try {
          const ids = selectedRecord?.children.map(
            ({ data: { user_coverage_preference_id } }) =>
              user_coverage_preference_id
          );
          if (ids.length) {
            const response = await userJobCoverageSlots(userId, {
              user_job_coverage_id: ids.join(','),
            });
            if (Array.isArray(response?.data) && response.data.length) {
              const slots = {};
              const slotHashMap = {};
              response.data.forEach(eachSlot => {
                if (slotHashMap[eachSlot.user_job_coverage_id])
                  slotHashMap[eachSlot.user_job_coverage_id].push(eachSlot);
                else slotHashMap[eachSlot.user_job_coverage_id] = [eachSlot];
              });
              for (const eachCategory of selectedRecord.children) {
                const uniqueKey = `${formik.values.type}_${eachCategory.data.project_categoryId}_${eachCategory.data.coverage_by}`;
                const userCoveragePreferenceId =
                  eachCategory.data.user_coverage_preference_id;
                if (slotHashMap[userCoveragePreferenceId]) {
                  const coverageSlot = {};
                  for (const eachCoverageSlot of slotHashMap[
                    userCoveragePreferenceId
                  ]) {
                    if (coverageSlot[eachCoverageSlot.coverage_value]) {
                      if (
                        coverageSlot[eachCoverageSlot.coverage_value][
                          eachCoverageSlot.day
                        ]
                      ) {
                        coverageSlot[eachCoverageSlot.coverage_value][
                          eachCoverageSlot.day
                        ].push(eachCoverageSlot);
                      } else {
                        coverageSlot[eachCoverageSlot.coverage_value] = {
                          ...coverageSlot[eachCoverageSlot.coverage_value],
                          [eachCoverageSlot.day]: [eachCoverageSlot],
                        };
                      }
                    } else {
                      coverageSlot[eachCoverageSlot.coverage_value] = {
                        [eachCoverageSlot.day]: [eachCoverageSlot],
                      };
                    }
                  }
                  const coverageSlotArr = [];
                  for (const key in coverageSlot) {
                    coverageSlotArr.push({
                      key,
                      slots: coverageSlot[key],
                    });
                  }
                  slots[uniqueKey] = coverageSlotArr;
                }
              }
              formik.setFieldValue('slots', slots);
            }
          }
        } catch (ex) {
          console.log(ex, 'error callUserCoverageSlotOnCloneOrEdit');
        } finally {
          setLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    if (
      isJob &&
      (isCloneOrEdit == ACTION.ROW_CLONE || isCloneOrEdit == ACTION.GROUP_CLONE)
    ) {
      callUserCoverageSlotOnCloneOrEdit();
    }
  }, [
    formik?.values?.type,
    formik?.values?.category,
    formik?.values?.category,
    formik?.values?.coverageBy,
    formik?.values?.coverageValues,
  ]);
  const previewData = useMemo(
    () =>
      prepareDataForPreview({
        selectedValues: formik.values,
        typesList,
        categoryList,
        storeList,
        districtList,
        COVERAGE_VALUES,
        selectedRecord,
        differentCoverageBy: formik.values.differentCoverageBy,
        differentCoverageValues: formik.values.differentCoverageValues,
        userId,
      }),
    [formik.values, selectedRecord]
  );
  return (
    <>
      <div className="coverage-dialog-parent">
        <PFDialog
          show={true}
          hide={() => {
            setSelectedRecord(null);
            setVisible(false);
          }}
          className="w-11 lg:w-9"
          style={{ height: '80vh' }}
          pt={{
            closeButtonIcon: { className: 'hidden' },
            header: { className: 'hidden' },
            content: { className: 'border-round' },
          }}
          appendTo="self"
          draggable={false}
          BodyComponent={
            <>
              <form onSubmit={() => {}}>
                <div className="flex justify-content-between mb-2 mt-3 align-items-center">
                  <div>
                    <h3>
                      {isCloneOrEdit === ACTION.CREATE
                        ? 'Add Coverage'
                        : isCloneOrEdit === ACTION.ROW_EDIT
                          ? 'Edit Coverage'
                          : isCloneOrEdit === ACTION.ROW_CLONE
                            ? 'Clone Coverage'
                            : isCloneOrEdit === ACTION.GROUP_EDIT
                              ? 'Edit Coverage Combination'
                              : isCloneOrEdit === ACTION.GROUP_CLONE
                                ? 'Clone Coverage Combination'
                                : ''}
                    </h3>
                  </div>
                  <div className="flex">
                    <PFButton
                      label="Cancel"
                      onClick={() => {
                        if (formik.isValid && formik.dirty) {
                          setIsCancelConfirmationPopup(true);
                        } else {
                          setSelectedRecord(null);
                          setVisible(false);
                        }
                      }}
                      size="small"
                      outlined
                      className="m-2"
                      severity="primary"
                      disabled={loading}
                    />
                    <PFButton
                      label="Save"
                      size="small"
                      onClick={() =>
                        isRequiredConfirmation
                          ? setIsShowConfirmationPopup(true)
                          : (formik.submitForm(), setLoading(true))
                      }
                      loading={loading}
                      className="m-2"
                      severity="primary"
                      disabled={!formik.isValid || !formik.dirty}
                    />
                  </div>
                </div>

                {(isCloneOrEdit === 2 || isCloneOrEdit === 3) &&
                  (Number(selectedRecord?.data?.rotationPercentage) > 0 ||
                    Number(selectedRecord?.data?.rotation_percentage) > 0) &&
                  CLIENT_DETAILS?.auto_assign_technicians === 1 &&
                  CLIENT_DETAILS?.auto_assign_technicians_value ===
                    'Rotation Percentage' && (
                    <div className="pb-3 text-yellow-300 flex gap-2">
                      <i className="pi pi-exclamation-triangle text-xl text-primary-500"></i>
                      <span>
                        Please ensure that the Rotation% is set to 100% for this
                        coverage combination to enable auto-assignment to work
                        as expected.
                      </span>
                    </div>
                  )}

                <div className="grid my-2">
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton width="25rem" height="3rem" />
                    ) : (
                      <span className="p-float-label">
                        <PFDropDown
                          value={formik?.values?.type || null}
                          optionLabel="project_type"
                          optionValue="project_type_id"
                          name="project_type"
                          options={typesList}
                          onChange={(name, value) => {
                            // formik.setFieldValue('type', value);
                            formik.setValues({
                              ...formik.values,
                              type: value,
                              differentCoverageBy: [],
                              differentCoverageValues: [],
                            });
                          }}
                          filter={true}
                          placeholder="Select a Type"
                          className="w-full h-40rem"
                          style={{ height: '40px' }}
                          pt={{
                            input: {
                              className: 'p-inputtext p-inputtext-sm ',
                            },
                          }}
                        />
                        <label htmlFor="client_timezone">Type</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4  py-0">
                    {loading ? (
                      <Skeleton width="25rem" height="3rem" />
                    ) : (
                      <span className="p-float-label">
                        {selectedRecord?.rowEdit || selectedRecord?.rowClone ? (
                          <PFDropDown
                            key="project_category_id"
                            value={formik.values.category?.[0] || null}
                            options={categoryList}
                            className="mr-3 w-full"
                            name="category"
                            filter={true}
                            optionLabel="category"
                            optionValue="project_category_id"
                            onChange={(name, value) => {
                              formik.setValues({
                                ...formik.values,
                                category: [value],
                                differentCoverageBy: [],
                                differentCoverageValues: [],
                              });
                            }}
                            style={{ height: '40px' }}
                          />
                        ) : (
                          <PFMultiSelect
                            value={formik?.values?.category || []}
                            optionLabel="category"
                            optionValue="project_category_id"
                            name="category"
                            maxSelectedLabels={1}
                            options={categoryList}
                            onChange={event => {
                              formik.setValues({
                                ...formik.values,
                                category: event?.value,
                                differentCoverageBy: [],
                                differentCoverageValues: [],
                              });
                            }}
                            filter={true}
                            // onFocus={displaySelectedCategory}
                            placeholder="Select a Category"
                            style={{ height: '40px' }}
                            className="w-full"
                            pt={{
                              input: {
                                className: 'p-inputtext p-inputtext-sm',
                              },
                            }}
                          />
                        )}

                        <label htmlFor="client_timezone">Category</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton width="25rem" height="3rem" />
                    ) : (
                      <span className="p-float-label">
                        <PFDropDown
                          key="coverageTypeList"
                          value={formik.values.coverageBy || null}
                          options={coverageTypeList}
                          className="mr-3 w-full"
                          name="coverage_type"
                          optionLabel="label"
                          optionValue="id"
                          onChange={(name, value) => {
                            formik.setValues({
                              ...formik.values,
                              coverageBy: value,
                              coverageValues: [], // reset values selected when type change
                              differentCoverageBy: [],
                              differentCoverageValues: [],
                            });
                          }}
                          style={{ height: '40px' }}
                        />
                        <label htmlFor="client_timezone">Coverage Type</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-12 mb-3 py-0">
                    {loading ? (
                      <Skeleton width="100%" height="3rem" />
                    ) : (
                      <span className="p-float-label">
                        {formik.values.coverageBy === COVERAGE_TYPE.ZIP_CODE ? (
                          <>
                            <Chips
                              value={formik.values.coverageValues || []}
                              name="Coverage_value"
                              onChange={event => {
                                formik.setValues({
                                  ...formik.values,
                                  coverageValues: event.target.value,
                                  differentCoverageBy: [],
                                  differentCoverageValues: [],
                                });
                              }}
                              separator=","
                              className="w-12 p-0 h-auto"
                              pt={{
                                container: {
                                  className: 'w-12 overflow-auto',
                                },
                                root: {
                                  className: 'p-inputtext p-inputtext-sm',
                                },
                              }}
                            />
                            <span className="m-2 text-xs text-500">
                              Enter zip code in comma separated
                            </span>
                          </>
                        ) : (
                          <PFMultiSelect
                            value={formik.values.coverageValues || []}
                            maxSelectedLabels={8}
                            style={{ height: '40px' }}
                            filter={true}
                            options={
                              formik.values.coverageBy === COVERAGE_TYPE.STORE
                                ? storeList.map(store => ({
                                    label: `${store.store_number} - ${store.store_name}`,
                                    value: store.store_id,
                                    store_number: store.store_number,
                                  }))
                                : formik.values.coverageBy ===
                                    COVERAGE_TYPE.DISTRICT
                                  ? districtList?.map(dist => dist?.district)
                                  : []
                            }
                            className="mr-3 w-12"
                            onChange={event => {
                              formik.setValues({
                                ...formik.values,
                                coverageValues: event?.value,
                                differentCoverageBy: [],
                                differentCoverageValues: [],
                              });
                            }}
                            placeholder="Select coverage value"
                            pt={{
                              input: {
                                className: 'p-inputtext p-inputtext-sm',
                              },
                            }}
                          />
                        )}

                        <label htmlFor="client_timezone">Coverage Values</label>
                      </span>
                    )}
                  </div>
                </div>
              </form>
              {loading ? (
                <TableLoader columnCount={4} noOfRow={4} mTopBottom={2} />
              ) : (
                <>
                  {formik.values.type && formik.values?.category?.length ? (
                    <PreviewTable
                      data={previewData}
                      isShowPreviousDetails={false}
                      isJob={isJob}
                      formik={formik}
                      selectedRecord={selectedRecord}
                      storeList={storeList}
                      isCloneOrEdit={isCloneOrEdit}
                      mode={mode}
                    />
                  ) : null}
                </>
              )}
              <Toast ref={toast} />
            </>
          }
        ></PFDialog>
      </div>
      <div className="coverage-dialog-child">
        <Dialog
          appendTo="self"
          baseZIndex={9999}
          header="Confirmation"
          visible={isShowConfirmationPopup}
          style={{ width: '450px' }}
          onHide={() => setIsShowConfirmationPopup(false)}
          footer={
            <div>
              <PFButton
                label="No"
                text
                outlined
                onClick={() => setIsShowConfirmationPopup(false)}
              />
              <PFButton
                label="Yes"
                onClick={() => {
                  setIsShowConfirmationPopup(false);
                  formik.submitForm();
                  setLoading(true);
                }}
              />
            </div>
          }
          draggable={false}
        >
          <div>
            Making a change at parent record will change all the values of its
            child records. Would you still like to continue?
          </div>
        </Dialog>
      </div>
      <div className="coverage-dialog-child">
        <Dialog
          appendTo="self"
          baseZIndex={9999}
          header="Warning"
          visible={isCancelConfirmationPopup}
          style={{ width: '450px' }}
          onHide={() => setIsCancelConfirmationPopup(false)}
          footer={
            <div>
              <PFButton
                label="No"
                text
                outlined
                onClick={() => setIsCancelConfirmationPopup(false)}
              />
              <PFButton
                label="Yes"
                onClick={() => {
                  setSelectedRecord(null);
                  setVisible(false);
                }}
              />
            </div>
          }
          draggable={false}
        >
          <div>
            All unsaved changes would be discarded. Would you like to continue ?
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default CoverageClone;
