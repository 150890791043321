import { useEffect, useRef, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputNumber } from 'primereact/inputnumber';

import PFCheckbox from '../../shared/PFPrime/PFCheckbox';
import PFButton from '../../shared/PFPrime/PFButton';
import PFGoogleMap from '../../shared/PFPrime/PFGoogleMap';
import LrrpConfiguration from '../Configuration/LrrpConfiguration';
import {
  PF_ADMIN,
  project_linkages,
  SALES_COMMISSION_TYPE,
  ALLOCATION_PROJECT_ITEMS,
  DOCUMENT_AUTO_PULL_RETRY_FIELDS,
  ORDER_COMPLETION_VALIDATION_FIELDS,
} from '../../../constants';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PageHeader from '../../shared/PageHeader/PageHeader';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import {
  getClientData,
  multiPartUpdateTenantData,
} from './service/TenantConfig.service';
import timezonesData from './timezones.json';
import EditHomeDepotConfig from './EditHomeDepotConfig';
import {
  DOCUMENT_EXPIRE_IN_DAYS_OPTIONS,
  JOB_ASSIGNMENT_ROTATION_VALUES,
  MFA_EXPIRE_IN_DAYS_OPTIONS,
  RESET_PASSWORD_DAYS_OPTIONS,
  SCHEDULER_CONFIG_DATA,
} from './company-config.constant';
import AdditionalFeatures from './AdditionalFeatures/AdditionalFeatures.tsx';

export const FEATURE_LIST = [
  {
    key: 'INSTALLER_WITH_ALL_DOCUMENT',
    label: 'Project Documents Visible to Technicians',
    checked: false,
  },
  {
    key: 'ENABLE_TENTATIVE_SCHEDULING',
    label: 'Enable Tentative Scheduling Functionality',
    checked: false,
  },
  {
    key: 'VIEW_ALL_MERCHANDISE_COST',
    label: 'Show Product Items Cost',
    checked: false,
  },
  {
    key: 'CONVERT_ISSUE_TO_LABOR_ITEM',
    label: 'Convert Issues to Labor Items',
    checked: false,
  },
];

const EditCompanyConfig = () => {
  const { id } = useParams();
  const [timeZones, setTimeZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);
  const [clientName, setClientName] = useState('');
  const [client, setClientDetail] = useState('');
  const [binaryImage, setBinaryImage] = useState();
  const [featureList, setFeatureList] = useState(FEATURE_LIST);
  const [allocationPermission, setAllocationPermission] = useState(
    'LEAVE_UNASSIGNED_ITEMS'
  );

  const [allocationPercentage, setAllocationPercentage] = useState(0);
  const pfClientsId = localStorage.getItem('client_id');
  const client_id = id;
  const [updatePayload, setUpdatePayload] = useState();
  const [lrrpData, setLrrpData] = useState();
  const [responseData, setResponseData] = useState();
  const { gMapFieldLayout, address: gAddress } = PFGoogleMap();
  const [forceSyncDisabled, setForceSyncDisabled] = useState(true);
  const [systemTenant, setSystemTenant] = useState([
    {
      roleId: 3,
      excluded_permissions: [],
      included_permissions: ['LEAVE_UNASSIGNED_ITEMS'],
    },
  ]);
  const fileUploadRef = useRef();
  const toast = useRef(null);
  const { categories } = useSelector(state => state.categories);
  const [btnDisables, setBtnDisables] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [groupSourceOpt, setGroupSourceOpt] = useState(0);
  const [allocationOption, setAllocationOption] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const client_details = JSON.parse(localStorage?.getItem('client_details'));

  const clientData = JSON.parse(
    localStorage?.getItem('client_details')
  )?.registeredSources;

  const hasSourceSystemIdTwo = clientData?.some(
    item => item?.source_system_id === 2
  );
  const otherSourceSystemIds = clientData?.filter(
    item => item?.source_system_id !== 2
  );
  const hasOtherSourceSystemIds = otherSourceSystemIds?.length > 0;

  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      text: `${clientName} Configurations`,
    },
  ];

  const validation = Yup.object().shape({
    client_name: Yup.string().required('Client Name is required.'),
    client_timezone: Yup.string().required('Required.'),
    contact_name: Yup.string().max(30, 'Too Long!').nullable(),
    contact_email: Yup.string().email('Invalid email').nullable(),
    personalized_private_project_number: Yup.string().when(
      'is_personalized_private_project',
      {
        is: 1,
        then: Yup.string()
          .matches(/^\d+$/, 'Project number must be a valid number')
          .min(3, 'Project number must be at least 3 characters')
          .max(7, 'Project number must not exceed 7 characters')
          .required('Project number is required'),
        otherwise: Yup.string().nullable(),
      }
    ),
  });

  const onSubmit = async values => {
    const payload = {
      client_id: values.client_id,
      client_name: values.client_name,
      phone_number: values.phone_number,
      email: values.email,
      fax: values.fax,
      contact_name: values.contact_name,
      contact_email: values.contact_email,
      contact_phone_number: values.contact_phone_number,
      logo: values.logo,
      principal_name: values.principal_name,
      installer_number_lowes: values.installer_number_lowes,
      renovator_name: values.renovator_name,
      renovator_certification_number: values.renovator_certification_number,
      client_timezone: values.client_timezone,
      client_geolocation: values.client_geolocation,
      quickbooks_refresh_token: values.quickbooks_refresh_token,
      routing_module: values.routing_module,
      crm_module: values.crm_module,
      warranty_module: values.warranty_module,
      floorsoft_opted: values.floorsoft_opted,
      is_measuresquare: values.is_measuresquare,
      create_project_private_lead: values.create_project_private_lead,
      is_customer_portal: values.is_customer_portal,
      lrrpPayload: lrrpData,
      tenant_permissions: systemTenant,
      is_line_item_visible: values.is_line_item_visible ? 1 : 0,
      due_days: values.due_days,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      address1: values.address1,
      address2: values.address2,
      latitude: values.latitude,
      longitude: values.longitude,
      state_id: '',
      occupant_type: '',
      address_id: values.address_id || '',
      project_linkage: values?.project_linkage,
      sales_commission_opted: values?.sales_commission_opted,
      skip_technician_assignment_cloning:
        values.skip_technician_assignment_cloning,
      sales_commission_criteria: values?.sales_commission_criteria,
      cx_scheduling_module: values?.cx_scheduling_module,
      hd_default_reason: values?.hd_default_reason,
      hd_lrrp_configuration: {
        lswp_followed: values?.hd_lrrp_configuration?.lswp_followed,
        hd_lead_test_result: values?.hd_lrrp_configuration?.hd_lead_test_result,
      },
      capture_live_location: values?.capture_live_location,
      group_source_category_opted: values?.group_source_category_opted,
      personalized_emails: values?.personalized_emails,
      default_calender_view: values?.default_calender_view,
      allocation_percentage: values?.allocation_percentage,
      fixed_top_bar: values?.fixed_top_bar,
      fixed_project_bar: values?.fixed_project_bar,
      scheduler_config: values?.scheduler_config,
      chargeback_approval_module: values?.chargeback_approval_module,
      reset_password_in_days: values?.reset_password_in_days,
      mfa_expire_in_days: values?.mfa_expire_in_days,
      short_url_generate_in_days: values?.short_url_generate_in_days,
      shipping_receiving: values?.shipping_receiving,
      highlight_store_notes: values?.highlight_store_notes,
      copy_total_sales: values?.copy_total_sales,
      admin_email: values?.admin_email,
      do_not_override_labor_cost: values?.do_not_override_labor_cost,
      is_capture_job_distance_enabled: values?.is_capture_job_distance_enabled,
      show_line_items_on_documents: values?.show_line_items_on_documents,
      assign_technician_on_revenue_excluded_items:
        values?.assign_technician_on_revenue_excluded_items,
      epa_guardrails_workflow: values?.epa_guardrails_workflow,
      document_auto_pull_retry: values?.document_auto_pull_retry,
      is_personalized_private_project: values?.is_personalized_private_project,
      personalized_private_project_number:
        values?.personalized_private_project_number,
      send_tentative_schedule_note_to_source:
        values?.send_tentative_schedule_note_to_source,
      auto_assign_technicians: values?.auto_assign_technicians ? 1 : 0,
      auto_assign_technicians_value: values?.auto_assign_technicians_value,
      order_completion_validation: values?.order_completion_validation,
    };
    try {
      const apiResponse = await multiPartUpdateTenantData(payload);

      if (apiResponse?.data?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Record Updated Successfully',
          life: 1500,
        });
        setBtnDisables(true);
        setOpenModal(false);
        if (apiResponse?.data?.data?.client) {
          setGroupSourceOpt(
            apiResponse?.data?.data?.client?.group_source_category_opted
          );
          setResponseData(apiResponse?.data?.data?.client);
          formik.setValues({
            ...formik.values,
            ...apiResponse?.data?.data?.address,
            ...apiResponse?.data?.data?.client,
            order_completion_validation:
              apiResponse?.data?.data?.client?.order_completion_validation,
          });
        }

        return;
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error occurred while Updating record',
          life: 1500,
        });
        setBtnDisables(true);
        return;
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.current.show({
          severity: 'error',
          detail:
            'Uploaded File Type is not supported. Please try with extension .jpg, .jpeg, .PNG, .JFIF ',
          life: 5000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          detail: 'Something Went Wrong',
          life: 3000,
        });
      }
      setBtnDisables(true);
      console.error(error);
    }
    setUpdate(true);
    setLoading(true);
  };

  const formik = useFormik({
    initialValues: {
      client_id: '',
      client_name: '',
      phone_number: '',
      email: '',
      fax: '',
      contact_name: '',
      contact_email: '',
      contact_phone_number: '',
      logo: '',
      principal_name: '',
      installer_number_lowes: '',
      measure_square_opted: '',
      renovator_name: '',
      renovator_certification_number: '',
      client_timezone: '',
      client_geolocation: { lat: '', lng: '' },
      default_lead_response_reason: '',
      quickbooks_refresh_token: '',
      routing_module: '',
      crm_module: '',
      warranty_module: '',
      floorsoft_opted: '',
      is_measuresquare: '',
      create_project_private_lead: '',
      is_customer_portal: '',
      is_capture_job_distance_enabled: 0,
      default_lead_configuration: lrrpData,
      tenant_permissions: updatePayload,
      is_line_item_visible: 0,
      due_days: 0,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      address_id: '',
      latitude: '',
      longitude: '',
      project_linkage: project_linkages,
      sales_commission_opted: 0,
      skip_technician_assignment_cloning: 0,
      sales_commission_criteria: '',
      hd_default_reason: '',
      hd_lrrp_configuration: {
        lswp_followed: '',
        hd_lead_test_result: '',
      },
      group_source_category_opted: 0,
      personalized_emails: 0,
      default_calender_view: '',
      allocation_percentage: 0,
      fixed_top_bar: 0,
      fixed_project_bar: 0,
      scheduler_config: SCHEDULER_CONFIG_DATA,
      chargeback_approval_module: 0,
      reset_password_in_days: RESET_PASSWORD_DAYS_OPTIONS[2].value,
      mfa_expire_in_days: MFA_EXPIRE_IN_DAYS_OPTIONS[1].value,
      admin_email: '',
      short_url_generate_in_days: DOCUMENT_EXPIRE_IN_DAYS_OPTIONS[0].value,
      shipping_receiving: 0,
      highlight_store_notes: 0,
      copy_total_sales: 1,
      do_not_override_labor_cost: 0,
      show_line_items_on_documents: 0,
      assign_technician_on_revenue_excluded_items: 0,
      epa_guardrails_workflow: 0,
      document_auto_pull_retry: DOCUMENT_AUTO_PULL_RETRY_FIELDS,
      personalized_private_project_number: 0,
      is_personalized_private_project: 0,
      send_tentative_schedule_note_to_source: 1,
      auto_assign_technicians: 0,
      auto_assign_technicians_value: JOB_ASSIGNMENT_ROTATION_VALUES[0]?.value,
      order_completion_validation: ORDER_COMPLETION_VALIDATION_FIELDS,
    },
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (gAddress) {
      formik.setFieldValue('city', gAddress.city);
      formik.setFieldValue('state', gAddress.stateCode);
      formik.setFieldValue('zipcode', gAddress.zipcode);

      formik.setFieldValue('client_geolocation', {
        lat: gAddress.latitude || '',
        lng: gAddress.longitude || '',
      });
      formik.setFieldValue('latitude', gAddress.latitude);
      formik.setFieldValue('longitude', gAddress.longitude);

      formik.setFieldValue('address1', gAddress.addressLineOne);
      formik.setFieldValue('address2', gAddress.addressLineTwo);
      setBtnDisables(false);
    }
  }, [gAddress]);

  useEffect(() => {
    formik.setFieldValue('client_geolocation', {
      lat: formik.values.latitude || '',
      lng: formik.values.longitude || '',
    });
  }, [formik.values.longitude, formik.values.latitude]);

  const fetchData = async client_id => {
    setLoading(true);
    try {
      const response = await getClientData(client_id || pfClientsId);
      setClientDetail(response?.data?.client);
      setSystemTenant(response?.data?.client?.tenant_permissions);

      const updatedFeatureList = [...featureList];
      updatedFeatureList.forEach(record => {
        if (
          response?.data?.client?.tenant_permissions?.[0]?.included_permissions?.includes(
            record.key
          )
        ) {
          record.checked = true;
        } else {
          record.checked = false;
        }
      });
      let includedPermissions = '';
      ALLOCATION_PROJECT_ITEMS.forEach(record => {
        if (
          response?.data?.client?.tenant_permissions?.[0].included_permissions?.includes(
            record
          )
        ) {
          includedPermissions = record;
        }
      });
      setAllocationPermission(includedPermissions);
      if (includedPermissions.includes('CUSTOM_ALLOCATION')) {
        setAllocationPercentage(
          response?.data?.client?.tenant_permissions?.[0]?.allocation_percentage
        );
        setAllocationOption(true);
      }
      setFeatureList(updatedFeatureList);
      if (response?.data?.client?.client_id?.length > 0) {
        setResponseData(response?.data?.client);
        formik.setValues({
          ...formik.values,
          ...response?.data?.address,
          ...response?.data?.client,
        });
        setClientName(response?.data?.client?.client_name || '');
        if (response?.data?.client?.group_source_category_opted === null) {
          setGroupSourceOpt(0);
        } else {
          setGroupSourceOpt(
            response?.data?.client?.group_source_category_opted
          );
        }
      }
      setLoading(false);
    } catch (error) {
      return error;
    }
  };

  const handleCheckbox = async (key, checkedState) => {
    const tempPermissions = [...systemTenant];

    tempPermissions.forEach(permissions => {
      if (checkedState) {
        if (permissions.excluded_permissions.includes(key)) {
          permissions.excluded_permissions =
            permissions?.excluded_permissions?.filter(record => record != key);
        }
        permissions.included_permissions.push(key);
      } else {
        if (permissions.included_permissions.includes(key)) {
          permissions.included_permissions =
            permissions?.included_permissions?.filter(record => record != key);
        }
        permissions.excluded_permissions.push(key);
      }
    });

    setFeatureList(
      featureList.map(record => {
        if (record.key == key) {
          record.checked = checkedState;
        }
        return record;
      })
    );
    setSystemTenant(tempPermissions);
  };
  const handleRadioButton = async (key, checkedState) => {
    const tempPermissions = [...systemTenant];
    ALLOCATION_PROJECT_ITEMS.forEach(items => {
      if (tempPermissions?.[0]?.excluded_permissions) {
        tempPermissions[0].excluded_permissions =
          tempPermissions?.[0]?.excluded_permissions?.filter(
            selected => items != selected
          );
      }
      if (tempPermissions?.[0]?.included_permissions) {
        tempPermissions[0].included_permissions =
          tempPermissions?.[0]?.included_permissions?.filter(
            selected => items != selected
          );
      }
    });

    if (key === 'CUSTOM_ALLOCATION') {
      setAllocationOption(true);
    } else {
      setAllocationOption(false);
    }

    tempPermissions?.[0]?.included_permissions?.push(key);
    setSystemTenant(tempPermissions);
  };

  useEffect(() => {
    if (client_id?.length > 0 || pfClientsId?.length > 0) {
      if (update === true) {
        fetchData(client_id || pfClientsId);
        setUpdate(false);
      }
    }
  }, [update, client_id]);

  useEffect(() => {
    const formattedTimeZones = timezonesData.reduce((accumulator, current) => {
      const utcTimeZone = current.utc.map(utc => {
        return {
          label: `${utc} (${current.offset >= 0 ? '+' : ''} ${current.offset}:00)`,
          value: utc,
        };
      });
      accumulator.push(...utcTimeZone);
      return accumulator;
    }, []);
    setTimeZones(formattedTimeZones);
  }, []);

  const ImageValidation = type => {
    if (!type || !type.startsWith('image')) {
      return false;
    } else if (
      type.includes('svg') ||
      type.includes('webp') ||
      type.includes('gif') ||
      type.includes('bmp')
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleLrrpDataChange = data => {
    setLrrpData(data);
  };

  function convertString(str) {
    return str
      .toLowerCase()
      .split('_')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  const confirmRemove = () => {
    setBinaryImage(null);
    formik.setFieldValue('logo', null);
    setBtnDisables(false);
    setShowConfirmation(false);
  };

  const cancelRemove = () => {
    setShowConfirmation(false);
  };

  const footerContent = (
    <div>
      <PFButton
        label="No"
        size="small"
        severity="primary"
        className="mx-2"
        outlined
        onClick={cancelRemove}
      />
      <PFButton
        label="Yes"
        size="small"
        severity="primary"
        className="mx-2"
        onClick={confirmRemove}
      />
    </div>
  );

  const allProjectCategory = categories => {
    const options =
      categories &&
      categories?.map(item => ({
        name: item.category,
        value: item.project_category_id,
      }));
    return options;
  };

  const categoryOptions = useMemo(
    () => allProjectCategory(categories),
    [categories]
  );
  useEffect(() => {
    if (formik?.values?.project_linkage?.options?.length > 0) {
      setForceSyncDisabled(false);
    } else {
      setForceSyncDisabled(true);
    }
  }, [formik?.values?.project_linkage?.options]);

  useEffect(() => {
    if (!formik?.values?.project_linkage?.enabled) {
      formik.setFieldValue('project_linkage', project_linkages);
    }
  }, [formik?.values?.project_linkage?.enabled]);

  useEffect(() => {
    if (forceSyncDisabled) {
      formik.setFieldValue('project_linkage.force_sync', false);
    }
  }, [forceSyncDisabled]);

  const featureColumnsData = [
    {
      field: 'label',
      header: 'Features',
      style: { width: '70%' },
    },
    {
      ...(!loading
        ? {
            body: rowData => {
              return (
                <PFCheckbox
                  inputId={`checkbox_${rowData.key}`}
                  onChange={e => {
                    handleCheckbox(rowData.key, e.checked);
                    setBtnDisables(false);
                  }}
                  checked={rowData.checked}
                />
              );
            },
          }
        : {}),
    },
  ];

  const featureTableLoader = useMemo(() => {
    return TableColumnsLoader(featureColumnsData, {
      rows: 4,
      isValue: false,
    });
  }, [featureColumnsData]);

  return (
    <div className="w-full">
      <Dialog
        header="Delete Confirmation"
        visible={showConfirmation}
        style={{ width: '50vw' }}
        onHide={() => setShowConfirmation(false)}
        footer={footerContent}
        draggable={false}
      >
        <p className="m-0">Are you sure you want to remove Logo?</p>
      </Dialog>
      <div className="flex justify-content-end">
        {pfClientsId === PF_ADMIN ? (
          <div className="grid w-12 flex justify-content-between align-items-center  overlay-x-hidden">
            <PageHeader
              pageTitle={`Edit ${clientName} Configuration`}
              breadCrumbArray={accessBreadcrumb}
            />
          </div>
        ) : null}
        <div className="ml-2">
          <PFButton
            label="Update"
            size="small"
            severity="primary"
            className="mx-2"
            onClick={formik.handleSubmit}
            disabled={
              btnDisables || (allocationOption && allocationPercentage === 0)
            }
          />
        </div>
      </div>
      <Toast ref={toast} />
      <div className="mt-4">
        <Accordion activeIndex={0}>
          <AccordionTab
            header="Company Details"
            pt={{
              headeraction: {
                className:
                  (formik.touched.client_name && formik.errors.client_name) ||
                  (formik.touched.contact_email &&
                    formik.errors.contact_email) ||
                  (formik.touched.contact_name && formik.errors.contact_name) ||
                  (formik.touched.client_timezone &&
                    formik.errors.client_timezone) ||
                  (formik.touched.admin_email && formik.errors.admin_email) ||
                  (formik.touched.personalized_private_project_number &&
                    formik.errors.personalized_private_project_number)
                    ? 'bg-red-500'
                    : '',
              },
            }}
          >
            <div className="grid">
              <div className="col-12 xl:col-3 ">
                {loading ? (
                  <Skeleton className="sm:w-4 lg:w-8 ml-6" height="14rem" />
                ) : (
                  <Image
                    src={
                      binaryImage ||
                      formik?.values?.logo ||
                      'https://placehold.jp/14/d1d5db/666/100x100.png?text=No Image'
                    }
                    alt="Tenant Logo"
                    preview={formik?.values?.logo ? true : false}
                    pt={{
                      image: {
                        className: 'sm:w-4 lg:w-8',
                      },
                      root: {
                        className: 'flex justify-content-center',
                      },
                    }}
                  />
                )}
                <div className="col-12 flex justify-content-center px-0">
                  {loading ? (
                    <>
                      <Skeleton className="sm:w-2 lg:w-5" height="40px" />
                      <Skeleton className="sm:w-2 lg:w-5 ml-2 " height="40px" />
                    </>
                  ) : (
                    <div className="grid my-2">
                      <div className="col-12  lg:col-6 mb-3 py-0 pl-0">
                        <PFButton
                          label="Remove"
                          className=" mx-2 w-full"
                          icon="pi pi-trash mr-1"
                          text
                          outlined
                          onClick={() => setShowConfirmation(true)}
                          disabled={formik?.values?.logo === null}
                        />
                      </div>
                      <div className="col-12 lg:col-6 mb-3 py-0 pr-0">
                        <FileUpload
                          ref={fileUploadRef}
                          mode="basic"
                          accept="image/jpg, image/png, image/jpeg, image/JFIF"
                          name="logo"
                          customUpload
                          auto
                          chooseLabel={'Choose'}
                          chooseOptions={{
                            icon: <i className="pi pi-upload mr-2"></i>,
                            label: null,
                          }}
                          onSelect={e => {
                            if (e?.files && e?.files[0]) {
                              if (ImageValidation(e.files[0].type)) {
                                setBinaryImage(e.files[0].objectURL);
                                formik.setFieldValue('logo', e.files[0]);
                                setBtnDisables(false);
                              } else {
                                toast.current.show({
                                  severity: 'error',
                                  summary: 'Error',
                                  detail:
                                    'Uploaded File Type is not supported. Please try with extension .jpg, .jpeg, .PNG, .JFIF',
                                  life: 3000,
                                });
                              }
                            }
                            fileUploadRef.current?.clear();
                          }}
                          pt={{
                            basicbutton: {
                              className: 'p-button-sm p-button-primary w-full ',
                            },
                            label: {
                              className: 'font-sm',
                            },
                            basicbutton: {
                              className: 'height-40',
                            },
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <div className="grid pl-3 mb-10">
                    <div className="col-12">
                      <Link
                        to={`/admin/company/business-hour-config/edit/${client_id || pfClientsId}`}
                        target="_blank"
                        className="no-underline text-700 hover:text-blue-700"
                      >
                        <span className="p-float-label flex align-items-center gap-2">
                          <i className="pi pi-clock"></i>
                          <span>Business Hours</span>
                        </span>
                      </Link>
                    </div>
                    {!!formik?.values?.cx_scheduling_module && (
                      <div className="col-12">
                        <Link
                          to={`/admin/company/cx-scheduling-config/edit/${
                            client_id || pfClientsId
                          }`}
                          target="_blank"
                          className="no-underline text-700 hover:text-blue-700"
                        >
                          <span className="p-float-label flex align-items-center gap-2">
                            <i className="pi pi-clock"></i>
                            <span>Customer Scheduling</span>
                          </span>
                        </Link>
                      </div>
                    )}
                    <div className="col-12">
                      <Link
                        to={`/admin/company/service-time-config/edit/${client_id || pfClientsId}`}
                        target="_blank"
                        className="no-underline text-700 hover:text-blue-700"
                      >
                        <span className="p-float-label flex align-items-center gap-2">
                          <i className="pi pi-clock"></i>
                          <span>Service Time</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 xl:col-9">
                <div className="grid mb-2">
                  <div className="col-12 md:col-4 lg:col-4 mb-3  py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label  ">
                        <PFInputText
                          id="client_id"
                          className="w-12"
                          value={formik.values.client_id}
                          disabled
                        />
                        <label htmlFor="tenant_id">Client ID</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-8 lg:col-8 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label ">
                        <PFInputText
                          id="client_name"
                          className="w-12"
                          value={
                            formik.values.client_name ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'client_name',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="tenant_name">Client Name</label>
                      </span>
                    )}
                    {formik.touched.client_name && formik.errors.client_name ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.client_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="grid  mb-2">
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label   ">
                        <InputMask
                          id="phone_number"
                          mask="(999) 999-9999"
                          className="w-12"
                          value={formik.values.phone_number || ''}
                          onChange={event => {
                            formik.setFieldValue(
                              'phone_number',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="phone_number">Phone Number</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="email"
                          className="w-12 h-5"
                          value={
                            formik.values.email ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue('email', event.target.value);
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="email">Email</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <InputMask
                          id="fax"
                          mask="999-999-9999"
                          className="w-12"
                          value={formik.values.fax || ''}
                          onChange={event => {
                            formik.setFieldValue('fax', event.target.value);
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="fax">Fax</label>
                      </span>
                    )}
                  </div>
                </div>
                <div className="grid mb-2">
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="contact_name"
                          className="w-12"
                          value={
                            formik.values.contact_name ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'contact_name',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="contact_name">Contact Name</label>
                      </span>
                    )}
                    {formik.touched.contact_name &&
                    formik.errors.contact_name ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.contact_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="contact_email"
                          className="w-12"
                          value={
                            formik.values.contact_email ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'contact_email',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="contact_email">Contact Email</label>
                      </span>
                    )}
                    {formik.touched.contact_email &&
                    formik.errors.contact_email ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.contact_email}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <InputMask
                          id="contact_phone_number"
                          className="w-12"
                          mask="(999) 999-9999"
                          value={formik.values.contact_phone_number || ''}
                          onChange={event => {
                            formik.setFieldValue(
                              'contact_phone_number',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="contact_phone_number">
                          Contact Phone Number
                        </label>
                      </span>
                    )}
                  </div>
                </div>
                <div className="grid ">
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        {gMapFieldLayout({
                          disabled: pfClientsId !== PF_ADMIN,
                        })}
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="address1"
                          className="w-12"
                          value={
                            formik.values.address1 ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'address1',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="address1">Address 1</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="address2"
                          className="w-12"
                          value={
                            formik.values.address2 ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'address2',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="address2">Address 2</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="city"
                          className="w-12"
                          value={
                            formik.values.city ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue('city', event.target.value);
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />

                        <label htmlFor="city">City</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="state"
                          className="w-12"
                          value={
                            formik.values.state ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue('state', event.target.value);
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="state">State</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="zipcode"
                          className="w-12"
                          value={
                            formik.values.zipcode ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue('zipcode', event.target.value);
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="zipcode">ZipCode</label>
                        <label htmlFor="zipcode">ZipCode</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="latitude"
                          className="w-12"
                          type="number"
                          value={formik.values?.latitude || ''}
                          onChange={event => {
                            formik.setFieldValue(
                              'latitude',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                        />
                        <label htmlFor="latitude">Latitude</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="longitude"
                          className="w-12"
                          type="number"
                          value={formik.values?.longitude || ''}
                          onChange={event => {
                            formik.setFieldValue(
                              'longitude',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                        />
                        <label htmlFor="longitude">Longitude</label>
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFDropdown
                          id="client_timezone"
                          name="client_timezone"
                          value={
                            formik.values.client_timezone ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          options={timeZones}
                          optionValue="value"
                          onChange={(event, value) => {
                            formik.setFieldValue(event, value);
                            <span className="p-float-label">
                              <PFDropdown
                                id="client_timezone"
                                name="client_timezone"
                                value={
                                  formik.values.client_timezone ||
                                  (pfClientsId !== PF_ADMIN
                                    ? 'Not Available'
                                    : '')
                                }
                                options={timeZones}
                                optionValue="value"
                                onChange={(event, value) => (
                                  formik.setFieldValue(event, value),
                                  setBtnDisables(false)
                                )}
                                optionLabel="label"
                                placeholder="Select a Time Zone"
                                disabled={pfClientsId !== PF_ADMIN}
                              />
                              <label htmlFor="client_timezone">Timezone</label>
                            </span>;
                          }}
                          optionLabel="label"
                          placeholder="Select a Time Zone"
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="client_timezone">Timezone</label>
                      </span>
                    )}
                    {formik.touched.client_timezone &&
                    formik.errors.client_timezone ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.client_timezone}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="quickbooks_refresh_token"
                          className="w-12"
                          value={
                            formik.values.quickbooks_refresh_token ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'quickbooks_refresh_token',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="quickbooks_refresh_token">
                          QuickBooks Refresh Token
                        </label>
                      </span>
                    )}
                  </div>

                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="installer_number_lowes"
                          className="w-12"
                          value={
                            formik.values.installer_number_lowes ||
                            (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'installer_number_lowes',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                          maxLength={10}
                          disabled={pfClientsId !== PF_ADMIN}
                        />
                        <label htmlFor="installer_number_lowes">
                          Lowes Vendor number
                        </label>
                      </span>
                    )}
                  </div>

                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    {loading ? (
                      <Skeleton className="w-full" height="40px" />
                    ) : (
                      <span className="p-float-label">
                        <PFInputText
                          id="admin_email"
                          className="w-12"
                          value={formik.values.admin_email}
                          onChange={event => {
                            formik.setFieldValue(
                              'admin_email',
                              event.target.value
                            );
                            setBtnDisables(false);
                          }}
                        />
                        <label htmlFor="installer_number_lowes">
                          CX Support Email
                        </label>
                      </span>
                    )}
                    {formik.touched.admin_email && formik.errors.admin_email ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.admin_email}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="grid w-full mx-0 py-0">
                      <div className="col-3 md:col-2 xl:col-1 mb-3 pb-0 pl-0">
                        <PFCheckbox
                          id="is_personalized_private_project"
                          name="is_personalized_private_project"
                          onChange={event => {
                            formik.setFieldValue(
                              'is_personalized_private_project',
                              event?.checked ? 1 : 0
                            );
                            if (!event.checked) {
                              formik.setFieldValue(
                                'personalized_private_project_number',
                                responseData?.personalized_private_project_number >
                                  0
                                  ? responseData?.personalized_private_project_number
                                  : '0'
                              );
                            }

                            setBtnDisables(false);
                          }}
                          checked={
                            formik?.values?.is_personalized_private_project ===
                            1
                          }
                          disabled={
                            responseData?.is_personalized_private_project > 0
                          }
                        />
                      </div>
                      <div className="col-9 md:col-10 xl:col-11 mb-3 py-0 pr-0">
                        <span className="p-float-label">
                          <InputNumber
                            id="personalized_private_project_number"
                            className="w-12"
                            value={
                              formik?.values
                                ?.personalized_private_project_number
                            }
                            onValueChange={e => {
                              formik.setFieldValue(
                                'personalized_private_project_number',
                                e?.value ? Number(e?.value) : ''
                              );
                              setBtnDisables(false);
                            }}
                            onBlur={formik.handleChange}
                            placeholder="Personalized Private Project #"
                            min={3}
                            maxLength={7}
                            disabled={
                              responseData?.personalized_private_project_number >
                                0 ||
                              responseData?.is_personalized_private_project >
                                0 ||
                              !formik?.values?.is_personalized_private_project
                            }
                          />
                          <label htmlFor="personalized_private_project_number">
                            Personalized Private Project #
                          </label>
                        </span>
                        {formik?.values?.is_personalized_private_project &&
                        formik.touched.personalized_private_project_number &&
                        formik.errors.personalized_private_project_number ? (
                          <div className="w-full text-red-500 text-xs">
                            {formik.errors.personalized_private_project_number}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>

          {hasOtherSourceSystemIds && (
            <AccordionTab header="Lowes EPA Details">
              <LrrpConfiguration
                onDataChange={handleLrrpDataChange}
                formik={formik}
                responseData={responseData}
                setBtnDisables={setBtnDisables}
              />
            </AccordionTab>
          )}

          {hasSourceSystemIdTwo && (
            <AccordionTab header="Home Depot EPA Details">
              <EditHomeDepotConfig
                formik={formik}
                setBtnDisables={setBtnDisables}
              />
            </AccordionTab>
          )}

          {pfClientsId === PF_ADMIN && (
            <AccordionTab header="Module">
              <div className="grid">
                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="route_optimization"
                    name="route_optimization"
                    checked={formik.values.routing_module == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'routing_module',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="route_optimization" className="ml-3">
                    Route Optimization
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="crm"
                    name="crm"
                    checked={formik.values.crm_module == 1}
                    onChange={event => {
                      formik.setFieldValue('crm_module', event.checked ? 1 : 0);
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="crm" className="ml-3">
                    CRM
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="warranty"
                    name="warranty"
                    checked={formik.values.warranty_module == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'warranty_module',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="warranty" className="ml-3">
                    Warranty
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="floorsoft_opted"
                    name="floorsoft_opted"
                    checked={formik.values.floorsoft_opted == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'floorsoft_opted',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="floorsoft_opted" className="ml-3">
                    FloorSoft
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="is_measuresquare"
                    name="is_measuresquare"
                    checked={formik.values.is_measuresquare == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'is_measuresquare',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="is_measuresquare" className="ml-3">
                    Measure Square
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="create_project_private_lead"
                    name="create_project_private_lead"
                    checked={formik.values.create_project_private_lead == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'create_project_private_lead',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="create_project_private_lead" className="ml-3">
                    Project for private Lead
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="cx_scheduling_module"
                    name="cx_scheduling_module"
                    checked={formik.values.cx_scheduling_module == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'cx_scheduling_module',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="cx_scheduling_module" className="ml-3">
                    Customer Scheduling
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="is_customer_portal"
                    name="is_customer_portal"
                    checked={formik.values.is_customer_portal == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'is_customer_portal',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="is_customer_portal" className="ml-3">
                    Customer Portal
                  </label>
                </div>

                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="capture_live_location"
                    name="capture_live_location"
                    checked={formik.values.capture_live_location == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'capture_live_location',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label htmlFor="capture_live_location" className="ml-3">
                    Capture Technician Live Location
                  </label>
                </div>
                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="is_capture_job_distance_enabled"
                    name="is_capture_job_distance_enabled"
                    checked={formik.values.is_capture_job_distance_enabled == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'is_capture_job_distance_enabled',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                  />
                  <label
                    htmlFor="is_capture_job_distance_enabled"
                    className="ml-3"
                  >
                    Capture Jobs Distance
                  </label>
                </div>
                <div className="col-12 md:col-3 lg:col-3">
                  <PFCheckbox
                    id="personalized_emails"
                    name="personalized_emails"
                    checked={formik.values.personalized_emails == 1}
                    onChange={event => {
                      formik.setFieldValue(
                        'personalized_emails',
                        event.checked ? 1 : 0
                      );
                      setBtnDisables(false);
                    }}
                    className="pt-1"
                  ></PFCheckbox>
                  <label htmlFor="personalized_emails" className="ml-3">
                    Personalized Emails
                  </label>
                </div>
              </div>
              <div className="grid mt-2 flex align-items-center">
                <div className="col-6 md:col-3 lg:col-3 text-sm">Payments</div>
                <div className="col-6 md:col-9 lg:col-9 flex flex-start">
                  <PFButton
                    severity="primary"
                    label="Configure"
                    size="small"
                    onClick={() => window.open(`/payments-config`, '_blank')}
                  />
                </div>
                <div className="col-6 md:col-3 lg:col-3 text-sm">Audit Log</div>
                <div className="col-6 md:col-9 lg:col-9 flex flex-start">
                  <PFButton
                    severity="primary"
                    label="Configure"
                    size="small"
                    onClick={() => window.open(`/audit-log-reports`, '_blank')}
                  />
                </div>
              </div>
            </AccordionTab>
          )}
          <AccordionTab header="Additional Features">
            <AdditionalFeatures
              loading={loading}
              allocationPermission={allocationPermission}
              allocationOption={allocationOption}
              handleRadioButton={handleRadioButton}
              setAllocationPermission={setAllocationPermission}
              setBtnDisables={setBtnDisables}
              allocationPercentage={allocationPercentage}
              setAllocationPercentage={setAllocationPercentage}
              formik={formik}
              convertString={convertString}
              btnDisables={btnDisables}
              client_id={client_id}
              pfClientsId={pfClientsId}
              forceSyncDisabled={forceSyncDisabled}
              groupSourceOpt={groupSourceOpt}
              responseData={responseData}
              categoryOptions={categoryOptions}
              client={client}
              handleCheckbox={handleCheckbox}
              featureList={featureList}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
};

export default EditCompanyConfig;
