// **** External Utilities ****
import axios from 'axios';

import { CONFIG } from '../../../constants';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { savePushNotification, updateNotificationViewed } from '../../shared/Topbar/Notification/Notification.service';
import { Notifications } from '../../../constants/notification.constant';
import { REACT_APP_ENABLE_PUSH_NOTIFICATION } from '../../../constants/envConstants';

// Get Notes Data
export const getNotesData = async (
  projectId,
  searchQuery,
  setLoading,
  setNotesList
) => {
  try {
    let newQueryString;
    newQueryString =
      Object.entries(searchQuery)
        ?.filter(([prop, value]) => Boolean(value) || value === 0)
        .map(([key, value], index) => `${key}=${value}`)
        .join('&') || '';
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/notes?${newQueryString}`
    );
    if (response?.data?.items) {
      setNotesList(response?.data);
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Get Notes types
export const getNotesTypeOptions = async setTypeOptions => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/notes-category`
    );
    setTypeOptions(response.data);
  } catch (error) {
    return null;
  }
};

//Add Notes
export const addNotes = async (
  projectId,
  values,
  setLoading,
  setAlert,
  setReloadList,
  setIsOpen
) => {
  setIsOpen(false);
  try {
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/notes`,
      {
        note_category_id: values.type,
        note_text: values.note_text.trim() || null,
        follow_up_date: values.follow_up_date,
      }
    );
    if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true && [Notifications.BACKENDMAPPING.INSTALLER, Notifications.BACKENDMAPPING.STORE].includes(values.type)) {
      savePushNotification(localStorage.getItem('user_id'), localStorage.getItem('client_id'),
        values.type == Notifications.INSTALLER ? Notifications.INSTALLER : Notifications.STORE, projectId, response?.data?.project_note_id);
    };
    response.data && setReloadList(true);

    setAlert('success', 'Notes Added Successfully.', false, true);
  } catch (error) {
    setAlert('error', 'Error in add notes.', false, true);
  } finally {
    setLoading(false);
  }
};

export const updateNote = async (projectId, noteId, payload) => {
  try {
    const response = await axios.patch(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/notes/${noteId}`,
      payload
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteNoteById = async (projectId, noteId) => {
  try {
    const response = await axios.delete(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/notes/${noteId}`
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const changeNoteVisibilityStatus = async (action, projectId, noteId, noteCategoryId) => {
  try {
    const notificationType = {}
    notificationType[`${Notifications.BACKENDMAPPING.INSTALLER}`] = 1;
    notificationType[`${Notifications.BACKENDMAPPING.STORE}`] = 2;

    if (REACT_APP_ENABLE_PUSH_NOTIFICATION === true && notificationType[`${noteCategoryId}`]
      && notificationType[`${noteCategoryId}`] > 0) {
      const type = noteCategoryId === Notifications.BACKENDMAPPING.STORE ? Notifications.STORE : Notifications.INSTALLER;
      let viewed = false;
      let reviewed = false;
      if (action === 'review') {
        reviewed = true
      } else {
        viewed = true
      }
      await updateNotificationViewed(noteId, type, localStorage.getItem('user_id'), viewed, reviewed)
    }

    return await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}/notes/${noteId}`,
      action === 'review' ? { reviewed: 1 } : { viewed: 1 }
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
