export const base64ToFile = (base64String: string, fileName: string): File => {
  const [mimeTypePart, base64Data] = base64String.split(',');
  const mimeType = mimeTypePart.split(':')[1].split(';')[0];
  const byteString = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: mimeType });
  const file = new File([blob], fileName, { type: mimeType });
  return file;
};
