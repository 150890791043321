import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getServiceTimeConfig = async (clientId, urlParams) => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.SERVICE_TIME.endPoints.getServiceTimeConfig,
      clientId
    ),
    urlParams,
    URL_CONSTANTS.SERVICE_TIME.baseUrl
  );
};
export const addServiceTimeById = async (clientId, configId, payload) => {
  return await ApiService.patchWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.SERVICE_TIME.endPoints.addServiceTimeById,
      clientId,
      configId
    ),
    payload,
    URL_CONSTANTS.SERVICE_TIME.baseUrl
  );
};
export const addBulkServiceTime = async (clientId, payload) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.SERVICE_TIME.endPoints.addServiceTimeByFilter,
      clientId
    ),
    payload,
    URL_CONSTANTS.SERVICE_TIME.baseUrl
  );
};
export const getProjectTypes = async () => {
  return await ApiService.get(`/projects/master/project-type`);
};
export const getProjectCategories = async client_id => {
  const loggedInClientId = localStorage.getItem('client_id');
  if (client_id != loggedInClientId) {
    return await ApiService.get(
      `/projects/master/project-category?req_from=${loggedInClientId}&client_id=${client_id}`
    );
  }
  return await ApiService.get(`/projects/master/project-category`);
};

export const getProjectStatusList = async () => {
  try {
    const response = await ApiService.get(`/system/status?statusType=Project`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
