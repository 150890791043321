import apiService from '../../../../services/api.service';
import {
  CreatePayload,
  UpdatePayload,
} from '../Types/order-validation-config.types';

export const getOrderValidations = async (client_id: string) => {
  try {
    const response = await apiService.get(
      `/admin-config/order-validation/client/${client_id}`
    );
    return { data: response.data.rows, count: response.data.totalCount };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addOrderValidations = async (
  client_id: string,
  payload: CreatePayload
) => {
  try {
    const response = await apiService.post(
      `/admin-config/order-validation/client/${client_id}/create`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateOrderValidations = async (
  client_id: string,
  payload: UpdatePayload,
  order_validation_id: number
) => {
  try {
    const response = await apiService.patch(
      `/admin-config/order-validation/client/${client_id}/${order_validation_id}`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteOrderValidations = async (
  client_id: string,
  order_validation_id: number
) => {
  try {
    const response = await apiService.delete(
      `/admin-config/order-validation/client/${client_id}/${order_validation_id}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getMatchedOrderConfigData = async (
  client_id: string,
  type_id: number,
  category_id: number
) => {
  try {
    const response = await apiService.get(
      `/admin-config/order-validation/client/${client_id}/get-photo-count?type_id=${type_id}&category_id=${category_id}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const deleteOrderValidationsByTypeId = async (
  client_id: string,
  projectTypeId: number
) => {
  try {
    const response = await apiService.delete(
      `/admin-config/order-validation/client/${client_id}/bulk-delete/${projectTypeId}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};
