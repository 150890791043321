import '../css/index.css';
import '../css/Calendar.css';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TimelineViews,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  DragAndDrop,
  Day,
  Week,
  Month,
  TimelineMonth,
  TimelineYear,
  Resize,
} from '@syncfusion/ej2-react-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import { ConfirmDialog } from 'primereact/confirmdialog';

import {
  ALL_DAYS,
  DEFAULT_DISABLED_TIME_SCALE,
  DEFAULT_WORK_DAYS,
  FULL_CALENDAR_GROUPS,
  WORK_HOURS,
} from '../constants/constants';
import { checkUrlString, momentTz } from '../../../utils/Helpers';
import { PROJECT_REPORT, CLIENT_DETAILS } from '../../../constants';
import { setInstallerViewCalendar } from '../../../redux/slices/installer-view-calendar.slice';
import { REACT_APP_SYNCFUSION_LICENCE_KEY } from '../../../constants/envConstants';
import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import JobDistanceOverlay from '../components/JobDistanceOverlay';
import {
  getCalendarProjectData,
  getCalendarResourceData,
} from '../services/calendar.service';
import {
  getDefaultView,
  getPeriod,
  selectedRapidSlotSyncFusion,
  getCalendarDefaultView,
  projectRecordData,
  blockTimeOffRecordData,
} from '../helpers/scheduler-calendar.helper';
import PFBlockUI from '../../shared/PFPrime/PFBlockUI';
import { validateReschedule } from '../../../utils/reschedule.helper';

import ProjectScheduleCard from './ProjectScheduleCard';
import ResourceTemplate from './ResourceTemplate';
import InstallerViewBlockCalendar from './InstallerViewBlockCalendar';
import ScheduleDropDialog from './ScheduleDropDialog';
import EventToolTipContent from './EventToolTipContent';
import RescheduleJobDialog from './RescheduleJobDialog';
import ConfirmationSchedulingDialog from './ConfirmSchedulingDialog';

registerLicense(`${REACT_APP_SYNCFUSION_LICENCE_KEY}`);

const NEW_EVENT_BUTTON = {
  id: 'newEventScheduler',
  align: 'Left',
  prefixIcon: 'e-plus',
  text: 'New Event',
  cssClass: 'e-active-view newEventScheduler',
};
const SEPARATOR_ICON = {
  id: 'separatorScheduler',
  align: 'Left',
  type: 'Separator',
  cssClass: 'e-schedule-seperator',
};
const REFRESH_ICON = {
  id: 'refreshIconScheduler',
  align: 'Right',
  prefixIcon: '',
  text: '',
  tooltipText: 'Refresh',
  cssClass:
    'material-icons-sharp md-28 md-refresh hover:text-xl px-2 refreshIconScheduler',
};
const FULLSCREEN_ICON = {
  id: 'fullscreenIconScheduler',
  align: 'Right',
  prefixIcon: '',
  text: '',
  tooltipText: 'Fullscreen',
  cssClass:
    'material-icons-sharp md-28 md-fullscreen hover:text-xl px-2 fullscreenIconScheduler',
};
const SchedulerCalendar = ({
  calendarRef,
  targetID,
  filter,
  data: parentData,
  loader,
  handleSetData,
  handleSetFilter,
  handleSetLoader,
  view,
  calendarData,
  setCalendarData,
}) => {
  const dispatch = useDispatch();
  const { isWeekendsVisible } = useSelector(state => state.newScheduler);
  const [defaultView, setDefaultView] = useState(
    view === PROJECT_REPORT.FULL_CALENDAR
      ? getCalendarDefaultView()
      : getDefaultView() || 'TimelineDay'
  );
  const [isNavigate, setIsNavigate] = useState(false);
  const [infoData, setInfoData] = useState();
  const [dropTimeOffRequestIds, setDropTimeOffRequestIds] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const toast = useRef(null);
  const visibleInstallerIds = useRef(null);
  // const blockTimePopover = useRef(null);
  // Reschedule
  const [isOpen, setIsOpen] = useState(false);
  const [refreshButton, setRefreshButton] = useState(false);
  const [rescheduleDialogData, setRescheduleDialogData] = useState({
    isRescheduleDialogOpen: false,
    rescheduleDropInfo: {},
  });
  const [dialogProps, setDialogProps] = useState({
    visible: false,
    props: {},
  });
  const currentPath = window?.location?.href;
  const isScheduler = checkUrlString(currentPath, '/scheduler-beta');
  const jobDistancePermission = CLIENT_DETAILS?.is_capture_job_distance_enabled;
  const handleSetCalendarData = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setCalendarData({
        ...calendarData,
        ...params,
      });
    }
  };

  const callGetCalendarDataApi = async (
    doLoadResource,
    localFilters = {},
    isHideShowBlockUI = false
  ) => {
    let tempCalendarData = {};

    try {
      const params = {
        ...calendarData.calendarFilter,
        ...filter.calendarFilter,
        date: calendarRef?.current?.selectedDate
          ? calendarRef.current.selectedDate
          : momentTz().format(),
        period: calendarRef?.current?.currentView
          ? getPeriod(calendarRef.current.currentView)
          : getPeriod(defaultView),
        ...(view == PROJECT_REPORT.FULL_CALENDAR
          ? { isFromCalendar: true }
          : {}),
        ...(localFilters || {}),
      };
      if (!isHideShowBlockUI) {
        doLoadResource &&
          handleSetCalendarData({
            calendarBlock: true,
          });
      }

      if (view !== PROJECT_REPORT.FULL_CALENDAR && loader?.calendarLoader) {
        const resourceApiResponse = await getCalendarResourceData(params);
        if (
          Array.isArray(resourceApiResponse?.data) &&
          resourceApiResponse.data.length
        ) {
          tempCalendarData.resourceData = resourceApiResponse.data;
          const installerIds = resourceApiResponse.data
            .slice(0, 20)
            .map(({ installerUserId }) => installerUserId);
          visibleInstallerIds.current = installerIds.join(',');
          params.installerIds = installerIds.join(',');
        } else {
          tempCalendarData.resourceData = [];
        }
      }
      const response = await getCalendarProjectData(params);
      const projectDetailsRecords = response[1]?.data;
      const timeOffRecords = response[0]?.data;
      if (
        Array.isArray(projectDetailsRecords) &&
        Array.isArray(timeOffRecords)
      ) {
        let eventDataLocal = [];
        if (
          Array.isArray(projectDetailsRecords) &&
          projectDetailsRecords.length
        ) {
          projectRecordData(projectDetailsRecords, eventDataLocal, view);
        }
        if (Array.isArray(timeOffRecords) && timeOffRecords.length) {
          blockTimeOffRecordData(timeOffRecords, eventDataLocal);
        }
        tempCalendarData.eventData = eventDataLocal;
      } else {
        tempCalendarData.eventData = [];
      }
    } catch (error) {
      console.log(error, '...error callGetCalendarDataApi');
    } finally {
      doLoadResource &&
        handleSetCalendarData({
          ...tempCalendarData,
          calendarBlock: false,
        });
    }
  };

  useEffect(() => {
    if (filter?.calendarFilter) {
      if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        !filter?.calendarFilter?.installerIds
      ) {
        handleSetCalendarData({
          eventData: [],
          resourceData: [],
        });
        handleSetLoader({ calendarLoader: false });
      } else {
        callGetCalendarDataApi(true).then(() => {
          handleSetLoader({ calendarLoader: false });
        });
      }
    }
  }, [filter?.calendarFilter, view]);

  useEffect(async () => {
    if (refreshButton) {
      await callGetCalendarDataApi(
        true,
        {
          installerIds: visibleInstallerIds.current,
        },
        true
      ).then(() => {
        handleSetLoader({ calendarLoader: false });
        setRefreshButton(false);
      });
    }
  }, [refreshButton]);

  const timelineEventTemplate = props => {
    let localProp = props?.eventData || {};
    if (
      props?.isTentativeTimeOff ||
      props?.isApprovedEventsAvailabilityTimeOff ||
      props?.isBlockedEvents
    ) {
      return (
        <div
          style={{
            background: props?.isBlockedEvents
              ? localProp?.backgroundColor
              : props?.isTentativeTimeOff
                ? localProp?.background
                : props?.isApprovedEventsAvailabilityTimeOff
                  ? localProp?.backgroundColor
                  : undefined,
            height: '100%',
          }}
          className="scheduler-event-parent flex justify-content-center  align-items-center  flex-wrap w-12 text-800"
          data-record-id={props?.recordId}
        >
          <div
            className="scheduler-event-child-type text-center p-m-2"
            style={{ whiteSpace: 'nowrap' }}
            data-record-id={props?.recordId}
          >
            {localProp?.eventType !== 'timeoff' &&
              localProp?.eventType !== 'blockedTime' &&
              localProp?.timeText}
          </div>
          <div
            className={`scheduler-event-child-text text-center p-m-2 block-time`}
            style={{ whiteSpace: 'nowrap' }}
            data-record-id={props?.recordId}
            data-pr-tooltip={`${localProp?.title}`}
            // onMouseEnter={e => blockTimePopover.current.show(e)}
            // onMouseLeave={e => blockTimePopover.current.hide(!e)}
          >
            {localProp?.title || ''}
          </div>
          {/* <OverlayPanel ref={blockTimePopover}>{localProp?.title}</OverlayPanel> */}
          <Tooltip target={`.block-time`}></Tooltip>
        </div>
      );
    }

    return (
      <div
        className="grid w-full grid-nogutter h-full"
        style={{
          background: localProp?.backgroundColor,
          color: localProp?.textColor,
        }}
        data-record-id={props?.recordId}
      >
        <div
          className={`w-12 p-1 event-${props?.recordId}`}
          data-record-id={props?.recordId}
        >
          {jobDistancePermission && isScheduler ? (
            <>
              <JobDistanceOverlay
                selectedRow={filter?.selectedRow}
                eventInfo={null}
                localProp={localProp}
              />
            </>
          ) : null}

          <div className="flex justify-content-end">
            <span>
              <EventToolTipContent
                eventInfo={localProp}
                time={localProp?.Time}
                view={view}
              />
            </span>
          </div>
          <ProjectScheduleCard
            key={`ProjectScheduleCard_${props?.recordId}`}
            eventInfo={localProp}
            schedulerCardView={parentData?.schedulerCardView}
            cxSchedulerIcon={parentData?.cxSchedulerIcon}
            period={
              calendarRef
                ? getPeriod(calendarRef?.current?.currentView)
                : getPeriod(defaultView)
            }
            view={view}
          />
        </div>
      </div>
    );
  };
  const resourceTemplate = resourceInfo => {
    return <ResourceTemplate resourceInfo={resourceInfo} />;
  };
  const handleNewEvent = () => {
    dispatch(
      setInstallerViewCalendar({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: new Date(),
          end: new Date(),
        },
      })
    );
  };
  const handleSelect = args => {
    if (!isOpen && args?.requestType === 'cellSelect' && args?.data) {
      const installerId = args?.data?.ResourceId;
      const { minutesDifference, startSlotTime } =
        selectedRapidSlotSyncFusion(args);
      dispatch(
        setInstallerViewCalendar({
          isSelectDialogOpen: true,
          newEventInfo: {
            start: args?.data?.StartTime,
            end: minutesDifference > 0 ? args?.data?.EndTime : startSlotTime,
          },
          initialInstaller: installerId,
        })
      );
    }
  };
  const handleEventClick = args => {
    args.cancel = true;
    if (args?.event?.isBlockedEvents) {
      dispatch(
        setInstallerViewCalendar({
          isSelectDialogOpen: true,
          newEventInfo: {
            start: args?.event?.StartTime,
            end: args?.event?.EndTime,
            timeOffRequestId: args?.event?.eventData?.timeOffRequestId,
            note: args?.event?.eventData?.title,
          },
          initialInstaller: args?.event?.ResourceId,
          mode: 'edit',
        })
      );
      return;
    }

    if (
      args?.event?.eventData?.project_id &&
      !args?.event?.eventData?.timeOffRequestId
    ) {
      window.open(
        `/project/view/${args?.event?.eventData?.project_id}`,
        '_blank'
      );
    }
  };
  const confirmScheduling = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This technician applied leave for this period. Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };

  const confirmBlockTimeWarning = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This slot is already blocked for the technician, Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };
  const add1Hour = input => {
    const inputDate = new Date(input);
    inputDate.setHours(inputDate.getHours() + 1);
    return inputDate;
  };
  const getInfoDateEnd = date => {
    let infoDateEnd;
    if (calendarRef?.current?.currentView === 'TimelineDay') {
      infoDateEnd = add1Hour(date);
    }
    return infoDateEnd;
  };
  const handleExternalDrop = async event => {
    try {
      const scheduleObj = calendarRef.current;
      if (scheduleObj && scheduleObj.element.contains(event.target)) {
        const cellData = scheduleObj.getCellDetails(event.target);
        if (
          cellData &&
          typeof cellData === 'object' &&
          'groupIndex' in cellData &&
          typeof cellData.groupIndex === 'number'
        ) {
          const resourceDetails = scheduleObj.getResourcesByIndex(
            cellData.groupIndex
          );
          const startTime = new Date(cellData.startTime);
          const endTime = new Date(cellData.endTime);
          const IsAllDay = !!cellData?.IsAllDay;
          const dragData = JSON.parse(event.dataTransfer.getData('eventData'));
          let showWarningMessage = false;
          let showBlockTimeWarning = false;
          const eventList = calendarData.eventData.filter(
            ({
              isTentativeTimeOff = false,
              isApprovedEventsAvailabilityTimeOff = false,
            }) => isTentativeTimeOff || isApprovedEventsAvailabilityTimeOff
          );
          for (let i = 0; i < eventList.length; i++) {
            const timeoff = eventList[i];
            if (
              (timeoff.ResourceId == resourceDetails.resourceData.id &&
                (momentTz(startTime).isBetween(
                  momentTz(timeoff.StartTime),
                  momentTz(timeoff.EndTime)
                ) ||
                  momentTz(endTime).isBetween(
                    momentTz(timeoff.StartTime),
                    momentTz(timeoff.EndTime)
                  ))) ||
              momentTz(startTime).isSame(momentTz(timeoff.StartTime)) ||
              momentTz(startTime).isSame(momentTz(timeoff.EndTime)) ||
              momentTz(endTime).isSame(momentTz(timeoff.EndTime)) ||
              momentTz(endTime).isSame(momentTz(timeoff.EndTime))
            ) {
              if (timeoff?.eventData?.rrule) {
                if (
                  momentTz(startTime).isBetween(
                    momentTz(timeoff.eventData.rrule?.dtstart),
                    momentTz(timeoff.eventData.rrule?.until)
                  ) ||
                  momentTz(startTime).isSame(
                    momentTz(timeoff.eventData.rrule?.dtstart)
                  ) ||
                  momentTz(startTime).isSame(
                    momentTz(timeoff.eventData.rrule?.until)
                  ) ||
                  momentTz(endTime).isBetween(
                    momentTz(timeoff.eventData.rrule?.dtstart),
                    momentTz(timeoff.eventData.rrule?.until)
                  ) ||
                  momentTz(endTime).isSame(
                    momentTz(timeoff.eventData.rrule?.dtstart)
                  ) ||
                  momentTz(endTime).isSame(
                    momentTz(timeoff.eventData.rrule?.until)
                  )
                ) {
                  if (
                    IsAllDay &&
                    (timeoff.eventData.rrule?.byweekday?.includes(
                      momentTz(startTime).format('dd').toLowerCase()
                    ) ||
                      timeoff.eventData.rrule?.byweekday?.includes(
                        momentTz(endTime).format('dd').toLowerCase()
                      ))
                  ) {
                    showWarningMessage = true;
                    break;
                  }
                  if (!IsAllDay) {
                    const newStartHour = momentTz(
                      timeoff.eventData.rrule.dtstart
                    ).get('hour');
                    const newStartMinute = momentTz(
                      timeoff.eventData.rrule.dtstart
                    ).get('minute');
                    const newEndHour = momentTz(
                      timeoff.eventData.rrule.until
                    ).get('hour');
                    const newEndMinute = momentTz(
                      timeoff.eventData.rrule.until
                    ).get('minute');
                    const newStartDate = momentTz(startTime).set({
                      hour: newStartHour,
                      minute: newStartMinute,
                    });
                    const newEndDate = momentTz(endTime).set({
                      hour: newEndHour,
                      minute: newEndMinute,
                    });
                    if (
                      (newStartDate < momentTz(startTime) &&
                        momentTz(endTime) < newEndDate) ||
                      (newStartDate < momentTz(startTime).add(1, 'hours') &&
                        momentTz(endTime).add(1, 'hours') < newEndDate)
                    ) {
                      showWarningMessage = true;
                      break;
                    }
                  }
                  if (IsAllDay && !timeoff.rrule?.byweekday.length) {
                    showWarningMessage = true;
                    break;
                  }
                }
              }
            }
          }
          const blockEventsOfDroppedInstaller = calendarData.eventData.filter(
            i =>
              i.isBlockedEvents &&
              i.ResourceId === parseInt(resourceDetails.resourceData.id)
          );
          const blockTimeRequests = [];
          for (const blockTimeEvent of blockEventsOfDroppedInstaller) {
            if (
              momentTz(startTime).isBetween(
                momentTz(new Date(blockTimeEvent?.StartTime)),
                momentTz(new Date(blockTimeEvent?.EndTime))
              ) ||
              momentTz(endTime).isBetween(
                momentTz(new Date(blockTimeEvent?.StartTime)),
                momentTz(new Date(blockTimeEvent?.EndTime))
              ) ||
              momentTz(getInfoDateEnd(startTime)).isBetween(
                momentTz(new Date(blockTimeEvent?.StartTime)),
                momentTz(new Date(blockTimeEvent?.EndTime))
              ) ||
              momentTz(getInfoDateEnd(endTime)).isBetween(
                momentTz(new Date(blockTimeEvent?.StartTime)),
                momentTz(new Date(blockTimeEvent?.EndTime))
              ) ||
              momentTz(startTime).isSame(
                momentTz(new Date(blockTimeEvent?.StartTime))
              ) ||
              momentTz(startTime).isSame(
                momentTz(new Date(blockTimeEvent?.EndTime))
              ) ||
              momentTz(endTime).isSame(
                momentTz(new Date(blockTimeEvent?.StartTime))
              ) ||
              momentTz(endTime).isSame(
                momentTz(new Date(blockTimeEvent?.EndTime))
              )
            ) {
              showBlockTimeWarning = true;
              blockTimeRequests.push(
                blockTimeEvent?.eventData?.timeOffRequestId
              );
            }
          }
          setDropTimeOffRequestIds(blockTimeRequests);
          if (showWarningMessage) {
            confirmScheduling({
              resourceDetails,
              rowData: dragData?.raw,
              startTime,
              endTime,
            });
          } else {
            if (showBlockTimeWarning) {
              confirmBlockTimeWarning({
                resourceDetails,
                rowData: dragData?.raw,
                startTime,
                endTime,
              });
            } else {
              dispatch(
                setNewSchedulerComponent({
                  dropDialogData: dragData?.raw,
                  dropInfo: {
                    start: startTime,
                    end: endTime,
                  },
                  isDropDialogOpen: true,
                  view: view,
                  droppedResourceDetails: { ...resourceDetails?.resourceData },
                })
              );

              const startDateObj = structuredClone(startTime);
              const endDateObj = structuredClone(endTime);
              switch (calendarRef?.current?.currentView) {
                case 'TimelineDay':
                  endDateObj.setMinutes(endDateObj.getMinutes() + 30);

                  break;
                case 'TimelineWeek':
                case 'TimelineMonth':
                  endDateObj.setDate(endDateObj.getDate());

                  break;
                default:
                  break;
              }
            }
          }
        } else if (
          view === PROJECT_REPORT.FULL_CALENDAR &&
          cellData?.startTime &&
          cellData?.endTime
        ) {
          const startTime = new Date(cellData.startTime);
          const endTime = new Date(cellData.endTime);
          const dragData = JSON.parse(event.dataTransfer.getData('eventData'));
          dispatch(
            setNewSchedulerComponent({
              dropDialogData: dragData?.raw,
              dropInfo: {
                start: startTime,
                end: endTime,
              },
              isDropDialogOpen: true,
              view: view,
            })
          );
        } else {
          let recordId = null;
          const parentElements = event.target.getElementsByClassName(
            'scheduler-event-parent'
          );
          if (parentElements && parentElements.length) {
            recordId = parentElements[0].getAttribute('data-record-id');
          } else {
            const childTypeElements = event.target.getElementsByClassName(
              'scheduler-event-child-type'
            );
            if (childTypeElements && childTypeElements.length) {
              recordId = childTypeElements[0].getAttribute('data-record-id');
            } else {
              const childTextElements = event.target.getElementsByClassName(
                'scheduler-event-child-text'
              );
              if (childTextElements && childTextElements.length) {
                recordId = childTextElements[0].getAttribute('data-record-id');
              } else {
                recordId = event.target.getAttribute('data-record-id');
              }
            }
          }
          if (recordId) {
            const eventData = calendarData.eventData.find(
              eachEvent => +eachEvent.recordId === +recordId
            );
            if (eventData) {
              const dragData = JSON.parse(
                event.dataTransfer.getData('eventData')
              );
              const startTime = new Date(eventData.StartTime);
              const endTime = new Date(eventData.EndTime);
              const resourceDetails = {
                resourceData: {
                  id: eventData.ResourceId,
                },
              };
              if (
                eventData?.isTentativeTimeOff ||
                eventData?.isApprovedEventsAvailabilityTimeOff
              ) {
                confirmScheduling({
                  resourceDetails,
                  rowData: dragData?.raw,
                  startTime,
                  endTime,
                });
              } else if (eventData?.isBlockedEvents) {
                setDropTimeOffRequestIds([
                  eventData?.eventData?.timeOffRequestId,
                ]);
                confirmBlockTimeWarning({
                  resourceDetails,
                  rowData: dragData?.raw,
                  startTime,
                  endTime,
                });
              } else if (
                view === PROJECT_REPORT.CALENDAR &&
                eventData?.ResourceId &&
                calendarData.resourceData.length
              ) {
                dispatch(
                  setNewSchedulerComponent({
                    dropDialogData: dragData?.raw,
                    dropInfo: {
                      start: startTime,
                      end: endTime,
                    },
                    isDropDialogOpen: true,
                    view: view,
                    droppedResourceDetails: calendarData.resourceData.find(
                      ({ id }) => id == eventData?.ResourceId
                    ),
                  })
                );

                const startDateObj = structuredClone(startTime);
                const endDateObj = structuredClone(endTime);
                switch (calendarRef?.current?.currentView) {
                  case 'TimelineDay':
                    endDateObj.setMinutes(endDateObj.getMinutes() + 30);

                    break;
                  case 'TimelineWeek':
                  case 'TimelineMonth':
                    endDateObj.setDate(endDateObj.getDate());

                    break;
                  default:
                    break;
                }
              } else {
                dispatch(
                  setNewSchedulerComponent({
                    dropDialogData: dragData?.raw,
                    dropInfo: {
                      start: startTime,
                      end: endTime,
                    },
                    isDropDialogOpen: true,
                    view: view,
                  })
                );
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error, 'on drop');
    }
  };

  const updateSchedule = info => {
    if (
      info?.currentDetails?.isBlockedEvents ||
      info?.currentDetails?.isTentativeTimeOff ||
      info?.currentDetails?.isApprovedEventsAvailabilityTimeOff
    ) {
      return;
    }
    setRescheduleDialogData({
      isRescheduleDialogOpen: true,
      rescheduleDropInfo: {
        startDateStr: info?.startDateTime,
        endDateStr: info?.endDateTime,
        currentDetails: info?.currentDetails,
      },
    });
  };
  const handleActionBegin = args => {
    if (args.requestType === 'eventChange') {
      handleWithinCalendarEventDragAndDrop(args);
    } else if (args.requestType === 'toolbarItemRendering') {
      args.items.unshift(SEPARATOR_ICON);
      view === PROJECT_REPORT.FULL_CALENDAR &&
        args.items.unshift(NEW_EVENT_BUTTON);
      args.items.push(REFRESH_ICON);
      args.items.push(FULLSCREEN_ICON);
    } else if (
      ['dateNavigate', 'viewNavigate'].includes(args.requestType) &&
      view === PROJECT_REPORT.FULL_CALENDAR
    ) {
      handleSetCalendarData({
        eventData: [],
        resourceData: [],
      });
    }
  };
  const handleActionComplete = async args => {
    const scheduleElement = document.getElementById(targetID);
    if (
      ['dateNavigate', 'viewNavigate'].includes(args.requestType) &&
      !isNavigate
    ) {
      setIsNavigate(true);
      setDefaultView(calendarRef?.current?.currentView);
      handleSetFilter({
        selectedDate: calendarRef?.current?.selectedDate || new Date(),
      });
      if (view != PROJECT_REPORT.FULL_CALENDAR) {
        const installerIds =
          calendarRef?.current?.resourceCollection?.[0]?.dataSource
            ?.slice(0, 20)
            .map(record => record.installerUserId)
            .join(',') || undefined;
        visibleInstallerIds.current = installerIds;
        await callGetCalendarDataApi(true, {
          installerIds: visibleInstallerIds.current,
        });
      } else if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        filter?.calendarFilter?.installerIds
      ) {
        await callGetCalendarDataApi(true);
      } else if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        !filter?.calendarFilter?.installerIds
      ) {
        handleSetCalendarData({
          eventData: [],
          resourceData: [],
          calendarBlock: false,
        });
      }
      // setIsNavigate(false);
    } else if (args.requestType === 'toolBarItemRendered' && scheduleElement) {
      const newEventElement =
        scheduleElement.querySelector('.newEventScheduler');
      if (newEventElement) newEventElement.onclick = () => handleNewEvent();
      const refreshIconElement = scheduleElement.querySelector(
        '.refreshIconScheduler'
      );
      if (refreshIconElement)
        refreshIconElement.onclick = () => calenderRefreshHandler();

      const fullscreenIconElement = scheduleElement.querySelector(
        '.fullscreenIconScheduler'
      );
      if (fullscreenIconElement) {
        fullscreenIconElement.onclick = () => {
          const fullscreenIconElementLocal = scheduleElement.querySelector(
            '.fullscreenIconScheduler'
          );
          if (fullscreenIconElementLocal.classList) {
            if (
              fullscreenIconElementLocal.classList.contains(
                'md-fullscreen_exit'
              )
            ) {
              fullscreenIconElementLocal.classList.remove('md-fullscreen_exit');
              fullscreenIconElementLocal.classList.add('md-fullscreen');
              fullscreenIconElementLocal.setAttribute('title', 'Fullscreen');
              handleSetData({ fullscreen: false, screenSize: '650px' });
            } else if (
              fullscreenIconElementLocal.classList.contains('md-fullscreen')
            ) {
              fullscreenIconElementLocal.classList.remove('md-fullscreen');
              fullscreenIconElementLocal.classList.add('md-fullscreen_exit');
              fullscreenIconElementLocal.setAttribute(
                'title',
                'Exit Fullscreen'
              );
              handleSetData({
                fullscreen: true,
                screenSize: `${window.innerHeight}px`,
              });
            }
          }
        };
      }
    }
  };
  const handleWithinCalendarEventDragAndDrop = args => {
    updateSchedule({
      startDateTime: args.data.StartTime,
      endDateTime: args.data.EndTime,
      currentDetails: { ...args.data },
    });
  };
  // Reschedule
  const calenderRefreshHandler = async () => {
    if (
      view === PROJECT_REPORT.FULL_CALENDAR &&
      !filter?.calendarFilter?.installerIds
    ) {
      return;
    }
    setRefreshButton(true);
    handleSetLoader({ calendarLoader: true });
  };

  const handleScroll = async options => {
    const selectedIds = options.resourceData.map(val => val.id);

    if (selectedIds.length) {
      const ids = [];
      selectedIds.forEach(installerId => {
        if (filter.installerMap?.[installerId]?.user_id) {
          ids.push(filter.installerMap[installerId].user_id);
        }
      });
      if (ids.length || filter?.calendarFilter?.installerIds) {
        let parsedIds = '';
        if (filter?.calendarFilter?.installerIds) {
          parsedIds = filter?.calendarFilter?.installerIds;
        } else {
          parsedIds = ids.join(',');
        }
        visibleInstallerIds.current = parsedIds;
        await callGetCalendarDataApi(
          true,
          {
            installerIds: parsedIds,
          },
          true
        );
      }
    }
  };

  const eventSettings = {
    dataSource: calendarData?.eventData,
    template: timelineEventTemplate,
    spannedEventPlacement: 'TimeSlot',
  };

  return (
    <div
      className={`w-full min-w-700 ${parentData?.fullscreen ? 'scheduler-calendar-parent calendar-view-fullscreen' : 'scheduler-calendar-inner mt-2'}`}
    >
      <PFBlockUI
        blocked={
          loader?.calendarLoader || calendarData?.calendarBlock || isNavigate
        }
      >
        <ScheduleComponent
          id={targetID}
          rowAutoHeight={true}
          ref={calendarRef}
          width="100%"
          {...(parentData.fullscreen
            ? { height: parentData.screenSize }
            : { height: '650px' })}
          currentView={defaultView}
          eventSettings={eventSettings}
          eventDragArea=".content-wrapper"
          cssClass="virtual-scrolling"
          actionComplete={handleActionComplete}
          eventClick={handleEventClick}
          eventDoubleClick={args => (args.cancel = true)}
          enableLazyLoading={true}
          enableVirtualization={true}
          allowVirtualScrolling={true}
          onDrop={handleExternalDrop}
          onDragOver={event => event.preventDefault()}
          showHeaderBar={true}
          actionBegin={handleActionBegin}
          readOnly={true}
          select={handleSelect}
          cellClick={args => {
            args.cancel = true;
          }}
          virtualScrollStop={handleScroll}
          timezone={localStorage.getItem('client_timezone')}
          dataBound={() => setIsNavigate(false)}
          selectedDate={calendarData?.selectedDate}
          workHours={WORK_HOURS}
          startHour="06:00"
          endHour="20:59"
        >
          {view === PROJECT_REPORT.FULL_CALENDAR ? (
            <ViewsDirective>
              <ViewDirective
                option="Day"
                showWeekend={isWeekendsVisible}
                workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
              />
              <ViewDirective
                option="Week"
                timeScale={DEFAULT_DISABLED_TIME_SCALE}
                showWeekend={isWeekendsVisible}
                workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
              />
              <ViewDirective
                option="Month"
                showWeekend={isWeekendsVisible}
                workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
              />
            </ViewsDirective>
          ) : (
            <ViewsDirective>
              <ViewDirective
                option="TimelineDay"
                displayName="Day"
                allowVirtualScrolling={true}
                enableLazyLoading={true}
                group={FULL_CALENDAR_GROUPS}
                resourceHeaderTemplate={resourceTemplate}
              />
              <ViewDirective
                option="TimelineWeek"
                displayName="Week"
                allowVirtualScrolling={true}
                enableLazyLoading={true}
                timeScale={DEFAULT_DISABLED_TIME_SCALE}
                group={FULL_CALENDAR_GROUPS}
                resourceHeaderTemplate={resourceTemplate}
              />
              <ViewDirective
                option="TimelineMonth"
                displayName="Month"
                allowVirtualScrolling={true}
                enableLazyLoading={true}
                group={FULL_CALENDAR_GROUPS}
                resourceHeaderTemplate={resourceTemplate}
              />
            </ViewsDirective>
          )}
          {view !== PROJECT_REPORT.FULL_CALENDAR ? (
            <ResourcesDirective>
              <ResourceDirective
                field="ResourceId"
                title="Technicians"
                name="Resources"
                // allowMultiple={true}
                dataSource={calendarData?.resourceData}
                textField="title"
                idField="id"
              ></ResourceDirective>
            </ResourcesDirective>
          ) : null}
          <Inject
            services={
              view === PROJECT_REPORT.FULL_CALENDAR
                ? [Day, Week, Month, DragAndDrop, Resize]
                : [
                    TimelineViews,
                    TimelineMonth,
                    TimelineYear,
                    DragAndDrop,
                    Resize,
                  ]
            }
          />
        </ScheduleComponent>
        <Toast ref={toast} />
        {/* Confirmation dialog for delete */}
        {confirmDialog && confirmDialog?.isOpen ? (
          <ConfirmationSchedulingDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            calenderRefreshHandler={calenderRefreshHandler}
            infoData={infoData}
            dropTimeOffRequestIds={dropTimeOffRequestIds}
            resourceData={calendarData.resourceData}
            setDialogProps={setDialogProps}
            setIsOpen={setIsOpen}
          />
        ) : null}

        <InstallerViewBlockCalendar
          calenderRefreshHandler={calenderRefreshHandler}
        />
        <ScheduleDropDialog
          calenderRefreshHandler={calenderRefreshHandler}
          reschedule={(body, cb) => {
            const technicianName = calendarData.resourceData.find(
              ({ id }) => id == body.installerId
            );
            body = {
              ...body,
              technician_name: technicianName?.title || '',
            };
            validateReschedule(
              body,
              setDialogProps,
              value =>
                dispatch(
                  setNewSchedulerComponent({
                    isDropDialogOpen: value,
                  })
                ),
              cb
            );
          }}
          parentData={parentData}
        />
        {/* <ProjectEditDialog calenderRefreshHandler={calenderRefreshHandler} /> */}
        {dialogProps.visible && (
          <ConfirmDialog visible={dialogProps.visible} {...dialogProps.props} />
        )}
        {rescheduleDialogData?.isRescheduleDialogOpen ? (
          <RescheduleJobDialog
            calenderRefreshHandler={calenderRefreshHandler}
            rescheduleDialogData={rescheduleDialogData}
            setRescheduleDialogData={setRescheduleDialogData}
            toast={toast}
            resourceData={calendarData.resourceData}
            setDialogProps={setDialogProps}
            setIsOpen={setIsOpen}
          />
        ) : null}
      </PFBlockUI>
    </div>
  );
};
export default SchedulerCalendar;
