import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import {
  addSlotInDay,
  deleteSlotBySlotId,
  getSlotsByDay,
  mergeSlotsByDay,
  updateSlotBySlotId,
} from '../service/CxSchedulingConfigService';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import { validateSlotForm } from '../../../../utils/validation';
import PFBlockUI from '../../../shared/PFPrime/PFBlockUI';

const AddEditCxSchedulingSlotDialog = ({
  clientId,
  isVisible,
  setIsVisible,
  slotDetail,
  workHours,
  // setCxSchedulingSlots,
}) => {
  if (!clientId) return <></>;
  const [loading, setLoading] = useState(false);
  const [mergeDialogVisible, setMergeDialogVisible] = useState(false);
  const [currentSlots, setCurrentSlot] = useState(slotDetail);
  const [slots, setSlots] = useState([]);
  const toast = useRef(null);
  const validation = Yup.object().shape({
    slot_name: Yup.string().nullable(true),
    start_time: Yup.date().nullable(true),
    end_time: Yup.date().nullable(true),
  });

  const handleSubmit = async values => {
    try {
      if (!validateSlotForm(formik, slots, workHours, currentSlots)) {
        return;
      }
      const payload = {
        slot_name: values.slot_name,
        start_time: dayjs(values.start_time).format('HH:mm:00'),
        end_time: dayjs(values.end_time).format('HH:mm:00'),
      };
      setLoading(true);
      const apiResponse = await (currentSlots?.slot_id
        ? updateSlotBySlotId(currentSlots?.slot_id, payload)
        : addSlotInDay(clientId, currentSlots?.day, payload));
      if (apiResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: apiResponse?.message || 'Record added Successfully',
          life: 1500,
        });
        /* if (!currentSlots?.slot_id) {
          setCxSchedulingSlots(prev => {
            const updatedCxSchedulingSlots = [];
            prev.forEach(eachDay => {
              if (eachDay.day === currentSlots.day) {
                updatedCxSchedulingSlots.push({
                  ...eachDay,
                  slots_count: eachDay.slots_count + 1,
                });
              } else {
                updatedCxSchedulingSlots.push(eachDay);
              }
            });
            return updatedCxSchedulingSlots;
          });
        } */
        formik.resetForm();
        setCurrentSlot(slotDetail);
        await callGetSlotsByDay();
      } else {
        toast.current.show({
          severity: 'error',
          summary:
            apiResponse.message || 'Error occurred while updating record',
          life: 3000,
        });
      }
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: currentSlots,
    validationSchema: validation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const handleHide = () => {
    setIsVisible(false);
  };
  const handleEdit = data => {
    setCurrentSlot({
      ...data,
      start_time: new Date(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${data.start_time}`
      ),
      end_time: new Date(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${data.end_time}`
      ),
    });
  };
  const handleDelete = async data => {
    setLoading(true);
    try {
      const apiResponse = await deleteSlotBySlotId(data?.slot_id);
      if (apiResponse?.status) {
        const remainingSlots = slots.filter(
          each => each.slot_id != data.slot_id
        );
        setSlots(remainingSlots);
        /* setCxSchedulingSlots(prev => {
          const updatedCxSchedulingSlots = [];
          prev.forEach(eachDay => {
            if (eachDay.day === data.day) {
              updatedCxSchedulingSlots.push({
                ...eachDay,
                slots_count: remainingSlots.length,
              });
            } else {
              updatedCxSchedulingSlots.push(eachDay);
            }
          });
          return updatedCxSchedulingSlots;
        }); */
        if (currentSlots?.slot_id === data.slot_id) {
          setCurrentSlot(slotDetail);
        }
        toast.current.show({
          severity: 'success',
          summary: apiResponse?.message || 'Record deleted Successfully',
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary:
            apiResponse.message || 'Error occurred while deleting record',
          life: 1500,
        });
      }
    } catch (ex) {
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const callGetSlotsByDay = async () => {
    setLoading(true);
    try {
      const apiResponse = await getSlotsByDay(clientId, currentSlots.day);
      if (
        apiResponse?.status &&
        Array.isArray(apiResponse?.data) &&
        apiResponse.data.length
      ) {
        setSlots(apiResponse?.data);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientId && currentSlots?.day) {
      callGetSlotsByDay();
    }
  }, [clientId, currentSlots?.day]);

  const actionTemplate = slot => {
    return (
      <div className="flex justify-content-left align-items-center">
        {loading ? (
          <Skeleton className="w-full" height="2rem" />
        ) : (
          <>
            <PFButton
              severity=" "
              icon="pi pi-pencil"
              size="small"
              tooltip="Edit"
              tooltipOptions={{ position: 'top' }}
              rounded={true}
              text={true}
              onClick={() => handleEdit(slot)}
            />
            <PFButton
              severity=" "
              icon="pi pi-trash"
              size="small"
              tooltip="Remove"
              tooltipOptions={{ position: 'top' }}
              className="text-red-700"
              rounded={true}
              text={true}
              onClick={() => handleDelete(slot)}
            />
          </>
        )}
      </div>
    );
  };
  const startTimeTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : slot?.start_time ? (
      dayjs(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${slot?.start_time}`
      ).format('hh:mm A')
    ) : (
      ''
    );
  };
  const slotNameTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : (
      slot?.slot_name
    );
  };
  const endTimeTemplate = slot => {
    return loading ? (
      <Skeleton className="w-full" height="2rem" />
    ) : slot?.end_time ? (
      dayjs(
        `${dayjs(new Date()).format('MM-DD-YYYY')} ${slot?.end_time}`
      ).format('hh:mm A')
    ) : (
      ''
    );
  };
  const handleMerge = async () => {
    try {
      setLoading(true);
      const apiResponse = await mergeSlotsByDay(clientId, currentSlots.day);
      console.log(apiResponse.data, '...apiResponse.data');
      if (apiResponse?.status && Array.isArray(apiResponse.data)) {
        setSlots(apiResponse.data);
        toast.current.show({
          severity: 'success',
          summary: apiResponse?.message || 'Record merged Successfully',
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: apiResponse.message || 'Error occurred while merging record',
          life: 3000,
        });
      }
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something Went Wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      header={`${currentSlots?.slot_id ? 'Edit' : 'Add'} Customer Scheduling Slot`}
      visible={isVisible}
      onHide={handleHide}
      style={{
        overflow: 'auto',
      }}
      className="w-10 lg:w-8"
    >
      <Toast ref={toast} />
      <PFBlockUI blocked={loading}>
        <div className="w-full flex">
          <div className="w-full flex flex-column gap-3 flex-wrap mt-2 max-w-35rem">
            <div className="w-full p-float-label flex flex-column">
              <PFInputText
                className="w-full"
                id="day"
                name="day"
                value={currentSlots?.day}
                disabled
              />
              <label htmlFor="day">Day</label>
            </div>
            <div className="w-full p-float-label flex flex-column">
              <PFInputText
                autoComplete="off"
                id="slot_name"
                name="slot_name"
                value={formik.values?.slot_name || ''}
                onChange={e =>
                  formik.setFieldValue('slot_name', e.target.value)
                }
                // onBlur={() => validateInput('slot_name', formik.values.slot_name)}
                className={`w-full ${formik.errors.slot_name ? 'border-red-300' : ''}`}
                aria-describedby="slot_name_error"
              />
              <label htmlFor="slot_name">
                Slot Name<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.slot_name && (
                <span
                  id="slot_name_error"
                  className="text-red-400 text-xs pl-2"
                >
                  {formik.errors.slot_name}
                </span>
              )}
            </div>
            <div className="w-full p-float-label flex flex-column">
              <Calendar
                id="start_time"
                name="start_time"
                timeOnly
                showTime
                hourFormat="12"
                value={formik.values?.start_time}
                onChange={e => formik.setFieldValue('start_time', e.value)}
                // onBlur={() => validateInput('start_time', formik.values.start_time)}
                aria-describedby="start_time_error"
                className="w-full"
                inputClassName={
                  formik.errors.start_time ? 'border-red-300' : ''
                }
              />
              <label htmlFor="start_time">
                Start Time<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.start_time && (
                <span
                  id="start_time_error"
                  className="text-red-400 text-xs pl-2"
                >
                  {formik.errors.start_time}
                </span>
              )}
            </div>
            <div className="w-full p-float-label flex flex-column">
              <Calendar
                id="end_time"
                name="end_time"
                timeOnly
                showTime
                hourFormat="12"
                value={formik.values?.end_time}
                onChange={e => formik.setFieldValue('end_time', e.value)}
                // onBlur={() => validateInput('end_time', formik.values.end_time)}
                aria-describedby="end_time_error"
                className="w-full"
                inputClassName={formik.errors.end_time ? 'border-red-300' : ''}
              />
              <label htmlFor="end_time">
                End Time<span style={{ color: 'red' }}>*</span>
              </label>
              {formik.errors.end_time && (
                <span id="end_time_error" className="text-red-400 text-xs pl-2">
                  {formik.errors.end_time}
                </span>
              )}
            </div>
            {formik.errors.exist && (
              <span id="exist_error" className="w-full text-red-400 text-xs">
                {formik.errors.exist}
              </span>
            )}
            <div className="w-full flex justify-content-end gap-3">
              {currentSlots?.slot_id && (
                <PFButton
                  label="Cancel"
                  size="small"
                  outlined
                  onClick={() => setCurrentSlot(slotDetail)}
                  disabled={loading}
                />
              )}
              <PFButton
                label="Save"
                size="small"
                onClick={formik.handleSubmit}
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  loading ||
                  !formik.values.slot_name ||
                  !formik.values.start_time ||
                  !formik.values.end_time
                }
              />
            </div>
          </div>
          <Divider layout="vertical" />
          <DataTable
            value={Array.isArray(slots) ? slots : []}
            emptyMessage="No slots found"
            className="w-full"
            size="small"
            scrollHeight="flex"
            scrollable
            tableStyle={{
              minWidth: 500,
            }}
            header={
              <div className="flex justify-content-between align-items-center">
                <h3>Slot List</h3>
                <PFButton
                  className="slot-btn"
                  label="Copy From"
                  size="small"
                  onClick={() => setMergeDialogVisible(true)}
                  tooltip={`Copy All Slots from Internal Business Hours - ${currentSlots.day}`}
                  tooltipOptions={{ position: 'top' }}
                />
              </div>
            }
          >
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="action"
              header="Action"
              body={actionTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="slot_name"
              header="Slot Name"
              body={slotNameTemplate}
              style={{ maxWidth: 70, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="start_time"
              header="Start Time"
              body={startTimeTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
            <Column
              alignHeader="center"
              align="center"
              bodyClassName="pt-1 pb-1"
              field="end_time"
              header="End Time"
              body={endTimeTemplate}
              style={{ maxWidth: 50, verticalAlign: 'middle' }}
            />
          </DataTable>
          {mergeDialogVisible && (
            <ConfirmDialog
              visible={mergeDialogVisible}
              message={
                <div>
                  Please confirm if you would like to copy all slots of{' '}
                  <b>Company Business Hours - {currentSlots.day}</b> to{' '}
                  <b>Customer Scheduling - {currentSlots.day}</b>?
                  <br />
                  On click confirm, all the current slots of{' '}
                  <b>Customer Scheduling - {currentSlots.day}</b> will be
                  deleted.
                </div>
              }
              header="Copy Slot Confirmation"
              icon="pi pi-info-circle"
              acceptClassName="p-button-danger"
              accept={handleMerge}
              rejectLabel="Cancel"
              acceptLabel="Confirm"
              onHide={() => setMergeDialogVisible(false)}
            />
          )}
        </div>
      </PFBlockUI>
    </Dialog>
  );
};

export default AddEditCxSchedulingSlotDialog;
