export const MESSAGES = {
  NOT_AVAILABLE: 'Not available',
};

export let EVENT_COLORS = [
  { bgColor: '#FFECEA', textColor: '#FF7F00' },
  { bgColor: '#DCFFDC', textColor: '#009500' },
  { bgColor: '#F3EDFF', textColor: '#4D00FF' },
  { bgColor: '#FFFAE5', textColor: '#FF2700' },
  { bgColor: '#ADD8E6', textColor: '#333333' },
];

export const EVENT_TYPES = {
  BLOCK: 'blockedTime',
  TIME_OFF: 'timeoff',
};

export const SCHEDULER_CARD_VIEW_OPTION = [
  {
    type: {
      description: 'description',
      time: 'time',
      store: 'store',
      project_type: 'project_type',
      category: 'category',
      status: 'status',
      total_sale: 'total_sale',
      total_revenue: 'total_revenue',
      project_number: 'project_number',
      client_name: 'client_name',
      address: 'address',
      phone: 'phone',
      basic_labor_quantity: 'basic_labor_quantity',
    },
  },
];

export const VIEW_MORE_JOBS = [
  {
    label: 'Show Only 1 Job',
    value: 1,
  },
  {
    label: 'Show Upto 5 Jobs',
    value: 5,
  },
  {
    label: 'Show Upto 10 Jobs',
    value: 10,
  },
  {
    label: 'Show Upto 15 Jobs',
    value: 15,
  },
  { label: 'Show Upto 25 Jobs', value: 25 },
  { label: 'Show Upto 40 Jobs', value: 40 },
  { label: 'Show Upto 50 Jobs', value: 50 },
  { label: 'Show Upto 60 Jobs', value: 60 },
  { label: 'Show Upto 70 Jobs', value: 70 },
  { label: 'Show Upto 80 Jobs', value: 80 },
  { label: 'Show Upto 90 Jobs', value: 90 },
  { label: 'Show Upto 100 Jobs', value: 100 },
];

export const FULL_CALENDAR_GROUPS = {
  enableCompactView: false,
  resources: ['Resources'],
};
export const WORK_HOURS = {
  highlight: true,
  start: '06:00',
  end: '20:59',
};

export const DEFAULT_DISABLED_TIME_SCALE = { enable: false };
export const DEFAULT_WORK_DAYS = [1, 2, 3, 4, 5];
export const ALL_DAYS = [0, 1, 2, 3, 4, 5, 6];
