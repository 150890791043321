export const DASHBOARD_FILTERS: string[] = [
  'checkDateFromFilter',
  'checkDateToFilter',
  'dateCompletionFilterEnd',
  'dateCompletionFilterStart',
  'dateScheduledFilterEnd',
  'dateScheduledFilterStart',
  'dateSoldFromFilter',
  'dateSoldToFilter',
  'projectCategories',
  'projectStatus',
  'projectTypes',
  'projectWorkrooms',
  'stores',
  'techniciansVal',
];
