// **** React Imports ****
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// **** External Utilities ****
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';

import { checkPermission, showFirst30Characters } from '../../../utils/Helpers';
import useToken from '../../../hooks/useToken';
import permissions from '../../../config/permissions';

// **** Custom Components ****
import PdfViewerComponentNew from '../../PSPDF/PdfViewerComponentNew';

// **** Services *****
import {
  getDocumentById,
  createProjectDocumentFromTemplateDocument,
  updateProjectDocument,
  getDocumentCount,
} from './Docs.service';

// **** Styles/Images/Icons ****
import '../../../pdfviewerpage.css';
import DocumentSaveConfirmationDialog from './DocumentSaveConfirmationDialog';
import { useStyles } from './Docs.styles';

import {
  applyInstallerVisibilityTemplates,
  updateDocumentOpen,
} from '../../Admin/DocumentCenter/Templates.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

import { UploadActionContext } from './context/context';
import UploadStoreTypeDialog from './UploadStoreTypeDialog';

import {
  getLaborItems,
  getMerchandiseItems,
} from '../ProjectItemsNew/ProjectItemsService';

import { Column } from 'primereact/column';

import './style.css';
import {
  openDocumentInfo,
  CLIENT_DETAILS,
  DOCUMENT_PHOTO_TYPE,
} from '../../../constants';
import {
  getCustomerId,
  getProjectById,
} from '../ProjectInfo/ProjectDetails.service';

import { OverlayPanel } from 'primereact/overlaypanel';

import { getLinkedItemsList } from '../ProjectItemsNewUI/ProjectItemsService';

import ViewLinkedItems from './Components/ViewLinkedItems';

import { Skeleton } from 'primereact/skeleton';
import { getMatchedOrderConfigData } from '../../Admin/NewConfiguration/service/order-validation-config.service';

//pspdf toolbar items
const sourceFieldOptions = [];
const toolbarItems = [
  { type: 'pager' },
  { type: 'pan' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'zoom-mode' },
  { type: 'export-pdf' },
  { type: 'debug' },
];

const ViewProjectDocument = () => {
  const templateRef = useRef();
  //Get projectId from url params
  const { projectId, documentId, documentType } = useParams();
  let history = useHistory();
  const [documentData, setDocumentData] = useState(null);
  const [annotationData, setAnnotationData] = useState(null);
  const [annotatedPdf, setAnnotatedPdf] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [tempLoading, setTempLoading] = React.useState(false);
  const [saveMode, setSaveMode] = React.useState(false);
  const [showSaveDialog, setShowSaveDialog] = React.useState(false);
  const classes = useStyles();
  const [isChecked, setIsChecked] = React.useState(false);
  const [reloadList, setReloadList] = React.useState(false);
  const [showLineItems, setShowLineItems] = useState(true);
  const [laborItems, setLaborItems] = useState([]);
  const [merchandiseItems, setMerchandiseItems] = useState([]);
  const clientDetails = CLIENT_DETAILS;
  const [projectDetailsData, setProjectDetailsData] = useState();
  const [completeButtonDisable, setCompleteButtonDisable] = useState(false);
  const [reviewPermission] = React.useState(
    checkPermission(permissions?.documentCenter?.viewDocumentReview)
  );
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const userId = localStorage.getItem('user_id');
  const [installerVisible, setInstallerVisible] = React.useState(
    !checkPermission(permissions?.userManagement?.userManagementModifyInstaller)
  );
  const [customerId, setCustomerId] = useState(null);
  const [linkedProjectItems, setLinkedProjectItems] = useState(null);
  const [addDocCategoryPermission] = React.useState(
    checkPermission(permissions?.documentCenter?.addDocCategory)
  );
  const [editDocCategory] = React.useState(
    !checkPermission(permissions?.documentCenter?.editDocCategory)
  );
  const userAssignedLaborItems = checkPermission(
    permissions?.projectItems.userAssignedLaborItems
  );

  const editDocsPermission = checkPermission(
    permissions?.viewEditProject?.editDocs
  );
  const [uploadContextValue, setUploadContextValue] = useState({
    show: false,
    req_from: 'template',
    permissions: {
      installerVisible: installerVisible,
      reviewPermission: reviewPermission,
      addCategoryPermission: addDocCategoryPermission,
      editCategoryPermission: editDocCategory,
    },
    document_type_id: 1,
    onShow: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: true,
      }));
    },
    onHide: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: false,
      }));
      uploadContextValue?.resetContext();
    },
    data: {
      document_store_type: 'INTERNAL',
      is_installer_visibility: 0,
      is_proj_docu_visibility: 0,
      is_customer_visible: 0,
      reviewed_status: 0,
      is_editable: 0,
    },
    handleChange: data => {
      setUploadContextValue(preValue => ({
        ...preValue,
        data: { ...preValue?.data, ...data },
      }));
    },
    resetContext: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        data: {
          document_store_type: 'INTERNAL',
          is_installer_visibility: 0,
          is_proj_docu_visibility: 0,
          is_customer_visible: 0,
          reviewed_status: 0,
        },
      }));
    },
  });
  const toastRef = React.useRef(null);
  const op = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [
    completeDisableforOrderValidation,
    setCompleteDisableforOrderValidation,
  ] = useState(false);
  const [orderConfigData, setOrderConfigData] = useState(null);
  const documentPhotoTypeId = DOCUMENT_PHOTO_TYPE?.PHOTO;
  const getprojectsCustomerDetails = async () => {
    try {
      const projectDetailsRequest = await getCustomerId(projectId);
      setCustomerId(projectDetailsRequest?.data?.customer?.customer_id || null);
      if (projectDetailsRequest?.data?.customer?.customer_id)
        getLinkedProjectItems(
          projectDetailsRequest?.data?.customer?.customer_id
        );
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getProjectById(projectId, setLoading, setAlert, setProjectDetailsData);
  }, [projectId]);

  useEffect(() => {
    if (clientDetails?.order_completion_validation?.enabled === 1) {
      const fetchConfigData = async () => {
        if (
          projectDetailsData?.project_type_id &&
          projectDetailsData?.project_category_id &&
          !orderConfigData
        ) {
          return await getMatchedOrderConfigData(
            clientDetails?.client_id,
            projectDetailsData?.project_type_id,
            projectDetailsData?.project_category_id
          );
        }
        return null;
      };
      Promise.all([
        getDocumentCount(projectId, documentPhotoTypeId),
        fetchConfigData(),
      ])
        .then(([documentCount, configData]) => {
          if (documentCount == null) {
            return;
          }
          if (configData) {
            setOrderConfigData(configData);
            if (configData?.count > documentCount) {
              setCompleteDisableforOrderValidation(true);
            }
          }
        })
        .catch(console.error);
    }
  }, [projectId, projectDetailsData, clientDetails]);

  useEffect(() => {
    getprojectsCustomerDetails();
  }, []);

  const columnData = [
    { field: 'item_number', header: 'Item#' },
    {
      field: 'item_description',
      header: 'Item Description',
      style: { minWidth: '150px', maxWidth: '150px' },
    },
    { field: 'quantity', header: 'Quantity' },
  ];
  function setAlert(severity, title, message) {
    try {
      toastRef.current.show({
        severity: severity,
        summary: title,
        detail: message,
        life: 3000,
      });
    } catch (error) {}
  }

  let pdfViewerRef = React.useRef(null);
  const setPdfViewerRef = React.useMemo(() => {
    return refObj => {
      if (refObj) {
        pdfViewerRef.current = refObj;
      }
    };
  }, []);

  useEffect(() => {
    setIsChecked(!!documentData?.is_customer_visible);
  }, [documentData?.is_customer_visible]);

  const handleSetDocumentData = data => {
    if (data) {
      setDocumentData(data);
      setUploadContextValue(preValue => ({
        ...preValue,
        data: {
          ...preValue?.data,
          is_customer_visible: data?.is_customer_visible,
          is_editable: data?.is_editable,
        },
      }));
    }
  };

  useEffect(() => {
    getDocumentById(
      projectId,
      documentId,
      handleSetDocumentData,
      setAnnotationData,
      setLoading,
      setAlert,
      getDocumentType()
    );
  }, []);

  const fetchLaborItems = async () => {
    try {
      const laborItems = await getLaborItems(projectId);
      const laborItemList = {};

      if (Array.isArray(laborItems?.data?.data)) {
        laborItems.data.data.forEach(item => {
          laborItemList[item.project_item_id] = {
            item_number: item?.item?.item_number,
            item_description: item?.item?.item_desc,
            quantity: item?.item_quantity,
            user_id: item?.project_item_user?.installer_details?.meta?.user_id,
          };
        });
      }

      let filteredLaborItem = Object.values(laborItemList);

      if (userAssignedLaborItems && Array.isArray(filteredLaborItem)) {
        filteredLaborItem = filteredLaborItem.filter(
          item => item?.user_id === userId
        );
      }

      setLaborItems(filteredLaborItem);
    } catch (error) {
      setLaborItems([]);
    }
  };

  const fetchMerchandiseItems = async () => {
    try {
      let merchandiseItems = await getMerchandiseItems(projectId);
      merchandiseItems =
        merchandiseItems?.data?.data?.map(item => ({
          item_number: item?.item?.item_number,
          item_description: item?.item?.item_desc,
          quantity: item?.item_quantity,
        })) || [];

      setMerchandiseItems(merchandiseItems);
    } catch (error) {
      setMerchandiseItems([]);
    }
  };

  useEffect(() => {
    fetchLaborItems();
    fetchMerchandiseItems();
  }, []);

  const getLinkedProjectItems = async customerId => {
    try {
      setLoading(true);
      const res = await getLinkedItemsList(customerId, projectId);
      setLinkedProjectItems(res);
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const updateOpenedDocumentCount = async templateId => {
    await updateDocumentOpen([
      { ...openDocumentInfo, template_id: templateId },
    ]);
  };

  useEffect(() => {
    if (documentData?.template_id)
      updateOpenedDocumentCount(documentData?.template_id);
  }, [documentData?.template_id]);

  const getDocumentType = () => {
    try {
      return parseInt(documentType);
    } catch (error) {}
    return 0;
  };
  const saveButtonClickHandler = async () => {
    uploadContextValue?.onShow();
    setShowSaveDialog(true);
  };

  const documentSaveConfirmationDialogHandler = async value => {
    let val = value;
    if (loading) return;
    await savePdf(val);
    await savePdf();
  };
  const savePdf = async () => {
    try {
      const pdfByteArray = await pdfViewerRef.current.exportPDF();
      const pdfBlob = new Blob([pdfByteArray], { type: 'application/pdf' });
      const uploadFileName = documentData?.template_name;
      if (getDocumentType() === 2) {
        createProjectDocumentFromTemplateDocument(
          projectId,
          pdfBlob,
          uploadFileName,
          setLoading,
          setAlert,
          history,
          uploadContextValue?.data?.document_store_type,
          documentData?.hd_doc_cd_number,
          documentData?.ll_document_type,
          Number(isChecked),
          documentData?.is_signature_required,
          documentId,
          uploadContextValue?.data
        );
      } else if (getDocumentType() === 1) {
        updateProjectDocument(
          uploadContextValue?.data,
          uploadContextValue?.data?.document_store_type,
          projectId,
          documentId,
          pdfBlob,
          setLoading,
          setAlert,
          history
        );
      } else {
        setAlert(
          'Error',
          'Invalid Document Type',
          'Cannot process this request'
        );
      }
    } catch (error) {
      setAlert('Error', 'Invalid Document Type', 'Cannot process this request');
    }
  };

  const cancelButtonClickHandler = async () => {
    updateOpenedDocumentCount(documentData?.template_id);
    setSaveMode(false);
  };

  const closeButtonClickHandler = async () => {
    history.push(`/project/view/${projectId}/#tab=docs`);
  };

  const editButtonClickHandler = async () => {
    updateOpenedDocumentCount(documentData?.template_id);
    setSaveMode(true);
  };

  const pdfUrl =
    getDocumentType() === 1
      ? documentData?.aws_doc_url
      : `${
          URL_CONSTANTS.API.BASE_URL
        }/documents/templates/${documentId}/project/${projectId}/render?client_id=${localStorage.getItem(
          'client_id'
        )}&token=${localStorage.getItem('cubejsAuthorizationToken')?.split(' ')?.[1]}`;

  const handleMouseEnter = (e, rowData) => {
    op.current.toggle(e);
    setSelectedRow(rowData);
  };

  const handleMouseLeave = () => {
    op.current.hide();
  };

  return (
    <React.Fragment>
      <Toast ref={toastRef} position="center" />

      <UploadActionContext.Provider
        value={{ uploadContextValue, setUploadContextValue }}
      >
        <UploadStoreTypeDialog
          type={'Document'}
          docInputRef={templateRef}
          bulkUpdateHandler={savePdf}
          projectDetailsData={projectDetailsData}
          setCompleteButtonDisable={setCompleteButtonDisable}
          completeButtonDisable={completeButtonDisable}
          clientDetails={clientDetails}
          editDocsPermission={editDocsPermission}
          completeDisableforOrderValidation={completeDisableforOrderValidation}
          orderConfigData={orderConfigData}
        />
      </UploadActionContext.Provider>

      <div className="document__editor__header">
        <div>
          <h2 className="document__editor__header__title">Project Document</h2>
          <h1 className="document__editor__header__name">
            {documentData ? documentData.template_name : ''}
          </h1>
        </div>
        {checkPermission(permissions.viewEditProject.tabDocsAddProjectDocs) && (
          <div className="document__editor__header__buttons">
            {getDocumentType() === 1 &&
            clientDetails?.show_line_items_on_documents ? (
              <>
                <label className="text-sm m-1">Show Line Items</label>
                <InputSwitch
                  checked={showLineItems}
                  onChange={e => setShowLineItems(e.value)}
                />
              </>
            ) : null}

            {saveMode && (
              <>
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  iconPos="left"
                  severity="primary"
                  outlined
                  disabled={loading}
                  onClick={cancelButtonClickHandler}
                />
                <Button
                  label="Save"
                  icon="pi pi-check"
                  iconPos="left"
                  severity="primary"
                  disabled={loading}
                  onClick={saveButtonClickHandler}
                />
              </>
            )}

            {!saveMode && (
              <Button
                label="Back"
                icon="pi pi-chevron-left"
                iconPos="left"
                severity="primary"
                outlined
                onClick={closeButtonClickHandler}
              />
            )}
            {!saveMode && (
              <Button
                label="Edit"
                icon="pi pi-file-edit"
                iconPos="left"
                severity="primary"
                disabled={
                  (getDocumentType() === 1 &&
                    documentData?.is_editable === 0) ||
                  loading
                }
                onClick={editButtonClickHandler}
              />
            )}
          </div>
        )}
      </div>
      <div className="PDF-viewer" style={{ width: '100%', height: '100vh' }}>
        {loading && (
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProgressSpinner />
          </div>
        )}
        {pdfUrl && (
          <div className="flex mx-2">
            {showLineItems &&
            getDocumentType() === 1 &&
            clientDetails?.show_line_items_on_documents ? (
              <div className="flex flex-column p-mr-3">
                <label className="text-base mb-1 mt-4">Labor Items :</label>
                <div className="mb-2 shadow-2 max-h-17rem">
                  <DataTable
                    scrollable
                    scrollHeight="flex"
                    value={laborItems}
                    emptyMessage="No items available"
                  >
                    {columnData.map((col, index) => (
                      <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        style={col.style}
                        body={rowData => (
                          <div
                            className="white-space-nowrap overflow-hidden text-overflow-ellipsis cursor-pointer	"
                            onMouseEnter={e => handleMouseEnter(e, rowData)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {showFirst30Characters(rowData?.[col.field] ?? '')}
                          </div>
                        )}
                      />
                    ))}
                  </DataTable>
                </div>

                <label className="text-base mb-1">Product Items :</label>
                <div className="shadow-2 max-h-17rem">
                  <DataTable
                    scrollable
                    scrollHeight="flex"
                    value={merchandiseItems}
                    emptyMessage="No product items available"
                  >
                    {columnData.map((col, index) => (
                      <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        style={col.style}
                        body={rowData => (
                          <div
                            className="white-space-nowrap overflow-hidden text-overflow-ellipsis cursor-pointer"
                            onMouseEnter={e => handleMouseEnter(e, rowData)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {showFirst30Characters(rowData?.[col.field] ?? '')}
                          </div>
                        )}
                      />
                    ))}
                  </DataTable>
                  <OverlayPanel
                    ref={op}
                    appendTo={null}
                    className="min-w-300 max-w-25rem"
                  >
                    {selectedRow && (
                      <div className="rounded-lg">
                        <div className="flex justify-content-start">
                          <div className="w-12rem font-bold">Item</div>
                          <div className="mx-2">:</div>
                          <div className="w-full word-break">
                            {selectedRow?.item_number}
                          </div>
                        </div>
                        <div className="flex justify-content-start">
                          <div className="w-12rem font-bold">Description</div>
                          <div className="mx-2">:</div>
                          <div className="w-full word-break">
                            {selectedRow?.item_description}
                          </div>
                        </div>
                        <div className="flex justify-content-start">
                          <div className="w-12rem font-bold">Quantity</div>
                          <div className="mx-2">:</div>
                          <div className="w-full word-break">
                            {selectedRow?.quantity}
                          </div>
                        </div>
                      </div>
                    )}
                  </OverlayPanel>
                </div>
                {linkedProjectItems ? (
                  <>
                    <ViewLinkedItems
                      projectId={projectId}
                      customerId={customerId}
                      type={2}
                      linkedProjectItems={linkedProjectItems}
                    />
                    <ViewLinkedItems
                      projectId={projectId}
                      customerId={customerId}
                      type={6}
                      linkedProjectItems={linkedProjectItems}
                    />
                  </>
                ) : (
                  <>
                    <Skeleton height="4rem" className="my-2"></Skeleton>
                    <Skeleton height="4rem" className="mb-2"></Skeleton>
                  </>
                )}
              </div>
            ) : null}

            <div className="flex-1">
              <PdfViewerComponentNew
                toolbarItems={toolbarItems}
                setParentRef={setPdfViewerRef}
                fieldOptions={sourceFieldOptions}
                document={pdfUrl}
                editable={false}
                readOnly={!saveMode}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewProjectDocument;
