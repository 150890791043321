import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { TreeTable, TreeTableEvent } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import {
  addOrderValidations,
  deleteOrderValidations,
  deleteOrderValidationsByTypeId,
  getOrderValidations,
  updateOrderValidations,
} from '../service/order-validation-config.service';
import {
  OrderValidationConfigProps,
  ProjectCategory,
  RootState,
  TreeNode,
  TypeCategory,
  ValidationResponse,
  ValidationResponseItem,
} from '../Types/order-validation-config.types';
import { useSelector } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import PFTableLoader from '../../../shared/Loader/PFTableLoader';
import { useFormik } from 'formik';
import CountTemplate from './CountTemplate';
import { ConfirmDialog } from 'primereact/confirmdialog';

const OrderValidationConfig: React.FC<OrderValidationConfigProps> = ({
  openModal,
  setOpenModal,
}) => {
  const toast = useRef<Toast>(null);
  const { id } = useParams<{ id: string }>();
  const client_id = id || localStorage.getItem('client_id') || '';
  const [treeTableData, setTreeTableData] = useState<TreeNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [hasChild, setHasChild] = useState(false);
  const [paginator, setPaginator] = useState({ first: 0, rows: 10, page: 1 });
  const [deleteDialogProps, setDeleteDialogProps] = useState<{
    visible: boolean;
    node: TreeNode | null;
  }>({
    visible: false,
    node: null,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = (await getOrderValidations(
        client_id
      )) as ValidationResponse | null;
      if (response?.data && response.data.length >= 0) {
        transformDataToTree(response?.data);
      }
    } catch (error) {
      console.error('Error fetching validation data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { types } = useSelector((state: RootState) => state.types);
  const { categories } = useSelector((state: RootState) => state.categories);
  const categoriesData: ProjectCategory[] = categories;
  const typesData: TypeCategory[] = types;

  const transformDataToTree = (data: ValidationResponseItem[]) => {
    const groupedData = data.reduce<Record<string, TreeNode>>((acc, item) => {
      const {
        project_type_id,
        project_category_id,
        order_completion_validation_id,
        project_type_order_validation,
        project_category_order_validation,
        count,
        createdBy,
        modifiedBy,
      } = item;

      if (!acc[project_type_id]) {
        acc[project_type_id] = {
          key: `${project_type_id}`,
          data: {
            projectType: project_type_order_validation.project_type,
            projectTypeId: project_type_id,
          },
          children: [],
        };
      }

      acc[project_type_id].children?.push({
        key: `${project_type_id}-${project_category_id}-${order_completion_validation_id}`,
        data: {
          order_completion_validation_id,
          projectTypeId: project_type_id,
          category: project_category_order_validation.category,
          categoryId: project_category_id,
          count,
          createdBy: `${createdBy.first_name} ${createdBy.last_name}`,
          modifiedBy: modifiedBy
            ? `${modifiedBy.first_name} ${modifiedBy.last_name}`
            : '',
        },
      });

      return acc;
    }, {});
    setHasChild(true);
    setTreeTableData(Object.values(groupedData));
  };
  const allProjectCategory = () => {
    const options: { label: string; value: number }[] =
      Array.isArray(categoriesData) && categoriesData.length > 0
        ? categoriesData.map(item => ({
            label: item.category,
            value: item.project_category_id,
          }))
        : [];
    return options;
  };

  const allProjectTypes = () => {
    const options: { label: string; value: number }[] =
      Array.isArray(typesData) && typesData.length > 0
        ? typesData.map(item => ({
            label: item.project_type,
            value: item.project_type_id,
          }))
        : [];
    return options;
  };

  const categoryOptions = useMemo(() => allProjectCategory(), [categories]);
  const typesOptions = useMemo(() => allProjectTypes(), [types]);

  const formik = useFormik({
    initialValues: {
      types: [] as number[],
      categories: [] as number[],
      count: null as number | null,
    },
    onSubmit: async values => {
      try {
        if (values.count === null) {
          toast.current?.show({
            severity: 'error',
            summary: 'Please enter count value',
            life: 1500,
          });
          return;
        }

        const transformedPayload = {
          project_type_id: values.types,
          project_category_id: values.categories,
          count: values.count,
        };

        setLoading(true);
        const response = await addOrderValidations(
          client_id,
          transformedPayload
        );
        if (response.success) {
          toast.current?.show({
            severity: 'success',
            summary: 'Record Added Successfully',
            life: 1500,
          });
          formik.resetForm();
          setLoading(false);
          fetchData();
        } else {
          toast.current?.show({
            severity: 'error',
            summary: 'Error while adding order validation',
            life: 1500,
          });
        }
      } catch (error) {
        console.error('Error adding validation data:', error);
      }
    },
  });

  const handleHide = (): void => setOpenModal(false);

  const renderActionColumn = (node: TreeNode) => {
    return (
      <div className="">
        <Button
          icon="pi pi-trash"
          type="button"
          outlined
          loading={loading}
          className="border-none p-0 text-500"
          onClick={() => handleDelete(node)}
          tooltip={
            node?.children?.length ? 'Delete All Entries' : 'Delete Entry'
          }
        />
      </div>
    );
  };
  const accept = async () => {
    if (deleteDialogProps?.node) {
      const { projectTypeId } = deleteDialogProps?.node?.data;
      if (projectTypeId === undefined) {
        toast.current?.show({
          severity: 'error',
          summary: 'Invalid Project Type ID',
          life: 1500,
        });
        return;
      }
      try {
        setLoading(true);
        const response = await deleteOrderValidationsByTypeId(
          client_id,
          projectTypeId
        );

        if (response && response.success) {
          const updatedData = treeTableData.filter(
            treeNode =>
              deleteDialogProps.node &&
              treeNode.key !== deleteDialogProps.node.key
          );

          setTreeTableData(updatedData);

          toast.current?.show({
            severity: 'success',
            summary: 'All related entries deleted successfully',
            life: 1500,
          });
          setLoading(false);
          fetchData();
        } else {
          toast.current?.show({
            severity: 'error',
            summary: 'Error while performing bulk delete',
            life: 1500,
          });
        }
      } catch (error) {
        console.error('Error in bulk delete:', error);
        toast.current?.show({
          severity: 'error',
          summary: 'Failed to delete entries',
          life: 1500,
        });
      }
    }
  };

  const handleDelete = async (node: TreeNode) => {
    const { order_completion_validation_id } = node?.data;
    if (node?.children?.length) {
      setDeleteDialogProps(prevState => ({ ...prevState, node }));
      setDeleteDialogProps(prevState => ({ ...prevState, visible: true }));
    } else {
      if (!order_completion_validation_id) {
        toast.current?.show({
          severity: 'error',
          summary: 'Invalid Order Validation ID',
          life: 1500,
        });
        return;
      }

      try {
        setLoading(true);
        const response = await deleteOrderValidations(
          client_id,
          order_completion_validation_id
        );

        if (response && response.success) {
          const updatedData = treeTableData.map(treeNode => ({
            ...treeNode,
            children: treeNode.children?.filter(
              child => child.key !== node.key
            ),
          }));

          setTreeTableData(updatedData);

          toast.current?.show({
            severity: 'success',
            summary: 'Record Deleted Successfully',
            life: 1500,
          });
          setLoading(false);
          fetchData();
        } else {
          toast.current?.show({
            severity: 'error',
            summary: 'Error while deleting order validation',
            life: 1500,
          });
        }
      } catch (error) {
        console.error('Error while deleting:', error);
        toast.current?.show({
          severity: 'error',
          summary: 'Failed to delete the record',
          life: 1500,
        });
      }
    }
  };

  const handleUpdate = async (node: TreeNode | null) => {
    if (node) {
      try {
        const order_validation_id = node?.data?.order_completion_validation_id;
        const payload = {
          project_type_id: node.data.projectTypeId ?? 0,
          project_category_id: node.data.categoryId ?? 0,
          count: node.data.count ?? 0,
        };

        if (order_validation_id) {
          setLoading(true);
          const response = await updateOrderValidations(
            client_id,
            payload,
            order_validation_id
          );
          if (response.success) {
            toast.current?.show({
              severity: 'success',
              summary: 'Record Updated Successfully',
              life: 1500,
            });
            setLoading(false);
            fetchData();
          } else {
            toast.current?.show({
              severity: 'error',
              summary: 'Failed to Update Record',
              life: 1500,
            });
          }
        }
      } catch (error) {
        console.error('Error updating validation data:', error);
      }
    }
  };

  const rowClassName = (node: TreeNode) => {
    return {
      'p-highlight': node?.children && node?.children?.length > 0,
    };
  };
  const onExpand = (event: TreeTableEvent) => {
    const expandedNodeKey = String(event.node.key);
    setExpandedKeys({ [expandedNodeKey]: true });
  };

  const onCollapse = (event: TreeTableEvent) => {
    const collapsedNodeKey = String(event.node.key);
    setExpandedKeys(prevState => {
      const newState = { ...prevState };
      delete newState[collapsedNodeKey];
      return newState;
    });
  };
  const countTemplate = (node: TreeNode) => {
    return (
      <CountTemplate node={node} loading={loading} onUpdate={handleUpdate} />
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog
        visible={deleteDialogProps.visible}
        onHide={() =>
          setDeleteDialogProps(prevState => ({ ...prevState, visible: false }))
        }
        message="Are you sure you delete all entries?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        style={{ width: '25vw' }}
      />
      {openModal && (
        <Dialog
          header="Order Validation Configuration"
          visible={openModal}
          onHide={handleHide}
          className="w-12 xl:w-10"
          style={{ minHeight: '600px' }}
          draggable={false}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="grid align-items-center">
              <div className="col-3 field">
                <label htmlFor="types">Type</label>
                <MultiSelect
                  id="types"
                  value={formik.values.types}
                  options={typesOptions}
                  onChange={e => formik.setFieldValue('types', e.value)}
                  placeholder="Select Project Type"
                  className="w-full"
                  filter
                />
              </div>
              <div className="col-3 field">
                <label htmlFor="categories">Category</label>
                <MultiSelect
                  id="categories"
                  value={formik.values.categories}
                  options={categoryOptions}
                  onChange={e => formik.setFieldValue('categories', e.value)}
                  placeholder="Select Project Category"
                  className="w-full"
                  filter
                />
              </div>
              <div className="col-3 field">
                <label htmlFor="count">Count</label>
                <InputNumber
                  id="count"
                  value={formik.values.count}
                  onValueChange={e =>
                    formik.setFieldValue('count', e.value || null)
                  }
                  min={1}
                  maxLength={12}
                  className="w-full"
                  placeholder="Enter Number"
                  inputStyle={{ fontSize: '17px' }}
                />
              </div>
              <div className="col-3 text-right align-items-end">
                <Button
                  size="small"
                  severity="info"
                  type="submit"
                  disabled={loading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>

          {loading ? (
            <PFTableLoader />
          ) : (
            <>
              <TreeTable
                value={treeTableData}
                columnResizeMode="expand"
                scrollable
                scrollHeight="800px"
                rowClassName={rowClassName}
                expandedKeys={expandedKeys}
                onToggle={e => setExpandedKeys(e.value)}
                onExpand={onExpand}
                onCollapse={onCollapse}
                paginator
                paginatorClassName="pt-2"
                first={paginator.first}
                rows={paginator.rows}
                onPage={event => {
                  setPaginator(event);
                }}
                loading={loading}
              >
                <Column body={renderActionColumn} style={{ width: '50px' }} />
                <Column
                  field="projectType"
                  header="Project Type"
                  expander
                  sortable
                  filter
                  pt={{
                    filterInput: {
                      root: { placeholder: 'Search By Type' },
                    },
                    headerTitle: {
                      className: 'font-bold',
                    },
                    headerCell: {
                      className: 'w-20rem',
                    },
                    bodyCell: {
                      className: 'w-20rem',
                    },
                  }}
                />

                <Column
                  field="category"
                  header="Category"
                  sortable={!!expandedKeys && hasChild}
                  filter={!!expandedKeys && hasChild}
                  pt={{
                    filterInput: {
                      root: { placeholder: 'Search By Category' },
                    },
                    headerTitle: {
                      className: 'font-bold',
                    },
                    headerCell: {
                      className: 'w-16rem',
                    },
                    bodyCell: {
                      className: 'w-15rem',
                    },
                  }}
                />
                <Column
                  field="count"
                  header="Count"
                  body={countTemplate}
                  className="p-highlight"
                  pt={{
                    headerTitle: {
                      className: 'font-bold',
                    },
                  }}
                />
                <Column
                  field="createdBy"
                  header="Created By"
                  pt={{
                    headerTitle: {
                      className: 'font-bold',
                    },
                  }}
                />
                <Column
                  field="modifiedBy"
                  header="Modified By"
                  pt={{
                    headerTitle: {
                      className: 'font-bold',
                    },
                  }}
                />
              </TreeTable>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default OrderValidationConfig;
