import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';

import { setNewSchedulerComponent } from '../../../../redux/slices/new-scheduler.slice';
import SchedulerCalendar from '../SchedulerCalendar';

const CalendarView = ({
  tableRef,
  calendarRef,
  targetID,
  filter,
  data,
  loader,
  handleSetFilter,
  handleSetData,
  handleSetLoader,
  statusOptions,
  view,
  calendarData,
  setCalendarData,
}) => {
  const dispatch = useDispatch();
  const { isWeekendsVisible } = useSelector(state => state.newScheduler);

  const handleWeekendToggle = () => {
    dispatch(
      setNewSchedulerComponent({
        isWeekendsVisible: !isWeekendsVisible,
      })
    );
  };

  return (
    <>
      <div className="flex flex-column w-full">
        <div className="flex flex-row w-full gap-4 mb-2">
          <div className="flex mt-2">
            <Checkbox
              inputId="weekends"
              name="weekends"
              value="weekends"
              onChange={handleWeekendToggle}
              checked={isWeekendsVisible}
            />
            <label htmlFor="weekends" className="ml-2">
              Show Weekends
            </label>
          </div>
        </div>
        <div className="flex flex-row w-full">
          <SchedulerCalendar
            tableRef={tableRef}
            calendarRef={calendarRef}
            targetID={targetID}
            filter={filter}
            data={data}
            loader={loader}
            handleSetFilter={handleSetFilter}
            handleSetData={handleSetData}
            handleSetLoader={handleSetLoader}
            statusOptions={statusOptions}
            view={view}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarView;
