// **** React Imports ****
import React, { useMemo } from 'react';

// **** Utilities ****
import { Grid, CircularProgress, TextField, Checkbox } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Custom Components *****
import { parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { formatDate } from '../../../utils/Helpers';

// **** Services *****
import permissions from '../../../config/permissions';
import { PermitsStatusTypeId } from '../../../constants';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import {
  getPermittingData,
  createPermit,
  updatePermit,
  deletePermit,
  getPermitStatus,
} from './Permitting.service';

// **** Styles *****
import { useStyles } from './Permitting.styles';

const permittingTableColumns = [
  {
    id: 'permit_follow_up_date',
    value: 'Permit Follow-up Date',
    width: '15%',
  },
  {
    id: 'muncipality',
    value: 'Municipality',
    width: '10%',
  },
  {
    id: 'permit_number',
    value: 'Permit Number',
    width: '15%',
  },
  {
    id: 'permit_application_date',
    value: 'Permit Application Date',
    width: '12%',
  },
  {
    id: 'permit_expiration_date',
    value: 'Permit Expiration Date',
    width: '12%',
  },
  {
    id: 'emergency_permit',
    value: 'Emergency Permit',
    width: '12%',
  },
  {
    id: 'inspection_required',
    value: 'Inspection Required',
    width: '12%',
  },
  {
    id: 'permit_required',
    value: 'Permit Required',
    width: '12%',
  },
  {
    id: 'permit_comments',
    value: 'Permit Comments',
    width: '20%',
  },
];

const cleanCurrencyPayload = (currencyString = '') => {
  return parseFloat(currencyString.replace(/[$,]/g, ''));
};

// ******** Permitting ************
const Permitting = ({ formik, action }) => {
  const classes = useStyles();
  const [permittingResponse, setPermittingResponse] = React.useState({});
  const [loading, setLoading] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [permitOptions, setPermitOptions] = React.useState();
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'View Technician',
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const [reloadList, setReloadList] = React.useState(false);
  const { setAlert } = useAlerts();

  let presentationPermittingList = [];

  React.useEffect(() => {
    getPermittingData(
      formik?.values?.project_id,
      setLoading,
      setPermittingResponse
    );
    getPermitStatus(formik?.values?.project_id, setLoading, setPermitOptions);
  }, [formik?.values?.projectId]);

  React.useEffect(() => {
    if (reloadList)
      getPermittingData(
        formik?.values?.project_id,
        setLoading,
        setPermittingResponse
      );
  }, [reloadList]);

  // **** Data Modification *****
  if (permittingResponse?.rows?.length) {
    presentationPermittingList = permittingResponse.rows.map(val => {
      return {
        permit_follow_up_date: {
          value: formatDate(val.follow_up_date),
        },
        permit_follow_up_date_value: {
          value: val.follow_up_date,
        },
        muncipality: {
          value: val.muncipality,
        },
        permit_number: {
          value: val.permit_number,
        },
        permit_application_date: {
          value: formatDate(val.application_date),
        },
        permit_expiration_date: {
          value: formatDate(val.expiration_date),
        },
        permit_application_date_value: {
          value: val.application_date,
        },
        permit_expiration_date_value: {
          value: val.expiration_date,
        },
        emergency_permit: {
          value:
            val.is_emergency_permit === 1
              ? 'Yes'
              : val.is_emergency_permit === 0
                ? 'No'
                : '',
        },
        inspection_required: {
          value:
            val.is_inspection_required === 1
              ? 'Yes'
              : val.is_inspection_required === 0
                ? 'No'
                : '',
        },
        permit_required: {
          value:
            val.is_permit_required === 1
              ? 'Yes'
              : val.is_permit_required === 0
                ? 'No'
                : '',
        },
        permit_comments: {
          value: val.comments,
        },
        status_id: {
          value: val.status_id,
        },
        status: {
          value: val.status,
        },
        loa_date_sent: {
          value: val.loa_date_sent,
        },
        loa_sent_to: {
          value: val.loa_sent_to,
        },
        loa_date_received: {
          value: val.loa_date_received,
        },
        no_loa_required: {
          value: val.no_loa_required === 1 ? true : false,
        },
        permit_cost: {
          value: val.permit_cost,
        },
      };
    });
  }

  // **** Form Validation Schema ****
  const PermittingValidationSchema = Yup.object().shape({
    permit_follow_up_date: Yup.date().nullable().default(null),
    muncipality: Yup.string().trim().nullable(),
    permit_number: Yup.string()
      .nullable()
      .when('status_id', {
        is: 138,
        then: Yup.string().trim().required('Required'),
      }),
    permit_application_date: Yup.date()
      .nullable()
      .when('status_id', {
        is: PermitsStatusTypeId.APPLICATIONSUBMITTED,
        then: Yup.date().required('Required'),
      }),
    permit_expiration_date: Yup.date()
      .nullable()
      .when('status_id', {
        is: 138,
        then: Yup.date().required('Required'),
      }),
    emergency_permit: Yup.string().trim().required('Required'),
    inspection_required: Yup.string()
      .trim()
      .nullable()
      .when('permit_required', {
        is: 'Yes',
        then: Yup.string().trim().required('Required'),
      }),
    permit_required: Yup.string().trim().required('Required'),
    status_id: Yup.number().required('Required.'),
    permit_comments: Yup.string().trim().nullable(),
  });

  // **** Formik Form Values ****
  const permittingFormik = useFormik({
    initialValues: {
      permit_follow_up_date:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.permit_follow_up_date_value
            .value) ||
        null,
      muncipality:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.muncipality.value) ||
        '',
      permit_number:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.permit_number.value) ||
        '',
      permit_application_date:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.permit_application_date_value
            .value) ||
        null ||
        null,
      permit_expiration_date:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.permit_expiration_date_value
            .value) ||
        null ||
        null,
      emergency_permit:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.emergency_permit.value) ||
        '',
      inspection_required:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.inspection_required.value) ||
        '',
      permit_required:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.permit_required.value) ||
        '',
      permit_comments:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.permit_comments.value) ||
        '',
      status_id:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.status_id.value) ||
        '',
      status:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.status.value) ||
        '',
      loa_date_sent:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.loa_date_sent?.value) ||
        null ||
        null,
      loa_sent_to:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.loa_sent_to?.value) ||
        '',
      loa_date_received:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.loa_date_received?.value) ||
        null ||
        null,
      no_loa_required:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.no_loa_required?.value) ||
        '',
      permit_cost:
        (formAction !== 'add' &&
          presentationPermittingList[rowIndex]?.permit_cost?.value) ||
        '',
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (formAction === 'add') {
        createPermit(
          formik?.values?.project_id,
          {
            follow_up_date: permittingFormik.values.permit_follow_up_date,
            ...{
              muncipality: permittingFormik.values.muncipality.trim() || null,
            },
            ...{
              permit_number:
                permittingFormik.values.permit_number.trim() || null,
            },
            ...{
              application_date: permittingFormik.values.permit_application_date,
            },
            ...{
              expiration_date: permittingFormik.values.permit_expiration_date,
            },
            is_emergency_permit:
              permittingFormik.values.emergency_permit === 'Yes'
                ? 1
                : permittingFormik.values.emergency_permit === 'No'
                  ? 0
                  : '',
            is_inspection_required:
              permittingFormik.values.inspection_required === 'Yes'
                ? 1
                : permittingFormik.values.inspection_required === 'No'
                  ? 0
                  : '',
            is_permit_required:
              permittingFormik.values.permit_required === 'Yes'
                ? 1
                : permittingFormik.values.permit_required === 'No'
                  ? 0
                  : '',
            comments: permittingFormik.values.permit_comments.trim() || null,
            status_id: permittingFormik.values.status_id || null,
            loa_date_sent: permittingFormik.values?.loa_date_sent,
            loa_sent_to: permittingFormik.values?.loa_sent_to?.trim() || null,
            loa_date_received: permittingFormik.values?.loa_date_received,
            no_loa_required:
              permittingFormik.values?.no_loa_required === true
                ? 1
                : permittingFormik.values?.permit_required === false
                  ? 0
                  : null,
            permit_cost: permittingFormik.values?.permit_cost
              ? cleanCurrencyPayload(permittingFormik.values?.permit_cost)
              : null,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen,
          resetForm
        );
      } else {
        updatePermit(
          formik?.values?.project_id,
          permittingResponse.rows[rowIndex]?.project_permit_id,
          {
            follow_up_date: permittingFormik.values.permit_follow_up_date,
            ...{
              muncipality: permittingFormik.values.muncipality.trim() || null,
            },
            ...{
              permit_number:
                permittingFormik.values.permit_number.trim() || null,
            },
            ...{
              application_date: permittingFormik.values.permit_application_date,
            },
            ...{
              expiration_date: permittingFormik.values.permit_expiration_date,
            },
            is_emergency_permit:
              permittingFormik.values.emergency_permit === 'Yes'
                ? 1
                : permittingFormik.values.emergency_permit === 'No'
                  ? 0
                  : '',
            is_inspection_required:
              permittingFormik.values.inspection_required === 'Yes'
                ? 1
                : permittingFormik.values.inspection_required === 'No'
                  ? 0
                  : '',
            is_permit_required:
              permittingFormik.values.permit_required === 'Yes'
                ? 1
                : permittingFormik.values.permit_required === 'No'
                  ? 0
                  : '',
            comments: permittingFormik.values.permit_comments.trim() || null,
            status_id: permittingFormik.values.status_id || null,
            loa_date_sent: permittingFormik.values?.loa_date_sent,
            loa_sent_to: permittingFormik.values?.loa_sent_to?.trim() || null,
            loa_date_received: permittingFormik.values?.loa_date_received,
            no_loa_required:
              permittingFormik.values?.no_loa_required === true
                ? 1
                : permittingFormik.values?.permit_required === false
                  ? 0
                  : null,
            permit_cost: permittingFormik.values?.permit_cost
              ? cleanCurrencyPayload(permittingFormik.values?.permit_cost)
              : null,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen,
          resetForm
        );
      }
      setReloadList(false);
    },
    validationSchema: PermittingValidationSchema,
    enableReinitialize: true,
  });

  //*** Labor Items - Action Handlers ***/

  const addNewPermit = () => {
    permittingFormik.handleReset();
    setFormAction('add');

    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Add Permit',
    }));

    permittingFormik?.setValues({
      permit_follow_up_date: null,
      muncipality: '',
      permit_number: '',
      permit_application_date: null,
      permit_expiration_date: null,
      emergency_permit: 'No',
      inspection_required: 'No',
      permit_required: 'No',
      permit_comments: '',
      status_id: '',
      status: '',
    });

    setIsOpen(true);
  };

  const handlePullPermitCheckbox = () => {
    formik.setFieldValue('is_pull_permit_required', true);
  };

  const viewEditPermit = (action, index) => {
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title: (action === 'view' && 'View Permit') || 'Edit Permit',
    }));

    setRowIndex(index);
    setFormAction(action);

    setIsOpen(true);
  };

  const deletePermitting = index => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: `Are you sure you want to delete Permit?`,
      header: 'Delete Permit',
    });
    setRowIndex(index);
  };

  const onPermitConfirmDialog = () => {
    setReloadList(false);
    deletePermit(
      formik?.values?.project_id,
      permittingResponse.rows[rowIndex]?.project_permit_id,
      setLoading,
      setAlert,
      setReloadList,
      setConfirmDialog
    );
  };

  const getPermitViewEditForm = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        className={
          (formAction === 'edit' || formAction === 'add') && classes.formEdit
        }
      >
        <Grid item className="mui-calendar">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              autoOk
              id="permit_follow_up_date"
              name="permit_follow_up_date"
              variant="inline"
              inputProps={{ autoComplete: 'off' }}
              label="Permit Follow Up Date"
              invalidDateMessage="Invalid Date"
              format="MM-dd-yyyy"
              value={
                (permittingFormik?.values?.permit_follow_up_date?.split('T')
                  ?.length &&
                  parseISO(
                    permittingFormik?.values?.permit_follow_up_date?.slice(
                      0,
                      10
                    )
                  )) ||
                null
              }
              onChange={(date, value) =>
                permittingFormik?.setFieldValue(
                  'permit_follow_up_date',
                  `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                )
              }
              onBlur={permittingFormik.handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formAction === 'view'}
              InputProps={{
                readOnly: formAction === 'view' ? true : false,
              }}
              disablePast
              error={
                permittingFormik?.touched?.permit_follow_up_date &&
                permittingFormik?.errors?.permit_follow_up_date
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <Autocomplete
            id="permit_required"
            name="permit_required"
            options={['Yes', 'No']}
            disableListWrap
            required={formAction === 'edit' || formAction === 'add'}
            getOptionLabel={option => option}
            renderInput={params => (
              <TextField
                {...params}
                label="Permit Required"
                required={formAction === 'edit' || formAction === 'add'}
              />
            )}
            value={permittingFormik?.values?.permit_required || ''}
            onChange={(event, value) => {
              permittingFormik.setFieldValue('permit_required', value);
              if (value === 'No') {
                permittingFormik.setFieldValue('inspection_required', 'No');
              }
            }}
            onBlur={permittingFormik.handleBlur}
            disabled={formAction === 'view'}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            id="inspection_required"
            name="inspection_required"
            options={['Yes', 'No']}
            disableListWrap
            required={
              (formAction === 'edit' || formAction === 'add') &&
              permittingFormik?.values?.permit_required === 'Yes'
            }
            InputProps={{
              readOnly:
                formAction === 'view' ||
                permittingFormik?.values?.permit_required === 'No',
            }}
            getOptionLabel={option => option}
            renderInput={params => (
              <TextField
                {...params}
                label="Inspection Required"
                required={
                  (formAction === 'edit' || formAction === 'add') &&
                  permittingFormik?.values?.permit_required === 'Yes'
                }
              />
            )}
            value={permittingFormik?.values?.inspection_required || ''}
            onChange={(event, value) =>
              permittingFormik.setFieldValue('inspection_required', value)
            }
            onBlur={permittingFormik.handleBlur}
            disabled={
              formAction === 'view' ||
              permittingFormik?.values?.permit_required === 'No'
            }
          />
        </Grid>
        <Grid item>
          <Autocomplete
            id="permit_status"
            name="permit_status"
            options={permitOptions || []}
            disableListWrap
            required={formAction === 'edit' || formAction === 'add'}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            getOptionLabel={option => option?.status}
            renderInput={params => (
              <TextField
                {...params}
                label="Permit Status"
                required={formAction === 'edit' || formAction === 'add'}
              />
            )}
            value={
              permitOptions?.find(
                type => type.status_id === permittingFormik?.values?.status_id
              ) || null
            }
            onChange={(event, value) =>
              permittingFormik.setFieldValue('status_id', value?.status_id)
            }
            onBlur={permittingFormik.handleBlur}
            disabled={formAction === 'view'}
            error={
              permittingFormik?.touched?.status_id &&
              permittingFormik?.errors?.status_id
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="muncipality"
            label="Municipality"
            name="muncipality"
            onChange={permittingFormik.handleChange}
            onBlur={permittingFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={permittingFormik?.values?.muncipality}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
            error={
              permittingFormik.touched.muncipality &&
              permittingFormik.errors.muncipality
            }
            helperText={
              permittingFormik.touched.muncipality &&
              permittingFormik.errors.muncipality
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="permit_number"
            label="Permit Number"
            name="permit_number"
            onChange={permittingFormik.handleChange}
            onBlur={permittingFormik.handleBlur}
            required={
              (formAction === 'edit' || formAction === 'add') &&
              permittingFormik?.values?.status_id === 138
            }
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={permittingFormik?.values?.permit_number}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </Grid>
        <Grid item className="mui-calendar">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              autoOk
              id="permit_application_date"
              name="permit_application_date"
              variant="inline"
              label="Permit Application Date"
              format="MM-dd-yyyy"
              inputProps={{ autoComplete: 'off' }}
              required={
                (formAction === 'edit' || formAction === 'add') &&
                permittingFormik?.values?.status_id ===
                  PermitsStatusTypeId.APPLICATIONSUBMITTED
              }
              invalidDateMessage="Invalid Date"
              value={
                (permittingFormik?.values?.permit_application_date?.split('T')
                  ?.length &&
                  parseISO(
                    permittingFormik?.values?.permit_application_date?.slice(
                      0,
                      10
                    )
                  )) ||
                null
              }
              onChange={(date, value) => {
                if (value !== null || '') {
                  permittingFormik?.setFieldValue(
                    'permit_application_date',
                    `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                  );
                } else {
                  permittingFormik?.setFieldValue(
                    'permit_application_date',
                    null
                  );
                }
              }}
              onBlur={permittingFormik.handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formAction === 'view'}
              InputProps={{
                readOnly: formAction === 'view',
              }}
              disablePast
              error={
                permittingFormik?.touched?.permit_application_date &&
                permittingFormik?.errors?.permit_application_date
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item className="mui-calendar">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              autoOk
              id="permit_expiration_date"
              name="permit_expiration_date"
              variant="inline"
              label="Permit Expiration Date"
              required={
                (formAction === 'edit' || formAction === 'add') &&
                permittingFormik?.values?.status_id === 138
              }
              format="MM-dd-yyyy"
              inputProps={{ autoComplete: 'off' }}
              invalidDateMessage="Invalid Date"
              value={
                (permittingFormik?.values?.permit_expiration_date?.split('T')
                  ?.length &&
                  parseISO(
                    permittingFormik?.values?.permit_expiration_date?.slice(
                      0,
                      10
                    )
                  )) ||
                null
              }
              onChange={(date, value) =>
                permittingFormik?.setFieldValue(
                  'permit_expiration_date',
                  `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                )
              }
              onBlur={permittingFormik.handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formAction === 'view'}
              InputProps={{
                readOnly: formAction === 'view',
              }}
              disablePast
              error={
                permittingFormik?.touched?.permit_expiration_date &&
                permittingFormik?.errors?.permit_expiration_date
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <Autocomplete
            id="emergency_permit"
            name="emergency_permit"
            required={formAction === 'edit' || formAction === 'add'}
            options={['Yes', 'No']}
            disableListWrap
            getOptionLabel={option => option}
            renderInput={params => (
              <TextField
                {...params}
                label="Emergency Permit"
                required={formAction === 'edit' || formAction === 'add'}
              />
            )}
            value={permittingFormik?.values?.emergency_permit || ''}
            onChange={(event, value) =>
              permittingFormik.setFieldValue('emergency_permit', value)
            }
            onBlur={permittingFormik.handleBlur}
            disabled={formAction === 'view'}
          />
        </Grid>

        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              autoOk
              id="loa_date_sent"
              name="loa_date_sent"
              variant="inline"
              label="LOA Date Sent"
              format="MM-dd-yyyy"
              inputProps={{ autoComplete: 'off' }}
              invalidDateMessage="Invalid Date"
              value={
                (permittingFormik?.values?.loa_date_sent?.split('T')?.length &&
                  parseISO(
                    permittingFormik?.values?.loa_date_sent?.slice(0, 10)
                  )) ||
                null
              }
              onChange={(date, value) =>
                permittingFormik?.setFieldValue(
                  'loa_date_sent',
                  `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                )
              }
              onBlur={permittingFormik.handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formAction === 'view'}
              disablePast
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item>
          <TextField
            id="loa_sent_to"
            label="LOA Sent To"
            name="loa_sent_to"
            onChange={permittingFormik.handleChange}
            onBlur={permittingFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            value={permittingFormik?.values?.loa_sent_to}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </Grid>

        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              autoOk
              id="loa_date_received"
              name="loa_date_received"
              variant="inline"
              label="LOA Date Received"
              format="MM-dd-yyyy"
              inputProps={{ autoComplete: 'off' }}
              invalidDateMessage="Invalid Date"
              value={
                (permittingFormik?.values?.loa_date_received?.split('T')
                  ?.length &&
                  parseISO(
                    permittingFormik?.values?.loa_date_received?.slice(0, 10)
                  )) ||
                null
              }
              onChange={(date, value) =>
                permittingFormik?.setFieldValue(
                  'loa_date_received',
                  `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                )
              }
              onBlur={permittingFormik.handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formAction === 'view'}
              InputProps={{
                readOnly: formAction === 'view',
              }}
              disablePast
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item>
          <NumberFormat
            id="permit_cost"
            name="permit_cost"
            inputProps={{ maxLength: 15 }}
            allowNegative={false}
            onChange={permittingFormik.handleChange}
            onBlur={permittingFormik.handleBlur}
            prefix="$"
            label={`Permit Cost`}
            decimalScale={2}
            customInput={TextField}
            thousandSeparator={true}
            inputmode="numeric"
            value={permittingFormik?.values?.permit_cost}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item>
          <TextField
            id="permit_comments"
            label="Comments"
            placeholder="Max 500 characters"
            multiline
            maxRows={5}
            inputProps={{ maxLength: 500 }}
            name="permit_comments"
            onChange={permittingFormik.handleChange}
            onBlur={permittingFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={permittingFormik?.values?.permit_comments}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12} className="pl-0">
          <Checkbox
            type="checkbox"
            name="no_loa_required"
            defaultChecked={permittingFormik?.values?.no_loa_required || false}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            onChange={permittingFormik.handleChange}
            disabled={formAction === 'view'}
          />
          No LOA Required
        </Grid>
      </Grid>
    );
  };

  const permittingColumnData = loading
    ? [
        { id: '', value: '', style: { width: '77px' } },
        ...permittingTableColumns,
      ]
    : permittingTableColumns;

  const permittingTableLoader = useMemo(() => {
    return TableColumnsLoader(permittingColumnData, {
      rows: 4,
      isValue: true,
    });
  }, [permittingColumnData]);

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item classes={{ root: classes.tableWrapper }}>
          <GenericTable
            title="Permitting"
            columnData={permittingColumnData}
            rowData={
              loading ? permittingTableLoader : presentationPermittingList
            }
            {...(!loading
              ? {
                  showActions: { view: true, edit: true, delete: true },
                  handleView: index => viewEditPermit('view', index),
                  handleEdit: index => viewEditPermit('edit', index),
                  handleDelete: index => deletePermitting(index),
                }
              : {})}
            headerLinks={[
              ...(formik?.values?.project_type?.project_type === 'Installation'
                ? [
                    {
                      label: 'Is Pull Permit Required',
                      handler: handlePullPermitCheckbox,
                      disabled:
                        action === 'view' ||
                        formik?.values?.is_pull_permit_required === 1 ||
                        formik?.values?.is_pull_permit_required === '1' ||
                        (formik?.values?.current_activity === 'Pull Permit' &&
                          formik?.values?.current_activity_status === 'Open'),
                      value: formik?.values?.is_pull_permit_required,
                      type: 'checkbox',
                    },
                  ]
                : []),
              {
                label: 'Add Item',
                handler: addNewPermit,
                add: permissions?.viewEditProject?.tabPermittingAdd,
              },
            ]}
            permissions={{
              view: permissions?.viewEditProject?.tabPermitting,
              edit: permissions?.viewEditProject?.tabPermittingModify,
              delete: permissions?.viewEditProject?.tabPermittingDelete,
            }}
          />
        </Grid>
      </Grid>

      {/* Dialog form for Add,edit form */}

      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          permittingFormik?.handleReset();
        }}
        handleSave={permittingFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={!permittingFormik?.dirty || !permittingFormik?.isValid}
        disabledButton1={false}
      >
        <form onSubmit={permittingFormik.handleSubmit}>
          {getPermitViewEditForm()}
        </form>
      </GenericDialog>
      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onPermitConfirmDialog}
      />
    </>
  );
};

export default Permitting;
