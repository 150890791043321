import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { ConfirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';

// import { getProjectStatusList } from '../../../Scheduler/new-scheduler/service/new-scheduler.service';
import { multiPartUpdateTenantData } from '../service/TenantConfig.service';
import IconsDropdownComponent from '../../../shared/IconsDropdown/IconsDropdownComponent';
import PFButton from '../../../shared/PFPrime/PFButton';
import {
  getProjectCategories,
  getProjectTypes,
  getProjectStatusList,
} from '../service/ServiceTimeConfigService';

const ICON_OPTIONS = [
  { name: 'pi pi-bell', label: 'Bell' },
  { name: 'pi pi-bolt', label: 'Bolt' },
  { name: 'pi pi-briefcase', label: 'Briefcase' },
  { name: 'pi pi-calendar', label: 'Calendar' },
  { name: 'pi pi-calendar-minus', label: 'Calendar Minus' },
  { name: 'pi pi-calendar-plus', label: 'Calendar Plus' },
  { name: 'pi pi-calendar-times', label: 'Calendar Times' },
  { name: 'pi pi-chart-pie', label: 'Chart Pie' },
  { name: 'pi pi-clock', label: 'Clock' },
  { name: 'pi pi-envelope', label: 'Envelope' },
  { name: 'pi pi-flag', label: 'Flag' },
  { name: 'pi pi-info-circle', label: 'Info Circle' },
  { name: 'pi pi-lock', label: 'Lock' },
  { name: 'pi pi-map', label: 'Map' },
  { name: 'pi pi-phone', label: 'Phone' },
  { name: 'pi pi-star', label: 'Star' },
  { name: 'pi pi-stopwatch', label: 'Stopwatch' },
  { name: 'pi pi-truck', label: 'Truck' },
  { name: 'pi pi-wrench', label: 'Wrench' },
];
const validation = Yup.object().shape({
  future_scheduling_days_limit: Yup.number().optional().nullable(),
  auto_scheduling_wait_time: Yup.number().optional().nullable(),
  job_identifier: Yup.string().optional().nullable(),
  app_job_status: Yup.array().of(Yup.number()).optional().nullable(),
  app_job_type: Yup.array().of(Yup.number()).optional().nullable(),
  app_job_category: Yup.array().of(Yup.number()).optional().nullable(),
  consecutive_jobs_break_time: Yup.number().optional().nullable(),
});
const SchedulingComponent = ({ clientId, clientDetail }) => {
  if (!clientId) return <></>;
  const clientJobStatus = useMemo(() => {
    if (Array.isArray(clientDetail?.customer_scheduling_app_job_statuses)) {
      return clientDetail?.customer_scheduling_app_job_statuses.map(
        eachStatus => eachStatus.status_id
      );
    }
    return [];
  }, [clientDetail?.customer_scheduling_app_job_statuses]);
  const clientJobType = useMemo(() => {
    if (Array.isArray(clientDetail?.customer_scheduling_app_job_types)) {
      return clientDetail?.customer_scheduling_app_job_types.map(
        eachType => eachType.project_type_id
      );
    }
    return [];
  }, [clientDetail?.customer_scheduling_app_job_types]);
  const clientJobCategory = useMemo(() => {
    if (Array.isArray(clientDetail?.customer_scheduling_app_job_categories)) {
      return clientDetail?.customer_scheduling_app_job_categories.map(
        eachCategory => eachCategory.project_category_id
      );
    }
    return [];
  }, [clientDetail?.customer_scheduling_app_job_categories]);
  const { capture_live_location = 0 } = clientDetail;
  const [loading, setLoading] = useState(false);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [jobBreakTimeDialogVisibility, setJobBreakTimeDialogVisibility] =
    useState(false);
  const toast = useRef(null);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      const payload = {
        client_id: clientId,
        app_job_status: Array.isArray(values?.app_job_status)
          ? values?.app_job_status.map(e => e.status_id)
          : [],
        app_job_type: Array.isArray(values?.app_job_type)
          ? values?.app_job_type.map(e => e.project_type_id)
          : [],
        app_job_category: Array.isArray(values?.app_job_category)
          ? values?.app_job_category.map(e => e.project_category_id)
          : [],
        ...values,
      };
      const apiResponse = await multiPartUpdateTenantData(payload, true);
      if (apiResponse?.data?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Record Updated Successfully',
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error occurred while Updating record',
          life: 1500,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.current.show({
          severity: 'error',
          detail: 'Something Went Wrong',
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      future_scheduling_days_limit:
        clientDetail?.future_scheduling_days_limit || null,
      auto_scheduling_wait_time:
        clientDetail?.auto_scheduling_wait_time || null,
      job_identifier: clientDetail?.job_identifier || null,
      app_job_status: clientJobStatus,
      app_job_type: clientJobType,
      app_job_category: clientJobCategory,
      is_consecutive_jobs_break_time:
        'object' === typeof clientDetail &&
        clientDetail?.consecutive_jobs_break_time !== null
          ? clientDetail?.consecutive_jobs_break_time
          : true,
      consecutive_jobs_break_time: capture_live_location
        ? 0
        : clientDetail?.consecutive_jobs_break_time || 0,
    },
    validationSchema: validation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    setLoading(true);
    Promise.all([
      getProjectStatusList(),
      getProjectTypes(),
      getProjectCategories(clientId),
    ])
      .then(([statusData, typeData, categoryData]) => {
        setProjectStatuses(
          Array.isArray(statusData) && statusData.length
            ? statusData.filter(e => e?.status)
            : []
        );
        setProjectTypes(
          Array.isArray(typeData) && typeData.length
            ? typeData.filter(e => e?.project_type)
            : []
        );
        setProjectCategories(
          Array.isArray(categoryData) && categoryData.length
            ? categoryData.filter(e => e?.category)
            : []
        );
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);
  const CardHeader = (
    <div className="flex justify-content-between align-items-center">
      <p>Default Configuration</p>
      <span className="flex flex-end align-items-center">
        {loading ? (
          <Skeleton width="5rem" height="3rem" />
        ) : (
          <span className="w-full">
            <PFButton
              label="Save"
              size="small"
              onClick={formik.handleSubmit}
              disabled={!formik.dirty || !formik.errors || loading}
            />
          </span>
        )}
      </span>
    </div>
  );
  const appJobStatusSortedItems = useMemo(() => {
    if (
      Array.isArray(formik.values?.app_job_status) &&
      formik.values?.app_job_status.length
    ) {
      const selectedSet = new Set(formik.values.app_job_status);
      return projectStatuses.sort((a, b) => {
        if (selectedSet.has(a.status_id) && !selectedSet.has(b.status_id)) {
          return -1;
        } else if (
          !selectedSet.has(a.status_id) &&
          selectedSet.has(b.status_id)
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return projectStatuses;
  }, [formik.values?.app_job_status, projectStatuses]);
  const appJobTypeSortedItems = useMemo(() => {
    if (
      Array.isArray(formik.values?.app_job_type) &&
      formik.values?.app_job_type.length
    ) {
      const selectedSet = new Set(formik.values.app_job_type);
      return projectTypes.sort((a, b) => {
        if (
          selectedSet.has(a.project_type_id) &&
          !selectedSet.has(b.project_type_id)
        ) {
          return -1;
        } else if (
          !selectedSet.has(a.project_type_id) &&
          selectedSet.has(b.project_type_id)
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return projectTypes;
  }, [formik.values?.app_job_type, projectTypes]);
  const appJobCategorySortedItems = useMemo(() => {
    if (
      Array.isArray(formik.values?.app_job_category) &&
      formik.values?.app_job_category.length
    ) {
      const selectedSet = new Set(formik.values.app_job_category);
      return projectCategories.sort((a, b) => {
        if (
          selectedSet.has(a.project_category_id) &&
          !selectedSet.has(b.project_category_id)
        ) {
          return -1;
        } else if (
          !selectedSet.has(a.project_category_id) &&
          selectedSet.has(b.project_category_id)
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return projectCategories;
  }, [formik.values?.app_job_category, projectCategories]);
  return (
    <Card
      title={CardHeader}
      pt={{
        body: {
          className: 'pt-1',
        },
        title: {
          className: 'p-0 mb-0 mt-0',
        },
        content: {
          className: 'p-0 mt-0',
        },
      }}
    >
      <Toast ref={toast} />
      <div className="flex flex-start align-items-center gap-3 flex-wrap w-full">
        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <div className="p-inputgroup flex-1">
              <span className="p-float-label flex align-items-center">
                <InputNumber
                  className="w-full p-inputtext-sm"
                  inputClassName="border-noround-right"
                  autoComplete="off"
                  id="future_scheduling_days_limit"
                  name="future_scheduling_days_limit"
                  onChange={e =>
                    formik.setFieldValue(
                      'future_scheduling_days_limit',
                      e.value
                    )
                  }
                  value={formik.values.future_scheduling_days_limit}
                  onBlur={formik.handleBlur}
                  min={0}
                />
                <label htmlFor="future_scheduling_days_limit">
                  Future Scheduling Days Limit
                </label>
              </span>
              <span className="p-inputgroup-addon height-40">days</span>
            </div>
          )}
        </div>

        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <div className="p-inputgroup flex-1">
              <span className="p-float-label flex align-items-center">
                <InputNumber
                  className="w-full p-inputtext-sm"
                  inputClassName="border-noround-right"
                  autoComplete="off"
                  id="auto_scheduling_wait_time"
                  name="auto_scheduling_wait_time"
                  onChange={e =>
                    formik.setFieldValue('auto_scheduling_wait_time', e.value)
                  }
                  value={formik.values.auto_scheduling_wait_time}
                  onBlur={formik.handleBlur}
                  min={0}
                />
                <label htmlFor="auto_scheduling_wait_time">
                  Auto scheduling wait time
                </label>
              </span>
              <span className="p-inputgroup-addon height-40">minutes</span>
            </div>
          )}
        </div>

        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <IconsDropdownComponent
              id="job_identifier"
              name="job_identifier"
              label="Job Identifier"
              value={formik.values.job_identifier}
              onChange={e => formik.setFieldValue('job_identifier', e.value)}
              other={{
                filter: true,
                onBlur: formik.handleBlur,
                options: ICON_OPTIONS,
              }}
            />
          )}
        </div>

        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <span className="p-float-label flex align-items-center">
              <MultiSelect
                id="app_job_type"
                name="app_job_type"
                className="w-full height-40 border-round"
                style={{
                  maxHeight: 40,
                }}
                value={formik.values?.app_job_type}
                onChange={e => formik.setFieldValue('app_job_type', e.value)}
                options={appJobTypeSortedItems || []}
                optionLabel="project_type"
                optionValue="project_type_id"
                selectedItemTemplate={
                  (Array.isArray(formik.values?.app_job_type) &&
                    formik.values?.app_job_type.length) ||
                  0
                }
                maxSelectedLabels={0}
                pt={{
                  label: {
                    className: 'text-sm',
                  },
                }}
                filter
              />
              <label htmlFor="app_job_type">Applicable Job Types</label>
            </span>
          )}
        </div>

        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <span className="p-float-label flex align-items-center">
              <MultiSelect
                id="app_job_category"
                name="app_job_category"
                className="w-full height-40 border-round"
                style={{
                  maxHeight: 40,
                }}
                value={formik.values?.app_job_category}
                onChange={e =>
                  formik.setFieldValue('app_job_category', e.value)
                }
                options={appJobCategorySortedItems || []}
                optionLabel="category"
                optionValue="project_category_id"
                selectedItemTemplate={
                  (Array.isArray(formik.values?.app_job_category) &&
                    formik.values?.app_job_category.length) ||
                  0
                }
                maxSelectedLabels={0}
                pt={{
                  label: {
                    className: 'text-sm',
                  },
                }}
                filter
              />
              <label htmlFor="app_job_category">
                Applicable Job Categories
              </label>
            </span>
          )}
        </div>

        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <span className="p-float-label flex align-items-center">
              <MultiSelect
                id="app_job_status"
                name="app_job_status"
                className="w-full height-40 border-round"
                style={{
                  maxHeight: 40,
                }}
                value={formik.values?.app_job_status}
                onChange={e => formik.setFieldValue('app_job_status', e.value)}
                options={appJobStatusSortedItems || []}
                optionLabel="status"
                optionValue="status_id"
                selectedItemTemplate={
                  (Array.isArray(formik.values?.app_job_status) &&
                    formik.values?.app_job_status.length) ||
                  0
                }
                maxSelectedLabels={0}
                pt={{
                  label: {
                    className: 'text-sm',
                  },
                }}
                filter
              />
              <label htmlFor="app_job_status">Applicable Job Status</label>
            </span>
          )}
        </div>

        <div className="w-20rem">
          {loading ? (
            <Skeleton className="w-full" height="3rem" />
          ) : (
            <div className="p-inputgroup flex-1 align-items-center">
              <span
                className="p-inputgroup-addon height-40"
                style={{
                  minWidth: '5px',
                }}
              >
                <Checkbox
                  checked={
                    capture_live_location
                      ? 0
                      : formik.values?.is_consecutive_jobs_break_time
                  }
                  onChange={e => {
                    if (e.target.checked)
                      formik.setFieldValue(
                        'is_consecutive_jobs_break_time',
                        e.target.checked
                      );
                    else setJobBreakTimeDialogVisibility(true);
                  }}
                  className="p-button-success p-0"
                  disabled={!!capture_live_location}
                />
              </span>
              <span className="p-float-label flex align-items-center w-full">
                <InputNumber
                  className="w-full p-inputtext-sm"
                  inputClassName="border-noround-right border-noround-left"
                  autoComplete="off"
                  id="consecutive_jobs_break_time"
                  name="consecutive_jobs_break_time"
                  onChange={e =>
                    formik.setFieldValue('consecutive_jobs_break_time', e.value)
                  }
                  value={formik.values.consecutive_jobs_break_time}
                  onBlur={formik.handleBlur}
                  disabled={
                    !!capture_live_location ||
                    !formik.values?.is_consecutive_jobs_break_time
                  }
                  min={0}
                />
                <label htmlFor="consecutive_jobs_break_time">
                  Consecutive jobs break time
                </label>
              </span>
              <span className="p-inputgroup-addon height-40 w-6rem">
                minutes
              </span>
              <ConfirmDialog
                visible={!capture_live_location && jobBreakTimeDialogVisibility}
                onHide={() => {
                  formik.setFieldValue(
                    'is_consecutive_jobs_break_time',
                    !!formik.values?.is_consecutive_jobs_break_time
                  );
                  setJobBreakTimeDialogVisibility(false);
                }}
                group="templating"
                message={
                  <div className="w-full flex flex-start gap-3">
                    <i className="pi pi-exclamation-triangle text-5xl text-yellow-700	flex align-items-center"></i>
                    <div className="flex flex-column flex-wrap">
                      <span className="max-w-25rem">
                        Disabling default consecutive job break time will
                        consider technician live location and would incur
                        additional operational cost.
                      </span>
                      <span>Would you still like to continue ?</span>
                    </div>
                  </div>
                }
                header="Confirmation"
                footer={
                  <span className="w-full justify-content-end align-items-center gap-3">
                    <PFButton
                      label="Cancel"
                      size="small"
                      outlined
                      onClick={() => {
                        formik.setFieldValue(
                          'is_consecutive_jobs_break_time',
                          true
                        );
                        setJobBreakTimeDialogVisibility(false);
                      }}
                    />
                    <PFButton
                      label="Confirm"
                      size="small"
                      onClick={() => {
                        formik.setFieldValue(
                          'is_consecutive_jobs_break_time',
                          false
                        );
                        formik.setFieldValue('consecutive_jobs_break_time', 0);
                        setJobBreakTimeDialogVisibility(false);
                      }}
                    />
                  </span>
                }
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default SchedulingComponent;
