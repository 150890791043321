// **** React Imports ****
import React, { useMemo, useState, useEffect } from 'react';
import {
  Avatar,
  Grid,
  Paper,
  Typography,
  TextField,
  Card,
  CardActionArea,
  CardContent,
  Button,
  Checkbox,
  Tooltip as MUITooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Add as AddIcon } from '@material-ui/icons';
import { Image } from 'primereact';
import { Tooltip } from 'primereact/tooltip';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { getRelatedPOData } from '../RelatedPO/RelatedPO.service';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { checkPermission, formatDateTimeMDYHM } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { DOCUMENT_PHOTO_TYPE } from '../../../constants';
import eyeOffIcon from '../../../assets/images/eye-off.svg';
import eyeOnIcon from '../../../assets/images/eye-show.svg';
import deleteOffIcon from '../../../assets/images/delete-icon-inactive.svg';
import expandOffIcon from '../../../assets/images/expand-icon-inactive.svg';
import PFDialog from '../../shared/PFPrime/PFDialog';
import { getPreSignedUrl } from '../../Admin/UserManagement/UserManagement.service';
import { epaDocumentsStyle } from '../ProjectInfo/helpers/helpers';
import DocPhotosLoader from '../../shared/Loader/DocSkeleton.js';

import RotatePhotoComponent from './Components/RotatePhotoComponent';
import { UploadActionContext } from './context/context';
import RelatedMeasurementPhotosCard from './RelatedMeasurementPhotosCard';
import {
  getListOfDocuments,
  getDocumentTypes,
  upLoadDocumentOrPhoto,
  updateDocumentById,
  getRelatedDocuments,
  applyInstallerVisibilityDocuments,
  deleteProjectDocument,
  bulkUpdateService,
} from './Docs.service';
import { useStyles } from './Docs.styles.js';
import UploadStoreTypeDialog from './UploadStoreTypeDialog';
const Photos = ({
  projectId,
  formik,
  setReloadForm,
  projectLeadRequired = null,
  projectDetailsData,
  setOpenPopup,
  clientDetails,
  photoCount,
  setPhotoAlert,
  setConfirmDialog,
  setPhotoCount,
  orderConfigData,
}) => {
  const [documents, setDocuments] = React.useState([]);
  const [relatedDocuments, setRelatedDocuments] = React.useState([]);

  const [getPhotoAddPermission, setPhotoAddPermission] = React.useState(false);
  const imageInputRef = React.useRef();
  const [photoError, setPhotoError] = React.useState(null);
  const [, setdocumentTypes] = useState([]);
  const [relatedPOResponse, setRelatedPOResponse] = React.useState({});
  const [loading, setLoading] = useState();
  const [photosLoader, setPhotosLoader] = useState();
  const [reloadList, setReloadList] = useState(false);
  const [selectedJob, setSelectedJob] = React.useState();
  const [relatedDocloading, setRelatedDocloading] = useState(false);
  const [photoDetailsToRotate, setPhotoDetailsToRotate] = useState(null);
  const [relatedOfflineDocuments, setRelatedOfflineDocuments] = React.useState(
    []
  );
  const [relatedDocsPayload, setRelatedDocsPayload] = React.useState([]);
  const [relatedProjectDocsOfflineMode, setRelatedProjectDocsOfflineMode] =
    React.useState(false);
  const installerVisible = !checkPermission(
    permissions?.userManagement?.userManagementModifyInstaller
  );
  const [reviewPermission] = React.useState(
    checkPermission(permissions?.documentCenter?.viewPhotoReview)
  );

  const [addPhotoCategoryPermission] = React.useState(
    checkPermission(permissions?.documentCenter?.addPhotoCategory)
  );

  const [editPhotoCategory] = React.useState(
    !checkPermission(permissions?.documentCenter?.editPhotoCategory)
  );

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const classes = useStyles();

  const { setAlert } = useAlerts();

  const tabPhotosAddPermission = checkPermission(
    permissions.viewEditProject.tabPhotosAdd
  );

  const [storeType] = useState('NEEDS_REVIEW');
  const [showHoverButton, setShowHoverButton] = useState(null);

  const [toolTipDocument, setToolTipDocument] = useState(null);
  const [preSignedUrl, setPreSignedUrl] = useState();
  const [completeButtonDisable, setCompleteButtonDisable] = useState(false);
  const [
    completeDisableforOrderValidation,
    setCompleteDisableforOrderValidation,
  ] = useState(false);

  const [uploadContextValue, setUploadContextValue] = useState({
    show: false,
    permissions: {
      installerVisible: installerVisible,
      reviewPermission: reviewPermission,
      addCategoryPermission: addPhotoCategoryPermission,
      editCategoryPermission: editPhotoCategory,
    },

    document_type_id: 3,
    onShow: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: true,
      }));
    },
    onHide: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        show: false,
      }));
      setCompleteButtonDisable(false);
      uploadContextValue?.resetContext();
    },
    data: {
      document_store_type: 'INTERNAL',
      is_installer_visibility: 0,
      is_proj_docu_visibility: 0,
      is_customer_visible: 0,
      reviewed_status: 0,
    },
    handleChange: data => {
      setUploadContextValue(preValue => ({
        ...preValue,
        data: { ...preValue?.data, ...data },
      }));
    },
    resetContext: () => {
      setUploadContextValue(preValue => ({
        ...preValue,
        req_from: '',
        data: {
          document_store_type: 'INTERNAL',
          is_installer_visibility: 0,
          is_proj_docu_visibility: 0,
          is_customer_visible: 0,
          reviewed_status: 0,
        },
      }));
    },
  });

  const documentPhotoTypeId = DOCUMENT_PHOTO_TYPE?.PHOTO;

  React.useEffect(() => {
    if (tabPhotosAddPermission) {
      setPhotoAddPermission(true);
    }
  }, []);

  React.useEffect(() => {
    async function getListOfDocumentsData(projectId) {
      await getListOfDocuments(
        projectId,
        setDocuments,
        setLoading,
        documentPhotoTypeId
      );
    }
    getDocumentTypes(setdocumentTypes);
    getRelatedPOData(projectId, setLoading, setRelatedPOResponse);
    getListOfDocumentsData(projectId);
    if (selectedJob?.project_id) {
      handleJobSelection(selectedJob);
    }
  }, [reloadList]);

  useEffect(() => {
    if (documents) {
      setPhotoCount(documents?.filter(doc => doc.documetTypeId == 3).length);
    }
    if (photoCount >= orderConfigData?.count) {
      setCompleteDisableforOrderValidation(false);
    } else {
      setCompleteDisableforOrderValidation(true);
    }
  }, [documents, photoCount, orderConfigData?.count]);
  const handleUpload = (files, documentTypeId) => {
    setReloadList(false);
    setLoading(false);
    setPhotosLoader(true);
    const uploadObj = {
      file: [],
      document_type_id: documentTypeId,
      ...uploadContextValue?.data,
    };
    Object.values(files).forEach(file => {
      uploadObj?.file?.push({ file: file, document_name: file?.name });
    });
    try {
      upLoadDocumentOrPhoto(
        projectId,
        uploadObj,
        setLoading,
        setAlert,
        setReloadList,
        setReloadForm,
        setPhotosLoader
      ).then(() => {
        uploadContextValue?.onHide();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleJobSelection = project => {
    getRelatedDocuments(
      project.project_id,
      setRelatedDocuments,
      setRelatedDocloading,
      documentPhotoTypeId
    );
    setSelectedJob(project);
    setRelatedDocsPayload([]);
  };

  const applyVisibilityDocuments = () => {
    setReloadList(false);
    let queryParam = 'visibilityAndOffline';
    if (relatedProjectDocsOfflineMode) {
      queryParam = 'relatedProjectDocuments';
    }
    if (relatedDocsPayload?.length > 0) {
      applyInstallerVisibilityDocuments(
        projectId,
        queryParam,
        relatedProjectDocsOfflineMode ? relatedDocsPayload : docsPayload,
        setLoading,
        setAlert,
        setReloadList,
        tabPhotosAddPermission
      );
    }
  };

  const handleOfflineAvailability = document_id => {
    let relOfflineDocs = _.cloneDeep(relatedOfflineDocuments);
    let relDocumentsPayload = _.cloneDeep(relatedDocsPayload);

    if (
      relOfflineDocs.filter(doc => doc.documentId == document_id).length > 0
    ) {
      relOfflineDocs.filter(
        doc => doc?.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine = !relOfflineDocs.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine;
    }
    if (
      relDocumentsPayload.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      relOfflineDocs.filter(doc => doc.documentId == document_id).length > 0
    ) {
      relDocumentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine = !relDocumentsPayload.filter(
        doc => doc.documentId == document_id
      )[0].isRelatedProjectDoucmentOffine;
    } else if (
      relDocumentsPayload.filter(doc => doc.documentId == document_id)?.length >
        0 &&
      relOfflineDocs.filter(doc => doc.documentId != document_id).length > 0
    ) {
      relDocumentsPayload.splice(
        relDocumentsPayload.findIndex(doc => doc.documentId == document_id),
        1
      );
    } else {
      relDocumentsPayload.push({
        documentId: document_id,
        isRelatedProjectDoucmentOffine: relOfflineDocs.filter(
          doc => doc.documentId == document_id
        )[0].isRelatedProjectDoucmentOffine,
      });
    }
    setRelatedProjectDocsOfflineMode(true);
    setRelatedDocsPayload(relDocumentsPayload);
    setRelatedOfflineDocuments(relOfflineDocs);
  };

  React.useEffect(() => {
    setRelatedOfflineDocuments(
      relatedDocuments
        ?.filter(doc => doc.documetTypeId === 3)
        ?.map(document => {
          return {
            documentId: document.documentId,
            isRelatedProjectDoucmentOffine: document.is_rel_proj_docu_visibility
              ? true
              : false,
          };
        })
    );
  }, [relatedDocuments, reloadList]);

  const getDocStoreTypeName = type => {
    let response = null;
    if (type === 'INTERNAL') {
      response = 'Internal Photo';
    } else if (type === 'SOURCE') {
      response = 'Send to Source';
    } else if (type === 'COMPLETE') {
      response = 'Complete';
    } else if (type === 'NEEDS_REVIEW') {
      response = 'Needs Review';
    }
    return response;
  };

  const handleDocUpload = (projectId, documentId, documentName, type) => {
    setReloadList(false);
    updateDocumentById(
      projectId,
      documentId,
      documentName,
      type,
      documents,
      'Photo',
      setDocuments,
      setLoading,
      setAlert,
      setReloadForm
    ).then(() => {
      setReloadList(true);
    });
  };

  const handleTooltipContent = value => {
    const truncatedCreatedBy =
      value?.docCreatedBy?.length > 150
        ? `${value?.docCreatedBy?.split(' ')?.slice(0, 15)?.join(' ')} |||`
        : value?.docCreatedBy;

    const content = `${truncatedCreatedBy} (${value?.docCreatedDate})`;

    return content;
  };

  const handleDeletePhoto = async (projectId, id) => {
    try {
      await deleteProjectDocument(projectId, id, 'photo');
      return true;
    } catch (ex) {
    } finally {
      setReloadList(prev => !prev);
    }
  };
  const [isSendPermission, isCompletePermission, isPhotoDeletePermission] =
    useMemo(
      () => [
        checkPermission(permissions?.documentCenter?.sendToSourcePhoto),
        checkPermission(permissions?.documentCenter?.completePhoto),
        checkPermission(permissions?.documentCenter?.deletePhoto),
      ],
      []
    );

  const [selectBulkUpload, setSelectBulkUpload] = useState({});

  const handleSelectBulkUploadDoc = (event, documentId) => {
    const idsObject = [];
    documents?.forEach(item => {
      if (item?.documetTypeId === 3) {
        idsObject?.push(item?.documentId);
        return true;
      }
    });
    setSelectBulkUpload(prevState => {
      if (event.target.checked) {
        return {
          ...prevState,
          copiedIds: [
            ...new Set([...(prevState?.copiedIds || []), documentId]),
          ],
          count: idsObject?.length || 0,
        };
      } else {
        return {
          ...prevState,
          copiedIds:
            prevState?.copiedIds?.filter(id => id !== documentId) || [],
          count: idsObject?.length || 0,
        };
      }
    });
  };

  const handleSelectAllBulkUpload = event => {
    const idsObject = [];
    documents?.forEach(item => {
      if (item?.documetTypeId === 3) {
        idsObject?.push(item?.documentId);
        return true;
      }
    });

    if (event.target.checked) {
      setSelectBulkUpload(prevState => ({
        ...prevState,
        copiedIds: idsObject,
        count: idsObject?.length || 0,
      }));
    } else {
      setSelectBulkUpload(prevState => ({
        ...prevState,
        copiedIds: [],
        count: idsObject?.length || 0,
      }));
    }
  };

  const bulkUpdateCall = () => {
    setReloadList(false);
    let payload = {
      ...uploadContextValue?.data,
      document_category_id:
        uploadContextValue?.data?.document_category_id?.document_category_id ||
        null,
    };
    if (uploadContextValue?.data?.document_store_type) {
      payload.document_store_type =
        uploadContextValue?.data?.document_store_type || null;
    } else {
      delete payload.document_store_type;
    }
    payload = { document_ids: selectBulkUpload?.copiedIds || [], ...payload };

    bulkUpdateService(projectId, payload)
      .then(res => {
        if (res?.status) {
          setSelectBulkUpload({});
          uploadContextValue?.onHide();
          setReloadList(true);
        }
      })
      .catch(err => {
        setAlert('error', err || '', false, true);
      });
  };

  const [reviewIds, setReviewIds] = useState([]);
  const reviewedCall = () => {
    setReloadList(false);
    const payload = { document_ids: reviewIds || [], reviewed_status: 1 };

    bulkUpdateService(projectId, payload)
      .then(res => {
        if (res?.status) {
          setReviewIds([]);
          setReloadList(true);
        }
      })
      .catch(err => {
        setAlert('error', err || '', false, true);
      });
  };

  const handleReviewedPhoto = documentId => {
    if (documentId) {
      setReviewIds(prevState => {
        if (!prevState?.includes(documentId)) {
          return [...new Set([...(prevState || []), documentId])];
        } else {
          return prevState?.filter(id => id !== documentId) || [];
        }
      });
    }
  };

  const applyOnClick = () => {
    reviewedCall();
  };

  const handlePreview = async imageUrl => {
    await getPreSignedUrl(() => null, setPreSignedUrl, imageUrl);
    setIsDialogVisible(true);
  };

  const showIsLeadRequiredAlertLowes = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.project_lead_response_reason === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showIsLeadRequiredAlertHD = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.epa_override_reason_code_id === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <ConfirmDialog />

      <UploadActionContext.Provider
        value={{ uploadContextValue, setUploadContextValue }}
      >
        <UploadStoreTypeDialog
          type={'Photo'}
          docInputRef={imageInputRef}
          sourceSystemId={formik?.values?.source_system?.source_system_id}
          bulkUpdateHandler={bulkUpdateCall}
          projectDetailsData={projectDetailsData}
          setCompleteButtonDisable={setCompleteButtonDisable}
          completeButtonDisable={completeButtonDisable}
          clientDetails={clientDetails}
          photoCount={photoCount}
          orderConfigData={orderConfigData}
          completeDisableforOrderValidation={completeDisableforOrderValidation}
          setCompleteDisableforOrderValidation={
            setCompleteDisableforOrderValidation
          }
        />
      </UploadActionContext.Provider>
      <Grid container spacing={4} direction="column">
        <Grid item spacing={2} xs={12}>
          <Paper classes={{ root: classes.gridPaperPhoto }}>
            <Grid item xs={12} lg={12} md={12} sm={12} className="mb-2">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                classes={{ root: classes.titleHeaderGrid }}
              >
                <Grid item>
                  <div className="flex justify-content-center pt-3">
                    <MUITooltip title="Select All For Bulk Update">
                      <Checkbox
                        onChange={event => handleSelectAllBulkUpload(event)}
                        checked={
                          selectBulkUpload?.count > 0 &&
                          selectBulkUpload?.count ==
                            selectBulkUpload?.copiedIds?.length
                        }
                      />
                    </MUITooltip>
                    <h3>Photos</h3>
                  </div>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      uploadContextValue?.onShow();
                      setUploadContextValue(preValue => ({
                        ...preValue,
                        req_from: 'bulk_upload',
                      }));
                    }}
                    disabled={
                      (selectBulkUpload?.copiedIds || [])?.length > 0
                        ? false
                        : true
                    }
                  >
                    Bulk Update
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="ml-2"
                    onClick={applyOnClick}
                    disabled={(reviewIds || [])?.length > 0 ? false : true}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="column" className="overflow-auto mt-2">
              <Grid item classes={{ root: classes.docsContainer }}>
                {getPhotoAddPermission && (
                  <div className="mr-4">
                    {photosLoader && (
                      <div className={classes.spinnerContainer}>
                        <ProgressSpinner />
                      </div>
                    )}
                    <Grid className={`${classes.photoUploadLabel}`}>
                      <input
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        multiple={storeType !== 'COMPLETE'}
                        onClick={event => {
                          event.target.value = null;
                        }}
                        ref={imageInputRef}
                        onChange={event => {
                          const files = event.target.files;
                          let isValid = true;
                          let errorMessage = '';

                          if (files?.length > 5) {
                            isValid = false;
                            errorMessage = 'Please select up to 5 files only.';
                          } else {
                            for (let i = 0; i < files.length; i++) {
                              const file = files[i];
                              if (file?.type?.split('/')?.[0] !== 'image') {
                                isValid = false;
                                errorMessage = 'Only image files are allowed.';
                                break;
                              } else if (
                                ['svg'].includes(file?.name?.split('.')?.pop())
                              ) {
                                isValid = false;
                                errorMessage =
                                  'System does not accept SVG files.';
                                break;
                              }
                            }
                          }

                          if (isValid) {
                            handleUpload(files, 3);
                            setPhotoError(null);
                          } else {
                            uploadContextValue?.onHide();
                            setPhotoError(errorMessage);
                            imageInputRef.current.value = '';
                          }
                        }}
                      />
                      <label onClick={uploadContextValue?.onShow}>
                        <Avatar
                          variant="square"
                          className={`w-12rem h-15rem bg-white border-dotted border-300 cursor-pointer border-2 mt-6`}
                        >
                          <AddIcon
                            fontSize="large"
                            className={`${classes.plusIcon}`}
                          />
                        </Avatar>
                      </label>
                      {photoError && (
                        <p className="ml-2 text-red-600">{photoError}</p>
                      )}
                    </Grid>
                  </div>
                )}
                {loading ? (
                  <DocPhotosLoader numberOfSkeletons={6} height="322px" />
                ) : (
                  documents &&
                  documents.filter(doc => doc.documetTypeId == 3).length > 0 &&
                  documents
                    .filter(doc => doc.documetTypeId == 3)
                    .map(document => (
                      <Card
                        className={`grid grid-nogutter mr-3 surface-overlay border-1 ${
                          epaDocumentsStyle(document, projectLeadRequired) ||
                          'border-gray-200'
                        } `}
                      >
                        <div className="grid w-14rem grid-nogutter">
                          <div className="col-12 col-md-12 col-lg-12 p-0">
                            <div className="grid grid-nogutter">
                              <div className="col-2 col-md-2 col-lg-2 p-0 m-0">
                                <div className="h-2rem">
                                  <div className="col-12 col-md-12 col-lg-12">
                                    <MUITooltip title={'Bulk Update'}>
                                      <Grid>
                                        <Checkbox
                                          color="secondary"
                                          onChange={(event, value) => {
                                            handleSelectBulkUploadDoc(
                                              event,
                                              document?.documentId
                                            );
                                          }}
                                          checked={
                                            selectBulkUpload?.copiedIds?.includes(
                                              document?.documentId
                                            ) || false
                                          }
                                        />
                                      </Grid>
                                    </MUITooltip>
                                  </div>
                                </div>
                                <div className="h-2rem">
                                  <div className="col-12 col-md-12 col-lg-12">
                                    <MUITooltip title="Expand">
                                      <div className="py-1 ml-3">
                                        <img
                                          src={expandOffIcon}
                                          className="cursor-pointer -ml-2"
                                          alt="Technician"
                                          width={13}
                                          height={13}
                                          onClick={() =>
                                            handlePreview(document?.documentUrl)
                                          }
                                        />
                                      </div>
                                    </MUITooltip>
                                  </div>
                                </div>
                                {reviewPermission && (
                                  <div className="grid grid-nogutter">
                                    <div className="h-2rem">
                                      <div className="col-12 col-md-12 col-lg-12">
                                        <MUITooltip
                                          title={
                                            <>
                                              {document?.reviewedBy
                                                ?.first_name ? (
                                                <>
                                                  <div>
                                                    <strong>
                                                      Reviewed By:{' '}
                                                    </strong>
                                                    {`${document?.reviewedBy?.first_name || ''} ${document?.reviewedBy?.last_name || ''}`}
                                                  </div>
                                                  <div>
                                                    <strong>
                                                      Reviewed At:{' '}
                                                    </strong>
                                                    {`${document?.reviewedAt ? formatDateTimeMDYHM(document?.reviewedAt) : ''}`}
                                                  </div>
                                                </>
                                              ) : (
                                                'Mark as Reviewed'
                                              )}
                                            </>
                                          }
                                        >
                                          <div>
                                            {reviewIds?.includes(
                                              document?.documentId
                                            ) ||
                                            document?.reviewedBy?.first_name ? (
                                              <img
                                                src={eyeOnIcon}
                                                className="ml-1 cursor-pointer"
                                                alt="Reviewed"
                                                width={18}
                                                height={18}
                                                onClick={() =>
                                                  !document?.reviewedBy
                                                    ?.first_name
                                                    ? handleReviewedPhoto(
                                                        document?.documentId
                                                      )
                                                    : {}
                                                }
                                              />
                                            ) : (
                                              <img
                                                src={eyeOffIcon}
                                                className="ml-1 cursor-pointer"
                                                alt="Reviewed"
                                                width={18}
                                                height={18}
                                                onClick={() =>
                                                  handleReviewedPhoto(
                                                    document?.documentId
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        </MUITooltip>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {isPhotoDeletePermission && (
                                  <div className="h-2rem">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="flex justify-content-center">
                                        <img
                                          src={deleteOffIcon}
                                          className="mt-1 ml-1 cursor-pointer"
                                          alt="Reviewed"
                                          width={16}
                                          height={16}
                                          onClick={() => {
                                            confirmDialog({
                                              message: `This action would delete this attachment and cannot be rolled back. Would you like to continue?`,
                                              header: 'Confirmation',
                                              icon: 'pi pi-exclamation-triangle',
                                              accept: () =>
                                                handleDeletePhoto(
                                                  document?.projectId,
                                                  document?.documentId
                                                ),
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <PFDialog
                                  show={isDialogVisible}
                                  style={{
                                    width: '50vw',
                                    height: '70vh',
                                  }}
                                  onHide={() => setIsDialogVisible(false)}
                                  header="Image Preview"
                                  BodyComponent={
                                    <div className="h-full">
                                      {preSignedUrl && (
                                        <Image
                                          alt="Preview Image"
                                          src={preSignedUrl}
                                          imageStyle={{
                                            verticalAlign: 'middle',
                                            margin: '0 8px 0 0',
                                            width: '100%',
                                            height: '100%',
                                            padding: '0px',
                                          }}
                                          preview={false}
                                        />
                                      )}
                                    </div>
                                  }
                                ></PFDialog>
                              </div>

                              <div className="col-10 col-md-10 col-lg-10 p-0 m-0">
                                <div className="grid grid-nogutter">
                                  <Card>
                                    <div className="col-12 col-md-12 col-lg-12 p-0">
                                      {document?.documentStoreType ? (
                                        <>
                                          <div className="grid p-1 grid-nogutter flex flex-wrap">
                                            <div className="col-12 p-0 flex align-items-center justify-content-center">
                                              <div className="font-bold text-xs ">
                                                Status
                                              </div>
                                            </div>
                                            <div className="col-12 p-0 flex align-items-center justify-content-center">
                                              <div className="font-normal text-xs">{`${getDocStoreTypeName(document?.documentStoreType)}`}</div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="grid p-1 grid-nogutter flex flex-wrap">
                                          <div className="col-12 p-0 flex align-items-center justify-content-center">
                                            <div className="font-bold text-xs ">
                                              Status
                                            </div>
                                          </div>
                                          <div className="col-12 p-0 flex align-items-center justify-content-center">
                                            <div className="font-normal text-xs">{`-`}</div>
                                          </div>
                                        </div>
                                      )}

                                      <>
                                        {document?.category && (
                                          <div className="flex justify-content-center">
                                            <hr className="w-5rem mb-0" />
                                          </div>
                                        )}
                                        {document?.category ? (
                                          <>
                                            <div className="grid p-1 grid-nogutter flex flex-wrap">
                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-bold text-xs">
                                                  Category
                                                </div>
                                              </div>
                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-normal text-xs">{` ${document?.category?.category_name}`}</div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="flex justify-content-center">
                                              <hr className="w-5rem mb-0" />
                                            </div>
                                            <div className="grid p-1 grid-nogutter flex flex-wrap">
                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-bold text-xs">
                                                  Category
                                                </div>
                                              </div>

                                              <div className="col-12 p-0 flex align-items-center justify-content-center">
                                                <div className="font-normal text-xs">{`-`}</div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 p-0">
                                      <Grid item className="mt-1 grid-nogutter">
                                        <>
                                          <Tooltip
                                            target=".photoToolTipCard"
                                            position="bottom"
                                            content={handleTooltipContent(
                                              toolTipDocument
                                            )}
                                          />
                                          <CardActionArea
                                            onMouseEnter={e => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setShowHoverButton(
                                                document?.documentId
                                              );
                                              setToolTipDocument(document);
                                            }}
                                            onMouseLeave={e => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setShowHoverButton(null);
                                            }}
                                            className="photoToolTipCard"
                                          >
                                            {showHoverButton ===
                                              document?.documentId && (
                                              <>
                                                {isSendPermission && (
                                                  <Button
                                                    classes={{
                                                      root: classes.sendToSourcePhotoButton,
                                                    }}
                                                    color="primary"
                                                    variant="contained"
                                                    className="px-2 ml-2"
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      handleDocUpload(
                                                        document?.projectId,
                                                        document?.documentId,
                                                        document?.documentName,
                                                        'SOURCE'
                                                      );
                                                    }}
                                                  >
                                                    Send To Source
                                                  </Button>
                                                )}
                                                {isCompletePermission && (
                                                  <Button
                                                    classes={{
                                                      root: classes.completePhotoButton,
                                                    }}
                                                    color="primary"
                                                    variant="contained"
                                                    className="px-2 mt-2 ml-2"
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      if (
                                                        clientDetails?.epa_guardrails_workflow ===
                                                        1
                                                      ) {
                                                        const isLeadRequiredShowAlert =
                                                          projectDetailsData.source_system_id ===
                                                          2
                                                            ? showIsLeadRequiredAlertHD()
                                                            : showIsLeadRequiredAlertLowes();
                                                        if (
                                                          isLeadRequiredShowAlert
                                                        ) {
                                                          setOpenPopup(true);
                                                          return;
                                                        }
                                                      }
                                                      if (
                                                        clientDetails
                                                          ?.order_completion_validation
                                                          ?.enabled === 1 &&
                                                        orderConfigData &&
                                                        orderConfigData?.count >
                                                          photoCount
                                                      ) {
                                                        setPhotoAlert(true);
                                                        setConfirmDialog({
                                                          header: 'Alert',
                                                          title: `You must upload at least ${orderConfigData?.count} photo(s) to complete this job.`,
                                                          isOpen: true,
                                                          button2Text: 'Ok',
                                                        });
                                                      } else {
                                                        handleDocUpload(
                                                          document?.projectId,
                                                          document?.documentId,
                                                          document?.documentName,
                                                          'COMPLETE'
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    Complete
                                                  </Button>
                                                )}
                                              </>
                                            )}

                                            <CardContent
                                              classes={{
                                                root: classes.docDetails,
                                              }}
                                            >
                                              <Image
                                                alt={document?.documentName}
                                                src={document?.documentUrl}
                                                imageStyle={{
                                                  verticalAlign: 'middle',
                                                  margin: '0 8px 0 0',
                                                }}
                                                onClick={() => {
                                                  setPhotoDetailsToRotate({
                                                    documentStoreType:
                                                      document?.documentStoreType,
                                                    documentUrl:
                                                      document?.documentUrl,
                                                    documentName:
                                                      document?.documentName,
                                                    documentId:
                                                      document?.documentId,
                                                    projectId:
                                                      document?.projectId,
                                                  });
                                                }}
                                                imageClassName={`${classes.photo} ${classes.avatarImg}`}
                                              />
                                            </CardContent>
                                          </CardActionArea>
                                        </>
                                      </Grid>
                                    </div>
                                  </Card>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ))
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {checkPermission(
          permissions?.viewEditProject?.relatedMeasurementPhotos
        ) && (
          <Grid item spacing={2} xs={12}>
            <Paper classes={{ root: classes.gridPaper }}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    classes={{ root: classes.titleHeaderGrid }}
                  >
                    <Grid item container justifyContent="space-between">
                      <Grid item xs={8} lg={8}>
                        <Typography variant="h3">
                          Related Measurement Photos
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        lg={4}
                        className="flex justify-content-end"
                      >
                        <Autocomplete
                          style={{
                            width: '200px',
                          }}
                          disableClearable
                          id="combo-box-demo"
                          options={relatedPOResponse?.items || []}
                          getOptionLabel={option =>
                            option.project_number + ' ' + option.project_type
                          }
                          openOnFocus={true}
                          value={
                            relatedPOResponse?.items?.filter(
                              rel => rel.project_id === selectedJob?.project_id
                            )[0]
                          }
                          onChange={(event, value) => handleJobSelection(value)}
                          renderInput={params => (
                            <TextField {...params} label="Select Job" />
                          )}
                        />
                        {relatedDocuments?.length > 0 &&
                          installerVisible &&
                          selectedJob?.project_type == 'Measurement' && (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={applyVisibilityDocuments}
                            >
                              Apply
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {relatedDocloading || loading ? (
                  <DocPhotosLoader numberOfSkeletons={6} />
                ) : (
                  <div className="w-full p-3">
                    <Grid
                      item
                      direction="row"
                      wrap="nowrap"
                      className="m-0 p-1 overflow-auto relative h-15rem mt-2 flex"
                    >
                      {relatedDocuments &&
                      relatedDocuments.filter(doc => doc.documetTypeId === 3)
                        .length > 0 ? (
                        relatedDocuments
                          .filter(doc => doc.documetTypeId === 3)
                          .map(document => (
                            <RelatedMeasurementPhotosCard
                              key={document.documentId}
                              docDetails={document}
                              handleOfflineAvailability={
                                handleOfflineAvailability
                              }
                              relatedOfflineDocumentsMode={true}
                              offlineDocuments={relatedOfflineDocuments}
                              installerVisible={installerVisible}
                              selectedJob={selectedJob}
                              handlePreview={handlePreview}
                            />
                          ))
                      ) : (
                        <Grid container justifyContent="center">
                          <span className={classes.emptyText}>
                            No Measurement Photos found!
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
      {photoDetailsToRotate && (
        <RotatePhotoComponent
          photoDetails={photoDetailsToRotate}
          setPhotoDetailsToRotate={setPhotoDetailsToRotate}
          setReloadList={setReloadList}
        />
      )}
    </>
  );
};

export default Photos;
